export const GET_LIST_ADVERTISEMENT_MERCHANT = 'GET_LIST_ADVERTISEMENT_MERCHANT'
export const GET_LIST_ADVERTISEMENT_MERCHANT_ERR = 'GET_LIST_ADVERTISEMENT_MERCHANT_ERR'

export const CREATE_ADVERTISEMENT_MERCHANT = 'CREATE_ADVERTISEMENT_MERCHANT'
export const CREATE_ADVERTISEMENT_MERCHANT_ERR = 'CREATE_ADVERTISEMENT_MERCHANT_ERR' 

export const UPDATE_ADVERTISEMENT_MERCHANT = 'UPDATE_ADVERTISEMENT_MERCHANT'
export const UPDATE_ADVERTISEMENT_MERCHANT_ERR = 'UPDATE_ADVERTISEMENT_MERCHANT_ERR'

export const DELETE_ADVERTISEMENT_MERCHANT = 'DELETE_ADVERTISEMENT_MERCHANT'
export const DELETE_ADVERTISEMENT_MERCHANT_ERR = 'DELETE_ADVERTISEMENT_MERCHANT_ERR'

export const GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE = 'GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE'
export const GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE_ERR = 'GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE_ERR'

export const GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL = 'GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL'
export const GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL_ERR = 'GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL_ERR'

export const RESET_LIST_ADVERTISEMENT_MERCHANT = 'RESET_LIST_ADVERTISEMENT_MERCHANT'
