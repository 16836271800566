import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

//component
import IcClose from '../../../../../../../../assets/images/IcClose'
import IcCreateAdvertisement from '../../../../../../../../assets/images/IcCreateAdvertisement'
import Button from '../../../../../../../Control/Button'
import UploadImageAdvertisement from './UploadImageAdvertisement'

//help
import bytesToSize from '../../../../../../../../helper/convertBytesToSize'
import fileToBase64 from '../../../../../../../../helper/fileToBase64'

//hook
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'

// styles
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapCreateNewAdvertisement } from './index.styles'

//action
import { createAdvertisementMerchant, getListAdvertisementMerchant } from '../../../../../../../../redux/merchant/detailsMerchant/advertisement/action'

const textImage = "hình ảnh"

const CreateNewAdvertisement = ({ setIsShowModalCreateAdvertisement, onClickIconClose}) => {
    const { merchantId } = useParams()
    const dispatch = useDispatch() 
    const refNewAdvertisement = useRef(null)

    const [advertisementImg, setAdvertisementImg] = useState(null);
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            merchantId: merchantId,
        }
    )

    const {condFilters} = useSelector(state => ({
        condFilters: state.merchantReducer.detailsMerchantReducer.advertisement.condFilters
    }))

    const callbackSuccess = () =>{
        onClickIconClose && onClickIconClose()
        dispatch(getListAdvertisementMerchant({ ...condFilters }))
    }

    const _handleSubmit = async () =>{
        const base64 = await fileToBase64(advertisementImg)
        const { name, size } = advertisementImg
        // const imageSize = bytesToSize(size,2)
        const dataRequest = {
            name,
            size : size + "",
            merchantId: stateForm.merchantId,
            format: textImage,
            file: base64.split(',')[1]
        }
        dispatch(createAdvertisementMerchant(dataRequest, callbackSuccess))
    }

    useOnClickOutside(refNewAdvertisement, () => setIsShowModalCreateAdvertisement(false))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapCreateNewAdvertisement ref={refNewAdvertisement}>
                    <IcClose className="icon-close" onClick={onClickIconClose}/>    
                    <p className="title">Tạo mới quảng cáo</p>
                    <div className="image">
                        <UploadImageAdvertisement 
                            file={advertisementImg}
                            handleRemoveFile={() => setAdvertisementImg(null)}
                            handleDropFiles={files => setAdvertisementImg(files[0])}
                        />
                    </div>
                    {
                        advertisementImg &&
                        <>  
                            <div className="description">
                                <p className="desc-img" title={advertisementImg.name}>{advertisementImg.name}</p>
                                <p className="desc-size">{bytesToSize(advertisementImg.size)}</p>
                            </div>
                            <Button
                                text="Hoàn tất"
                                iconBtn={<IcCreateAdvertisement />}
                                className="btn-creteNew"
                                onClick={_handleSubmit}
                            />
                        </>
                    }
            </WrapCreateNewAdvertisement>
        </div>
    </OverlayFullScreen>
    )
}

export default CreateNewAdvertisement
