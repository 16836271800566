import React, { useState, useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sha256 } from 'js-sha256';

//styles
import { WrapLogin } from './index.styles'

//component
import Input from '../../Control/Input';
import InputPassword from '../../Control/InputPassword';
import Button from '../../Control/Button';

// action
import { loginActicon } from '../../../redux/login/action';

// constant
import { MAIN } from '../../../constant/path';

//image
import IcInputUser from '../../../assets/images/IcInputUser';
import IcInputUserActive from '../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../assets/images/IcInputUserError'
import IcInputLock from '../../../assets/images/IcInputLock'
import IcInputLockError from '../../../assets/images/IcInputLockError'
import IcInputLockActive from '../../../assets/images/IcInputLockActive'
import IcButtonLogin from '../../../assets/images/IcButtonLogin';
import LogoLogin from '../../../assets/images/LogoLogin';
import bg_terminal from '../../../assets/images/bg_terminal.png'
import LogoCraftedWith from '../../../assets/images/LogoCraftedWith';

const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}
const iconPassword = {
    icon: IcInputLock,
    iconActive: IcInputLockActive,
    iconError: IcInputLockError,
}

const BankLogin = () => {

    const history = useHistory();
    const dispatch = useDispatch()

    const { loggedIn } = useSelector(state => ({
        loggedIn: state.loginReducer.loggedIn
    }))

    const [textErrorForm, setTextErrorForm] = useState("")
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            username: "",
            pwd: "",
        }
    )

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const SubmitFormSuccess = () => {
        // console.log("success")
        setTextErrorForm("")
    }

    const SubmitFormError = () => {
        // console.log("error")
        setTextErrorForm("Tài khoản hoặc Password không đúng")
    }

    const submitForm = (e) => {
        e.preventDefault();

        setStateForm({
            username: stateForm.username,
            pwd: stateForm.pwd,
        })
        const dataLogin = {
            username: stateForm.username,
            pwd: sha256(stateForm.pwd),
        };
        dispatch(loginActicon(history, dataLogin, SubmitFormSuccess, SubmitFormError))
    }

    useEffect(() => {
        if (!loggedIn) return;
        history.push(`/${MAIN}`)

    }, [loggedIn]);

    const _handleValidationUser = (value, callback) => {
        if (!value.trim() || 0 === value.length) {
            callback("Tài khoản không được để trống")
            return
        }
        if (value.indexOf(' ') >= 0) {
            callback("Tài khoản không chứa khoản trắng")
            return
        }
    }

    const _handleValidationUPassword = (value, callback) => {
        if (value.length === 0) {
            callback("Mật khẩu không được để trống")
        }
    }


    useEffect(() => {
        if (!loggedIn) return;
        history.push(`/${MAIN}`)

    }, [loggedIn]);

    return (
        <WrapLogin>
            <div className="bg_terminal">
                <img src={bg_terminal} alt="ic_terminal" />
                <div className="wrap-inner-login">
                    <div className="login-form">
                        <LogoLogin className="icon-logo" />
                        <h2 className="title">No Card - No Cash - No Phone</h2>
                        <p className="note">Login your account</p>
                        <form className="form" onSubmit={submitForm} encType="multipart/form-data">
                            <Input
                                heightInput="40px"
                                placeholder="Username"
                                inputType="TEXT"
                                name="username"
                                value={stateForm.username}
                                required
                                onChange={handleChangeValue}
                                iconInput={iconUser}
                                handleValidationUser={_handleValidationUser}
                            />
                            <InputPassword
                                heightInput="40px"
                                placeholder="Password"
                                type="password"
                                inputType="PWD"
                                name="pwd"
                                required
                                value={stateForm.pwd}
                                onChange={handleChangeValue}
                                iconInput={iconPassword}
                                handleValidationUPassword={_handleValidationUPassword}
                            />
                            <div className="error">{textErrorForm}</div>
                            <Button
                                type="submit"
                                text="Login"
                                iconBtn={<IcButtonLogin />}
                                className="btn-login"
                            />
                        </form>
                    </div>
                </div>
                <LogoCraftedWith className="logo-crafted-with" />
            </div>
        </WrapLogin>
    )
}

export default BankLogin
