import styled from 'styled-components'

// styles
import { WrapPopupDel } from '../../../../MerchantList/MerchantItem/OptionMerchant/PopupDelMerchant/index.styles';

const WrapPopupConfirmRefund = styled(WrapPopupDel)`
    width: 238px;

    .sub-title {
        margin-top: 10px;
    }

    .btn-del {
        margin-top: 30px;
    }

    .btn-cancel {
        margin-top: 10px;
    }
`;

export {
    WrapPopupConfirmRefund
}