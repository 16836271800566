import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// component
import IcArrowDownGray from '../../../../../assets/images/IcArrowDownGray'
import DropdownSelectTypeSearch, { dataSelection, KEY_BANK_ID, KEY_FACEPAY_ID, KEY_MERCHANT_ID, KEY_TIME_SELECTION } from './DropdownSelectTypeSearch'
import DatePickerItem from './DatePickerItem'
import FilterControl from '../../../../Control/FilterControl'
import { InputFilter } from '../../../../Control/FilterControl/index.styles'

// styles
import { WrapDateRange } from './index.styles'

//action
import { getListTransaction, 
    getTransSearchBankId, 
    getTransSearchFacepayId, 
    getTransSearchInterval, 
    getTransSearchMerchantId,
    resetListTransaction
 } from '../../../../../redux/transaction/listTransaction/action'
import { listTransactions } from '../../../../../mockData/listTransactions'
// type
import { GET_LIST_TRANSACTION, GET_TRANSACTION_SEARCH_BANKTX_ID, GET_TRANSACTION_SEARCH_FACEPAYTX_ID, GET_TRANSACTION_SEARCH_INTERVAL, GET_TRANSACTION_SEARCH_MERCHANTTX_ID } from '../../../../../redux/transaction/listTransaction/types'
import { BANK_ID, FACEPAY_ID, INTERVAL, MERCHANT_ID } from '../../../../../constant/keys'

const filterSize = 20

const SelectDateRange = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) => {

    const _handleFilterStartTime = (time) => {
        if (!endDate) return true
        const selectedDate = new Date(time);
        return selectedDate.getTime() <= endDate.getTime()
    }

    const _handleFilterEndTime = (time) => {
        if(!startDate) return true
        const selectedDate = new Date(time);
        return selectedDate.getTime() >= startDate.getTime()
    }

    return <WrapDateRange>
        <DatePickerItem
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate ? endDate : new Date()}
            filterTime={_handleFilterStartTime}
            textEmpty="Từ ngày"
        />
        <IcArrowDownGray className="arrow-right" />
        <DatePickerItem
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            filterTime={_handleFilterEndTime}
            textEmpty="Tới ngày"
        />
    </WrapDateRange>
}

const FilterTransaction = () => {

    const dispatch = useDispatch()
    const inputSearchRef = useRef(null)

    const [filterCond, setFilterCond] = useState(dataSelection[0]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [idSearch, setIdSearch] = useState("");

    const { listTransactions, condFilters } = useSelector(state => ({
        listTransactions: state.transactionReducer.listTransactionReducer,
        // total: state.transactionReducer.listTransactionReducer.total,
        condFilters: state.transactionReducer.listTransactionReducer.condFilters,
    }))

    useEffect(()=>{
        if (!condFilters.keyFilter) return
        setFilterCond(dataSelection?.filter((item,i)=>item.key === condFilters.keyFilter)[0])
    },[condFilters.keyFilter])
    
    useEffect(() => {
        setIdSearch("")
        setStartDate(null)
        setEndDate(null)
        if(inputSearchRef.current) inputSearchRef.current.value = ''
    }, [JSON.stringify(filterCond)]);

    useEffect(() => {
        if(listTransactions.type === GET_LIST_TRANSACTION) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            if(inputSearchRef.current) inputSearchRef.current.value = ''
            return
        }
        if(listTransactions.type === GET_TRANSACTION_SEARCH_MERCHANTTX_ID || listTransactions.type === GET_TRANSACTION_SEARCH_BANKTX_ID || listTransactions.type === GET_TRANSACTION_SEARCH_FACEPAYTX_ID) {
            setIdSearch(condFilters.searchValue.value)
            setStartDate(null)
            setEndDate(null)
            if(inputSearchRef.current) inputSearchRef.current.value = condFilters.searchValue.value
            return
        }
        if(listTransactions.type === GET_TRANSACTION_SEARCH_INTERVAL) {
            setIdSearch("")
            setStartDate(condFilters.searchValue.startTime)
            setEndDate(condFilters.searchValue.closeTime)
            if(inputSearchRef.current) inputSearchRef.current.value = ''
            return
        }
    }, [JSON.stringify(listTransactions)]);

    const _handleSearch = () => {
        const { key } = filterCond
        if(!idSearch && !startDate && !endDate){
            const callbackSuccess = () => dispatch(getListTransaction({
                filterFrom: '',
                filterSkip: 0,
                filterSize,
            }))
            dispatch(resetListTransaction(callbackSuccess))
        }
        switch (key) {
            case MERCHANT_ID: {
                if (!idSearch) break;
                const callbackSuccess = () => dispatch(getTransSearchMerchantId({
                    merchantTrxCode: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                })) 
                dispatch(resetListTransaction(callbackSuccess))
                break;
            }
            case BANK_ID: {
                if (!idSearch) break;
                const callbackSuccess = () => dispatch(getTransSearchBankId({
                    bankTrxCode: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))  
                dispatch(resetListTransaction(callbackSuccess))
                break;
            }
            case FACEPAY_ID: {
                if (!idSearch) break;
                const callbackSuccess = () => dispatch(getTransSearchFacepayId({
                    facepayTrxCode: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                })) 
                dispatch(resetListTransaction(callbackSuccess))
                break;
            }
            case INTERVAL: {
                if (!startDate || !endDate) break;
                const callbackSuccess = () => dispatch(getTransSearchInterval({
                    startTime: startDate,
                    closeTime: endDate,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                dispatch(resetListTransaction(callbackSuccess))
                break;
            }
            default:
                break;
        }
    }

    const dropdownFilter = useMemo(() => (
        <DropdownSelectTypeSearch 
            filterCond={filterCond} 
            setFilterCond={setFilterCond} 
        />
    ), [filterCond, setFilterCond])

    const filterComponent = useMemo(() => {
        const { key, text } = filterCond
        if (key === INTERVAL)
            return <SelectDateRange
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        return <InputFilter
            type="text"
            placeholder={`Nhập ${text}`}
            ref={inputSearchRef}
            onKeyPress={(e) => {
                if(e.key === 'Enter') {
                    _handleSearch()
                }
            }}
            onChange={e => setIdSearch(e.target.value)}
        />
    }, [startDate, endDate, JSON.stringify(filterCond), _handleSearch])

    return (
        <FilterControl 
            dropdownFilter={dropdownFilter}
            filterComponent={filterComponent}
            handleSearch={_handleSearch}
        />
    )
}

export default FilterTransaction
