import styled from 'styled-components'

const SIZE_TOOLTIP = 153

const WrapChart = styled.div`
    margin-top: 30px;
    width: 100%;
    height: 500px;
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    overflow: hidden;
    position: relative;

    canvas {
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .tooltip-col {
        position: absolute;
        width: ${SIZE_TOOLTIP}px;
        height: ${SIZE_TOOLTIP}px;
        background: #EFF9FF;
        border: 0.5px solid #33ADFF;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 9px 14px;
        border-radius: 4px;
        top: -100%;
        left: 0;

        &::before {
            content: '';
            position: absolute;
            width: 23px;
            height: 23px;
            left: -12px;
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            background: #EFF9FF;
            border-left: 0.5px solid #33ADFF;
            border-bottom: 0.5px solid #33ADFF;
        }

        &.reverse {
            &.top {
                border-top-right-radius: initial;
            }

            &.bottom {
                border-bottom-right-radius: initial;
            }

            &::before {
                left: initial;
                right: -12px;
                border-left: unset;
                border-bottom: unset;
                border-right: 0.5px solid #33ADFF;
                border-top: 0.5px solid #33ADFF;
            }
        }

        &.top {
            border-top-left-radius: initial;
            &::before {
                top: 4px;
                transform: rotate(45deg);
            }
        }

        &.bottom {
            border-bottom-left-radius: initial;
            &::before {
                top: unset;
                bottom: 4px;
                transform: rotate(45deg);
            }
        }
        
        .date {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131;
        }

        .wrap-data {
            margin-top: 4px;
        }

        .wrap-total {
            margin-top: 10px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;

            .value {
                font-size: 20px;
                line-height: 27px;
                letter-spacing: -0.01em;
                color: #313131;
            }

            .title {
                margin-top: 4px;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #777777;
            }
        }
    
    }
`;

export {
    SIZE_TOOLTIP,
    WrapChart
}