import React from 'react'

const IcSend = (props) => {
    return (
        <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8337 1.1665L6.41699 7.58317" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.8535 1.50956C12.2489 1.37117 12.629 1.75125 12.4906 2.14666L9.16695 11.6428C9.01736 12.0702 8.42203 12.0945 8.23812 11.6807L6.4951 7.7589C6.44482 7.64579 6.35438 7.55534 6.24126 7.50507L2.31946 5.76205C1.90565 5.57813 1.92994 4.98281 2.35735 4.83321L11.8535 1.50956Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default IcSend
