import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";

// component
import Button from '../../../../../../../Control/Button'
import IcTrashWhite from '../../../../../../../../assets/images/IcTrashWhite'

// styles
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupDelBank } from './index.styles'

//action
import { deleteBank, getListBank } from '../../../../../../../../redux/bank/listBank/action'
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'


// constant
import * as path from '../../../../../../../../constant/path'


const PopupDelBank = ({ bankData: { id, bankShortName }, handleClose, setKeyShowForm, isDetails = false }) => {

    const dispatch = useDispatch()
    const refDeleteBank = useRef(null)
    const history = useHistory()

    const callbackSuccess = () => {
        setKeyShowForm("")
        dispatch(getListBank({
            filterFrom: "",
            filterSkip: 0,
            filterSize: 20
        }))
    }

    const _handleSubmit = () => {
        dispatch(deleteBank(
            { id: id },
            callbackSuccess
        ))
        if (!!isDetails) {
            history.push(`/${path.MAIN}/${path.BANK}`)
        }
    }

    useOnClickOutside(refDeleteBank, () => setKeyShowForm(""))

    return (
        <OverlayFullScreen onClick={e => e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupDelBank ref={refDeleteBank}>
                    <div className="title">Xóa Bank</div>
                    <div className="sub-title">
                        Bạn vui lòng xác nhận xóa <span title={bankShortName}>“{bankShortName.length > 20 ? `${bankShortName.substring(0, 20)}...` : bankShortName}”</span>
                    </div>
                    <div className="notice">
                        *Các tài khoản liên kết với Banks sẽ bị xóa và không thể phục hồi
                    </div>
                    <Button
                        text="Xóa Bank"
                        iconBtn={<IcTrashWhite />}
                        className="btn-del"
                        onClick={_handleSubmit}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={handleClose}
                    />
                </WrapPopupDelBank>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupDelBank
