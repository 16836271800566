import styled from 'styled-components'

// styles
import { WrapCreateNewPopup } from '../../Content/BreadcrumbAndTool/CreateNewMerchant/index.styles';

const WrapPopupPopupChangePass = styled(WrapCreateNewPopup)`
    width: 320px;
    box-sizing: border-box;
    .title{
        display: flex;
        .icon-title{
            width: 48px;
            height: 48px;
            background: #F7F7F7;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
            }
        }
        .right-title{
            margin-left: 10px;
            .title-bold{

            }
            .title-normal{
                margin-top: 6px;
                color: #777777;
                letter-spacing: -0.02em;
                font-size: 11px;
                line-height: 15px;
                font-family: "Open Sans";
            }
        }
    }
    .inputFullName{
        margin-top: 37px;
    }
    .btn-changePass{
        width: 100%;
        margin-top: 42px;
    }
`;

export {
    WrapPopupPopupChangePass
}