import styled from 'styled-components'

const WrapSelectStatus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;

    .label-select-status {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.01em;
        cursor: pointer;
        color: #313131;

        span {
            color: #33ADFF;
        }
    }
`;

export {
    WrapSelectStatus,
}