export const headerTransListData = [
    "Status",
    "Merchant Tx ID",
    "Bank Tx ID",
    "Facepay Tx ID",
    "Faceprint",
    "Ngày",
    "Thời gian",
    "Số tiền (đ)",
    "Merchant",
    "Ngân hàng",
    "Hoàn tiền"
]