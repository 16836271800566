import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//data
import dataDeviceStatus from '../../../../../data/dataDeviceStatus'

//component
import Button from '../../../../Control/Button'
import PageHeader from '../PageHeader'
import IcViewStatus from '../../../../../assets/images/IcViewStatus'
import IcViewList from '../../../../../assets/images/IcViewList'
import DeviceAdminByStatusView from './DeviceAdminByStatusView'
import DeviceAdminByListView from './DeviceAdminByListView'

//styles
import { WrapDeviceListByAdmin } from './index.styles'

// action
import { toggleListViewMode, resetListViewMode, setTotalDisconnected } from '../../../../../redux/device/listDevicesByAdmin/action'

const DeviceListByAdmin = () => {
    const dispatch = useDispatch()
    
    const { total, isStatusView } = useSelector(state => ({
        total: state.deviceReducer.listDevicesByAdmin.total,
        isStatusView: state.deviceReducer.listDevicesByAdmin.isStatusView
    }))

    useEffect(() => {
        return () => {
            dispatch(resetListViewMode())
            // dispatch(setTotalDisconnected(0))
        }
    }, []);

    const btnViewProp = useMemo(() => {
        const iconBtn = isStatusView ? <IcViewList /> : <IcViewStatus />
        const text = isStatusView ? "List view" : "Status view"
        const onClick = () => dispatch(toggleListViewMode())
        return {
            iconBtn,
            text,
            onClick
        }
    }, [isStatusView])

    return (
        <WrapDeviceListByAdmin>
            <div className="wrap-header">
                <PageHeader>Danh sách thiết bị ({total})</PageHeader>
                <div className="wrap-status-device">
                    {
                        Object.values(dataDeviceStatus).map(({type, vnText, color}) =>(
                            <div className="devices-status" key={type}>
                                <div style={{ background:color }} className="block-status"></div>
                                <p>{vnText}</p>
                            </div>
                        ))
                    }
                    <div className="wrap-view-type">
                        <Button
                            className="btn-header" 
                            {...btnViewProp}
                        />
                    </div>
                </div>
            </div>
            {
                isStatusView 
                ?   <DeviceAdminByStatusView />
                :   <DeviceAdminByListView />
            }
        </WrapDeviceListByAdmin>
    )
}

export default DeviceListByAdmin
