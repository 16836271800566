import React, { useRef } from 'react'

// component
import IcCheck from '../../../assets/images/IcCheck'

// hook
import useScrollEnd from '../../../hook/useScrollEnd'

// styles
import { WrapDropdownList } from './index.styles'

const DropdownList = ({ isShowCheckStatus=false, listItem, valueSelected, setValueSelect, alignTop, className, maxItemShow, handleScrollEnd }) => {
    const eleRef = useRef()
    
    useScrollEnd(eleRef, (callback) => {
        if(!maxItemShow) return;
        if(!handleScrollEnd) return;
        handleScrollEnd(callback)
    }, [handleScrollEnd])

    return (
        <WrapDropdownList ref={eleRef} alignTop={alignTop} className={className} maxItemShow={maxItemShow}>
            {
                listItem.map((item) => {
                    const { text, key } = item
                    return <div key={key} onClick={() => setValueSelect(item)}>
                        <span title={text}>{text}</span>
                        {isShowCheckStatus && valueSelected?.key === key && <IcCheck />}
                    </div>
                })
            }
        </WrapDropdownList>
    )
}

export default DropdownList
