import styled from 'styled-components'

// styles
import { WrapPopupDel } from '../PopupDelMerchant/index.styles';

const WrapPopupCannotDelMerchant = styled(WrapPopupDel)`
    width: 272px;

    .sub-title:not(.sub-title ~ .sub-title) {
        margin-top: 10px;
    }
    .sub-title ~ .sub-title {
        margin-top: 25px;
    }

    .notice {
        margin-top: 25px;
    }

    .btn-del {
        margin-top: 30px;
    }
`;

export {
    WrapPopupCannotDelMerchant
}