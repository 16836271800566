import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

// component
import IcTrashWhite from '../../../../../../../../../assets/images/IcTrashWhite'
import Button from '../../../../../../../../Control/Button'

// hook
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'

// styles
import { WrapPopupDelDevice } from './index.styles'
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

// action
import { getListDevicesByAdmin, delDevice } from '../../../../../../../../../redux/device/listDevicesByAdmin/action'

const PopupDelDevice = ({ data, handleClose }) => {
    const dispatch = useDispatch()
    const { name } = data || { name: "-" }

    const refDeletedDevice = useRef(null)

    const callbackSubmitSuccess = () => {
        dispatch(getListDevicesByAdmin({
            filterFrom: "", 
            filterSkip: 0, 
            filterSize: 20
        }))
        handleClose()
    }

    const _handleSubmit = () => {
        const { id } = data
        dispatch(delDevice({ id }, callbackSubmitSuccess))
    }

    useOnClickOutside(refDeletedDevice, handleClose)

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupDelDevice ref={refDeletedDevice}>
                    <div className="title">Xóa thiết bị</div>
                    <div className="sub-title">
                        Bạn vui lòng xác nhận xóa thiết bị <span title={name}>“{name.length > 20 ? `${name.substring(0, 20)}...` : name}”</span>
                    </div>
                    <div className="notice">
                        *Thiết bị sẽ mất kết nối với hệ thống
                    </div>
                    <Button
                        text="Xóa thiết bị"
                        iconBtn={<IcTrashWhite />}
                        className="btn-del"
                        onClick={_handleSubmit}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={handleClose}
                    />
                </WrapPopupDelDevice>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupDelDevice
