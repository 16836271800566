import React from 'react'
//image
import IcInputChatacterActive from '../../../../../../assets/images/IcInputCharacterActive'
import IcInputChatacterError from '../../../../../../assets/images/IcInputCharacterError'
import IcInputChatacter from '../../../../../../assets/images/IcInputChatacter'
//component
import Input from '../../../../../Control/Input'

const iconMap = {
    icon: IcInputChatacter,
    iconActive: IcInputChatacterActive,
    iconError: IcInputChatacterError,
}

const InputBankCode = (valueInput) => {
    return (
        <Input 
            name="bankCode"
            className="input-merchant"
            placeholder="Ký tự ghi nhớ"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconMap}
        />
    )
}

export default InputBankCode
