export const GET_LIST_ACCOUNT_MERCHANT = 'GET_LIST_ACCOUNT_MERCHANT'
export const GET_LIST_ACCOUNT_MERCHANT_ERR = 'GET_LIST_ACCOUNT_MERCHANT_ERR'

export const CREATE_ACCOUNT_MERCHANT = 'CREATE_ACCOUNT_MERCHANT'
export const CREATE_ACCOUNT_MERCHANT_ERR = 'CREATE_ACCOUNT_MERCHANT_ERR'

export const UPDATE_ACCOUNT_MERCHANT = 'UPDATE_ACCOUNT_MERCHANT'
export const UPDATE_ACCOUNT_MERCHANT_ERR = 'UPDATE_ACCOUNT_MERCHANT_ERR'

export const LOCK_ACCOUNT_MERCHANT = 'LOCK_ACCOUNT_MERCHANT'
export const LOCK_ACCOUNT_MERCHANT_ERR = 'LOCK_ACCOUNT_MERCHANT_ERR'

export const UNLOCK_ACCOUNT_MERCHANT = 'UNLOCK_ACCOUNT_MERCHANT'
export const UNLOCK_ACCOUNT_MERCHANT_ERR = 'UNLOCK_ACCOUNT_MERCHANT_ERR'

export const RESET_LIST_ACCOUNT_MERCHANT = 'RESET_LIST_ACCOUNT_MERCHANT'

export const GET_ACCOUNT_MERCHANT_SEARCH_USERNAME = 'GET_ACCOUNT_MERCHANT_SEARCH_USERNAME'
export const GET_ACCOUNT_MERCHANT_SEARCH_USERNAME_ERR = 'GET_ACCOUNT_MERCHANT_SEARCH_USERNAME_ERR'

export const GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME = 'GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME'
export const GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME_ERR = 'GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME_ERR'

export const GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL = 'GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL'
export const GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL_ERR = 'GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL_ERR'