import * as types from './types'

const initialState = {
    
}
const detailsByAdmin = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DETAILS_DEVICE_BY_ADMIN: {
            return {
                ...state,
                ...action.payload.details
            }
        }
        case types.RESET_DETAILS_DEVICE: {
            return initialState
        }
        default:
            return state;
    }

}

export default detailsByAdmin