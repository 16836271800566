import Axios from "axios";
//host
import { host } from "../../constant/host";
//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
//path
import * as path from '../../constant/path'
//type
import * as types from './types'
import { NETWORK_ERR } from "../notifies/types";
//key
import * as keys from '../../helper/localStore'

const apiLogin = `${host}/access/login`;
const apiLogout = `${host}/access/logout`;

/**
 * @param {*any} history
 * @param  {{ username: string, pwd: string }} dataLogin
 */

export const loginActicon = (history, dataLogin, callbackSuccess, callbackError) => async (dispatch) => {

    const requestData = JSON.stringify(dataLogin);

    try {
        const res = await Axios.post(apiLogin, requestData);
        const { data } = res;
        if (checkIsSuccessAction(data.code)) {
            data.username = dataLogin.username
            localStorage.setItem(keys.FACEPAY_PORTAL, JSON.stringify({ langKey: 'vn' }));
            localStorage.setItem(keys.FACEPAY_PORTAL_LOGIN, JSON.stringify(data));
            Axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
            dispatch({
                type: types.LOGIN,
                data: data,
            });
            callbackSuccess && callbackSuccess()
            history.push(`/${path.MAIN}`);
        }

        else {
            callbackError && callbackError()
        }

    } catch (error) {
        const response = error?.response;
        if (response) {
            callbackError && callbackError()
            return;
        }

        dispatch({
            type: NETWORK_ERR,
        });
    }
}

export const logoutAction = (history, callApi = true) => async dispatch => {
    localStorage.removeItem(keys.FACEPAY_PORTAL_LOGIN);
    history.push('/login')
    dispatch({
        type: types.LOGOUT
    })
    callApi && await Axios.post(apiLogout)

}