import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

// styles
import { LabelCol, Row, RowLabelColumn, WrapListAccount, WrapRow } from './index.styles'

// component
import AccountItem from './AccountItem'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'

//action
import { getAccountBankSearchFullname, getAccountBankSearchUsername, getAccountBankSearchInterval, getListAccountBank, resetListAccountBank } from '../../../../../../redux/bank/detailtsBank/account/action'
import { GET_LIST_ACCOUNT_BANK, GET_ACCOUNT_BANK_SEARCH_USERNAME, GET_ACCOUNT_BANK_SEARCH_FULLNAME, GET_ACCOUNT_BANK_SEARCH_INTERVAL } from '../../../../../../redux/bank/detailtsBank/account/types'
import useScrollEnd from '../../../../../../hook/useScrollEnd'


const labelColumns = [
    "Username",
    "Họ tên",
    "Ngày tạo",
    "Ngày update",
]

const ListAccounts = () => {

    const dispatch = useDispatch()
    const listRef = useRef(null)
    const { bankId } = useParams();

    const { listAccountBank, total, type, condFilters } = useSelector(state => ({
        listAccountBank: state.bankReducer.detailsBankReducer.accountReducer.list,
        total: state.bankReducer.detailsBankReducer.accountReducer.total,
        type: state.bankReducer.detailsBankReducer.accountReducer.type,
        condFilters: state.bankReducer.detailsBankReducer.accountReducer.condFilters
    }))

    useEffect(() => {
        dispatch(getListAccountBank({
            bankId: bankId,
            filterFrom: "",
            filterSkip: 0,
            filterSize: 20
        }))

        return () => {
            dispatch(resetListAccountBank())
        }
    }, []);

    useScrollEnd(listRef, (callback) => {
        switch (type) {
            case GET_LIST_ACCOUNT_BANK:
                dispatch(getListAccountBank({
                    bankId,
                    filterFrom: listAccountBank[0]?.id,
                    filterSkip: listAccountBank.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ACCOUNT_BANK_SEARCH_USERNAME:
                dispatch(getAccountBankSearchUsername({
                    bankId,
                    username: condFilters.username,
                    filterFrom: listAccountBank[0]?.id,
                    filterSkip: listAccountBank.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ACCOUNT_BANK_SEARCH_FULLNAME:
                dispatch(getAccountBankSearchFullname({
                    bankId,
                    fullname: condFilters.fullname,
                    filterFrom: listAccountBank[0]?.id,
                    filterSkip: listAccountBank.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ACCOUNT_BANK_SEARCH_INTERVAL:
                dispatch(getAccountBankSearchInterval({
                    bankId,
                    startTime: condFilters.startTime,
                    closeTime: condFilters.closeTime,
                    filterFrom: listAccountBank[0]?.id,
                    filterSkip: listAccountBank.length,
                    filterSize: 20
                }, true, callback))
                break;
            default:
                break;
        }
    }, [listAccountBank, type, condFilters], [total])

    return (
        <WrapListAccount>
            {
                total === 0 ?
                    <div className="wrap-empty">
                        <EmptyDataControl />
                    </div>
                    :
                    <>
                        <RowLabelColumn className="row">
                            {
                                labelColumns.map((labelColumn) => (
                                    <LabelCol key={labelColumn}>{labelColumn}</LabelCol>
                                ))
                            }
                        </RowLabelColumn>
                        <WrapRow ref={listRef}>
                            <div className="wrap-inner" >
                                {
                                    listAccountBank.map((item, i) => (
                                        <AccountItem
                                            data={item}
                                            key={item.id}
                                            className="row"
                                            refList={listRef}
                                        />
                                    ))
                                }
                            </div>
                        </WrapRow>
                    </>
            }
        </WrapListAccount>
    )
}

export default ListAccounts
