import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns: 
        minmax( 87px, 97px) 
        minmax( 96px, 106px) 
        minmax( 71px, 81px) 
        minmax( 480px, 1fr) 
        minmax( 135px, 145px) 
        minmax( 111px, 121px)
        minmax( 75px, 85px);
    grid-gap: 5px;    
    align-items: center;
    height: 60px;    
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 6px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #313131;
    padding: 0 12px 0 18px;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        border: 1px solid #33ADFF;
    }
    div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }   
    .avatar-user{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        .avatar{
            width: 36px;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .customer-channel{   
        display: flex;
        align-items: center;
        .logo{
            height: 24px;
            width: 24px;
            object-fit: contain;
            margin-right: 4px;
        }
        .name-channel{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }  
    }  

    .wrap-time {
        .time{
            width: unset;
        }
        .icon-arrow{
            width: 14px;
            height: 14px;
            margin-left: 27px;            
        }
    }
`
export { Bound }