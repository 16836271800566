import styled from 'styled-components'

// styles
import { WrapPageItem } from '../TransactionsList/index.styles'

const WrapTransactionHistory = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .wrap-empty {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }
    
    .wrap-list-history {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        overflow: auto;
        margin-top: 11px;
        position: relative;
        padding-right: 10px;
        .list-history {
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
        }
    }
`
export { WrapTransactionHistory }