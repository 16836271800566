import { combineReducers } from "redux";
import listTransactionReducer from './listTransaction/listTransactionReducer'
import transactionDetailReducer from './transactionDetail/transactionDetailReducer'
import transactionHistoryReducer from './transactionHistory/transactionHistoryReducer'
import transactionCountReducer from './transactionCount/transactionCountReducer'

const transactionReducer = combineReducers({
    listTransactionReducer,
    transactionDetailReducer,
    transactionHistoryReducer,
    transactionCountReducer
})

export default transactionReducer;