import React from 'react'

const IcEditWhite = ({...props}) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.41699 2.33301H2.33366C2.02424 2.33301 1.72749 2.45592 1.5087 2.67472C1.28991 2.89351 1.16699 3.19026 1.16699 3.49967V11.6663C1.16699 11.9758 1.28991 12.2725 1.5087 12.4913C1.72749 12.7101 2.02424 12.833 2.33366 12.833H10.5003C10.8097 12.833 11.1065 12.7101 11.3253 12.4913C11.5441 12.2725 11.667 11.9758 11.667 11.6663V7.58301" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.792 1.45814C11.0241 1.22608 11.3388 1.0957 11.667 1.0957C11.9952 1.0957 12.3099 1.22608 12.542 1.45814C12.7741 1.6902 12.9044 2.00495 12.9044 2.33314C12.9044 2.66133 12.7741 2.97608 12.542 3.20814L7.00033 8.74981L4.66699 9.33314L5.25033 6.99981L10.792 1.45814Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcEditWhite
