import React from 'react'

const IcCustomerDefault = (props) => {
    return (
        <svg {...props} width={141} height={140} viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="70.5" cy={70} r={70} fill="#B7B7B7" />
            <path d="M107.167 100V93.3336C107.165 90.3794 106.182 87.5095 104.372 85.1746C102.562 82.8398 100.027 81.1722 97.167 80.4336" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M87.1668 100V93.3333C87.1668 89.7971 85.7621 86.4057 83.2616 83.9052C80.7611 81.4048 77.3697 80 73.8335 80H47.1668C43.6306 80 40.2392 81.4048 37.7387 83.9052C35.2383 86.4057 33.8335 89.7971 33.8335 93.3333V100" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M83.833 40.4336C86.7011 41.1679 89.2431 42.8359 91.0585 45.1746C92.8738 47.5133 93.8591 50.3897 93.8591 53.3503C93.8591 56.3108 92.8738 59.1872 91.0585 61.5259C89.2431 63.8646 86.7011 65.5326 83.833 66.2669" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M60.4998 66.6667C67.8636 66.6667 73.8332 60.6971 73.8332 53.3333C73.8332 45.9695 67.8636 40 60.4998 40C53.136 40 47.1665 45.9695 47.1665 53.3333C47.1665 60.6971 53.136 66.6667 60.4998 66.6667Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCustomerDefault
