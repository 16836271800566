import React, { useState, useMemo } from 'react'

// component
import MoreOption from '../../../../../../../Control/MoreOption'
import IcUnlockBlue from '../../../../../../../../assets/images/IcUnlockBlue'
import IcLockRed from '../../../../../../../../assets/images/IcLockRed'
import IcEdit from '../../../../../../../../assets/images/IcEdit'
import PopupEditAccount from './PopupEditAccount'
import Portal from '../../../../../../../Control/Portal'
import PopupLockAccount from './PopupLockAccount'
import PopupUnlockAccount from './PopupUnlockAccount'
import IcMail from '../../../../../../../../assets/images/IcMail'


const EDIT = "EDIT"
const RESEND = 'RESEND'
const LOCK = "LOCK"
const UNLOCK = "UNLOCK"

const OptionAccount = ({ data, refList, ...props }) => {
    const { isDisable } = data

    const [activeKey, setActiveKey] = useState("");

    const handleEdit = () => {
        setActiveKey(EDIT)
    }

    const handleRecendPass = () => {
        setActiveKey(RESEND)
    }


    const handleUnLock = () => {
        setActiveKey(UNLOCK)
    }

    const handleLock = () => {
        setActiveKey(LOCK)
    }

    // const handleClosePopup = () => {
    //     setActiveKey("")
    // }

    const dataMoreOption = useMemo(() => {
        if (isDisable) {
            return [
                {
                    Icon: IcEdit,
                    text: "Tùy chỉnh",
                    handleOption: handleEdit
                },
                {
                    Icon: IcUnlockBlue,
                    text: "Mở khóa",
                    handleOption: handleUnLock
                }
            ]
        }
        return [
            {
                Icon: IcEdit,
                text: "Tùy chỉnh",
                handleOption: handleEdit
            },
            {
                Icon: IcMail,
                text: "Resend Password",
                handleOption: handleRecendPass
            },
            {
                Icon: IcLockRed,
                text: "Khóa tài khoản",
                handleOption: handleLock
            }            
        ]
    }, [isDisable])

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
                {...props}
            />
            {
                activeKey === EDIT &&
                <Portal>
                    <PopupEditAccount 
                        data={data}
                        setActiveKey={setActiveKey}
                    />
                </Portal>
            }
            {
                activeKey === LOCK &&
                <Portal>
                    <PopupLockAccount 
                        data={data}
                        setActiveKey={setActiveKey}
                    />
                </Portal>
            }
            {
                activeKey === UNLOCK &&
                <Portal>
                    <PopupUnlockAccount 
                        data={data}
                        setActiveKey={setActiveKey}
                    />
                </Portal>
            }
        </>
    )
}

export default OptionAccount
