import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IcTrashWhite from '../../../../../../../../../assets/images/IcTrashWhite'

import { deleteAdvertisementMerchant, getListAdvertisementMerchant } from '../../../../../../../../../redux/merchant/detailsMerchant/advertisement/action'
import Button from '../../../../../../../../Control/Button'
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

// styles
import { WrapPopupDelAdvertisement } from './index.styles'

const PopupDelAdvertisement = ({ dataAdvertisement: {id, name}, handleClose, setKeyShowForm }) => {
    const dispatch = useDispatch()
    
    const {condFilters} = useSelector(state => ({
        condFilters: state.merchantReducer.detailsMerchantReducer.advertisement.condFilters
    }))

    const callbackSuccess = () =>{
        setKeyShowForm && setKeyShowForm()
        dispatch(getListAdvertisementMerchant({ ...condFilters }))
    }

    const handleDelete = () =>{
        const dataRequest = {
            id
        }
        dispatch(deleteAdvertisementMerchant(dataRequest, callbackSuccess))
    }

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupDelAdvertisement>
                    <div className="title">Xóa quảng cáo</div>
                    <div className="sub-title">
                    Bạn vui lòng xác nhận xóa quảng cáo <span title={name}>“{name.length > 20 ? `${name.substring(0, 20)}...` : name}”</span>
                    </div>
                    <div className="notice">
                        *Quảng cáo bị xóa sẽ không thể phục hồi
                    </div>
                    <Button
                        text="Xóa quảng cáo"
                        iconBtn={<IcTrashWhite />}
                        className="btn-del"
                        onClick={handleDelete}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={handleClose}
                    />
                </WrapPopupDelAdvertisement>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupDelAdvertisement
