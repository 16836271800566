import styled from 'styled-components'

// styles
import { WrapItem } from '../../ListStores/StoreItem/index.styles';

const WrapAccountItem = styled(WrapItem)`
    
    .option {
        .wrap-option {
            min-width: 150px;
        }
    }
`;

export {
    WrapAccountItem
}