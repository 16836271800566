import { combineReducers } from "redux";

import listMerchantReducer from './listMerchant'
import detailsMerchantReducer from './detailsMerchant'
import merchantCountReducer from './merchantCount'

const merchantReducer = combineReducers({
    listMerchantReducer,
    detailsMerchantReducer,
    merchantCountReducer
})

export default merchantReducer;