import Axios from "axios";
//host
import { host } from "../../constant/host";

// constant
import { REFUNDED, SUCCESS } from "../../constant/transactionStatusTypes";

//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";
//type
import * as types from './types'

const apiGetDataAnalytic = `${host}/transaction/analytic`

const getPercentage = (value, sum) => sum === 0 ? 0 : Math.round((value / sum) * 100)

const getDataAnalytic = ({ type, month, year }, callbackSuccess) => async dispatch => {
    const requestData = JSON.stringify({
        type, 
        month,
        year
    })

    try {
        const res = await Axios.post(apiGetDataAnalytic, requestData)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            const { analyticList } = data
            // const { bills } = analyticList

            const barChartData = analyticList.map(({ bills }) => {

                const transList = bills || []
                const totalOrderSuccess = transList.filter(item => [SUCCESS, REFUNDED].includes(item.status))
                
                // 10,000 - 50,000
                const numOfOrderLv1 = totalOrderSuccess.filter(item => 
                    item.trxAmount >= 10_000 && item.trxAmount <= 50_000
                )
    
                // 50,001 - 100,000
                const numOfOrderLv2 = totalOrderSuccess.filter(item => 
                    item.trxAmount >= 50_001 && item.trxAmount <= 100_000
                )
    
                // 101,000 - 200.000
                const numOfOrderLv3 = totalOrderSuccess.filter(item => 
                    item.trxAmount >= 101_000 && item.trxAmount <= 200_000
                )
    
                // 201,000 - 500,000
                const numOfOrderLv4 = totalOrderSuccess.filter(item => 
                    item.trxAmount >= 201_000 && item.trxAmount <= 500_000
                )
    
                // >= 501,000
                const numOfOrderLv5 = totalOrderSuccess.filter(item => 
                    item.trxAmount >= 501_000
                )

                const totalAmount = totalOrderSuccess.reduce((sum, cur) => sum + cur.trxAmount, 0)
                const percentSuccess = transList.length 
                    ? getPercentage(totalOrderSuccess.length, transList.length)
                    : 0
                return {
                    totalAmountBarChart: Math.round((totalAmount / 1_000_000) * 10) / 10,
                    totalOrder: transList.length, //tổng số đơn hàng
                    totalAmount,//doanh số - amount bill success
                    percentSuccess,
                    percentFailed: transList.length
                        ? 100 - percentSuccess
                        : 0,
                    percentChartData: transList.length
                        ? [
                            getPercentage(numOfOrderLv1.length, totalOrderSuccess.length),
                            getPercentage(numOfOrderLv2.length, totalOrderSuccess.length),
                            getPercentage(numOfOrderLv3.length, totalOrderSuccess.length),
                            getPercentage(numOfOrderLv4.length, totalOrderSuccess.length),
                            getPercentage(numOfOrderLv5.length, totalOrderSuccess.length),
                        ]
                        : [],
                }
            })

            const maxAmount = barChartData.reduce((max, item) => max < item.totalAmount ? item.totalAmount : max, 0)

            dispatch({
                type: types.GET_DATA_ANALYTIC,
                payload: {
                    list: barChartData,
                    maxAmount: Math.round((maxAmount / 1_000_000) * 10) / 10
                }
            })
            callbackSuccess(barChartData, type)
        }
        else {
            dispatch({
                type: types.GET_DATA_ANALYTIC_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(error) {
        console.log(error)
        dispatch(mapErrors(error, types.GET_DATA_ANALYTIC_ERR))
    }
}

export {
    getDataAnalytic
}