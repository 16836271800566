import Axios from 'axios'
//host
import { host } from '../../../../constant/host'
//help
import checkIsSuccessAction from '../../../../helper/checkIsSuccessAction'
import mapErrors from '../../../../helper/mapErrors'
//types
import * as types from './types'


const apiGetDetailsBank = `${host}/supplier/bank/details`

export const getGeneralBankInfos = ({ id }, callbackSuccess, callbackError) => async dispatch => {

    try {
        const res = await Axios.post(`${apiGetDetailsBank}?id=${id}`)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_GENERAL_BANK_INFOS,
                payload: {
                    details: data.details
                }
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_GENERAL_BANK_INFOS_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_GENERAL_BANK_INFOS_ERR))
    }
}


export const resetGeneralBankInfos = () => ({
    type: types.RESET_GENERAL_BANK_INFOS
})