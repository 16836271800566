import styled from 'styled-components'

const WrapSelectStatus = styled.div`
    flex-grow: 1;
    margin-left: 14px;
    display: flex;
    justify-content: center;
`;

export {
    WrapSelectStatus
}