import styled from 'styled-components'

const WrapHeaderContent = styled.div`
    width: 100%;
    height: 112px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    .wrap-header-content{
        max-width: calc(1280px - 100px);
        box-sizing: border-box;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        align-items: center;
        .bank_info{
            display: flex;
            align-items: center;
            .logo{
                width: 60px;
                height: 60px;
            }
            .name_bank{
                color: #313131;
                font-weight: 600;
                font-size: 24px;
                font-family: "Open Sans";
                line-height: 33px;
                padding-left: 15px;
                letter-spacing: -0.01em;
            }
        }
        .total{
            color: #313131;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.01em;
        }
    }
`;

export {
    WrapHeaderContent
}