import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//component
import AdvertisementItem from './AdvertisementItem'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'

//styles
import { LabelCol, RowLabelColumn, WrapRow } from '../ListStores/index.styles'
import { WrapListAdvertisement } from './index.styles'

//action
import { getListAdvertisementMerchant, 
    getListAdvertisementSearchByInterval, 
    getListAdvertisementSearchByName, 
    resetListAdvertisementMerchant } from '../../../../../../redux/merchant/detailsMerchant/advertisement/action'
import { GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE,
    GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL,
    GET_LIST_ADVERTISEMENT_MERCHANT } from '../../../../../../redux/merchant/detailsMerchant/advertisement/types'

//hook
import useScrollEnd from '../../../../../../hook/useScrollEnd'

const labelColumns = [
    "Tên file",
    "Định dạng",
    "Ngày tạo",
    "Ngày update"
]

const ListAdvertisement = ({ merchantId }) => {
    const dispatch = useDispatch()
    const listRef = useRef(null)

    const { list, total, type, condFilters } = useSelector(state => ({
        list: state.merchantReducer.detailsMerchantReducer.advertisement.list,
        total: state.merchantReducer.detailsMerchantReducer.advertisement.total,
        type: state.merchantReducer.detailsMerchantReducer.advertisement.type,
        condFilters: state.merchantReducer.detailsMerchantReducer.advertisement.condFilters,
    }))

    useEffect(() => {
        dispatch(getListAdvertisementMerchant({
            merchantId,
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        return () => {
            dispatch(resetListAdvertisementMerchant())
        }
        
    }, []);

    useScrollEnd(listRef, (callback) => {
        switch (type) {
            case GET_LIST_ADVERTISEMENT_MERCHANT:
                dispatch(getListAdvertisementMerchant({
                    merchantId,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE:
                dispatch(getListAdvertisementSearchByName({
                    merchantId,
                    name: condFilters.name,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL:
                dispatch(getListAdvertisementSearchByInterval({
                    merchantId,
                    startTime: condFilters.startTime,
                    closeTime: condFilters.closeTime,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            default:
                break;
        }
    }, [list, type, condFilters], [total])

    return (
        <WrapListAdvertisement>
            {
                total === 0
                    ? (
                        <div className="wrap-empty">
                            <EmptyDataControl />
                        </div>
                    )
                    : (
                        <>
                            <RowLabelColumn className="row">
                                {
                                    labelColumns.map((labelColumn) => (
                                        <LabelCol key={labelColumn}>{labelColumn}</LabelCol>
                                    ))
                                }
                            </RowLabelColumn>
                            <WrapRow ref={listRef}>
                                <div className="wrap-inner">
                                    {
                                        list.map((item, i) => (
                                            <AdvertisementItem
                                                className="row"
                                                dataItem={item}
                                                key={i}
                                                refList={listRef}
                                            />
                                        ))
                                    }
                                </div>
                            </WrapRow>
                        </>
                    )
            }            
        </WrapListAdvertisement>
    )
}

export default ListAdvertisement
