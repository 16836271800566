import * as types from './types'

const intitialState = {
    list: [],
    condFilters: {}
}
const transactionHistoryReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_TRANSACTION_HISTORY: {
            const { list,isScroll,...payload } = action.payload
            return {
                ...state,
                ...payload,
                list: isScroll ? [...state.list, ...list] : list
            }
        }
        case types.CLEAR_TRANSACTION_HISTORY: {
            return intitialState
        }
        default:
            return state;
    }

}

export default transactionHistoryReducer