import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

//component
import IcCreateNew from '../../../../../../../assets/images/IcCreateNew';
import Button from '../../../../../../Control/Button';
import DropdownControl from '../../../../../../Control/DropdownControl';
import FilterControl from '../../../../../../Control/FilterControl';
import Portal from '../../../../../../Control/Portal';
import PopupCreateAccount from './PopupCreateAccount';
import IcArrowDownGray from '../../../../../../../assets/images/IcArrowDownGray';
import DatePickerItem from '../../../../BreadcrumbAndTool/FilterTransaction/DatePickerItem';

//styles
import { InputFilter } from '../../../../../../Control/FilterControl/index.styles';
import { WrapToolAccount } from './index.styles'
import { WrapDateRange } from '../../../../BreadcrumbAndTool/FilterTransaction/index.styles';

//action
import { GET_LIST_ACCOUNT_MERCHANT } from '../../../../../../../redux/merchant/detailsMerchant/account/types';
import { getListAccountMerchant, getListAccountSearchFullName, getListAccountSearchInterval, getListAccountSearchUserName, resetListAccountMerchant } from '../../../../../../../redux/merchant/detailsMerchant/account/action';

//help
import { formatTimeEndDate } from '../../../../../../../helper/formatTimeEndDate';

const KEY_ACCOUNT_USER_NAME = "1"
const KEY_ACCOUNT_FULL_NAME = "2"
const KEY_TIME_SELECTION = "3"

const listFilterAccountTypes = [
    {
        key: KEY_ACCOUNT_USER_NAME,
        text: 'Username'
    },
    {
        key: KEY_ACCOUNT_FULL_NAME,
        text: 'Họ tên'
    },
    {
        key: KEY_TIME_SELECTION,
        text: 'Ngày tạo'
    },
]

const filterSize = 20

const SelectDateRange = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) => {
    return <WrapDateRange>
        <DatePickerItem
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            textEmpty="Từ ngày"
            showTimeSelect={false}
            dateFormat="dd/MM/yy"
        />
        <IcArrowDownGray className="arrow-right" />
        <DatePickerItem
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            textEmpty="Đến ngày"
            showTimeSelect={false}
            dateFormat="dd/MM/yy"
        />
    </WrapDateRange>
}

const ToolAccount = () => {
    const { merchantId } = useParams();
    const dispatch = useDispatch()
    const inputSearchRef = useRef(null)

    const { total, list, type } = useSelector(state => ({
        total: state.merchantReducer.detailsMerchantReducer.account.total,
        list: state.merchantReducer.detailsMerchantReducer.account.list,
        type: state.merchantReducer.detailsMerchantReducer.account.type,

    }))
    
    const [filterCond, setFilterCond] = useState(listFilterAccountTypes[0]);
    const [isShowPopupAdd, setIsShowPopupAdd] = useState(false);
    const [idSearch, setIdSearch] = useState("")
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        setIdSearch("")
        setStartDate(null)
        setEndDate(null)
        if (inputSearchRef.current) inputSearchRef.current.value = ''
    }, [JSON.stringify(filterCond)]);

    useEffect(() => {
        // const types = list.type
        const { key } = filterCond
        if ( key !== KEY_ACCOUNT_USER_NAME && type === GET_LIST_ACCOUNT_MERCHANT) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key !== KEY_ACCOUNT_USER_NAME && type === GET_LIST_ACCOUNT_MERCHANT && idSearch) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            setFilterCond(listFilterAccountTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key !== KEY_ACCOUNT_USER_NAME && type === GET_LIST_ACCOUNT_MERCHANT && startDate && endDate) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            setFilterCond(listFilterAccountTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key === KEY_ACCOUNT_USER_NAME && type === GET_LIST_ACCOUNT_MERCHANT) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            setFilterCond(listFilterAccountTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
    }, [JSON.stringify(list), JSON.stringify(filterCond), type]);

    const _handleSearch = () => {
        const { key } = filterCond
        if(!idSearch && !startDate && !endDate){
            dispatch(resetListAccountMerchant())
            dispatch(getListAccountMerchant({
                merchantId,
                filterFrom: '',
                filterSkip: 0,
                filterSize,
            }))
        }
        switch (key) {
            case KEY_ACCOUNT_USER_NAME:
                if(!idSearch) break;
                dispatch(resetListAccountMerchant())
                dispatch(getListAccountSearchUserName({
                    merchantId,
                    username: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                break;
            case KEY_ACCOUNT_FULL_NAME:
                if(!idSearch) break;
                dispatch(resetListAccountMerchant())
                dispatch(getListAccountSearchFullName({
                    merchantId,
                    fullname: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                break;
            case KEY_TIME_SELECTION:
                if(!startDate || !endDate) break;
                dispatch(resetListAccountMerchant())
                dispatch(getListAccountSearchInterval({
                    merchantId,
                    startTime: startDate,
                    closeTime: formatTimeEndDate(endDate),
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                break;
            default:
                break;
        }
    }

    const dropdownFilter = useMemo(() => {
        return (
            <DropdownControl 
                dataSelection={listFilterAccountTypes}
                valueSelected={filterCond} 
                handleSetValueSelect={val => setFilterCond(val)}
            />
        )
    }, [filterCond])

    const filterComponent = useMemo(() => {
        const { key, text } = filterCond
        if(key === KEY_TIME_SELECTION)
            return <SelectDateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
        />
        return (
            <InputFilter 
                type="text"
                ref={inputSearchRef}
                placeholder={`Nhập ${text}`}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        _handleSearch()
                    }
                }}
                onChange={e => setIdSearch(e.target.value)}
            />
        )
    }, [startDate, endDate, JSON.stringify(filterCond), _handleSearch])

    return (
        <WrapToolAccount>
            <div className="wrap-filter">
                <FilterControl 
                    dropdownFilter={dropdownFilter}
                    filterComponent={filterComponent}
                    handleSearch={_handleSearch}
                />
            </div>
            <div className="total-of-data">
                <span>Tài khoản:</span>
                <span>{total}</span>
            </div>
            <div>
                <Button 
                    iconBtn={<IcCreateNew />}
                    text="Thêm mới"
                    onClick={() => setIsShowPopupAdd(true)}
                />
            </div>
            {
                isShowPopupAdd &&
                <Portal>
                    <PopupCreateAccount 
                        handleClose={() => setIsShowPopupAdd(false)}
                        setIsShowPopupAdd={setIsShowPopupAdd}
                    />
                </Portal>
            }
        </WrapToolAccount>
    )
}

export default ToolAccount
