import { combineReducers } from 'redux';

import generalInfos from './generalInfos'
import account from './account'
import store from './store'
import advertisement from './advertisement';

const detailsMerchantReducer = combineReducers({
    generalInfos,
    account,
    store,
    advertisement,
})

export default detailsMerchantReducer