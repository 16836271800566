import * as types from './types'

const initialState = {
    
}
const generalInfos = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_GENERAL_MERCHANT_INFOS:
            return {
                ...state,
                ...action.payload.infos
            }
        case types.RESET_GENERAL_MERCHANT_INFOS:
            return initialState
        default:
            return state;
    }

}

export default generalInfos