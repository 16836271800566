import styled from 'styled-components'

const WrapDeviceAdminByList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .header-list{
        margin-top: 0;
    }
    .row {
        grid-template-columns: 50px 90px 120px 70px 130px 1fr 120px 130px 25px;
    }
    .wrap-empty {
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

export {
    WrapDeviceAdminByList
}