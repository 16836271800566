import styled from 'styled-components'

const WrapButton = styled.div`
    display: flex;
    align-items: center;
    padding-left: 25px;
    color: #313131;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    font-family: "Open Sans";
    cursor: pointer;
    svg{
        padding-right: 5px;
        cursor: pointer;
    }
`;

export {
    WrapButton
}