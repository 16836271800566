import React from 'react'

const IcViewStatus = ({...props}) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8337 7H10.5003L8.75033 12.25L5.25033 1.75L3.50033 7H1.16699" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcViewStatus
