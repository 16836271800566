import React, { forwardRef, useEffect, useState, useMemo } from 'react'
import { useRef } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

// component
import IcArrowDownGray from '../../../../../../assets/images/IcArrowDownGray'
import IcCalendar from '../../../../../../assets/images/IcCalendar'
import IcCalendarActive from '../../../../../../assets/images/IcCalendarActive'
import IcCalendarError from '../../../../../../assets/images/IcCalendarError'

// hook
import { useOnClickOutside } from '../../../../../../hook/useClickOutside'

// styles
import { WrapInputDropdown } from '../../../../../Control/InputDropdown/index.styles'

const SELECT = "SELECT"
const SELECT_ACTIVE = "SELECT_ACTIVE"
const SELECT_ERROR = "SELECT_ERROR"

const iconCalendar = {
    icon: IcCalendar,
    iconActive: IcCalendarActive,
    iconError: IcCalendarError,
}

const SelectDate = forwardRef(({ 
    className,
    iconInput=iconCalendar, 
    textEmpty,
    typeInput,
    borderActive,
    value,
    onClick,
}, ref) => {
    const iconTypeInput = useMemo(() => {
        if (!iconInput) return
        switch (typeInput) {
            case SELECT:
                return <iconInput.icon className="icon-left" />
            case SELECT_ACTIVE:
                return <iconInput.iconActive className="icon-left" />
            case SELECT_ERROR:
                return <iconInput.iconError className="icon-left" />
            default: return;
        }
    }, [typeInput])

    return (
        <WrapInputDropdown 
            onClick={onClick}
            className={className}
        >
            <div className={`wrap-input${borderActive ? " active" : ""}`}>
                { iconInput && iconTypeInput }
                <div className={value ? 'text-dropdown' : 'placeholder'}>{value || textEmpty}</div>
                <IcArrowDownGray className="arrow-down"/>
            </div>
        </WrapInputDropdown>
    )
})

const InputDatePicker = ({ className, placeholder, selected, onChange, ...props}) => {
    
    const [isShowCalendar, setIsShowCalendar] = useState(false);
    const [typeInput, setTypeInput] = useState(SELECT)
    const [borderActive, setBorderActive] = useState(false)

    useEffect(() => {
        if(!isShowCalendar && !selected) {
            setTypeInput(SELECT)
            setBorderActive(false)
            return;
        }

        if(!isShowCalendar && selected) {
            setTypeInput(SELECT_ACTIVE)
            setBorderActive(false)
            return;
        }

        if(isShowCalendar) {
            setTypeInput(SELECT_ACTIVE)
            setBorderActive(true)
            return;
        }
    }, [isShowCalendar, selected])
    
    return (
        <DatePicker
            customInput={
                <SelectDate 
                    className={className}
                    textEmpty={placeholder} 
                    typeInput={typeInput}
                    borderActive={borderActive}
                />
            }
            selected={selected}
            onChange={onChange}
            dateFormat="MMMM dd yyyy"
            showPopperArrow={false}
            popperPlacement="bottom"
            onCalendarOpen={() => setIsShowCalendar(true)}
            onCalendarClose={() => setIsShowCalendar(false)}
            {...props}
        />
    )
}

export default InputDatePicker
