import * as types from './types'

const intitialState = {
    details: {},
    refundReasons: [],
    detailId: ""
}
const transactionDetailReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_DETAIL_TRANSACTION: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case types.GET_REASON_REFUND: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.CLEAR_REASON_REFUND: {
            return {
                ...state,
                refundReasons: intitialState.refundReasons
            }
        }
        case types.GET_DETAIL_TRANSACTION_ERR: {
            return intitialState
        }
        case types.CLEAR_TRANSACTION_DETAILS: {
            return {
                ...state,
                details: {},
                refundReasons: []
            }
        }    
        default:
            return state;
    }

}

export default transactionDetailReducer