import React from 'react'

const IcHistoryTransaction = ({ ...props }) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.08333 13.4167L1.75 11.0833L4.08333 8.75" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.25 7.58334V8.75C12.25 9.36884 12.0042 9.96233 11.5666 10.3999C11.129 10.8375 10.5355 11.0833 9.91667 11.0833H1.75" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.91663 0.583336L12.25 2.91667L9.91663 5.25" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.75 6.41666V5.25C1.75 4.63116 1.99583 4.03767 2.43342 3.60008C2.871 3.1625 3.46449 2.91666 4.08333 2.91666H12.25" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcHistoryTransaction
