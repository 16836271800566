import { CONNECTED, DISCONNECTED, WARNING } from '../constant/deviceStatusTypes'

const dataDeviceStatus = {
    [CONNECTED]: {
        type: CONNECTED,
        text: 'Connected',
        color: '#33ADFF',
        vnText: 'Đang hoạt động',
    },
    [DISCONNECTED]: {
        type: DISCONNECTED,
        text: 'Disconnected',
        color: '#F45C5C',
        vnText: 'Mất kết nối',
    },
    [WARNING]: {
        type: WARNING,
        text: 'Warning',
        color: '#FFBA33',
        vnText: 'Có vấn đề',
    },
}

const mapStatus = {
    1: CONNECTED,
    0: DISCONNECTED,
    2: WARNING
}

const mapStatusToNum = {
    CONNECTED: 1,
    DISCONNECTED: 0,
    WARNING: 2
}

export {
    mapStatus,
    mapStatusToNum
}

export default dataDeviceStatus