import React from 'react'

const IcCloseOutlineWhite = (props) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49984 12.8337C10.7215 12.8337 13.3332 10.222 13.3332 7.00033C13.3332 3.77866 10.7215 1.16699 7.49984 1.16699C4.27818 1.16699 1.6665 3.77866 1.6665 7.00033C1.6665 10.222 4.27818 12.8337 7.49984 12.8337Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.25 5.25L5.75 8.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.75 5.25L9.25 8.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCloseOutlineWhite
