import styled from 'styled-components'

const WrapTools = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    grid-gap: 19px;

    .wrap-filter {
        justify-self: center;
    }

    .total-of-data {
        display: flex;
        align-items: center;
        font-family: "Open Sans";
        font-style: normal;
        letter-spacing: -0.01em;
        color: #313131;

        span:first-child {
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
        }

        span:last-child {
            margin-left: 8px;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
        }
    }
`;

const WrapToolAccount = styled(WrapTools)`
    
`;

export {
    WrapTools,
    WrapToolAccount
}