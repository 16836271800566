import { combineReducers } from 'redux';

import generalInfosReducer from './generalInfos'
import accountReducer from './account'

const detailsBankReducer = combineReducers({
    generalInfosReducer,
    accountReducer
})

export default detailsBankReducer