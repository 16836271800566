import React from 'react'
import { NavLink } from "react-router-dom";

//data
import dataMenu from '../../../data/dataMenu';

//const
import { env } from '../../../constant/environmentTypes'; 

// styles
import { WrapSideMenu } from './index.styles'

// component
import IcWeeDigital from '../../../assets/images/IcWeeDigital';
import IcHeart from '../../../assets/images/IcRedHeart';
import { TRANSACTION } from '../../../constant/path';
import { useDispatch } from 'react-redux';

// redux
import { getListTransaction, resetListTransaction } from '../../../redux/transaction/listTransaction/action'

const SideMenu = () => {

    const dispatch = useDispatch()

    const handleClick = (key) => {
        const callbackSuccess = () => dispatch(getListTransaction({ filterFrom: "", filterSkip: 0, filterSize: 20 }))
        switch (key) {
            case TRANSACTION:
                dispatch(resetListTransaction(callbackSuccess))
                break;
        
            default:
                break;
        }

   
    }
    return (
        <WrapSideMenu>
            <div className="wrap-menu">
            {
                dataMenu.map((item,index) =>(
                    item.envValid.includes(env) &&
                    <NavLink
                        to={item.path}
                        key={index}
                        className="link-item"
                        activeClassName="selected"
                        onClick={() => handleClick(item.key)}
                    >
                        <item.IconActive className="icon active"/>
                        <item.Icon className="icon"/>
                        <p>{item.name}</p>
                    </NavLink>
                ))
            }
            </div>
            <div className="crafted">
                <div>
                    <span>crafted with</span>
                    <IcHeart className="ic-heart" />
                    <span>by</span>
                </div>
                <IcWeeDigital />
            </div>
        </WrapSideMenu>
    )
}

export default SideMenu
