import styled from 'styled-components'

const WrapDateRange = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 245px;
    margin-left: 6px;
    & > *:not(.arrow-right) {
        flex-grow: 1;
    }

    .arrow-right {
        transform: rotate(-90deg);
        flex-shrink: 0;
    }

    & > *:not(:first-child) {
        margin-left: 7px;
    }
`
export {
    WrapDateRange,
}