import React, { useReducer, useRef, useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPopupExportFile } from './index.styles'
import { OverlayFullScreen } from '../../Page/TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

//component
import IcClose from '../../../../../assets/images/IcClose'
import Button  from '../../../../Control/Button'
import IcExportFileWhite from '../../../../../assets/images/IcExportFileWhite'
import InputOptionExport, { objOption, CUSTOM, THIS_MONTH, TODAY } from './InputOptionExport'
import InputDatePicker from './InputDatePicker'

// hook
import { useOnClickOutside } from '../../../../../hook/useClickOutside'

// helper
import { formatTimeEndDate } from '../../../../../helper/formatTimeEndDate'
import numberFormatToThousand from '../../../../../helper/numberFormatToThousand'

// action
import { exportTransaction, getCountExportTransaction } from '../../../../../redux/transaction/listTransaction/action'

const PopupExportFile = ({onClickIconClose , setIsShowModalExportFile}) => {

    const refExportFile = useRef(null)
    const dispatch = useDispatch()
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            option: objOption[0],
        }
    )

    const [startTime, setStartTime] = useState(null);
    const [closeTime, setCloseTime] = useState(null);
    const [amountOfData, setAmountOfData] = useState(null);
    const [isDisabledBtnExport, setIsDisabledBtnExport] = useState(false);

    const _handleSetValueDropdown = (val) =>{
        setStateForm({
            option: val,
        })
    }

    const _handleSubmitToDay = () => {
        const dayCurrent = new Date()
        const startTime = moment(dayCurrent).startOf('day').toDate()
        const closeTime = formatTimeEndDate(dayCurrent)
        const dataRequest = {
            startTime,
            closeTime
        }
        dispatch(exportTransaction(dataRequest, callbackSuccess))
    }

    const _handleSubmitThisMonth = () => {
        const dayCurrent = new Date()
        const startTime = moment(dayCurrent).startOf('month').toDate()
        const closeTime = moment(dayCurrent).endOf('month').toDate()
        const dataRequest = {
            startTime,
            closeTime
        }
        dispatch(exportTransaction(dataRequest, callbackSuccess))
    }

    const _handleSubmitCustom = () => {
        const dataRequest = {
            startTime: moment(startTime).startOf('day').toDate(),
            closeTime: moment(closeTime).endOf('day').toDate()
        }
        dispatch(exportTransaction(dataRequest, callbackSuccess))
    }

    const callbackSuccess = () => {
        setIsShowModalExportFile(false)
    }

    const _handleSubmit = () => {
        const { option: { key } } = stateForm
        switch (key) {
            case TODAY: {
                _handleSubmitToDay()
                break;
            }
            case THIS_MONTH: {
                _handleSubmitThisMonth()
                break;
            }
            case CUSTOM: {
                _handleSubmitCustom()
                break;
            }
            default:
                break;
        }
    }

    const _handleChangeFromDate = (date) => {
        setStartTime(date)
        // setAmountOfData(0)
    }

    const _handleChangeToDate = (date) => {
        setCloseTime(date)
        // setAmountOfData(0)
    }

    useEffect(() => {
        if(!stateForm.option) {
            setIsDisabledBtnExport(true)
            return;
        }

        const { key } = stateForm.option
        if(key === CUSTOM) {
            const isDisable = !startTime || !closeTime
            setIsDisabledBtnExport(isDisable)
            return;
        }
        setIsDisabledBtnExport(false)
    }, [stateForm.option, startTime, closeTime]);

    useEffect(() => {
        // if(stateForm.option.key !== CUSTOM) return
        setStartTime(null)
        setCloseTime(null)
        setAmountOfData(null)
    }, [stateForm.option]);

    useEffect(() => {
        if(stateForm.option.key !== CUSTOM) return
        if(!startTime || !closeTime) return;
        dispatch(getCountExportTransaction({
            startTime: moment(startTime).startOf('day').toDate(), 
            closeTime: moment(closeTime).endOf('day').toDate()
        }, (amount) => setAmountOfData(amount)))
    }, [stateForm.option, startTime, closeTime]);

    useOnClickOutside(refExportFile, () => setIsShowModalExportFile(false))

    const NumberCountAmount = amountOfData !== null ? amountOfData : 0

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupExportFile ref={refExportFile}>
                    <IcClose className="icon-close" onClick={onClickIconClose}/>
                    <p className="title">Xuất file</p>
                    <p className="description">Chọn thời gian xuất file</p>
                    <InputOptionExport 
                        valueDropdown={_handleSetValueDropdown}
                        defaultValue={stateForm.option}
                    />
                    {
                        stateForm.option.key === CUSTOM &&
                        <>
                            <InputDatePicker 
                                className="input-dropdown"
                                selected={startTime}
                                onChange={_handleChangeFromDate}
                                placeholder="Từ ngày"
                                selectsStart
                                startDate={startTime}
                                endDate={closeTime}
                                maxDate={closeTime ? closeTime : new Date()}
                                dateFormat="dd/MM/yyyy"
                            />
                            <InputDatePicker 
                                className="input-dropdown"
                                selected={closeTime}
                                onChange={_handleChangeToDate}
                                placeholder="Đến ngày"
                                selectsEnd
                                startDate={startTime}
                                endDate={closeTime}
                                maxDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                            />
                            <p className={`count-amount${amountOfData !== null ? ' visible' : ''}`}>Số lượng giao dịch: <span>{numberFormatToThousand(NumberCountAmount)}</span></p>
                        </>
                    }
                    <Button
                        text="Xuất file"
                        iconBtn={<IcExportFileWhite />}
                        className="btn-export"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnExport}
                    />
                </WrapPopupExportFile>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupExportFile
