import React, { useState, useRef } from 'react'

// data
import dataDeviceStatus from '../../../../../../data/dataDeviceStatus';
import { useOnClickOutside } from '../../../../../../hook/useClickOutside';

// component
import DropdownList from '../../../../../Control/DropdownList';

// styles
import { WrapSelectStatus } from './index.styles'

const dataStatus = Object.values(dataDeviceStatus).map(item => ({ 
    ...item, 
    key: item.type,
    text: item.vnText
}))

const PLACEHOLDER = "Chọn trạng thái"

const SelectStatus = ({ objValue, handleSelectStatus }) => {
    const eleRef = useRef()

    const [isShowDropdown, setIsShowDropdown] = useState(false);
    
    const _handleSelectStatus = (val) => {
        handleSelectStatus(val)
        setIsShowDropdown(false)
    }

    useOnClickOutside(eleRef, () => {
        setIsShowDropdown(false)
    })
    
    return (
        <WrapSelectStatus ref={eleRef}>
            <div className="label-select-status" onClick={() => setIsShowDropdown(pre => !pre)}>
                {objValue?.text || <span>{PLACEHOLDER}</span>}
            </div>
            {
                isShowDropdown &&
                <DropdownList 
                    listItem={dataStatus}
                    setValueSelect={_handleSelectStatus}
                />
            }
        </WrapSelectStatus>
    )
}

export default SelectStatus
