import React from 'react'


// component
import Input from '../../../../../Control/Input'
import IcDeviceGray from '../../../../../../assets/images/IcDeviceGray'
import IcDeviceActive from '../../../../../../assets/images/IcDeviceActive'
import IcDeviceError from '../../../../../../assets/images/IcDeviceError'


const iconDevice = {
    icon: IcDeviceGray,
    iconActive: IcDeviceActive,
    iconError: IcDeviceError,
}

const InputDeviceName = ({ valueInput, onChange, placeholder, ...props }) => {
    return (
        <Input
            type="text"
            className="input-merchant"
            placeholder={placeholder}
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconDevice}
            {...props}
        />
    )
}

export default InputDeviceName
