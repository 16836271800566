import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapGeneralInfos } from './index.styles'

// image
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault'

//help
import { convertDateTime } from '../../../../../../helper/convertDateTime'
import { apiSupplierLogo } from '../../../../../../constant/host'

//component
import OptionBank from '../../BankList/BankItem/OptionBank'

//action
import { getGeneralBankInfos, resetGeneralBankInfos } from '../../../../../../redux/bank/detailtsBank/generalInfos/action'


const GeneralInfosBank = () => {

    const dispatch = useDispatch()
    const { bankId } = useParams();

    const [isErrLogoBank, setIsErrLogoBank] = useState(false)

    const { details } = useSelector(state => ({
        details: state.bankReducer.detailsBankReducer.generalInfosReducer.details
    }))

    useEffect(() => {
        setIsErrLogoBank(false)
    }, [details?.bankCode])

    useEffect(() => {
        dispatch(getGeneralBankInfos({ id: bankId }))
        return () => {
            dispatch(resetGeneralBankInfos())
        }
    }, [])

    return (
        <WrapGeneralInfos>
            <div className="wrap-logo">
                {
                    isErrLogoBank ?
                        <IcMerchantLogoDefault className="logo" />
                        :
                        <img
                            className="logo"
                            src={details?.bankCode ? apiSupplierLogo(details.bankCode) : ""}
                            alt="logo"
                            onError={() => setIsErrLogoBank(true)}
                        />
                }
            </div>
            <div className="wrap-bank-infos bank-name">
                <div className="content-bold" title={details?.bankShortName || ""}>
                    {details?.bankShortName || "-"}
                </div>
                <div className="content-gray" title={details?.bankName || ""}>
                    {details?.bankName || "-"}
                </div>
            </div>
            <div className="wrap-bank-infos">
                <div className="content-bold">{details?.bankCode || "-"}</div>
                <div className="content-gray">Bankcode</div>
            </div>
            <div className="wrap-bank-infos">
                <div className="content-bold">{details?.createAt ? convertDateTime(details.createAt, "DD/MM/YYYY") : "-"}</div>
                <div className="content-gray">Ngày tạo</div>
            </div>
            <div className="wrap-bank-infos">
                <div className="content-bold">{details?.lastUpdateAt ? convertDateTime(details.lastUpdateAt, "DD/MM/YYYY") : "-"}</div>
                <div className="content-gray">Ngày update</div>
            </div>
            <div className="wrap-tools">
                <OptionBank bankData={details} isDetails={true} />
            </div>
        </WrapGeneralInfos>
    )
}

export default GeneralInfosBank
