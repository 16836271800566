import styled from 'styled-components'

const WrapApp = styled.div`
    min-height: 100vh;
    width: 100vw;
    height: 800px;
    background: #F7F7F7;
`;

export {
    WrapApp
}