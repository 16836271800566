import React, { useEffect, useReducer, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPopupEditBank } from './index.styles'
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

//help
import fileToBase64 from '../../../../../../../../helper/fileToBase64'

//component
import DragAndDropImage from '../../../../../../../Control/DragAndDropImage'
import IcClose from '../../../../../../../../assets/images/IcClose'
import Button from '../../../../../../../Control/Button'
import IcEditWhite from '../../../../../../../../assets/images/IcEditWhite'
import InputBankName from '../../../../../BreadcrumbAndTool/CreateNewBank/InputBankName'
import InputBankShortName from '../../../../../BreadcrumbAndTool/CreateNewBank/InputBankShortName'
import InputBankCode from '../../../../../BreadcrumbAndTool/CreateNewBank/InputBankCode'

//action
import { getListBank, updateBank } from '../../../../../../../../redux/bank/listBank/action'
import { getGeneralBankInfos } from '../../../../../../../../redux/bank/detailtsBank/generalInfos/action'

//help
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'
import { apiSupplierLogo } from '../../../../../../../../constant/host'

const PopupEditBank = ({ onClickIconClose, bankData, setKeyShowForm, isDetails = false }) => {

    const dispatch = useDispatch()
    const refEditBank = useRef(null)

    const { condFilters } = useSelector(state => ({
        condFilters: state.bankReducer.listBankReducer.condFilters
    }))

    const [faceImg, setFaceImg] = useState(null);
    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            id: bankData.id,
            bankName: bankData.bankName,
            bankShortName: bankData.bankShortName,
            bankCode: bankData.bankCode
        }
    )

    useEffect(() => {
        const { id, bankName, bankShortName, bankCode } = stateForm
        const isEnable = id && bankName && bankShortName && bankCode
        setIsDisabledBtnCreate(!isEnable)
        return () => {
        }
    }, [stateForm, faceImg])

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const callbackSuccess = () => {
        setKeyShowForm("")
        if (!!isDetails) {
            dispatch(getGeneralBankInfos({ id: stateForm.id }))
        } else {
            dispatch(getListBank({ ...condFilters }))
        }
    }

    const _handleSubmit = async () => {
        const { id, bankName, bankShortName, bankCode } = stateForm

        const dataRequest = {
            id,
            bankName,
            bankShortName,
            bankCode
        }

        if (faceImg) {
            const base64 = await fileToBase64(faceImg)
            dataRequest.logo = base64.split(',')[1]
        }

        dispatch(updateBank(
            dataRequest, callbackSuccess, null
        ))

    }

    useOnClickOutside(refEditBank, () => setKeyShowForm(""))

    return (
        <OverlayFullScreen onClick={e => e.stopPropagation()}>
            <div className="wrap-inner-screen" >
                <WrapPopupEditBank ref={refEditBank}>
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <p className="title">Thông tin Banks</p>
                    <p className="description">{bankData?.bankShortName} <span>- {bankData?.bankCode}</span></p>
                    <div className="image">
                        <DragAndDropImage
                            defaultUrl={apiSupplierLogo(bankData.bankCode)}
                            handleRemoveFile={() => setFaceImg(null)}
                            handleDropFiles={files => setFaceImg(files[0])}
                        />
                    </div>
                    <InputBankName
                        valueInput={stateForm.bankName}
                        onChange={handleChangeValue}
                    />
                    <InputBankShortName
                        valueInput={stateForm.bankShortName}
                        onChange={handleChangeValue}
                    />
                    <InputBankCode
                        valueInput={stateForm.bankCode}
                        onChange={handleChangeValue}
                    />
                    <Button
                        text="Chỉnh sửa"
                        iconBtn={<IcEditWhite />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapPopupEditBank>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupEditBank
