import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// component
import IcShoppingBag from '../../../../../../assets/images/IcShoppingBag'
import IcShoppingBagActive from '../../../../../../assets/images/IcShoppingBagActive'
import IcShoppingBagError from '../../../../../../assets/images/IcShoppingBagError'
import InputDropdown from '../../../../../Control/InputDropdown'

// action
import { getListStoreMerchant, resetListStoreMerchant } from '../../../../../../redux/merchant/detailsMerchant/store/action'

const iconStore = {
    icon: IcShoppingBag,
    iconActive: IcShoppingBagActive,
    iconError: IcShoppingBagError,
}

const InputStore = ({ handleGetValue, merchant, store }) => {

    const dispatch = useDispatch()

    const { list } = useSelector(state => ({
        list: state.merchantReducer.detailsMerchantReducer.store.list.map(item => ({
            ...item,
            key: item.id,
            text: item.name
        }))
    }))

    const _handleSetValueDropdown = (val) =>{
        handleGetValue && handleGetValue(val)
    }

    const _handleScrollEnd = useCallback((callback) => {
        dispatch(getListStoreMerchant({
            merchantId: merchant?.key,
            filterFrom: list[0]?.id,
            filterSkip: list.length,
            filterSize: 20
        }, true, callback))
    }, [JSON.stringify(merchant), list])

    useEffect(() => {
        dispatch(resetListStoreMerchant())
    }, []);

    return (
        <InputDropdown 
            key={store?.key}
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder="Cửa hàng"
            dataFilterOption={list}
            objCategory={store}
            handleScrollEnd={_handleScrollEnd}
        />
    )
}

export default InputStore
