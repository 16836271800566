import React, { useState, useMemo } from 'react'

// component
import MoreOption from '../../../../../../../Control/MoreOption'
import IcEdit from '../../../../../../../../assets/images/IcEdit'
import Portal from '../../../../../../../Control/Portal'
import IcDelete from '../../../../../../../../assets/images/IcDetete'
import PopupEditAccountPermission from './PopupEditAccountPermission'
import PopupDelAccountPermission from './PopupDelAccountPermission'
import IcMail from '../../../../../../../../assets/images/IcMail'

const EDIT = "EDIT"
const RESEND = 'RESEND'
const DEL = "DEL"

const OptionPermission = ({ data, refList }) => {
    const { isDisable } = data

    const [activeKey, setActiveKey] = useState("");

    const handleEdit = () => {
        setActiveKey(EDIT)
    }

    const handleRecendPass = () => {
        setActiveKey(RESEND)
    }

    const handleDel = () => {
        setActiveKey(DEL)
    }


    const dataMoreOption = useMemo(() => {
        if (isDisable) {
            return [
                {
                    Icon: IcEdit,
                    text: "Edit",
                    handleOption: handleEdit
                },
                {
                    Icon: IcDelete,
                    text: "Remove",
                    handleOption: handleDel
                }
            ]
        }
        return [
            {
                Icon: IcEdit,
                text: "Edit",
                handleOption: handleEdit
            },
            {
                Icon: IcMail,
                text: "Resend Password",
                handleOption: handleRecendPass
            },
            {
                Icon: IcDelete,
                text: "Remove",
                handleOption: handleDel
            }
        ]
    }, [isDisable])

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
            />
            {
                activeKey === EDIT &&
                <Portal>
                    <PopupEditAccountPermission
                        data={data}
                        setActiveKey={setActiveKey}
                    />
                </Portal>
            }
            {
                activeKey === DEL &&
                <Portal>
                    <PopupDelAccountPermission
                        data={data}
                        setActiveKey={setActiveKey}
                    />
                </Portal>
            }
        </>
    )
}

export default OptionPermission
