import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { sha256 } from 'js-sha256'

// styles
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupEditAccount } from './index.styles'

// component
import IcEditWhite from '../../../../../../../../../assets/images/IcEditWhite'
import InputUser from '../../../../TabListAndTool/ToolAccount/PopupCreateAccount/InputUser'
import InputPassword from '../../../../../../../../Control/InputPassword'
import Button from '../../../../../../../../Control/Button'
import IcCreateNew from '../../../../../../../../../assets/images/IcCreateNew'
import IcClose from '../../../../../../../../../assets/images/IcClose'

// action
import { editAccountMerchant, getListAccountMerchant } from '../../../../../../../../../redux/merchant/detailsMerchant/account/action'
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'

const PopupEditAccount = ({ data, setActiveKey }) => {
    const { id, username, fullname } = data
    
    const dispatch = useDispatch()
    const refEditAccountBank = useRef(null)
    const { merchantId } = useParams()

    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({...state, ...action}),
        {
            fullname,
            username,
        }
    );
    
    const callbackUpdateAccountSuccess = () => {
        dispatch(getListAccountMerchant({
            merchantId,
            filterFrom: '', 
            filterSkip: 0, 
            filterSize: 20 
        }))
        setActiveKey('')
    }

    const _handleSubmit = () => {
        const { fullname } = stateForm
        dispatch(editAccountMerchant(
            {
                id, 
                fullname,
            }, 
            callbackUpdateAccountSuccess
        ))
    }

    const _handleChange = (stateKey) => (e) => {
        setStateForm({ [stateKey]: e.target.value})
    }
  
    useEffect(() => {
        if(Object.values(stateForm).some(item => !item)){
            setIsDisabledBtnCreate(true)
            return;
        }
        setIsDisabledBtnCreate(false)
    }, [stateForm]);

    useOnClickOutside(refEditAccountBank, () => setActiveKey(''))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupEditAccount ref={refEditAccountBank}>
                    <IcClose className="ic-close" onClick={() => setActiveKey('')} />
                    <div className="title">Thông tin tài khoản</div>
                    <div className="sub-title">Thông tin chi tiết tài khoản</div>
                    <InputUser 
                        valueInput={stateForm.fullname}
                        onChange={_handleChange("fullname")}
                        placeholder="Họ tên"
                        className="full-name"
                    />
                    <InputUser 
                        valueInput={stateForm.username}
                        onChange={_handleChange("username")}
                        placeholder="Email"
                        disabled="disabled"
                        autoComplete="off"
                    />                    
                    <Button
                        text="Chỉnh sửa"
                        iconBtn={<IcEditWhite />}
                        className="btn-submit"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapPopupEditAccount>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupEditAccount
