import { combineReducers } from "redux";

import listDevicesReducer from './listDevices'
import listDevicesByAdmin from './listDevicesByAdmin'
import deviceCountReducer from './deviceCount'
import detailsByAdmin from './detailsByAdmin'

const deviceReducer = combineReducers({
    listDevicesReducer,
    listDevicesByAdmin,
    deviceCountReducer,
    detailsByAdmin
})

export default deviceReducer;