import styled from 'styled-components'
//image
import bg_bank_login from '../../../assets/images/bg_bank_login.png'

const WrapLogin = styled.div`
    min-width: 100vw;
    width: 1280px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #313131;
    padding: 50px 60px 56px;
    box-sizing: border-box;
    background-image:url(${bg_bank_login});
    background-size:100%;
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
    position: relative;
    .bg_terminal{
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        display: flex;
    }
    .wrap-inner-login {
        display: flex;
        width: 298px;
        height: 478px;
        position: absolute;
        left: 50%;
        bottom: 169px;
        transform: translate(-50%, 0);
        .login-form{
            width: 298px;
            background: #FFFFFF;
            text-align: center;
            .icon-logo{
                padding-top: 41px;
                width: 100%;
            }
            .title{
                padding-top: 34px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                color: #313131;
            }
            .note{
                color: #777777;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                font-family: "Open Sans";
                padding-bottom: 38px;
            }
            .form{
                display: flex;
                flex-direction: column;
                padding: 0 18px;
                .error{
                    color: #F45C5C;
                    font-size: 13px;
                    line-height: 18px;
                    height: 20px;
                }
                .btn-login{
                    margin-top: 20px;
                }
            }            
        }        
    }
    .logo-crafted-with{
        position: absolute;
        left: 50%;
        bottom: 41px;
        transform: translate(-50%, 0);
    }
`;

export {
    WrapLogin
}

