import Axios from 'axios'

//host
import { host } from '../../../../constant/host'

//help
import checkIsSuccessAction from '../../../../helper/checkIsSuccessAction';
import mapErrors from '../../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListStoreMerchant = `${host}/merchant/store/filter`
const apiCreateStoreMerchant = `${host}/merchant/store/create`
const apiUpdateStoreMerchant = `${host}/merchant/store/update`
const apiDeleteStoreMerchant = `${host}/merchant/store/delete`
const apiGetStoreSearchByCode = `${host}/merchant/store/search/byCode`
const apiGetStoreSearchByName = `${host}/merchant/store/search/byName`

export const getListStoreMerchant = ({ merchantId, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        merchantId, 
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetListStoreMerchant, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_STORE_MERCHANT,
                payload: {
                    list: data.storeList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_STORE_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_STORE_MERCHANT_ERR))
    }
}

export const getListStoreSearchByCode = ({ merchantId, code, filterFrom, filterSkip, filterSize  }, isScroll, callbackSuccess) => async dispatch =>{
    const condFilters = {
        merchantId, 
        code,
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetStoreSearchByCode, dataRequest);
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_STORE_MERCHANT_SEARCH_CODE,
                payload: {
                    list: data.storeList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_STORE_MERCHANT_SEARCH_CODE_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_STORE_MERCHANT_SEARCH_CODE_ERR))
    }
}

export const getListStoreSearchByName = ({ merchantId, name, filterFrom, filterSkip, filterSize  }, isScroll, callbackSuccess) => async dispatch =>{
    const condFilters = {
        merchantId, 
        name,
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetStoreSearchByName, dataRequest);
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_STORE_MERCHANT_SEARCH_NAME,
                payload: {
                    list: data.storeList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_STORE_MERCHANT_SEARCH_NAME_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_STORE_MERCHANT_SEARCH_NAME_ERR))
    }
}

export const createStoreMerchant = ({ merchantId, code, name, address, lat, lng }, callbackSuccess, callbackError) => async dispatch =>{
    const dataRequest = JSON.stringify({
        merchantId, 
        code,
        name,
        address,
        lat,
        lng
    })
    try{
        const res = await Axios.post(apiCreateStoreMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.CREATE_STORE_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.CREATE_STORE_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }catch(err){
        dispatch(mapErrors(err, types.CREATE_STORE_MERCHANT_ERR))
    }
}


export const updateStoreMerchant = ({ merchantId, id, code, name, address, lat, lng }, callbackSuccess, callbackError) => async dispatch =>{
    const dataRequest = JSON.stringify({
        merchantId,
        id, 
        code,
        name,
        address,
        lat,
        lng
    })
    try{
        const res = await Axios.post(apiUpdateStoreMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.UPDATE_STORE_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.UPDATE_STORE_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }catch(err){
        dispatch(mapErrors(err, types.UPDATE_STORE_MERCHANT_ERR))
    }
}

export const deleteStoreMerchant = ({id}, callbackSuccess, callbackError) => async dispatch =>{
    try {
        const res = await Axios.delete(`${apiDeleteStoreMerchant}?id=${id}`)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.DELETE_STORE_MERCHANT
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.DELETE_STORE_MERCHANT_ERR,
                payload: { ...data }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.DELETE_STORE_MERCHANT_ERR))
    }
}

export const resetListStoreMerchant = () =>({
    type: types.RESET_LIST_STORE_MERCHANT
})