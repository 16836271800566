export const GET_LIST_STORE_MERCHANT = 'GET_LIST_STORE_MERCHANT'
export const GET_LIST_STORE_MERCHANT_ERR = 'GET_LIST_STORE_MERCHANT_ERR'

export const CREATE_STORE_MERCHANT = 'CREATE_STORE_MERCHANT'
export const CREATE_STORE_MERCHANT_ERR = 'CREATE_STORE_MERCHANT_ERR' 

export const UPDATE_STORE_MERCHANT = 'UPDATE_STORE_MERCHANT'
export const UPDATE_STORE_MERCHANT_ERR = 'UPDATE_STORE_MERCHANT_ERR'

export const DELETE_STORE_MERCHANT = 'DELETE_STORE_MERCHANT'
export const DELETE_STORE_MERCHANT_ERR = 'DELETE_STORE_MERCHANT_ERR'

export const GET_STORE_MERCHANT_SEARCH_CODE = 'GET_STORE_MERCHANT_SEARCH_CODE'
export const GET_STORE_MERCHANT_SEARCH_CODE_ERR = 'GET_STORE_MERCHANT_SEARCH_CODE_ERR'

export const GET_STORE_MERCHANT_SEARCH_NAME = 'GET_STORE_MERCHANT_SEARCH_NAME'
export const GET_STORE_MERCHANT_SEARCH_NAME_ERR = 'GET_STORE_MERCHANT_SEARCH_NAME_ERR'

export const RESET_LIST_STORE_MERCHANT = 'RESET_LIST_STORE_MERCHANT'
