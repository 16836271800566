import React, { useState, useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'

// component
import GeneralInfos from './GeneralInfos'
import TabListAndTool, { STORE, ACCOUNT, ADVERTISEMENT, listTabs } from './TabListAndTool'
import ListStores from './ListStores'
import ListAccount from './ListAccount'
import ListAdvertisement from './ListAdvertisement'

// styles
import { WrapMerchantDetails } from './index.styles'

// action
import { getDetailsMerchant } from '../../../../../redux/merchant/detailsMerchant/generalInfos/action'

const MerchantDetails = () => {
    const { merchantId } = useParams()
    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(listTabs[0]);

    useEffect(() => {
        dispatch(getDetailsMerchant({ id: merchantId }))
    }, []);

    const listData = useMemo(() => {
        const { type } = activeTab
        switch(type) {
            case STORE:
                return <ListStores merchantId={merchantId} />
            case ACCOUNT:
                return <ListAccount merchantId={merchantId} />
            case ADVERTISEMENT:
                return <ListAdvertisement merchantId={merchantId} />
            default:
                return null
        }
    }, [activeTab, merchantId])

    return (
        <WrapMerchantDetails>
            <div className="wrap-head">
                <GeneralInfos />
                <TabListAndTool 
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
            {listData}
        </WrapMerchantDetails>
    )
}

export default MerchantDetails
