import React from 'react'

//style
import { ButtonWrapper } from './index.styles'

const Button = ({ text, className, onClick, iconBtn, isDisable, ...props}) => {
    return (
        <ButtonWrapper className={`${className}${isDisable ? " disable" : ""}`} onClick={onClick} isDisable={isDisable} {...props}>
            {
                iconBtn && iconBtn
            }
            {text}
        </ButtonWrapper>
    )
}

export default Button
