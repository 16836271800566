import React from 'react'

const IcRefreshWhite = (props) => {
    return (
        <svg {...props} width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 10V7H8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M0.5 2V5H3.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.245 4.49999C9.99142 3.78338 9.56043 3.14269 8.99227 2.6377C8.4241 2.13271 7.73727 1.77987 6.99586 1.61212C6.25445 1.44437 5.48262 1.46716 4.7524 1.67838C4.02219 1.8896 3.35737 2.28235 2.82 2.81999L0.5 4.99999M11.5 6.99999L9.18 9.17999C8.64263 9.71764 7.97781 10.1104 7.2476 10.3216C6.51738 10.5328 5.74555 10.5556 5.00414 10.3879C4.26273 10.2201 3.5759 9.86727 3.00773 9.36228C2.43957 8.85729 2.00858 8.2166 1.755 7.49999" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcRefreshWhite
