import React from 'react'

const IcMapActive = ({...props}) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.75 4.5V16.5L6 13.5L12 16.5L17.25 13.5V1.5L12 4.5L6 1.5L0.75 4.5Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 4.5V16.5" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 1.5V13.5" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMapActive
