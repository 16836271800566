import Axios from 'axios'

//host
import { host } from '../../../../constant/host'

//help
import checkIsSuccessAction from '../../../../helper/checkIsSuccessAction';
import mapErrors from '../../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetMerchantDetails = `${host}/merchant/chain/details`

export const getGeneralMerchantInfos = (infos) => ({
    type: types.GET_GENERAL_MERCHANT_INFOS,
    payload: {
        infos
    }
})

export const getDetailsMerchant = ({ id }) => async dispatch => {
    const dataRequest = JSON.stringify({
        id
    })

    try {
        const res = await Axios.post(`${apiGetMerchantDetails}?id=${id}`, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch(getGeneralMerchantInfos(data.details))
        }
        else {
            dispatch({
                type: types.GET_GENERAL_MERCHANT_INFOS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_GENERAL_MERCHANT_INFOS_ERR))
    }
}


export const resetGeneralMerchantInfos = () => ({
    type: types.RESET_GENERAL_MERCHANT_INFOS
})