import React from 'react'
import IcInputUser from '../../../../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../../../../assets/images/IcInputUserError'
import Input from '../../../../../../../Control/Input'


const iconFullname = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

const InputFullname = ({ valueInput, onChange, placeholder, ...props }) => {
    return (
        <Input
            type="text"
            className="input-setting"
            placeholder={placeholder}
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconFullname}
            {...props}
        />
    )
}

export default InputFullname
