import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListDevicesByAdmin = `${host}/terminal/filter`
const apiGetListDevicesByAdminById = `${host}/terminal/search/byId`
const apiCreateDevice = `${host}/terminal/create`
const apiEditDevice = `${host}/terminal/update`
const apiDelDevice = `${host}/terminal/delete`
const apiControlDevice = `${host}/terminal/control`

export const setDataScreenShot = (screenShot) => ({
    type: types.SET_DATA_SCREEN_SHOT,
    payload: {
        screenShot
    }
})

export const getListDevicesByAdmin = ({ filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        filterFrom, 
        filterSkip, 
        filterSize
    }

    const dataRequest = JSON.stringify(condFilters)
    
    try {
        const res = await Axios.post(apiGetListDevicesByAdmin, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_DEVICES_BY_ADMIN,
                payload: {
                    list: data.deviceList || [],
                    condFilters,
                    total: data.total,
                    totalDisconnect: data.disconnectedCount || 0,
                    type: types.GET_LIST_DEVICES_BY_ADMIN,
                    isScroll
                }
            })

            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_DEVICES_BY_ADMIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_DEVICES_BY_ADMIN_ERR))
    }
}

export const resetListDevicesAdmin = () => ({
    type: types.RESET_LIST_DEVICES_BY_ADMIN
})

export const getListDevicesByAdminById = ({ id, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        id,
        filterFrom, 
        filterSkip, 
        filterSize
    }

    const dataRequest = JSON.stringify(condFilters)
    
    try {
        const res = await Axios.post(apiGetListDevicesByAdminById, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_DEVICES_BY_ADMIN_BY_ID,
                payload: {
                    list: data.deviceList || [],
                    condFilters,
                    total: data.total,
                    totalDisconnect: data.disconnectedCount || 0,
                    type: types.GET_LIST_DEVICES_BY_ADMIN_BY_ID,
                    isScroll
                }
            })

            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_DEVICES_BY_ADMIN_BY_ID_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_DEVICES_BY_ADMIN_BY_ID_ERR))
    }
}

export const getListDevicesByAdminByStatus = ({ status=-1, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        status,
        filterFrom, 
        filterSkip, 
        filterSize
    }

    const dataRequest = JSON.stringify(condFilters)
    
    try {
        const res = await Axios.post(apiGetListDevicesByAdmin, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_DEVICES_BY_ADMIN_BY_STATUS,
                payload: {
                    list: data.deviceList || [],
                    condFilters,
                    total: data.total,
                    totalDisconnect: data.disconnectedCount || 0,
                    type: types.GET_LIST_DEVICES_BY_ADMIN_BY_STATUS,
                    isScroll
                }
            })

            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_DEVICES_BY_ADMIN_BY_STATUS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_DEVICES_BY_ADMIN_BY_STATUS_ERR))
    }
}

export const toggleListViewMode = () => ({
    type: types.TOGGLE_LIST_VIEW_MODE,
})

export const resetListViewMode = () => ({
    type: types.RESET_LIST_VIEW_MODE,
})

export const createDevice = ({ name, merchantId, storeId }, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        name,
        merchantId,
        storeId
    })
    try {
        const res = await Axios.post(apiCreateDevice, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CREATE_DEVICE,
            })
            callbackSuccess && callbackSuccess({
                token: data.token,
                expiredDate: data.expiredDate
            })
        }
        else {
            dispatch({
                type: types.CREATE_DEVICE_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.CREATE_DEVICE_ERR))
    }
}

export const editDevice = ({ id, name, merchantId, storeId }, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        id,
        name,
        merchantId,
        storeId
    })
    try {
        const res = await Axios.post(apiEditDevice, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.EDIT_DEVICE,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.EDIT_DEVICE_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.EDIT_DEVICE_ERR))
    }
}

export const delDevice = ({ id }, callbackSuccess) => async dispatch => {
    try {
        const res = await Axios.delete(`${apiDelDevice}?id=${id}`)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.DEL_DEVICE,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.DEL_DEVICE_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.DEL_DEVICE_ERR))
    }
}

/**
 * @param
 * action: "screenshot" \| "restartApp" \| "reboot"
 */
const SCREEN_SHOT = "screenshot"
export const controlDevice = (action, callbackSuccess, deviceId) => async dispatch => {

    try {
        const res = await Axios.post(`${apiControlDevice}?action=${action}`)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            if(action === SCREEN_SHOT) {
                dispatch(setDataScreenShot({
                    deviceId,
                    isLoading: true
                }))
            }
            dispatch({
                type: types.CONTROL_DEVICE,
                payload: {
                    action
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.CONTROL_DEVICE_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.CONTROL_DEVICE_ERR))
    }
}

export const toggleShowPopupAdd = (isShow) => ({
    type: types.TOGGLE_SHOW_POPUP_ADD,
    payload: {
        isShow
    }
})

export const setTotalDisconnected = (total) => ({
    type: types.SET_TOTAL_DEVICE_DISCONNECTED,
    payload: {
        total
    }
})

