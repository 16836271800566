import styled from 'styled-components'

import { WrapCreateNewPopup } from '../../../../TabListAndTool/ToolStore/CreateNewStore/index.styles';

const WrapPopupEditAdvertisement = styled(WrapCreateNewPopup)`
    width: 300px;
    box-sizing: border-box;
    padding-bottom: 42px;
    .title{
        margin-bottom: 10px;
    }
    .description{
        display: flex;
        margin-top: 8px;
        .desc-img{
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            color: #313131;
            letter-spacing: -0.01em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .desc-size{
            margin-left: 4px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #777777;
            white-space: nowrap;
        }
    }
    .btn-creteNew{
        width: 100%;
        margin-top:22px;
    }
`;

export {
    WrapPopupEditAdvertisement,
    WrapCreateNewPopup,
}