import React from 'react'

const IcDeleteWhite = (props) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.25 3.5H3.41667H12.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.1665 3.49984V2.33317C5.1665 2.02375 5.28942 1.72701 5.50821 1.50821C5.72701 1.28942 6.02375 1.1665 6.33317 1.1665H8.6665C8.97592 1.1665 9.27267 1.28942 9.49146 1.50821C9.71026 1.72701 9.83317 2.02375 9.83317 2.33317V3.49984M11.5832 3.49984V11.6665C11.5832 11.9759 11.4603 12.2727 11.2415 12.4915C11.0227 12.7103 10.7259 12.8332 10.4165 12.8332H4.58317C4.27375 12.8332 3.97701 12.7103 3.75821 12.4915C3.53942 12.2727 3.4165 11.9759 3.4165 11.6665V3.49984H11.5832Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.6665 6.4165V9.9165" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.3335 6.4165V9.9165" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcDeleteWhite
