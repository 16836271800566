import React from 'react'
import { useHistory } from 'react-router'

// image
import DevicesInfo from '../../../../../../assets/images/img_devices_info.png'

// component
import IcMapActive from '../../../../../../assets/images/IcMapActive'

// styles
import { WrapPopupDeviceInfos } from './index.styles'

// data
import dataDeviceStatus, { mapStatus } from '../../../../../../data/dataDeviceStatus'

// helper
import { convertDateTime } from '../../../../../../helper/convertDateTime'

// constant
import { DEVICE, LOCATION, MAIN } from '../../../../../../constant/path'

const PopupDeviceInfos = ({ data }) => {
    const {
        name,
        id,
        status,
        merchantName,
        merchantStoreName,
        appVersion,
        lat,
        lng,
        lastPingTime
    } = data
    const history = useHistory()

    const objStatus = dataDeviceStatus[mapStatus[status]] || {}
    const { vnText, color } = objStatus

    const _handleOpenMap = () => {
        history.push(`/${MAIN}/${DEVICE}/${id}/${LOCATION}`)
    }
    
    return (
        <WrapPopupDeviceInfos colorByStatus={color}>
            <div className="wrap-row">
                <div className="image">
                    <img src={DevicesInfo} alt="device"/>
                </div>
                <div className="wrap-column">
                    <div className="device-name" title={name}>{name}</div>
                    <div className="device-id">DeviceID: {id.substring(0, 9).toUpperCase()}</div>
                </div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Trạng thái</div>
                <div className="field-value status">{vnText}</div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Merchant</div>
                <div className="field-value" title={merchantName}>{merchantName}</div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Cửa hàng</div>
                <div className="wrap-outer-value">
                    <div className="field-value" title={merchantStoreName}>{merchantStoreName}</div>
                    <IcMapActive 
                        className="map"
                        onClick={_handleOpenMap}
                    />
                </div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Phiên bản</div>
                <div className="field-value" title={appVersion}>{appVersion}</div>
            </div>
            <div className="wrap-field">
                <div className="field-name">Location</div>
                <div className="field-value" title={`${lat},${lng}`}>{`${lat},${lng}`}</div>
            </div>
            <div className="wrap-field last-field">
                <div className="field-name">Lần cuối kết nối</div>
                <div className="field-value">{convertDateTime(lastPingTime, "HH:mm - DD/MM/YYYY")}</div>
            </div>
        </WrapPopupDeviceInfos>
    )
}

export default PopupDeviceInfos
