import React from 'react'
//image
import IcInputBank from '../../../../../../assets/images/IcInputBank'
import IcInputBankActive from '../../../../../../assets/images/IcInputBankActive'
import IcInputBankError from '../../../../../../assets/images/IcInputBankError'
//component
import Input from '../../../../../Control/Input'


const iconUser = {
    icon: IcInputBank,
    iconActive: IcInputBankActive,
    iconError: IcInputBankError,
}

const InputBankName = (valueInput) => {
    return (
        <Input
            name="bankName"
            className="input-merchant"
            placeholder="Tên đây đủ của Banks"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconUser}
        />
    )
}

export default InputBankName
