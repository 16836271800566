import React, { useState, useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPermissionItem } from './index.styles'

//component
import IcLockRed from '../../../../../../../assets/images/IcLockRed'
import IcUnlockBlue from '../../../../../../../assets/images/IcUnlockBlue'
import OptionPermission from './OptionPermission'

//help
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import BtnToggle from '../../../../../../Control/BtnToggle'
import { convertNumberSttList } from '../../../../../../../helper/convertSttList'

//action
import { getListPermission, updateAccountPermission } from '../../../../../../../redux/permission/listPermission/action'

const PermissionItem = (props) => {

    const dispatch = useDispatch()
    const { data, stt, refList } = props

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            id: data.id,
            email: data.email,            fullname: data.fullname,
            dailyReport: data.dailyReport,
            monthlyReport: data.monthlyReport,
            createTime: data.createTime,
            lastUpdateAt: data.lastUpdateAt,
            isDisable: data.isDisable
        }
    );

    useEffect(() => { setStateForm({ id: data.id }) }, [data.id])
    useEffect(() => { setStateForm({ email: data.email }) }, [data.email])
    useEffect(() => { setStateForm({ fullname: data.fullname }) }, [data.fullname])
    useEffect(() => { setStateForm({ dailyReport: data.dailyReport }) }, [data.dailyReport])
    useEffect(() => { setStateForm({ monthlyReport: data.monthlyReport }) }, [data.monthlyReport])
    useEffect(() => { setStateForm({ createTime: data.createTime }) }, [data.createTime])
    useEffect(() => { setStateForm({ lastUpdateAt: data.lastUpdateAt }) }, [data.lastUpdateAt])
    useEffect(() => { setStateForm({ isDisable: data.isDisable }) }, [data.isDisable])

    const callbackSuccess = () => {
        dispatch(getListPermission({
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
    }


    const handleChangeToggleDaily = (isCheck) => {

        setStateForm({
            dailyReport: isCheck
        })

        const dataRequest = {
            ...stateForm,
            dailyReport: isCheck
        }

        dispatch(updateAccountPermission(
            dataRequest, callbackSuccess, null
        ))
    }

    const handleChangeToggleMonthly = (isCheck) => {

        setStateForm({
            monthlyReport: isCheck
        })

        const dataRequest = {
            ...stateForm,
            monthlyReport: isCheck
        }

        dispatch(updateAccountPermission(
            dataRequest, callbackSuccess, null
        ))
    }
    const NumberStt = convertNumberSttList(stt)

    return (
        <WrapPermissionItem {...props}>
            <div className="normal">
                {NumberStt || ""}
            </div>
            <div className="normal" title={stateForm?.fullname || ""}>
                {stateForm?.fullname || "-"}
            </div>
            <div className="normal" title={stateForm?.email || ""}>
                {stateForm?.email || "-"}
            </div>
            <div className="btn-toggle">
                <div className="btn-daily">
                    <p className="normal">Daily</p>
                    <BtnToggle
                        defaultChecked={stateForm?.dailyReport}
                        handleChangeCheck={(isCheck) => handleChangeToggleDaily(isCheck)}
                    />
                </div>
                <div className="btn-daily">
                    <p className="normal">Monthly</p>
                    <BtnToggle
                        defaultChecked={stateForm?.monthlyReport}
                        handleChangeCheck={(isCheck) => handleChangeToggleMonthly(isCheck)}
                    />
                </div>
            </div>
            <div className="normal">{stateForm?.createTime ? convertDateTime(stateForm.createTime, "DD/MM/YYYY") : "-"}</div>
            <div className="normal">{stateForm?.lastUpdateAt ? convertDateTime(stateForm.lastUpdateAt, "DD/MM/YYYY") : "-"}</div>
            <div className="wrap-tools">
                {
                    stateForm?.isDisable === false
                        ?
                        <p className="active">Active</p>
                        :
                        <p className="active false">Deactive</p>
                }
                <OptionPermission
                    data={data}
                    className="option"
                    refList={refList}
                />
            </div>
        </WrapPermissionItem>
    )
}

export default PermissionItem
