import styled from 'styled-components'

const OverlayFullScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    width: 1280px;
    height: 800px;
    background: ${ props => props.notUseOverlay ? 'transparent' : '#22222280' };
    z-index: 6;

    .wrap-inner-screen {
        width: 1280px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const WrapPopupShowMedia = styled.div`
    /* width: 680px; */
    height: 680px;
    min-width: 680px;
    position: relative;
    /* background: gray; */
    
    .close {
        position: absolute;
        bottom: calc(100% + 12px);
        right: 5px;
        cursor: pointer;
    }

    video, img {
        max-width: 1280px;
        height: 100%;
        object-fit: contain;
        border-radius: 3px;
    }
`;

export {
    OverlayFullScreen,
    WrapPopupShowMedia
}