import React, {useRef} from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

// component
import Button from '../../../../../../../../Control/Button'
import IcLockWhite from '../../../../../../../../../assets/images/IcLockWhite'

// styles
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupLockAccount } from './index.styles'

// action
import { getListAccountMerchant, lockAccountMerchant } from '../../../../../../../../../redux/merchant/detailsMerchant/account/action'
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'

const PopupLockAccount = ({ data: { id, username }, setActiveKey }) => {
    
    const dispatch = useDispatch()
    const refEditAccountBank = useRef(null)
    const { merchantId } = useParams()

    const callbackSuccess = () => {
        dispatch(getListAccountMerchant({
            merchantId,
            filterFrom: '', 
            filterSkip: 0, 
            filterSize: 20 
        }))
        setActiveKey('')
    }

    const _handleLock = () => {
        dispatch(lockAccountMerchant(
            { id },
            callbackSuccess
        ))
    }

    useOnClickOutside(refEditAccountBank, () => setActiveKey(''))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupLockAccount ref={refEditAccountBank}>
                    <div className="title">Khóa tài khoản</div>
                    <div className="sub-title">
                        Bạn vui lòng xác nhận khóa tài khoản <span title={username}>“{username.length > 20 ? `${username.substring(0, 20)}...` : username}”</span>
                    </div>
                    <div className="notice">
                        *Tài khoản bị khóa sẽ không thể đăng nhập ứng dụng.
                    </div>
                    <Button
                        text="Khóa tài khoản"
                        iconBtn={<IcLockWhite />}
                        className="btn-del"
                        onClick={_handleLock}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={() => setActiveKey('')}
                    />
                </WrapPopupLockAccount>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupLockAccount
