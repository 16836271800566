import React from 'react'

const IcClock = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99967 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99967C14.6663 4.31778 11.6816 1.33301 7.99967 1.33301C4.31778 1.33301 1.33301 4.31778 1.33301 7.99967C1.33301 11.6816 4.31778 14.6663 7.99967 14.6663Z" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 4V8L10.6667 9.33333" stroke="#777777" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcClock
