import styled from 'styled-components'
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapDeviceList = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    .wrap-device-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wrap-status-device{
            display: flex;
            align-items: center;
            .devices-status{
                margin-left: 32px;
                display: flex;
                align-items: center;
                .block-status{
                    width: 12px;
                    height: 12px;
                    margin-right: 8px;
                }
                p{
                    color: #313131;
                    letter-spacing: -0.01em;
                    font-family: "Open Sans";
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }
    }
    .wrap-list-devices {
        flex-grow: 1;
        position: relative;
        overflow: auto;

        .wrap-inner-list-devices {
            position: absolute;
            width: 100%;

            & > *:not(:first-child) {
                margin-top: 6px;
            }
        }
    }
`;

export {
    WrapDeviceList
}