import * as types from './types'

const initialState = {
    list: [],
    condFilters: {},
    total: 0,
    listCategories: {}
}
const listMerchantReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_CATEGORIES:
            return {
                ...state,
                listCategories: action.payload.listCategories
            }
        case types.GET_LIST_MERCHANT:
            const { list, ...payload } = action.payload
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        case types.RESET_LIST_MERCHANT:
            return {
                ...initialState,
                listCategories: state.listCategories
            }
        default:
            return state;
    }

}

export default listMerchantReducer