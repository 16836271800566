import React from 'react'

//image
import IcInputBank from '../../../../../../assets/images/IcInputBank'
import IcInputBankActive from '../../../../../../assets/images/IcInputBankActive'
import IcInputBankError from '../../../../../../assets/images/IcInputBankError'
//component
import Input from '../../../../../Control/Input'

const iconEmail = {
    icon: IcInputBank,
    iconActive: IcInputBankActive,
    iconError: IcInputBankError,
}

const InputBankShortName = (valueInput) => {
    return (
        <Input
            name="bankShortName"
            className="input-merchant"
            placeholder="Tên Banks"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconEmail}
        />
    )
}

export default InputBankShortName
