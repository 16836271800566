import styled from 'styled-components'
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapDeviceMapByAdmin = styled(WrapPageItem)`
    padding: 0;
    position: relative;
    height: 100%;

    .wrap-map {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 100%;
        
        .tip-popup {
            width: 300px;
            .leaflet-popup-content-wrapper {
                border-radius: 4px;
                padding: 0;
                .leaflet-popup-content {
                    margin: 0;
                    .row {
                        padding-left: 16px;
                        padding-right: 16px;
                        display: flex;
                        /* align-items: center; */
                        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
                        font-family: "Open Sans";

                        &.row-title {
                            padding-top: 15px;
                            padding-bottom: 15px;

                            img {
                                display: block;
                                min-width: 48px;
                                height: 48px;
                                object-fit: contain;
                            }

                            .general-infos {
                                margin-left: 12px;
                                min-width: 0;

                                .name {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 15px;
                                    line-height: 20px;
                                    letter-spacing: -0.01em;
                                    color: #313131;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .code {
                                    margin-top: 4px;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 11px;
                                    line-height: 15px;
                                    letter-spacing: -0.02em;
                                    color: #777777;
                                }
                            }
                        }

                        &.row-addr {
                            padding-top: 13px;
                            padding-bottom: 14px;

                            .value {
                                /* min-width: 0;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical; */
                            }
                        }

                        &.row-location {
                            padding-top: 13px;
                            padding-bottom: 13px;
                        }

                        & > .label {
                            white-space: nowrap;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 11px;
                            line-height: 18px;
                            letter-spacing: -0.02em;
                            color: #777777;
                        }

                        & > .value {
                            margin-left: 5px;
                            text-align: right;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 18px;
                            letter-spacing: -0.01em;
                            color: #313131;
                            flex-grow: 1;
                        }
                    }
                }
            }

            .leaflet-popup-tip-container {
                display: none;
            }
        }
    }
`;

export {
    WrapDeviceMapByAdmin
}