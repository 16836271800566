import React from 'react'

const IcViewList = ({...props}) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.583008 3.50033V12.8337L4.66634 10.5003L9.33301 12.8337L13.4163 10.5003V1.16699L9.33301 3.50033L4.66634 1.16699L0.583008 3.50033Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.33301 3.5V12.8333" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66699 1.16699V10.5003" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcViewList
