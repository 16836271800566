export const GET_DETAIL_TRANSACTION = "GET_DETAIL_TRANSACTION"
export const GET_DETAIL_TRANSACTION_ERR = "GET_DETAIL_TRANSACTION_ERR"

export const REQUEST_REFUND = 'REQUEST_REFUND'
export const REQUEST_REFUND_ERR = 'REQUEST_REFUND_ERR'

export const GET_REASON_REFUND = 'GET_REASON_REFUND'
export const GET_REASON_REFUND_ERR = 'GET_REASON_REFUND_ERR'

export const CLEAR_REASON_REFUND = 'CLEAR_REASON_REFUND'

export const CLEAR_TRANSACTION_DETAILS = "CLEAR_TRANSACTION_DETAILS"