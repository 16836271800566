import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPopupDeleteStore } from './index.styles'

//component
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import IcTrashWhite from '../../../../../../../../../assets/images/IcTrashWhite'
import Button from '../../../../../../../../Control/Button'

//action
import { deleteStoreMerchant, getListStoreMerchant } from '../../../../../../../../../redux/merchant/detailsMerchant/store/action'

const PopupDeleteStore = ({ dataStore: {id, name}, handleClose, setKeyShowForm }) => {
    const dispatch = useDispatch()
    
    const {condFilters} = useSelector(state => ({
        condFilters: state.merchantReducer.detailsMerchantReducer.store.condFilters
    }))

    const callbackSuccess = () =>{
        setKeyShowForm && setKeyShowForm()
        dispatch(getListStoreMerchant({ ...condFilters }))
    }

    const handleDelete = () =>{
        const dataRequest = {
            id
        }
        dispatch(deleteStoreMerchant(dataRequest, callbackSuccess))
    }

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupDeleteStore>
                    <div className="title">Xóa cửa hàng</div>
                    <div className="sub-title">
                        Bạn vui lòng xác nhận xóa cửa hàng <span title={name}>“{name.length > 20 ? `${name.substring(0, 20)}...` : name}”</span>
                    </div>
                    <div className="notice">
                        *Các thiết bị liên kết với cửa hàng sẽ mất kết nối
                    </div>
                    <Button
                        text="Xóa cửa hàng"
                        iconBtn={<IcTrashWhite />}
                        className="btn-del"
                        onClick={handleDelete}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={handleClose}
                    />
                </WrapPopupDeleteStore>
            </div>
        </OverlayFullScreen>
        
    )
}

export default PopupDeleteStore
