import React from 'react'

// component
import IcCloseOutlineWhite from '../../../../../../../../assets/images/IcCloseOutlineWhite'
import Button from '../../../../../../../Control/Button'

// styles
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupCannotDelMerchant } from './index.styles'

const PopupCannotDelMerchant = ({ numOfDevice, handleClose }) => {
    return (
        <OverlayFullScreen onClick={e => e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupCannotDelMerchant>
                    <div className="title">Không thể xóa Merchant</div>
                    <div className="sub-title">Merchant hiện tại đang có {numOfDevice || "-"} thiết bị đang kết nối.</div>
                    <div className="sub-title">Để tiếp hành xóa Merchant, bạn vui lòng xóa kết nối với tất cả thiết thị đang liên kết</div>
                    <div className="notice">*Merchant bị xóa sẽ không thể phục hồi</div>
                    <Button
                        text="Tôi đã hiểu"
                        iconBtn={<IcCloseOutlineWhite />}
                        className="btn-del"
                        onClick={handleClose}
                    />
                </WrapPopupCannotDelMerchant>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupCannotDelMerchant
