import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'

// constant
import { apiSupplierLogo } from '../../../../constant/host';
import * as path from '../../../../constant/path'

// styles
import { WrapHeaderContent } from './index.styles'

//help
import numberFormatToThousand from '../../../../helper/numberFormatToThousand';
//image
import IcMerchantLogoDefault from '../../../../assets/images/IcMerchantLogoDefault';
//action
import { getTransactionCount } from '../../../../redux/transaction/transactionCount/action';
import { getBankCount } from '../../../../redux/bank/bankCount/action';
import { getMerchantCount } from '../../../../redux/merchant/merchantCount/action';
import { getDeviceCount } from '../../../../redux/device/deviceCount/action';
import { getCustomerCount } from '../../../../redux/customer/customerCount/action';

// const dataNumberTrans = 313242
// const ImageBank = `https://picsum.photos/100/100`

const TotalTransaction = () => {

    const dispatch = useDispatch()

    const { total } = useSelector(state => ({
        total: state.transactionReducer.transactionCountReducer.total
    }))

    const [stringTransaction, setStringTransaction] = useState("")

    useEffect(() => {
        dispatch(getTransactionCount())
    }, [])
    
    useEffect(() => {
        if (total <= 1) {
            setStringTransaction("giao dịch")
        } else if (total > 1) {
            setStringTransaction("giao dịch")
        }
    }, [total])

    return (
        <>
            {numberFormatToThousand(total)} {stringTransaction}
        </>
    )
}

const TotalBank = () => {
    const dispatch = useDispatch()

    const { total } = useSelector(state => ({
        total: state.bankReducer.bankCountReducer.total
    }))

    const [stringBank, setStringBank] = useState("")

    useEffect(() => {
        dispatch(getBankCount())
    }, [])
    
    useEffect(() => {
        if (total <= 1) {
            setStringBank("Bank")
        } else if (total > 1) {
            setStringBank("Banks")
        }
    }, [total])

    return (
        <>{numberFormatToThousand(total)} {stringBank}</>
    )
}

const TotalMerchant = () => {
    const dispatch = useDispatch()

    const { total } = useSelector(state => ({
        total: state.merchantReducer.merchantCountReducer.total
    }))

    const [stringMerchant, setStringMerchant] = useState("")

    useEffect(() => {
        dispatch(getMerchantCount())
    }, [])
    
    useEffect(() => {
        if (total <= 1) {
            setStringMerchant("Merchant")
        } else if (total > 1) {
            setStringMerchant("Merchants")
        }
    }, [total])

    return (
        <>{numberFormatToThousand(total)} {stringMerchant}</>
    )
}

const TotalDevice = () => {
    const dispatch = useDispatch()

    const { total } = useSelector(state => ({
        total: state.deviceReducer.deviceCountReducer.total
    }))

    const [stringDevice, setStringDevice] = useState("")

    useEffect(() => {
        dispatch(getDeviceCount())
    }, [])
    
    useEffect(() => {
        if (total <= 1) {
            setStringDevice("Device")
        } else if (total > 1) {
            setStringDevice("Devices")
        }
    }, [total])

    return (
        <>{numberFormatToThousand(total)} {stringDevice}</>
    )
}

const TotalCustomer = () => {
    const dispatch = useDispatch()

    const { total } = useSelector(state => ({
        total: state.customerReducer.customerCountReducer.total
    }))

    const [stringCustomer, setStringCustomer] = useState("")

    useEffect(() => {
        dispatch(getCustomerCount())
    }, [])
    
    useEffect(() => {
        if (total <= 1) {
            setStringCustomer("Customer")
        } else if (total > 1) {
            setStringCustomer("Customers")
        }
    }, [total])

    return (
        <>{numberFormatToThousand(total)} {stringCustomer}</>
    )
}

const HeaderContent = () => {

    const [isErrLogoBank, setIsErrLogoBank] = useState(false)

    const { supplier, supplierShortName } = useSelector(state => ({
        supplier: state.loginReducer.dataLogin.supplier,
        supplierShortName: state.loginReducer.dataLogin.supplierShortName,
    }))

    const matchTransaction = useRouteMatch(`/${path.MAIN}/${path.TRANSACTION}/:facepayId`)

    return (
        <WrapHeaderContent>
            <div className="wrap-header-content">
                <div className="bank_info">
                    {
                        isErrLogoBank ?
                            <IcMerchantLogoDefault className="logo" />
                            :
                            <img
                                className="logo"
                                src={supplier ? apiSupplierLogo(supplier) : ""}
                                alt="logo"
                                onError={() => setIsErrLogoBank(true)}
                            />
                    }
                    <p className="name_bank">{supplierShortName || ""}</p>
                </div>
                <p className="total">
                    <Switch>
                        <Route path={`/${path.MAIN}/${path.ANALYTIC}`}>
                            <TotalTransaction />
                        </Route>
                        <Route path={`/${path.MAIN}/${path.TRANSACTION}`}>
                            {
                                !matchTransaction && <TotalTransaction />
                            }
                        </Route>
                        <Route path={`/${path.MAIN}/${path.DEVICE}`}>
                            <TotalDevice />
                        </Route>
                        <Route path={`/${path.MAIN}/${path.PAYMENT_TERMINAL}`}>
                            {/* <TotalDevice /> */}
                        </Route>
                        <Route path={`/${path.MAIN}/${path.MERCHANT}`}>
                            <TotalMerchant/>
                        </Route>
                        <Route path={`/${path.MAIN}/${path.BANK}`}>
                            <TotalBank />
                        </Route>
                        <Route path={`/${path.MAIN}/${path.CUSTOMER}`}>
                            <TotalCustomer/>
                        </Route>
                    </Switch>
                </p>
            </div>
        </WrapHeaderContent>
    )
}

export default HeaderContent
