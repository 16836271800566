import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// styles
import { WrapMerchantItem } from './index.styles'

//component
import OptionMerchant from './OptionMerchant'

//constant
import { MAIN, MERCHANT } from '../../../../../../constant/path'

//image
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault'

//help
import { apiMerchantLogo } from '../../../../../../constant/host'

const MerchantItem = ({ data, extendStyles, refList }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    
    const { listCategories } = useSelector(state => ({
        listCategories: state.merchantReducer.listMerchantReducer.listCategories
    }))

    const [isErrMerchantLogo, setIsErrMerchantLogo] = useState(false)

    useEffect(() => {
        setIsErrMerchantLogo(false)
    }, [data?.id])

    const _goToDetails = () => {
        history.push(`/${MAIN}/${MERCHANT}/${data.id}`)
    }

    return (
        <WrapMerchantItem extendStyles={extendStyles} onClick={_goToDetails}>
            <div className="wrap-logo">
                {
                    isErrMerchantLogo ?
                        <IcMerchantLogoDefault />
                        :
                        <img
                            className="logo"
                            src={data?.id ? apiMerchantLogo(data.id) : ""}
                            alt="logo"
                            onError={() => setIsErrMerchantLogo(true)}
                        />
                }
            </div>
            <div className="wrap-merchant-infos merchant-name">
                <div className="content-bold" title={data?.name || ""}>
                    {data?.name || "-"}
                </div>
                <div className="content-gray" title={data?.code || ""}>
                    {data?.code || ""}
                </div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold merchant-type" title={listCategories[data.category]?.text}>
                    {listCategories[data.category]?.text ?? '-' }
                </div>
                <div className="content-gray">Loại chuỗi</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{data?.advertisementTotal || "0"}</div>
                <div className="content-gray">Quảng cáo</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{data?.userTotal || "0"}</div>
                <div className="content-gray">Users</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{data?.storeTotal || "0"}</div>
                <div className="content-gray">Cửa hàng</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{data?.deviceTotal || "0"}</div>
                <div className="content-gray">Thiết bị</div>
            </div>
            <div className="wrap-tools">
                <OptionMerchant merchantData={data} refList={refList}/>
            </div>
        </WrapMerchantItem>
    )
}

export default MerchantItem
