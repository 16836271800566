import React from 'react'
import IcInputEmail from '../../../../../../../../../assets/images/IcInputEmail'
import IcInputEmailActive from '../../../../../../../../../assets/images/IcInputEmailActive'
import IcInputEmailError from '../../../../../../../../../assets/images/IcInputEmailError'
import Input from '../../../../../../../../Control/Input'

const iconEmail = {
    icon: IcInputEmail,
    iconActive: IcInputEmailActive,
    iconError: IcInputEmailError,
}

const InputEmail = ({ valueInput, onChange, placeholder, handleValidationUser, ...props }) => {
 
    return (
        <Input
            type="text"
            className="input-setting"
            placeholder={placeholder}
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconEmail}
            handleValidationUser={handleValidationUser}
            {...props}
        />
    )
}

export default InputEmail
