import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'


const apiGetTransactionCount = `${host}/transaction/count`

export const getTransactionCount = (callbackSuccess) => async dispatch => {
   
    try {
        const res = await Axios.post(apiGetTransactionCount);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_TRANSACTION_COUNT,
                payload: {
                    ...data
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_TRANSACTION_COUNT_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_TRANSACTION_COUNT_ERR))
    }
}
