import styled from 'styled-components'


// styles
import {WrapCreatNewAccountPermission} from '../../../CreatNewAccountPermission/index.styles'

const WrapPopupEditAccountPermission = styled(WrapCreatNewAccountPermission)`
    
`;

export {
    WrapPopupEditAccountPermission
}