export const GET_LIST_PERMISSION = 'GET_LIST_PERMISSION'
export const GET_LIST_PERMISSION_ERR = 'GET_LIST_PERMISSION_ERR'

export const RESET_LIST_PERMISSION = 'RESET_LIST_PERMISSION'

export const CREATE_NEW_ACCOUNT_PERMISSION = 'CREATE_NEW_ACCOUNT_PERMISSION'
export const CREATE_NEW_ACCOUNT_PERMISSION_ERR = 'CREATE_NEW_ACCOUNT_PERMISSION_ERR'

export const UPDATE_ACCOUNT_PERMISSION = 'UPDATE_ACCOUNT_PERMISSION'
export const UPDATE_ACCOUNT_PERMISSION_ERR = 'UPDATE_ACCOUNT_PERMISSION_ERR'

export const DELETE_ACCOUNT_PERMISSION = 'DELETE_ACCOUNT_PERMISSION'
export const DELETE_ACCOUNT_PERMISSION_ERR = 'DELETE_ACCOUNT_PERMISSION_ERR'

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

