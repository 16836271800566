import React from 'react'

const IcArrowGreenRight = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.91669 7H11.0834" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 2.91669L11.0833 7.00002L7 11.0834" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcArrowGreenRight
