import React from 'react'

const IcStore = ({...props}) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.25 8.25V11.6862C2.25 16.5 2.25 16.5 5.62312 16.5H11.0246C15.75 16.5 15.75 16.5 15.75 13.0638V8.25" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99987 9C10.3724 9 11.3849 7.8825 11.2499 6.51L10.7549 1.5H7.25237L6.74987 6.51C6.61487 7.8825 7.62737 9 8.99987 9Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.7326 9C15.2476 9 16.3576 7.77 16.2076 6.2625L15.9976 4.2C15.7276 2.25 14.9776 1.5 13.0126 1.5H10.7251L11.2501 6.7575C11.3776 7.995 12.4951 9 13.7326 9Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.22979 9C5.46729 9 6.58479 7.995 6.70479 6.7575L6.86979 5.1L7.22979 1.5H4.94229C2.97729 1.5 2.22729 2.25 1.95729 4.2L1.75479 6.2625C1.60479 7.77 2.71479 9 4.22979 9Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 12.75C7.7475 12.75 7.125 13.3725 7.125 14.625V16.5H10.875V14.625C10.875 13.3725 10.2525 12.75 9 12.75Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcStore
