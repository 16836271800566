import styled from 'styled-components'

const WrapImage = styled.div`
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 4px;
`

const WrapDropImage = styled(WrapImage)`
    position: relative;
    .wrap-face {
        width: 100%;
        height: 100%;
        /* border: 2px solid #33ADFF;
        box-sizing: border-box;
        border-radius: 4px; */
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
            display: block;
        }
    }

    .remove-face {
        position: absolute;
        right: 7px;
        bottom: 7px;

        &:hover {
            cursor: pointer;
        }
    }
`

const DragAndDropArea = styled(WrapImage)`
    /* height: 130px; */
    border: 1px dashed #B7B7B7;
    background: #F7F7F7;
    padding-bottom: 8px;
    text-align: center;

    .ic-upload {
        margin-top: 16px;
        padding: 15px;
        border-radius: 50%;
        background: #b7b7b71a;
    }


    p {
        margin-top: 11px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #777777;
        user-select: none;
    }

    &:hover {
        cursor: pointer;
        background: #EFF9FF;
        border: 1px dashed #33ADFF;
    }
`;

export {
    WrapDropImage,
    DragAndDropArea
}