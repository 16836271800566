import styled from 'styled-components'

import { WrapCreateNewPopup } from '../../../../../BreadcrumbAndTool/CreateNewMerchant/index.styles';

const WrapPopupEditMerchant = styled(WrapCreateNewPopup)`
    min-width: 320px;
    .title{
        margin-bottom: 10px;
    }
    .description{
        margin-bottom: 33px;
    }
    .image{
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
    .input-merchant{
        /* margin-bottom: 10px; */
        &:last-of-type{
            margin-bottom: 22px;
        }
    }
    .btn-creteNew{
        width: 100%;
    }
`;

export {
    WrapPopupEditMerchant
}