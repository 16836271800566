import React from 'react'

const IcCreateAdvertisement = ({...props}) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3337 6.46309V6.99976C13.3329 8.25767 12.9256 9.48165 12.1724 10.4892C11.4193 11.4967 10.3606 12.2337 9.15428 12.5904C7.94799 12.947 6.65873 12.9042 5.47877 12.4683C4.29881 12.0323 3.29137 11.2267 2.60672 10.1714C1.92206 9.11611 1.59687 7.8678 1.67964 6.61261C1.7624 5.35742 2.2487 4.16262 3.06599 3.20638C3.88328 2.25015 4.98778 1.58373 6.21477 1.30651C7.44175 1.02929 8.72548 1.15612 9.8745 1.66809" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3333 2.33301L7.5 8.17218L5.75 6.42217" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCreateAdvertisement
