import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

// component
import Button from '../../../../../../../../Control/Button'
import IcUnlockWhite from '../../../../../../../../../assets/images/IcUnlockWhite'

// styles
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupUnlockAccount } from './index.styles'

// action
import { getListAccountBank, unlockAccountBank } from '../../../../../../../../../redux/bank/detailtsBank/account/action'
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'

const PopupUnlockAccount = ({ data: { id, username }, setActiveKey }) => {

    const dispatch = useDispatch()
    const refEditAccountBank = useRef(null)
    const { bankId } = useParams()

    const callbackSuccess = () => {
        dispatch(getListAccountBank({
            bankId,
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        setActiveKey('')
    }

    const _handleLock = () => {
        dispatch(unlockAccountBank(
            { id },
            callbackSuccess
        ))
    }

    useOnClickOutside(refEditAccountBank, () => setActiveKey(''))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupUnlockAccount ref={refEditAccountBank}>
                    <div className="title">Mở khóa tài khoản</div>
                    <div className="sub-title">
                        Bạn vui lòng xác nhận mở khóa tài khoản <span title={username}>“{username.length > 20 ? `${username.substring(0, 20)}...` : username}”</span>
                    </div>
                    <Button
                        text="Mở khóa tài khoản"
                        iconBtn={<IcUnlockWhite />}
                        className="btn-unlock"
                        onClick={_handleLock}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={() => setActiveKey('')}
                    />
                </WrapPopupUnlockAccount>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupUnlockAccount
