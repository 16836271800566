import styled from 'styled-components'

// styles
import { Row } from '../index.styles';

const WrapItem = styled(Row)`
    min-height: 60px;
    background: #FFFFFF;

    .bold, .normal {
        font-family: "Open Sans";
        font-style: normal;
        letter-spacing: -0.01em;
        color: #313131;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bold {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
    }

    .normal {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
    }
`

const WrapStoreItem = styled(WrapItem)`

`;

export {
    WrapStoreItem,
    WrapItem,
}