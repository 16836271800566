import React, { useState, useRef } from 'react'

// component
import IcArrowDownGray from '../../../../../../assets/images/IcArrowDownGray'
import DropdownList from '../../../../../Control/DropdownList';

// styles
import { WrapDropdownSelectTypeChart } from './index.styles'

// hook
import { useOnClickOutside } from '../../../../../../hook/useClickOutside';

export const KEY_DAY = 0
export const KEY_MONTH = 1

export const dataFilterChartSelect = [
    {
        text: 'Day',
        key: KEY_DAY
    },
    {
        text: 'Month',
        key: KEY_MONTH
    },
]

const DropdownSelectTypeChart = ({ filterType: { text, key }, setFilterType }) => {

    const refTitleDropdown = useRef(null)

    const [isShowDropdown, setIsShowDropdown] = useState(false);

    const _handleSetValueSelect = (val) => {
        setFilterType(val)
        setIsShowDropdown(false)
    }

    useOnClickOutside(refTitleDropdown, () => setIsShowDropdown(false))

    return (
        <WrapDropdownSelectTypeChart ref={refTitleDropdown}>
            <div className="wrap-title" onClick={() => setIsShowDropdown(pre => !pre)}>
                <span className="value">{text}</span>
                <IcArrowDownGray className="icon" />
            </div>
            {
                isShowDropdown &&
                <DropdownList 
                    listItem={dataFilterChartSelect}
                    setValueSelect={_handleSetValueSelect}
                    alignTop="10px"
                />
            }
        </WrapDropdownSelectTypeChart>
    )
}

export default DropdownSelectTypeChart
