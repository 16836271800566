import styled from 'styled-components'

const WrapCreateNewBank = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 24px 24px 26px 24px;
    z-index: 20;
    position: relative;
    min-width: 320px;
    .icon-close{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    .title{
        color: #313131;
        letter-spacing: -0.01em;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .description{
        color: #313131;
        letter-spacing: -0.02em;
        font-family: "Open Sans";
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 33px;
    }
    .image{
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
    .input-merchant{
        /* margin-bottom: 10px; */
        &:last-of-type{
            margin-bottom: 22px;
        }
    }
    .btn-creteNew{
        width: 100%;
        margin-top: 42px
    }
`;

export {
    WrapCreateNewBank
}