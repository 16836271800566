import styled from 'styled-components'

const WrapDeviceItem = styled.div`
    padding: 16px 20px 24px;
    display: flex;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);

    .wrap-data {
        
        .title {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #313131; 
        }

        &.wrap-device-infos {
            flex-grow: 1;
            border-right: 1px solid #B7B7B7;
            .content{
                margin-top: 20px;   
                display: flex;
                .image{
                    width: 128px;
                    height: 128px;
                    display: flex;
                    justify-content: center;
                    border-radius: 50%;
                    /* border: 1px solid red; */
                    margin-right: 51px;
                    flex-shrink: 0;
                    border: 1px solid ${props => props.colorByStatus};
                    img{
                        height: 100%;
                    }
                }
                .content-right{
                    flex-grow: 1;
                    display: flex;
                    justify-content: space-between;

                    & > * {
                        flex-grow: 1;
                    }

                    .right-first, .right-last {
                        flex-basis: 30%;
                    }

                    .right-middle {
                        flex-basis: 40%;
                    }

                    .content-item{
                        margin-bottom: 18px;
                        .item-info{
                            color: #313131;
                            letter-spacing: -0.01em;
                            font-family: "Open Sans";
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 20px;
                            margin-bottom: 4px;
                            word-break: break-all;
                            max-width: 200px;
                            &.status {
                                color: ${props => props.colorByStatus};
                            }
                            &.name {
                                max-width: 20ch;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            &.version {
                                max-width: 15ch;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .title{
                            color: #777777;
                            letter-spacing: -0.02em;
                            font-family: "Open Sans";
                            font-size: 11px;
                            line-height: 15px;
                        }
                    }
                }
            }
        }

        &.wrap-transaction {
            flex-shrink: 0;
            margin-left: 58px;
            margin-right: 17px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .input-sent{
                width: 269px;
                height: 48px;

                input {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: -0.01em;
                    color: #313131;

                    &:placeholder-shown {
                        font-family: "Open Sans";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 18px;
                        letter-spacing: -0.01em;
                        color: #B7B7B7;
                    }
                }
            }
            .btn-sent{

            }
        }
    }
`;

export {
    WrapDeviceItem
}