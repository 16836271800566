import React from 'react'

const IcNewDevices = ({...props}) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0837 8.75033V2.33366C11.0837 1.68933 10.5613 1.16699 9.91699 1.16699H4.08366C3.43933 1.16699 2.91699 1.68933 2.91699 2.33366V11.667C2.91699 12.3113 3.43933 12.8337 4.08366 12.8337H7.00033" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.33301 9.33301V12.833" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.58301 11.083H11.083" stroke="#F7F7F7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcNewDevices
