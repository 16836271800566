import * as types from './types'

const initialState = {
    list: [],
    condFilters: {},
    total: 0
}
const listDevicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_DEVICES:
            return {
                ...state,
                ...action.payload
            }
        case types.RESET_LIST_DEVICES:
            return initialState
        default:
            return state;
    }

}

export default listDevicesReducer