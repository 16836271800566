import styled from 'styled-components'

import { WrapTools } from '../ToolStore/index.styles';

const WrapToolAccount = styled(WrapTools)`
    
`;

export {
    WrapTools,
    WrapToolAccount
}