import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'


const apiGetCustomerHistory = `${host}/customer/history`

export const getCustomerHistory = ({ id, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        id,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        id,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetCustomerHistory, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_CUSTOMER_HISTORY,
                payload: {                    
                    list: data.transactionList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll               
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_CUSTOMER_HISTORY_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_CUSTOMER_HISTORY_ERR))
    }
}

export const clearHistory = () => ({
    type: types.CLEAR_CUSTOMER_HISTORY
})

