import React from 'react'

const IcMerchantDefault = (props) => {
    return (
        <svg {...props} width={141} height={140} viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="70.5" cy={70} r={70} fill="#B7B7B7" />
            <path d="M40.5 66.667V81.9391C40.5 103.334 40.5 103.334 55.4917 103.334H79.4983C100.5 103.334 100.5 103.334 100.5 88.0616V66.667" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.5 70.0003C76.6 70.0003 81.1 65.0337 80.5 58.9337L78.3 36.667H62.7333L60.5 58.9337C59.9 65.0337 64.4 70.0003 70.5 70.0003Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M91.5337 70.0003C98.267 70.0003 103.2 64.5337 102.534 57.8337L101.6 48.667C100.4 40.0003 97.067 36.667 88.3337 36.667H78.167L80.5003 60.0337C81.067 65.5337 86.0337 70.0003 91.5337 70.0003Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M49.3001 70.0003C54.8001 70.0003 59.7668 65.5337 60.3001 60.0337L61.0334 52.667L62.6334 36.667H52.4668C43.7334 36.667 40.4001 40.0003 39.2001 48.667L38.3001 57.8337C37.6334 64.5337 42.5668 70.0003 49.3001 70.0003Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.4998 86.667C64.9332 86.667 62.1665 89.4337 62.1665 95.0003V103.334H78.8332V95.0003C78.8332 89.4337 76.0665 86.667 70.4998 86.667Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMerchantDefault
