import React from 'react'

const IcTransPhotoDefault = (props) => {
    return (
        <svg {...props} width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.3334 49V44.3333C37.3334 41.858 36.35 39.484 34.5997 37.7337C32.8494 35.9833 30.4754 35 28 35H11.6667C9.19135 35 6.81738 35.9833 5.06704 37.7337C3.3167 39.484 2.33337 41.858 2.33337 44.3333V49" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42 18.6667L53.6667 30.3334" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M53.6667 18.6667L42 30.3334" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8333 25.6667C24.988 25.6667 29.1667 21.488 29.1667 16.3333C29.1667 11.1787 24.988 7 19.8333 7C14.6787 7 10.5 11.1787 10.5 16.3333C10.5 21.488 14.6787 25.6667 19.8333 25.6667Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcTransPhotoDefault
