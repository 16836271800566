import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListDevices = `${host}/device/filter`
const apiRequestPayment = `${host}/device/payment`

export const getListDevices = ({ filterFrom, filterSkip, filterSize }) => async dispatch => {
    const condFilters = {
        filterFrom, 
        filterSkip, 
        filterSize
    }

    const dataRequest = JSON.stringify(condFilters)
    
    try {
        const res = await Axios.post(apiGetListDevices, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_DEVICES,
                payload: {
                    list: data.deviceList || [],
                    condFilters,
                    total: data.total
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_DEVICES_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_DEVICES_ERR))
    }
}

export const resetListDevices = () => ({
    type: types.RESET_LIST_DEVICES
})

let isCalling = false
export const requestPayment = ({ id, amount, currencyCode="VND" }, callbackSuccess, callbackError) => async dispatch => {
    if(isCalling) return;
    isCalling = true

    const dataRequest = JSON.stringify({
        id, 
        amount, 
        currencyCode
    })

    try {
        const res = await Axios.post(apiRequestPayment, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.REQUEST_PAYMENT
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.REQUEST_PAYMENT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.REQUEST_PAYMENT_ERR))
    }
    isCalling = false
}