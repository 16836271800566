import React from 'react'


import IcAddress from '../../../../../../../../../assets/images/IcAddress'
import IcAddressActive from '../../../../../../../../../assets/images/IcAddressActive'
import Input from '../../../../../../../../Control/Input'


const iconStore = {
    icon: IcAddress,
    iconActive: IcAddressActive,
    iconError: IcAddressActive,
}

const InputAddress = (valueInput) => {
    return (
        <Input
            name="address"
            className="input-store"
            placeholder="Địa chỉ cửa hàng"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconStore}
        />
    )
}

export default InputAddress
