import React, { useMemo } from 'react'

// styles
import { WrapTabListAndTool } from './index.styles'

// component
import ToolStore from './ToolStore'
import ToolAdvertisement from './ToolAdvertisement'
import ToolAccount from './ToolAccount'

export const STORE = 'STORE'
export const ACCOUNT = 'ACCOUNT'
export const ADVERTISEMENT = 'ADVERTISEMENT'
export const listTabs = [
    {
        type: STORE,
        name: 'Cửa hàng'
    },
    {
        type: ACCOUNT,
        name: 'Tài khoản'
    },
    {
        type: ADVERTISEMENT,
        name: 'Quảng cáo'
    },
]

const TabListAndTool = ({ 
    activeTab: { 
        type: activeTabType, 
    }, 
    setActiveTab 
}) => {

    const toolComponent = useMemo(() => {
        switch(activeTabType) {
            case STORE:
                return <ToolStore />
            case ACCOUNT:
                return <ToolAccount />
            case ADVERTISEMENT:
                return <ToolAdvertisement />
            default:
                return null
        }
    })

    return (
        <WrapTabListAndTool>
            <div className="wrap-tab">
                {
                    listTabs.map(({ type, name }) => (
                        <div 
                            key={type}
                            className={`tab${activeTabType === type ? ' active' : ''}`}
                            onClick={() => setActiveTab({ type, name })}
                        >
                            {name}
                        </div>
                    ))
                }
            </div>
            <div className="wrap-tool">
                {toolComponent}
            </div>
        </WrapTabListAndTool>
    )
}

export default TabListAndTool
