import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapCustomerList, TableCustomerList, TableHeaderCustomer, TableContentCustomer } from './index.styles'

//data
import { headerCustomerListData } from '../../../../../data/headerCustomerListData'

//constant
import * as keys from '../../../../../constant/keys'

// component
import PageHeader from '../PageHeader'
import EmptyDataControl from '../../../../Control/EmptyDataControl'
import IcCustomerDefault from '../../../../../assets/images/IcCustomerDefault'
import ItemCustomer from './ItemCustomer'

//action
import { getCustomerSearchInterval, getListCustomer, resetListCustomer, getCustomerSearchProfileId, getCustomerSearchBiometricId } from '../../../../../redux/customer/listCustomer/action'

// hooks
import useScrollEnd from '../../../../../hook/useScrollEnd'

const filterSize = 20

const CustomerList = () => {

    const dispatch = useDispatch()
    const listRef = useRef()

    const { listCustomers, total, condFilters } = useSelector(state => ({
        listCustomers: state.customerReducer.listCustomerReducer.list,
        total: state.customerReducer.listCustomerReducer.total,
        condFilters: state.customerReducer.listCustomerReducer.condFilters,
    }))

    useEffect(() => {
        const callbackSuccess = () => dispatch(getListCustomer({ filterFrom: "", filterSkip: 0, filterSize }))
        dispatch(resetListCustomer(callbackSuccess))
    }, [])

    useScrollEnd(listRef, (callbackSuccess) => {
        switch (condFilters?.keyFilter) {
            case keys.PROFILE_ID: {
                dispatch(getCustomerSearchProfileId({
                    profileId: condFilters.searchValue.value,
                    filterFrom: listCustomers[0]?.id,
                    filterSkip: listCustomers.length,
                    filterSize: filterSize,
                }, true, callbackSuccess))
            }
                break;
            case keys.BIOMETRIC_ID: {
                dispatch(getCustomerSearchBiometricId({
                    biometricId: condFilters.searchValue.value,
                    filterFrom: listCustomers[0]?.id,
                    filterSkip: listCustomers.length,
                    filterSize: filterSize,
                }, true, callbackSuccess))
            }
                break;
            case keys.INTERVAL: {
                dispatch(getCustomerSearchInterval({
                    startTime: condFilters.searchValue.startTime,
                    closeTime: condFilters.searchValue.closeTime,
                    filterFrom: listCustomers[0]?.id,
                    filterSkip: listCustomers.length,
                    filterSize: filterSize,
                }, true, callbackSuccess))
            }
                break;

            default:
                dispatch(getListCustomer({ filterFrom: listCustomers[0]?.id, filterSkip: listCustomers.length, filterSize }, true, callbackSuccess))
                break;
        }

    }, [listCustomers], [total])

    return (
        <WrapCustomerList>
            <PageHeader>Danh sách khách hàng ({total ? total : 0})</PageHeader>
            <TableCustomerList>
                {
                    total === 0 ?
                        <div className="wrap-empty">
                            <EmptyDataControl
                                icon={<IcCustomerDefault />}
                                text={"There are currently no new customers"}
                            />
                        </div>
                        :
                        <>
                            <TableHeaderCustomer>
                                {
                                    headerCustomerListData.map((item, i) => (
                                        <div className="table-header" key={i}>
                                            {item}
                                        </div>
                                    ))
                                }
                            </TableHeaderCustomer>
                            <TableContentCustomer>
                                <div className="wrap-list-customer" ref={listRef}>
                                    <div className="list-customer">
                                        {
                                            listCustomers.map((item, i) => (
                                                <ItemCustomer key={item.id} data={item} />
                                            ))
                                        }
                                    </div>
                                </div>
                            </TableContentCustomer>
                        </>
                }
            </TableCustomerList>
        </WrapCustomerList>
    )
}

export default CustomerList
