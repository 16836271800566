import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// component
import MoreOption from '../../../../../../../Control/MoreOption'
import IcDelete from '../../../../../../../../assets/images/IcDetete'
import IconEye from '../../../../../../../../assets/images/IcEye'
import IcSetting from '../../../../../../../../assets/images/IcSetting'
import IcSettingsActiveOutline from '../../../../../../../../assets/images/IcSettingsActiveOutline'
import PopupEditDevice from './PopupEditDevice'
import PopupDelDevice from './PopupDelDevice'
import Portal from '../../../../../../../Control/Portal'

// action
import { controlDevice } from '../../../../../../../../redux/device/listDevicesByAdmin/action'

const EDIT = 'EDIT'
const DEL = 'DEL'

const MoreOptionDevice = ({ data, refList }) => {
    const dispatch = useDispatch()

    const [showPopup, setShowPopup] = useState("");

    const _handleScreenshot = () => {
        dispatch(controlDevice("screenshot", null, data.id))
    }
    const _handleRestart = () => {
        dispatch(controlDevice("restartApp"))
    }
    const _handleReboot = () => {
        dispatch(controlDevice("reboot"))
    }

    const dataMoreOption =  [
        {
            Icon: IconEye,
            text: "Screenshot Device",
            handleOption: _handleScreenshot
        },
        {
            Icon: IcSetting,
            text: "Restart Facepay App",
            handleOption: _handleRestart
        },
        {
            Icon: IcSetting,
            text: "Reboot thiết bị",
            handleOption: _handleReboot
        },
        {
            Icon: IcSettingsActiveOutline,
            text: "Tùy chỉnh",
            handleOption: () => {
                setShowPopup(EDIT)
            }
        },
        {
            Icon: IcDelete,
            text: "Xóa thiết bị",
            handleOption: () => {
                setShowPopup(DEL)
            }
        }
    ]

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
            />
            {
                showPopup === EDIT &&
                <Portal>
                    <PopupEditDevice 
                        data={data}
                        handleClose={() => setShowPopup("")} 
                    />
                </Portal>
            }
            {
                showPopup === DEL &&
                <Portal>
                    <PopupDelDevice 
                        data={data}
                        handleClose={() => setShowPopup("")} 
                    />
                </Portal>
            }
        </>
    )
}

export default MoreOptionDevice
