import Axios from 'axios'
//host
import { host } from '../../../constant/host'
//type
import * as types from './types'

// helper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import autoDownloadFromBlob from '../../../helper/autoDownloadFromBlob'
import mapErrors from '../../../helper/mapErrors'

// constant
import * as keys from '../../../constant/keys'

// import mapErrors from '../../../helpper/mapErrors'

const apiGetListCustomer = `${host}/customer/filter`
const apiGetCustomerSearchBiometricId = `${host}/customer/search/byBiometricId`
const apiGetCustomerSearchProfileId = `${host}/customer/search/byProfileId`
const apiGetCustomerSearchInterval = `${host}/customer/search/byInterval`

export const getListCustomer = ({ filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: null,
        keyFilter: '',
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetListCustomer, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_CUSTOMER,
                payload: {
                    list: data.customerList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_LIST_CUSTOMER_ERR))
    }
}

export const getCustomerSearchProfileId = ({ profileId, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: { value: profileId },
        keyFilter: keys.PROFILE_ID,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        profileId,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetCustomerSearchProfileId, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_CUSTOMER_SEARCH_PROFILE_ID,
                payload: {
                    list: data.customerList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_CUSTOMER_SEARCH_PROFILE_ID_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_CUSTOMER_SEARCH_PROFILE_ID_ERR))
    }
}

export const getCustomerSearchBiometricId = ({ biometricId, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: { value: biometricId },
        keyFilter: keys.BIOMETRIC_ID,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        biometricId,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetCustomerSearchBiometricId, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_CUSTOMER_SEARCH_BIOMERTRIC_ID,
                payload: {
                    list: data.customerList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_CUSTOMER_SEARCH_BIOMERTRIC_ID_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_CUSTOMER_SEARCH_BIOMERTRIC_ID_ERR))
    }
}

export const getCustomerSearchInterval = ({ startTime, closeTime, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        searchValue: { startTime, closeTime },
        keyFilter: keys.INTERVAL,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        startTime,
        closeTime,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetCustomerSearchInterval, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_CUSTOMER_SEARCH_INTERVAL,
                payload: {
                    list: data.customerList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_CUSTOMER_SEARCH_INTERVAL_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_CUSTOMER_SEARCH_INTERVAL_ERR))
    }
}

export const resetListCustomer = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_LIST_CUSTOMER,
    })
    callbackSuccess && callbackSuccess()
}


