import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapBankList } from './index.styles'

// component
import PageHeader from '../PageHeader'
import BankItem from './BankItem'
import EmptyDataControl from '../../../../Control/EmptyDataControl'
import IcBankDefault from '../../../../../assets/images/IcBankDefault'

//action
import { getListBank, resetListBank } from '../../../../../redux/bank/listBank/action'

//hook
import useScrollEnd from '../../../../../hook/useScrollEnd'

const BankList = () => {

    const dispatch = useDispatch()
    const listRef = useRef()

    const { listBank, total } = useSelector(state => ({
        listBank: state.bankReducer.listBankReducer.list,
        total: state.bankReducer.listBankReducer.total
    }))    

    useEffect(() => {
        const callbackSuccess = () => dispatch(getListBank({ filterFrom: "", filterSkip: 0, filterSize: 20 }))

        dispatch(resetListBank(callbackSuccess))
    }, [])

    useScrollEnd(listRef, (callback) => {
        dispatch(getListBank({
            filterFrom: listBank[0]?.id,
            filterSkip: listBank.length,
            filterSize: 20,
        }, true, callback))

    }, [listBank])

    return (
        <WrapBankList>
            <PageHeader>Danh sách Bank ({total ? total : 0})</PageHeader>
            <div className="wrap-list" ref={listRef}>
                {
                    total === 0
                        ? (
                            <div className="wrap-empty">
                                <EmptyDataControl
                                    icon={<IcBankDefault />}
                                    text={"The list of banks is empty"}
                                />
                            </div>
                        )
                        : (
                            <div className="wrap-inner-list" >
                                {
                                    listBank.map((item, i) => (
                                        <BankItem data={item} key={item.id} refList={listRef} />
                                    ))
                                }
                            </div>
                        )
                }
            </div>
        </WrapBankList>
    )
}

export default BankList
