import React, { useState } from 'react'

//component
import IcDelete from '../../../../../../../../assets/images/IcDetete'
import IcEdit from '../../../../../../../../assets/images/IcEdit'
import MoreOption from '../../../../../../../Control/MoreOption'
import Portal from '../../../../../../../Control/Portal'
import PopupDeleteStore from './PopupDeleteStore'
import PopupEditStore from './PopupEditStore'

const EDIT = 'EDIT'
const DEL = 'DEL'

const OptionStore = ({ dataStore, refList }) => {
    const [keyShowForm, setKeyShowForm] = useState('')

    const handleEdit = () =>{
        setKeyShowForm(EDIT)
    }

    const handleDelete = () =>{
        setKeyShowForm(DEL)
    }

    const handleClose = (e) => {
        e.stopPropagation()
        setKeyShowForm('')
    }

    const dataMoreOption =  [
        {
            Icon : IcEdit,
            text : "Tùy chỉnh",
            handleOption :  handleEdit
        },
        {
            Icon : IcDelete,
            text : "Xóa cửa hàng",
            handleOption :  handleDelete
        },
    ]

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
            />
            {
                keyShowForm === DEL &&
                <Portal>
                    <PopupDeleteStore 
                        dataStore={dataStore}
                        handleClose={handleClose}
                        setKeyShowForm={setKeyShowForm}
                    />
                </Portal>
            }
            {
                keyShowForm === EDIT &&
                <Portal>
                    <PopupEditStore 
                        dataStore={dataStore}
                        handleClose={handleClose}
                        setKeyShowForm={setKeyShowForm}
                    />
                </Portal>
            }
        </>
    )
}

export default OptionStore
