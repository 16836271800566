import styled from 'styled-components'

// styles
import { WrapPageItem } from '../../TransactionsList/index.styles';

const LabelCol = styled.div`
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #313131;
`

const Row = styled.div`
    display: grid;
    grid-column-gap: 10px;
    align-items: center;    
    padding: 10px 11px 10px 20px;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-sizing: border-box;

    &:not(:first-child) {
        margin-top: 6px;
    }

    &:hover {
        border: 1px solid #33ADFF;
    }
`

const RowLabelColumn = styled(Row)`
    margin-top: 23px;
    padding-bottom: 4px;
    box-shadow: unset;
    &:hover {
        border: none;
    }
`
const WrapRow = styled.div`
    margin-top: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
   
    & > .wrap-inner {
        position: absolute;
        width: 100%;
    }
`

const WrapListAccount = styled(WrapPageItem)`
    display: flex;
    flex-direction: column;
    height: 100%;
    .row {
        grid-template-columns: 150px 1fr 125px 125px 20px 25px;
    }
    .wrap-empty {
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

export {
    LabelCol,
    Row,
    RowLabelColumn,
    WrapRow,
    WrapListAccount
}