import React from 'react'

const IcCompareArrow = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.41644 4.39557H12.8746" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3643 1.88525L12.8745 4.39552L10.3643 6.90578" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.20856 9.64557H1.75043" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.26074 7.13525L1.75048 9.64552L4.26074 12.1558" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCompareArrow
