import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// component
import DragAndDrop from '../DragAndDrop'
import IcRefreshWhite from '../../../assets/images/IcRefreshWhite'
import IcUpload from '../../../assets/images/IcUpload'

// styles
import { DragAndDropArea, WrapDropImage } from './index.styles'

// const MOCK_FACE = "https://picsum.photos/100/100"

const MAX_SIZE = 3 * 1024 * 1024 // 3MB

const DragAndDropImage = ({ defaultUrl, handleRemoveFile, handleDropFiles }) => {
    const dispatch = useDispatch()

    const inputRef = useRef(null);
    const [urlImg, setUrlImg] = useState(defaultUrl || "");

    const _handleClickUploadFile = () => {
        // if(process.env.NODE_ENV !== "development") return;
        inputRef.current.click()
    }

    const _handleCreateImgUrl = async (files) => {
        if (urlImg) {
            URL.revokeObjectURL(urlImg);
        }

        try {
            const url = URL.createObjectURL(files[0]);
            setUrlImg(url);
        } catch (err) {
            setUrlImg("");
        }
    }

    const _handleDropFiles = (files) => {
        if (files.length !== 1) {
            inputRef.current.value = '';
            return;
        }

        const file = files[0]
        // console.log(file)
        const { type, size } = file

        const validFormat = ["png", "jpg", "jpeg"]// a Thịnh nhờ verify

        if (/image/.test(type) && validFormat.includes(type.split("/")[1])) {
            if(size > MAX_SIZE) {
                inputRef.current.value = '';
                dispatch({
                    type: "MAX_SIZE_ERR",
                    payload: {
                        code: "CLIENT_MAX_SIZE"
                    }
                })
                return
            }
            handleDropFiles(files)
            _handleCreateImgUrl(files)
            inputRef.current.value = '';
            return
        }

        inputRef.current.value = '';
    }

    const _handleRemoveFile = () => {
        handleRemoveFile()
        setUrlImg("")
    }

    useEffect(() => {
        const inputFile = inputRef.current = document.createElement('input');
        inputFile.type = 'file';
        inputFile.onchange = (e) => {
            _handleDropFiles(e.target.files)
        }
    }, [])

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(urlImg)
        }
    }, []);

    return (
        urlImg 
        ? (
            <WrapDropImage>
                <div className="wrap-face">
                    <img src={urlImg} alt="face" />
                </div>
                <IcRefreshWhite className="remove-face" onClick={_handleRemoveFile} />
            </WrapDropImage>
        )
        : (
            <DragAndDrop handleDropFiles={_handleDropFiles}>
                <DragAndDropArea onClick={_handleClickUploadFile}>
                    <IcUpload className="ic-upload" />
                    <p>Image size max 3Mb</p>
                </DragAndDropArea>
            </DragAndDrop>
        )
    )
}

export default DragAndDropImage
