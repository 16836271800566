import React from 'react'

const IcTransVerifyVideo = (props) => {
    return (
        <svg {...props} width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.8734 11.6667H32.6667C33.9044 11.6667 35.0914 12.1584 35.9665 13.0335C36.8417 13.9087 37.3334 15.0957 37.3334 16.3334V24.1267L39.6667 26.46L53.6667 16.3334V39.6667M37.3334 37.3334V39.6667C37.3334 40.9044 36.8417 42.0914 35.9665 42.9665C35.0914 43.8417 33.9044 44.3334 32.6667 44.3334H7.00004C5.76236 44.3334 4.57538 43.8417 3.70021 42.9665C2.82504 42.0914 2.33337 40.9044 2.33337 39.6667V16.3334C2.33337 15.0957 2.82504 13.9087 3.70021 13.0335C4.57538 12.1584 5.76236 11.6667 7.00004 11.6667H11.6667L37.3334 37.3334Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.33337 2.33331L53.6667 53.6666" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcTransVerifyVideo
