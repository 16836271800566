import React from 'react'

// component
import IcSearchGreen from '../../../assets/images/IcSearchGreen'
import IcArrowGreenRight from '../../../assets/images/IcArrowGreenRight'

// styles
import { WrapFilterControl } from './index.styles'

/**
 *  filterCond: {
 *      text: any,
 *      key: any
 *  }
 * */

const FilterControl = ({ 
    dropdownFilter,
    filterComponent,
    handleSearch
}) => {
    return (
        <WrapFilterControl>
            <IcSearchGreen />
            {dropdownFilter}
            {filterComponent}
            <IcArrowGreenRight
                className="ic-arrow-right"
                onClick={handleSearch}
            />
        </WrapFilterControl>
    )
}

export default FilterControl
