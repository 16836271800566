import * as types from './types'
import * as keys from '../../../constant/keys'

const intitialState = {
    list: [],
    condFilters: {
        searchValue: '',
        keyFilter: '',
        filterFrom: '',
        filterSkip: 0,
        filterSize: 20,
    },
    isSearching: false
}
const listTransactionReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_LIST_TRANSACTION:
        case types.GET_TRANSACTION_SEARCH_MERCHANTTX_ID:
        case types.GET_TRANSACTION_SEARCH_BANKTX_ID:
        case types.GET_TRANSACTION_SEARCH_FACEPAYTX_ID:
        case types.GET_TRANSACTION_SEARCH_INTERVAL: {
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list,
                type: action.type,
                isSearching : 
                    (action.payload.condFilters.keyFilter === keys.MERCHANT_ID && action.payload.condFilters.searchValue.value !== intitialState.condFilters.searchValue.value) || 
                    (action.payload.condFilters.keyFilter === keys.BANK_ID && action.payload.condFilters.searchValue.value !== intitialState.condFilters.searchValue.value) ||
                    (action.payload.condFilters.keyFilter === keys.FACEPAY_ID && action.payload.condFilters.searchValue.value !== intitialState.condFilters.searchValue.value) ? true : false
            }
        }
        case types.RESET_LIST_TRANSACTION: {
            return { 
                ...intitialState,
                condFilters: state.condFilters,
                type: action.type }
        }

        default:
            return state

    }
}
export default listTransactionReducer