import React, { useState } from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";

// constant
import * as path from '../../../../constant/path'

// styles
import { WrapBreadcrumbAndTool } from './index.styles'

//image
import IcExportFile from '../../../../assets/images/IcExportFile'
import IcHistoryTransaction from "../../../../assets/images/IcHistoryTransaction"
import IcRefresh from "../../../../assets/images/IcRefresh"
import IcCreateNew from '../../../../assets/images/IcCreateNew';
import IcNewDevices from '../../../../assets/images/IcNewDevices';
import IcEdit from '../../../../assets/images/IcEdit';

// component
import Breadcrumb from './Breadcrumb'
import Button from './Button'
import FilterTransaction from './FilterTransaction'
import ButtonCustom from '../../../Control/Button/index'
import FilterDevices from './FilterDevices';
import CreateNewMerchant from './CreateNewMerchant';
import Portal from '../../../Control/Portal';
import CreateNewBank from './CreateNewBank';
import CreateNewDevice from './CreateNewDevice';
import SettingBread from './SettingBread';
import PopupExportFile from './PopupExportFile';
import FilterCustomer from './FilterCustomer';

//action
import { getListTransaction, resetListTransaction } from '../../../../redux/transaction/listTransaction/action'
import { getTransactionCount } from '../../../../redux/transaction/transactionCount/action';
import { toggleShowPopupAdd } from '../../../../redux/device/listDevicesByAdmin/action';


const HistoryList = () => {
    const { facepayId } = useParams();
    const history = useHistory()

    const { transactionDetail } = useSelector(state => ({
        transactionDetail: state.transactionReducer.transactionDetailReducer.details
    }))

    const _handleOnClickTransaction = () => {
        history.push(`/${path.MAIN}/${path.TRANSACTION}`)
    }

    const _handleOnClickTransactionDetail = () => {
        history.push(`/${path.MAIN}/${path.TRANSACTION}/${facepayId}`)
    }

    return (
        <>
            <Breadcrumb arrPath={[
                { value: "Dữ liệu giao dịch", onClick: _handleOnClickTransaction },
                { value: "Chi tiết giao dịch", onClick: _handleOnClickTransactionDetail },
                { value: "Lịch sử giao dịch" }]} />
            {/* <div className='wrap-right-content'>
                <div className="wrap-id-trans">
                    <span className="id-name">Image Proof :</span>
                    <span className="id-value" title={transactionDetail.matchedTo || ""}>{transactionDetail.matchedTo || "Null"}</span>
                </div>
            </div> */}
        </>
    )
}

const TransDetails = () => {
    const { facepayId } = useParams();
    const history = useHistory()

    const { transactionDetail } = useSelector(state => ({
        transactionDetail: state.transactionReducer.transactionDetailReducer.details
    }))

    const _handleOnClickTransaction = () => {
        history.push(`/${path.MAIN}/${path.TRANSACTION}`)
    }

    const _handleOnClickUserHistory = () => {
        history.push(`/${path.MAIN}/${path.TRANSACTION}/${facepayId}/${path.HISTORY}/${transactionDetail?.matchedTo}`)
    }

    return (
        <>
            <Breadcrumb arrPath={[{ value: "Dữ liệu giao dịch", onClick: _handleOnClickTransaction }, { value: "Chi tiết giao dịch" }]} />
            <div className="list-button">
                <Button Icon={<IcHistoryTransaction />} _handleOnClick={_handleOnClickUserHistory}>Lịch sử giao dịch</Button>
                {/* <Button Icon={<IcExportFile />}>Xuất file</Button> */}
            </div>
        </>
    )
}

const MerchantDetailsBread = () => {
    const history = useHistory()
    return (
        <>
            <Breadcrumb
                arrPath={[
                    {
                        value: "Merchant",
                        onClick: () => history.push(`/${path.MAIN}/${path.MERCHANT}`)
                    },
                    {
                        value: "Chi tiết Merchant"
                    }
                ]}
            />
            <Button Icon={<IcExportFile />}>Xuất file</Button>
        </>
    )
}

const MerchantBread = () => {
    const [isShowModalAddMerChant, setIsShowModalAddMerChant] = useState(false)

    const _handleClickBtn = () => {
        setIsShowModalAddMerChant(pre => !pre)
    }


    return (
        <>
            <Breadcrumb arrPath={[{ value: "Merchant" }]} />
            <div className="wrap-right-content">
                <Button Icon={<IcExportFile />}>Xuất file</Button>
                <ButtonCustom
                    text="Tạo mới"
                    iconBtn={<IcCreateNew />}
                    className="btn-creteNew"
                    onClick={_handleClickBtn}
                />
            </div>
            {
                isShowModalAddMerChant &&
                <Portal>
                    <CreateNewMerchant onClickIconClose={_handleClickBtn} setIsShowModalAddMerChant={setIsShowModalAddMerChant} />
                </Portal>
            }
        </>
    )

}

const BankDetailsBread = () => {
    const history = useHistory()
    return (
        <>
            <Breadcrumb
                arrPath={[
                    {
                        value: "Banks",
                        onClick: () => history.push(`/${path.MAIN}/${path.BANK}`)
                    },
                    {
                        value: "Chi tiết Banks"
                    }
                ]}
            />
            <Button Icon={<IcExportFile />}>Xuất file</Button>
        </>
    )
}

const BankBread = () => {
    const [isShowModalAddBank, setIsShowModalAddBank] = useState(false)

    const _handleClickBtn = () => {
        setIsShowModalAddBank(pre => !pre)
    }

    return (
        <>
            <Breadcrumb arrPath={[{ value: "Bank" }]} />
            <div className="wrap-right-content">
                <Button Icon={<IcExportFile />}>Xuất file</Button>
                <ButtonCustom
                    text="Tạo mới"
                    iconBtn={<IcCreateNew />}
                    className="btn-creteNew"
                    onClick={_handleClickBtn}
                />
            </div>
            {
                isShowModalAddBank &&
                <Portal>
                    <CreateNewBank onClickIconClose={_handleClickBtn} setIsShowModalAddBank={setIsShowModalAddBank} />
                </Portal>
            }
        </>
    )

}

const DeviceByAdminTool = () => {

    // const [isShowPopupAdd, setIsShowPopupAdd] = useState(false);
    const dispatch = useDispatch()
    const { isShowPopupAdd } = useSelector(state => ({
        isShowPopupAdd: state.deviceReducer.listDevicesByAdmin.isShowPopupAdd
    }))

    const _setIsShowPopupAdd = (isShow) => dispatch(toggleShowPopupAdd(isShow))

    return (
        <>
            <Breadcrumb arrPath={[{ value: "Thiết bị" }]} />
            <FilterDevices />
            <div className="wrap-right-content">
                <Button Icon={<IcExportFile />}>Xuất file</Button>
                <ButtonCustom
                    text="Thêm mới"
                    iconBtn={<IcNewDevices />}
                    className="btn-creteNew"
                    onClick={() => _setIsShowPopupAdd(true)}
                />
            </div>
            {
                isShowPopupAdd &&
                <Portal>
                    <CreateNewDevice
                        handleClose={() => _setIsShowPopupAdd(false)}
                    />
                </Portal>
            }
        </>
    )
}

const DeviceLocationByAdminTool = () => {
    const history = useHistory()

    const _handleClick = () => history.push(`/${path.MAIN}/${path.DEVICE}`)

    return <>
        <Breadcrumb 
            arrPath={[
                { value: "Thiết bị", onClick: _handleClick }, 
                { value: "Địa điểm thiết bị" }
            ]} 
        />
    </>
}

const TransactionBread = () => {
    const dispatch = useDispatch()

    const [isShowModalExportFile, setIsShowModalExportFile] = useState(false)

    const onRefreshTransactionList = () => {
        dispatch(getTransactionCount())

        const callbackSuccess = () => dispatch(getListTransaction({ filterFrom: "", filterSkip: 0, filterSize: 20 }))
        dispatch(resetListTransaction(callbackSuccess))
    }

    const onExportFile = () => {
        setIsShowModalExportFile(pre => !pre)
    }


    return (
        <>
            <Breadcrumb arrPath={[{ value: "Dữ liệu giao dịch" }]} />
            <FilterTransaction />
            <div className="wrap-right-content">
                <Button Icon={<IcRefresh />} _handleOnClick={onRefreshTransactionList}> Tải lại </Button>
                <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}> Xuất file </Button>
            </div>
            {
                isShowModalExportFile &&
                <Portal>
                    <PopupExportFile
                        setIsShowModalExportFile={setIsShowModalExportFile}
                        onClickIconClose={() => setIsShowModalExportFile(false)}
                    />
                </Portal>
            }
        </>
    )
}

const CustomerBread = () => {
    const dispatch = useDispatch()

    const [isShowModalExportFile, setIsShowModalExportFile] = useState(false)
   
    const onExportFile = () => {
        setIsShowModalExportFile(pre => !pre)
    }


    return (
        <>
            <Breadcrumb arrPath={[{ value: "Customer" }]} />
            <FilterCustomer/>
            <div className="wrap-right-content">
                <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}> Xuất file </Button>
            </div>
            {
                isShowModalExportFile &&
                <Portal>
                    <PopupExportFile
                        setIsShowModalExportFile={setIsShowModalExportFile}
                        onClickIconClose={() => setIsShowModalExportFile(false)}
                    />
                </Portal>
            }
        </>
    )
}

const CustomerDetailBread = () =>{
    const history = useHistory()
    const { custId } = useParams() 

    const _handleOnClickCustomer = () =>{
        history.push(`/${path.MAIN}/${path.CUSTOMER}`)
    }

    const onClickHistoryTransaction = () =>{
        history.push(`/${path.MAIN}/${path.CUSTOMER}/${custId}/${path.HISTORY}`)
    }

    const onExportFile = () =>{
    }

    return (
        <>
            <Breadcrumb arrPath={[{ value: "Customer", onClick: _handleOnClickCustomer }, { value: "Chi tiết khách hàng" }]} />
            <div className="wrap-right-content">
                <Button Icon={<IcEdit />} _handleOnClick={onClickHistoryTransaction}> Lịch sử giao dịch </Button>
                <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}> Xuất file </Button>
            </div>
        </>
    )
}

const CustomerHistoryBread = () =>{
    const history = useHistory()
    const { custId } = useParams() 

    const _handleOnClickCustomer = () =>{
        history.push(`/${path.MAIN}/${path.CUSTOMER}`)
    }

    const _handleOnClickCustomerDetail = () =>{
        history.push(`/${path.MAIN}/${path.CUSTOMER}/${custId}`)
    }

    const onExportFile = () =>{
    }
    
    return (
        <>
            <Breadcrumb arrPath={[
                { value: "Customer", onClick: _handleOnClickCustomer }, 
                { value: "Chi tiết khách hàng", onClick: _handleOnClickCustomerDetail }, 
                { value: "Lịch sử giao dịch" }
            ]} />
            <div className="wrap-right-content">
                <Button Icon={<IcExportFile />} _handleOnClick={onExportFile}> Xuất file </Button>
            </div>
        </>
    )
}


const BreadcrumbAndTool = () => {

    const [notHaveBoxShadow, setNotHaveBoxShadow] = useState(false);

    return (
        <WrapBreadcrumbAndTool notHaveBoxShadow={notHaveBoxShadow}>
            <div className='wrap-breadcrumb-and-tool'>
                <Switch>
                    <Route path={`/${path.MAIN}/${path.ANALYTIC}`}>
                        <Breadcrumb arrPath={[{ value: "Data Analytics" }]} />
                        {/* <Button Icon= {<IcExportFile />}> Export File </Button> */}
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.TRANSACTION}`}>
                        <TransactionBread />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.TRANSACTION}/:facepayId/${path.HISTORY}/:biometricId`}>
                        <HistoryList />
                    </Route>
                    <Route path={`/${path.MAIN}/${path.TRANSACTION}/:facepayId`}>
                        <TransDetails />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.MERCHANT}`}>
                        <MerchantBread />
                    </Route>
                    <Route path={`/${path.MAIN}/${path.MERCHANT}/:merchantId`}>
                        <MerchantDetailsBread />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.BANK}`}>
                        <BankBread />
                    </Route>
                    <Route path={`/${path.MAIN}/${path.BANK}/:bankId`}>
                        <BankDetailsBread />
                    </Route>
                    <Route path={`/${path.MAIN}/${path.PAYMENT_TERMINAL}`}>
                        <Breadcrumb arrPath={[{ value: "Payment Terminal" }]} />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.DEVICE}`}>
                        <DeviceByAdminTool />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.DEVICE}/:deviceId/${path.LOCATION}`}>
                        <DeviceLocationByAdminTool />
                    </Route>
                    <Route path={`/${path.MAIN}/${path.PERMISSION}`}>
                        <SettingBread setNotHaveBoxShadow={setNotHaveBoxShadow} />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.CUSTOMER}`}>
                        <CustomerBread />
                    </Route>
                    <Route exact path={ `/${path.MAIN}/${path.CUSTOMER}/:custId`}>
                        <CustomerDetailBread />
                    </Route>
                    <Route exact path={`/${path.MAIN}/${path.CUSTOMER}/:custId/${path.HISTORY}`}>
                        <CustomerHistoryBread />
                    </Route>
                </Switch>
            </div>
        </WrapBreadcrumbAndTool>
    )
}

export default BreadcrumbAndTool
