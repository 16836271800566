import React, { useRef, useEffect, useState } from 'react'

//const 
import { CONNECTED, DISCONNECTED, WARNING } from '../../../../../../../constant/deviceStatusTypes'

//Data
import dataDeviceStatus, { mapStatus } from '../../../../../../../data/dataDeviceStatus'

// styles
import { WrapDeviceItemByStatus } from './index.styles'

// component
import PopupDeviceInfos from '../../PopupDeviceInfos'

const initialPos = {
    top: "50%",
    left: "50%",
    right: "initial",
    bottom: "initial",
}

const DeviceItemByStatus = ({ data, ...props }) => {
    const wrapPopupRef = useRef(null)

    const [isEnter, setIsEnter] = useState(false);
    const [posPopup, setPosPopup] = useState({
        ...initialPos
    });

    const objStatus = dataDeviceStatus[mapStatus[data.status]] || {}
    const { color } = objStatus

    const _handleMouseEnter = () => {
        setIsEnter(true)
    }

    const _handleMouseLeave = () => {
        setIsEnter(false)
        setPosPopup({
            ...initialPos
        })
    }

    // useEffect(() => {
    //     console.log(posPopup)
    // }, [posPopup]);

    useEffect(() => {
        if(!isEnter) return;

        const posPopup = wrapPopupRef.current.getBoundingClientRect()
        // console.log(posPopup)

        const { height, top, left } = posPopup

        const isOutY = height + top + 10 > window.innerHeight
        const isOutX = 300 + left + 10 > window.innerWidth

        if (isOutY && isOutX) {
            setPosPopup({
                top: "initial",
                left: "initial",
                bottom: "50%",
                right: "50%",
            })
            return;
        }

        if (isOutY) {
            setPosPopup({
                top: "initial",
                left: "50%",
                bottom: "50%",
                right: "initial",
            })
            return;
        }

        if (isOutX) {
            setPosPopup({
                top: "50%",
                left: "initial",
                bottom: "initial",
                right: "50%",
            })
            return;
        }

        setPosPopup({
            ...initialPos
        })
    }, [isEnter]);

    return (
        <WrapDeviceItemByStatus 
            color={color}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
            posPopup={posPopup}
        >
            <div 
                className="wrap-device-infos" 
                ref={wrapPopupRef}
            >
                <PopupDeviceInfos data={data} />
            </div>
        </WrapDeviceItemByStatus>
    )
}

export default DeviceItemByStatus
