import styled from 'styled-components'

const WrapInputOther = styled.textarea`
    margin-top: 20px;
    resize: none;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    background: #FFFFFF;
    outline: none;
    border-radius: 4px;
    border: 0.5px solid #B7B7B7;
    padding: 11px 10px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #313131;

    &::placeholder {
        color: #B7B7B7;
    }
`;

export {
    WrapInputOther
}