import styled from 'styled-components'

// styles
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapTransactionDetails = styled(WrapPageItem)`
    
`;

export {
    WrapTransactionDetails
}