import Logo_GS25 from "../assets/images/GS25.png";
import Logo_7eleven from "../assets/images/7eleven.png";
import Logo_DefautStore from "../assets/images/Default_Store.png";

export const GS25 = "3791";
export const SEVENELEVEN = "SEVENELEVEN";


export const listMerchant = [
  {
    logo: Logo_GS25,
    logoDefault: Logo_DefautStore,
    shortName: "GS25",
    fullName: "Chuỗi cửa hàng tiện lợi GS25 Việt Nam",
    address: "",
    key: GS25,
  },
  {
    logo: Logo_7eleven,
    logoDefault: Logo_DefautStore,
    shortName: "7eleven",
    fullName: "Chuỗi cửa hàng tiện lợi 7-Eleven Việt Nam",
    address: "",
    key: SEVENELEVEN,
  }
];
