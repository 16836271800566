import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// component
import Content from './Content'
import Header from './Header'
import SideMenu from './SideMenu'

// styles
import { WrapMain } from './index.styles'

// action
import { getListCategories } from '../../redux/merchant/listMerchant/action'
import { DEV_ENV, env, WEE_ENV } from '../../constant/environmentTypes'

// hook
import useSocket from '../../hook/useSocket'

const Main = () => {
    const dispatch = useDispatch()

    useSocket()

    useEffect(() => {
        if(![DEV_ENV, WEE_ENV].includes(env)) return;
        dispatch(getListCategories())
    }, []);

    return (
        <WrapMain>
            <Header className="header" />
            <SideMenu />
            <Content />
        </WrapMain>
    )
}

export default Main
