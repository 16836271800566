import React from 'react'

export default function IcRefresh(props) {
    return (
        <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4167 11.6666V8.16663H9.91669" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.583313 2.33337V5.83337H4.08331" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.9525 5.24996C11.6566 4.41392 11.1538 3.66645 10.491 3.07729C9.8281 2.48813 9.02679 2.07649 8.16181 1.88078C7.29683 1.68507 6.39637 1.71166 5.54445 1.95808C4.69253 2.2045 3.91692 2.66271 3.28998 3.28996L0.583313 5.8333M13.4166 8.16663L10.71 10.71C10.083 11.3372 9.30743 11.7954 8.45551 12.0418C7.60359 12.2883 6.70313 12.3149 5.83815 12.1191C4.97317 11.9234 4.17186 11.5118 3.509 10.9226C2.84614 10.3335 2.34333 9.586 2.04748 8.74996" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
