import React from 'react'

const IcBanks = ({...props}) => {
    return (
        <svg {...props} width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.87 2.64984L21.87 6.24982C22.22 6.38982 22.5 6.80981 22.5 7.17981V10.4998C22.5 11.0498 22.05 11.4998 21.5 11.4998H3.5C2.95 11.4998 2.5 11.0498 2.5 10.4998V7.17981C2.5 6.80981 2.78 6.38982 3.13 6.24982L12.13 2.64984C12.33 2.56984 12.67 2.56984 12.87 2.64984Z" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.5 22.5H2.5V19.5C2.5 18.95 2.95 18.5 3.5 18.5H21.5C22.05 18.5 22.5 18.95 22.5 19.5V22.5Z" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 18.5V11.5" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.5 18.5V11.5" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 18.5V11.5" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.5 18.5V11.5" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.5 18.5V11.5" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5 9C13.3284 9 14 8.32843 14 7.5C14 6.67157 13.3284 6 12.5 6C11.6716 6 11 6.67157 11 7.5C11 8.32843 11.6716 9 12.5 9Z" stroke="#313131" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBanks
