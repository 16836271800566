import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListSettingReport = `${host}/report/receiver/filter`
const apiCreateNewSettingEmail = `${host}/report/receiver/create`
const apiUpdateSettingEmail = `${host}/report/receiver/update`
const apiDeleteSettingEmail = `${host}/report/receiver/delete`

export const getListSettingReport = ({ filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess, callbackError) => async dispatch => {
    const condFilters = {
        searchValue: null,
        keyFilter: '',
        filterFrom,
        filterSkip,
        filterSize
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetListSettingReport, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_SETTING_REPORT,
                payload: {
                    list: data.receiverList || [],
                    condFilters,
                    total: data.total || 0,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_SETTING_REPORT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.GET_LIST_SETTING_REPORT_ERR))
    }
}

export const resetListSettingReport = () => ({
    type: types.RESET_LIST_SETTING_REPORT
})

export const createNewSettingEmail = ({ email, fullname, dailyReport = false, monthlyReport = false }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        email,
        fullname,
        dailyReport,
        monthlyReport,
    })
    try {
        const res = await Axios.post(apiCreateNewSettingEmail, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CREATE_NEW_SETTING_EMAIL,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CREATE_NEW_SETTING_EMAIL_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.CREATE_NEW_SETTING_EMAIL_ERR))
    }
}

export const updateSettingEmail = ({ id, email, fullname, dailyReport, monthlyReport }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id,
        email,
        fullname,
        dailyReport,
        monthlyReport,
    })
    try {
        const res = await Axios.post(apiUpdateSettingEmail, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UPDATE_SETTING_EMAIL,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.UPDATE_SETTING_EMAIL_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_SETTING_EMAIL_ERR))
    }
}

export const deleteSettingEmail = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    try {
        const res = await Axios.delete(`${apiDeleteSettingEmail}?id=${id}`)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.DELETE_SETTING_EMAIL,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.DELETE_SETTING_EMAIL_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.DELETE_SETTING_EMAIL_ERR))
    }
}

export const setActiveTab = (activeTab) => ({
    type: types.SET_ACTIVE_TAB,
    payload: {
        activeTab
    }
})

