import styled from 'styled-components'

// styles
import { Row } from '../index.styles';

const WrapPermissionItem = styled(Row)`
    min-height: 60px;
    background: #FFFFFF;    
    .bold, .normal {
        font-family: "Open Sans";
        font-style: normal;
        letter-spacing: -0.01em;
        color: #313131;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }

    .bold {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
    }

    .normal {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        
    }
    .btn-toggle{
        display: flex;
        align-items: center;
        .btn-daily{
            display: flex;
            align-items: center;
            margin-right: 27px;
            p{
                padding-right: 7px;
            }
        }
        
    }
    .wrap-tools {
        /* margin-left: 19px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        .active{
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #15BA39;
            &.false{
                color: #F45C5C;
            }
        }
    }
`;

export {
    WrapPermissionItem
}