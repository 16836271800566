import React from 'react'

//image
import IcInputUser from '../../../../../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../../../../../assets/images/IcInputUserError'

//component
import Input from '../../../../../../../../Control/Input'


const iconFullname = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

const InputFullName = ({ valueInput, onChange, placeholder, ...props }) => {
    return (
        <Input
            type="text"
            className="input-merchant"
            placeholder={placeholder}
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconFullname}
        />
    )
}

export default InputFullName
