import React from 'react'

const IcHeart = (props) => {
    return (
        <svg {...props} width={9} height={9} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.86036 2.25393C7.90138 -0.510217 4.7847 0.72552 4.5 2.10759C4.11041 0.644221 1.06865 -0.445178 0.13964 2.25393C-0.89426 5.26198 4.14038 7.96109 4.5 8.4001C4.85962 8.04238 9.89426 5.2132 8.86036 2.25393Z" fill="url(#paint0_linear_805:10061)" />
            <defs>
                <linearGradient id="paint0_linear_805:10061" x1="4.5" y1="0.600098" x2="4.5" y2="8.4001" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F24141" />
                    <stop offset={1} stopColor="#D22222" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default IcHeart
