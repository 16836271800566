import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//component
import IcMerchantLogoDefault from '../../../../../../../assets/images/IcMerchantLogoDefault'
import PopupDeviceInfos from '../../PopupDeviceInfos'
import MoreOptionDevice from './MoreOptionDevice'
import PopupShowMedia, { IMAGE } from '../../../TransactionHistory/TransHistoryItem/PopupShowMedia'
import Portal from '../../../../../../Control/Portal'

// data
import dataDeviceStatus, { mapStatus } from '../../../../../../../data/dataDeviceStatus'

// styles
import { WrapDeviceItemByList } from './index.styles'

// helper
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

// api
import { apiMerchantLogo } from '../../../../../../../constant/host'

// action
import { setDataScreenShot } from '../../../../../../../redux/device/listDevicesByAdmin/action'

const IMG_MERCHANT_MOCK =  "https://picsum.photos/360/360"

const DeviceItemByList = ({ data, refList, ...props}) => {
    const { 
        status, 
        id, 
        name, 
        merchantId, 
        merchantStoreName, 
        appVersion, 
        address,
        lastPingTime 
    } = data
    const dispatch = useDispatch()

    const { screenShot: { deviceId, isLoading, image, imageName } } = useSelector(state => ({
        screenShot: state.deviceReducer.listDevicesByAdmin.screenShot
    }))

    const [isErrMerchantLogo, setIsErrMerchantLogo] = useState(false);
    const [isShowScreenImg, setIsShowScreenImg] = useState(false);

    const _handleCloseScreenShot = () => {
        setIsShowScreenImg(false)
        dispatch(setDataScreenShot({
            deviceId: "",
            image: "",
            imageName: ""
        }))
    }

    useEffect(() => {
        if(deviceId !== id) return;
        if(isLoading || !image) return;
        setIsShowScreenImg(true)
    }, [isLoading, image, deviceId, id]);

    const objStatus = dataDeviceStatus[mapStatus[status]] || {}
    const { color } = objStatus

    return (
        <WrapDeviceItemByList {...props} colorStatus={color}>
            <div className="wrap-status">
                <div className="status" />
                <div className="wrap-device-infos">
                    <PopupDeviceInfos data={data} />
                </div>
            </div>
            <div className="normal">{id.substring(0, 9).toUpperCase()}</div>
            <div className="normal">{name}</div>
            <div className="image-merchant">
                {
                    !merchantId || isErrMerchantLogo 
                    ? <IcMerchantLogoDefault />
                    : <img 
                        src={apiMerchantLogo(merchantId)} 
                        alt="img-merchant"
                        onError={() => setIsErrMerchantLogo(true)}
                    />
                }
            </div>
            <div className="normal">{merchantStoreName}</div>
            <div className="normal">{address}</div>
            <div className="normal">{appVersion}</div>
            <div className="normal">{convertDateTime(lastPingTime, "HH:mm - DD/MM/YYYY")}</div>
            <div>
                <MoreOptionDevice 
                    data={data}
                    refList={refList} 
                />
            </div>
            {
                isShowScreenImg &&
                <Portal>
                    <PopupShowMedia 
                        mediaType={IMAGE}
                        url={`data:image/png;base64, ${image}`}
                        closePopup={_handleCloseScreenShot}
                    />
                </Portal>
            }
        </WrapDeviceItemByList>
    )
}

export default DeviceItemByList
