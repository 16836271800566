import React from 'react'

const IcNoDataDefault = (props) => {
    return (
        <svg {...props} width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="70.5" cy="70" r="70" fill="#B7B7B7"/>
        <path d="M77.1663 36.6667H50.4997C48.7316 36.6667 47.0359 37.3691 45.7856 38.6193C44.5354 39.8695 43.833 41.5652 43.833 43.3333V96.6667C43.833 98.4348 44.5354 100.13 45.7856 101.381C47.0359 102.631 48.7316 103.333 50.4997 103.333H90.4997C92.2678 103.333 93.9635 102.631 95.2137 101.381C96.464 100.13 97.1663 98.4348 97.1663 96.6667V56.6667L77.1663 36.6667Z" fill="#B7B7B7"/>
        <path d="M83.8337 86.6667H57.167" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M83.8337 73.3333H57.167" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M63.8337 60H60.5003H57.167" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M77.167 36.6667V56.6667H97.167" fill="white"/>
        </svg>

    )
}

export default IcNoDataDefault
