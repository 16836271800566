import styled from 'styled-components'

const WrapPopupCreate = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;
    position: relative;

    .ic-close {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }

    .title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #313131;
    }

    .sub-title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
    }

    .btn-submit {
        width: 100%;
        margin-top: 76px;
    }
`;

const WrapPopupInfosAccount = styled(WrapPopupCreate)`
    min-width: 320px;
    
    .sub-title {
        margin-top: 10px;
    }

    .full-name {
        margin-top: 33px;
    }
`

const WrapPopupCreateAccount = styled(WrapPopupInfosAccount)`

`;

export {
    WrapPopupCreate,
    WrapPopupInfosAccount,
    WrapPopupCreateAccount
}