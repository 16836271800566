import styled from 'styled-components'

const WrapHeader = styled.div`
    width: 100%;
    height: 60px;
    background: ${props => props.isWarning ? "#F45C5C" : "#313131"};
    .wrap-header{
        display: flex;
        max-width: 1280px;
        box-sizing: border-box;
        height: 100%;
        align-items: center;
        padding: 0px 30px;
        justify-content: space-between;
        
        .logo-facepay {
            display: flex;
            &:hover {
                cursor: pointer;
            }
        }

        .device-disconnected {
            display: flex;
            align-items: center;

            span {
                margin-left: 4px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #F7F7F7;
            }
        }

        .user{
            display: flex;
            align-items: center;
            position: relative;
            .user_name{
                font-size: 13px;
                line-height: 18px;
                font-family: "Open Sans";
                color: #FFFFFF;
                /* padding-right: 10px; */
                letter-spacing: -0.01em;
            }
            .ic-user{
                margin-left: 12px;
                cursor: pointer;
            }
            .ic-logout{
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .popup-logout{
            position: absolute;
            top: 33px;
            right: 0;
            width: 229px;
            height: 172px;
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;
            z-index: 2;
            p{
                font-family: "Open Sans";
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                color: #313131;
            }
            .tit-popup{
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
            }
            .des-popup{
               padding: 10px 0 30px 0;
               letter-spacing: -0.01em;
            }
            .button{
                background: #F45C5C;
                border-radius: 4px;
                width: 189px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .text-popup{
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 18px;
                    color: #FFFFFF;
                    padding-left: 4px;
                }
            }
        }
    }
    
`;

export {
    WrapHeader
}