import React from 'react'

// component
import DropdownControl from '../../../../../Control/DropdownControl'

export const KEY_PROFILE_ID = "1"
export const KEY_BIOMETRIC_ID = "2"
export const KEY_TIME_SELECTION = "3"

export const dataSelection = [
    {
        text: "Profile ID",
        key: KEY_PROFILE_ID
    },
    {
        text: "Biometric ID",
        key: KEY_BIOMETRIC_ID
    },
    {
        text: "Thời gian đăng ký",
        key: KEY_TIME_SELECTION
    },
]

const DropdownSelectTypeSearch = ({ filterCond: { text, key }, setFilterCond }) => {

    const _handleSetValueSelect = (val) => {
        setFilterCond(val)
    }

    return (
        <DropdownControl 
            dataSelection={dataSelection}
            valueSelected={{ text, key }}
            handleSetValueSelect={_handleSetValueSelect}
        />
    )
}

export default DropdownSelectTypeSearch
