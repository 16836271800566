import React from 'react'

// styles
import { WrapStoreItem } from './index.styles'

// help
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import OptionStore from './OptionStore'

const StoreItem = ({dataItem, refList, ...props}) => {
    return (
        <WrapStoreItem {...props}>
            <div className="bold" title={dataItem?.code ? `${dataItem?.code}` : ""}>{dataItem?.code || "-"}</div>
            <div className="normal" title={dataItem?.name ? `${dataItem?.name}` : ""}>{dataItem?.name || "-"}</div>
            <div className="normal" title={dataItem?.address ? `${dataItem?.address}` : ""}>{dataItem?.address || "-"}</div>
            <div className="normal" title={dataItem?.lng + ',' + dataItem?.lat}>{dataItem?.lng  || "0"},{dataItem?.lat || "0"}</div>
            <div className="normal" title={dataItem?.deviceTotal ? `${dataItem?.deviceTotal}` : ""}>{dataItem?.deviceTotal}</div>
            <div className="normal" title={dataItem?.createAt ? convertDateTime(dataItem.createAt, "DD/MM/YYYY") : ""}>
                {dataItem?.createAt ? convertDateTime(dataItem.createAt, "DD/MM/YYYY") : "-"}
            </div>
            <div className="normal" title={dataItem?.lastUpdateAt ? convertDateTime(dataItem.lastUpdateAt, "DD/MM/YYYY") : ""}>
                {dataItem?.lastUpdateAt ? convertDateTime(dataItem.lastUpdateAt, "DD/MM/YYYY") : "-"}
            </div>
            <div>
                <OptionStore dataStore={dataItem} refList={refList}/>
            </div>
        </WrapStoreItem>
    )
}

export default StoreItem
