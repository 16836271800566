import styled from 'styled-components'

const WrapSelectReason = styled.div`
    position: relative;
    .wrap-select {
        margin-top: 33px;
        height: 40px;
        border: 0.5px solid #B7B7B7;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 11px 11px 11px 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        .icon {
            flex-shrink: 0;
        }
    }

    .dropdown {
        z-index: 1;
        width: 100%;
    }
`;

const SelectedValue = styled.span`
    margin: 0 12px;
    flex-grow: 1;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: ${props => props.isSelected ? "#313131" : "#B7B7B7"};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export {
    WrapSelectReason,
    SelectedValue
}