import styled from 'styled-components'

const WrapPopupReasonRefund = styled.div`
    position: relative;
    width: 344px;
    min-height: 268px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 24px 24px 26px;
    display: flex;
    flex-direction: column;

    .ic-close {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }

    .title {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #313131;
    }

    .desc {
        margin-top: 10px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
    }

    .content {
        flex-grow: 1;
    }

    .btn-send-reason {
        margin-top: 46px;
    }
`;

export {
    WrapPopupReasonRefund
}