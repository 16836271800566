import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { LabelCol, RowLabelColumn, WrapRow } from '../ListStores/index.styles'
import { WrapListAccount } from './index.styles'

//component
import AccountItem from './AccountItem'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'

// action
import { getListAccountMerchant, 
    getListAccountSearchFullName, 
    getListAccountSearchInterval, 
    getListAccountSearchUserName, 
    resetListAccountMerchant } from '../../../../../../redux/merchant/detailsMerchant/account/action'
import { GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME, 
    GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL, 
    GET_ACCOUNT_MERCHANT_SEARCH_USERNAME, 
    GET_LIST_ACCOUNT_MERCHANT } from '../../../../../../redux/merchant/detailsMerchant/account/types'

// hook
import useScrollEnd from '../../../../../../hook/useScrollEnd'

const labelColumns = [
    "Username",
    "Họ tên",
    "Ngày tạo",
    "Ngày update"
]

const ListAccount = ({ merchantId }) => {
    const dispatch = useDispatch()
    const listRef = useRef(null)

    const { account: { list, total, type, condFilters } } = useSelector(state => ({
        account: state.merchantReducer.detailsMerchantReducer.account,
        
    }))

    useEffect(() => {
        dispatch(getListAccountMerchant({
            merchantId,
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        return () => {
            dispatch(resetListAccountMerchant())
        }
    }, []);

    useScrollEnd(listRef, (callback) => {
        switch (type) {
            case GET_LIST_ACCOUNT_MERCHANT:
                dispatch(getListAccountMerchant({
                    merchantId,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ACCOUNT_MERCHANT_SEARCH_USERNAME:
                dispatch(getListAccountSearchUserName({
                    merchantId,
                    username: condFilters.username,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME:
                dispatch(getListAccountSearchFullName({
                    merchantId,
                    fullname: condFilters.fullname,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL:
                dispatch(getListAccountSearchInterval({
                    merchantId,
                    startTime: condFilters.startTime,
                    closeTime: condFilters.closeTime,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            default:
                break;
        }
    }, [list, type, condFilters], [total])

    return (
        <WrapListAccount>
            {
                total === 0
                    ? (
                        <div className="wrap-empty">
                            <EmptyDataControl />
                        </div>
                    )
                    : (
                        <>
                            <RowLabelColumn className="row">
                                {
                                    labelColumns.map((labelColumn) => (
                                        <LabelCol key={labelColumn}>{labelColumn}</LabelCol>
                                    ))
                                }
                            </RowLabelColumn>
                            <WrapRow ref={listRef}>
                                <div className="wrap-inner">
                                    {
                                        list.map(data => (
                                            <AccountItem
                                                key={data.id}
                                                data={data}
                                                className="row"
                                                refList={listRef}
                                            />
                                        ))
                                    }
                                </div>
                            </WrapRow>
                        </>
                    )
            }
        </WrapListAccount>
    )
}

export default ListAccount
