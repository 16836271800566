import Axios from 'axios'

//host
import { host } from '../../../../constant/host'

//help
import checkIsSuccessAction from '../../../../helper/checkIsSuccessAction';
import mapErrors from '../../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListAdvertisementMerchant = `${host}/merchant/advertisement/filter`
const apiCreateAdvertisementMerchant = `${host}/merchant/advertisement/create`
const apiUpdateAdvertisementMerchant = `${host}/merchant/advertisement/update`
const apiDeleteAdvertisementMerchant = `${host}/merchant/advertisement/delete`
const apiGetAdvertisementSearchByFile = `${host}/merchant/advertisement/search/byName`
const apiGetAdvertisementSearchByInterval = `${host}/merchant/advertisement/search/byInterval`


export const getListAdvertisementMerchant = ({ merchantId, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        merchantId, 
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetListAdvertisementMerchant, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_ADVERTISEMENT_MERCHANT,
                payload: {
                    list: data.advertisementList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_ADVERTISEMENT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_ADVERTISEMENT_MERCHANT_ERR))
    }
}

export const getListAdvertisementSearchByName = ({ merchantId, name, filterFrom, filterSkip, filterSize  }, isScroll, callbackSuccess) => async dispatch =>{
    const condFilters = {
        merchantId, 
        name,
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetAdvertisementSearchByFile, dataRequest);
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE_ERR))
    }
}

export const getListAdvertisementSearchByInterval = ({ merchantId, startTime, closeTime, filterFrom, filterSkip, filterSize  }, isScroll, callbackSuccess) => async dispatch =>{
    const condFilters = {
        merchantId, 
        startTime,
        closeTime,
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetAdvertisementSearchByInterval, dataRequest);
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL_ERR))
    }
}

export const createAdvertisementMerchant = ({ merchantId, name, format, file, size }, callbackSuccess, callbackError) => async dispatch =>{
    const dataRequest = JSON.stringify({
        merchantId, 
        name,
        format,
        file,
        size
    })
    try{
        const res = await Axios.post(apiCreateAdvertisementMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.CREATE_ADVERTISEMENT_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.CREATE_ADVERTISEMENT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }catch(err){
        dispatch(mapErrors(err, types.CREATE_ADVERTISEMENT_MERCHANT_ERR))
    }
}

export const updateAdvertisementMerchant = ({ id, name, format, file, size }, callbackSuccess, callbackError) => async dispatch =>{
    const dataRequest = JSON.stringify({
        id, 
        name,
        format,
        file,
        size
    })
    try{
        const res = await Axios.post(apiUpdateAdvertisementMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.UPDATE_ADVERTISEMENT_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.UPDATE_ADVERTISEMENT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }catch(err){
        dispatch(mapErrors(err, types.UPDATE_ADVERTISEMENT_MERCHANT_ERR))
    }
}

export const deleteAdvertisementMerchant = ({id}, callbackSuccess, callbackError) => async dispatch =>{
    try {
        const res = await Axios.delete(`${apiDeleteAdvertisementMerchant}?id=${id}`)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.DELETE_ADVERTISEMENT_MERCHANT
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.DELETE_ADVERTISEMENT_MERCHANT_ERR,
                payload: { ...data }
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.DELETE_ADVERTISEMENT_MERCHANT_ERR))
    }
}

export const resetListAdvertisementMerchant = () =>({
    type: types.RESET_LIST_ADVERTISEMENT_MERCHANT
})