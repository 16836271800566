import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapMerchantList } from './index.styles'

// component
import PageHeader from '../PageHeader'
import MerchantItem from './MerchantItem'
import EmptyDataControl from '../../../../Control/EmptyDataControl'
import IcMerchantDefault from '../../../../../assets/images/IcMerchantDefault'

//action
import { getListMerchant, resetListMerchant } from '../../../../../redux/merchant/listMerchant/action'

//hook
import useScrollEnd from '../../../../../hook/useScrollEnd'



const MerchantList = () => {

    const dispatch = useDispatch()
    const listRef = useRef()

    const { list, total } = useSelector(state => ({
        list: state.merchantReducer.listMerchantReducer.list,
        total: state.merchantReducer.listMerchantReducer.total
    }))
   
    useEffect(() => {
        const callbackSuccess = () => dispatch(getListMerchant({ filterFrom: "", filterSkip: 0, filterSize: 20 }))
        dispatch(resetListMerchant(callbackSuccess))
    }, [])

    useScrollEnd(listRef, (callback) => {
        dispatch(getListMerchant({
            filterFrom: list[0]?.id,
            filterSkip: list.length,
            filterSize: 20
        }, true, callback))
    }, [list])

    return (
        <WrapMerchantList>
            <PageHeader>Danh sách Merchant ({total ? total : 0})</PageHeader>
            <div className="wrap-list" ref={listRef}>
                {
                    total === 0 ?
                        <div className="wrap-empty">
                            <EmptyDataControl
                                icon={<IcMerchantDefault />}
                                text={"Merchant list is empty"}
                            />
                        </div>
                        :
                        <div className="wrap-inner-list">
                            {
                                list.map((item, i) => (
                                    <MerchantItem data={item} key={item.id} refList={listRef}/>
                                ))
                            }
                        </div>
                }
            </div>

        </WrapMerchantList>
    )
}

export default MerchantList
