import * as types from './types'

const intitialState = {
    total: 0
}
const customerCountReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_CUSTOMER_COUNT: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.GET_CUSTOMER_COUNT_ERR: {
            return intitialState
        }
        default:
            return state;
    }

}

export default customerCountReducer