const autoDownloadFromBlob = (data, fileName = '') => {
    const downloadUrl = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export default autoDownloadFromBlob
