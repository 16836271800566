import React from 'react'

const IcBankDefault = (props) => {
    return (
        <svg {...props} width={141} height={140} viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="70.5" cy={70} r={70} fill="#B7B7B7" />
            <path d="M71.7332 37.1668L101.733 49.1667C102.9 49.6334 103.833 51.0334 103.833 52.2667V63.3334C103.833 65.1667 102.333 66.6667 100.5 66.6667H40.4998C38.6665 66.6667 37.1665 65.1667 37.1665 63.3334V52.2667C37.1665 51.0334 38.0999 49.6334 39.2665 49.1667L69.2665 37.1668C69.9332 36.9001 71.0665 36.9001 71.7332 37.1668Z" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M103.833 103.333H37.1665V93.3333C37.1665 91.5 38.6665 90 40.4998 90H100.5C102.333 90 103.833 91.5 103.833 93.3333V103.333Z" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M43.8335 90.0003V66.667" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M57.1665 90.0003V66.667" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.5 90.0003V66.667" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M83.833 90.0003V66.667" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M97.167 90.0003V66.667" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.5 58.333C73.2614 58.333 75.5 56.0944 75.5 53.333C75.5 50.5716 73.2614 48.333 70.5 48.333C67.7386 48.333 65.5 50.5716 65.5 53.333C65.5 56.0944 67.7386 58.333 70.5 58.333Z" stroke="#B7B7B7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBankDefault
