import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListPermission = `${host}/bank/account/filter`
const apiCreateNewAccountPermission = `${host}/bank/account/create`
const apiUpdateAccountPermission = `${host}/bank/account/update`
const apiDeleteAccountPermission = `${host}/bank/account/delete`

export const getListPermission = ({ filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess, callbackError) => async dispatch => {
    const condFilters = {
        searchValue: null,
        keyFilter: '',
        filterFrom,
        filterSkip,
        filterSize
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetListPermission, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_PERMISSION,
                payload: {
                    list: data.receiverList || [],
                    condFilters,
                    total: data.total || 0,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_PERMISSION_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.GET_LIST_PERMISSION_ERR))
    }
}

export const resetListPermission = () => ({
    type: types.RESET_LIST_PERMISSION
})

export const createNewAccountPermission = ({ email, fullname, isDisable = false }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        email,
        fullname,
        isDisable,
    })
    try {
        const res = await Axios.post(apiCreateNewAccountPermission, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CREATE_NEW_ACCOUNT_PERMISSION,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CREATE_NEW_ACCOUNT_PERMISSION_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.CREATE_NEW_ACCOUNT_PERMISSION_ERR))
    }
}

export const updateAccountPermission = ({ id, fullname, dailyReport, monthlyReport, isDisable }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id,
        fullname,
        dailyReport,
        monthlyReport,
        isDisable
    })
    try {
        const res = await Axios.post(apiUpdateAccountPermission, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UPDATE_ACCOUNT_PERMISSION,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.UPDATE_ACCOUNT_PERMISSION_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_ACCOUNT_PERMISSION_ERR))
    }
}

export const deleteAccountPermission = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    try {
        const res = await Axios.delete(`${apiDeleteAccountPermission}?id=${id}`)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.DELETE_ACCOUNT_PERMISSION,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.DELETE_ACCOUNT_PERMISSION_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.DELETE_ACCOUNT_PERMISSION_ERR))
    }
}

export const setActiveTab = (activeTab) => ({
    type: types.SET_ACTIVE_TAB,
    payload: {
        activeTab
    }
})

