import styled, { css } from 'styled-components'

// styles
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapSetting = styled(WrapPageItem)`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 0 30px;
`;

export {
    WrapSetting,
}