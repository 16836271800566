import * as types from './types'

const intitialState = {
    total: 0
}
const bankCountReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_BANK_COUNT: {
            return {
                ...state,
                ...action.payload
              }
        }             
        case types.GET_BANK_COUNT_ERR: {
            return intitialState
        }  
        default:
            return state;
    }

}

export default bankCountReducer