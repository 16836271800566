import React, { useEffect, useReducer, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapCreatNewAccountPermission } from './index.styles'
import { OverlayFullScreen } from '../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

//component
import Button from '../../../../../../Control/Button'
import IcCreateNew from '../../../../../../../assets/images/IcCreateNew'
import IcClose from '../../../../../../../assets/images/IcClose'
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'
import InputFullname from './InputFullname'
import InputEmail from './InputEmail'
import BtnToggle from '../../../../../../Control/BtnToggle'

//action
import { checkEmailSuccess } from '../../../../../../../helper/checkEmailSuccess'
import { createNewAccountPermission, getListPermission } from '../../../../../../../redux/permission/listPermission/action'

const titleErrorEmail = "Email invalid"

const CreatNewAccountPermission = ({ setIsShowModalAddNew, onClickIconClose }) => {

    const dispatch = useDispatch()
    const refNewEmail = useRef(null)

    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);
    const [isErrorEmail, setIsErrorEmail] = useState(false)
    const [isToggle, setIsToggle] = useState(true)

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fullname: "",
            email: "",
            isDisable:!isToggle
        }
    )

    const _handleChange = (stateKey) => (e) => {
        setStateForm({ [stateKey]: e.target.value })
    }

    const handleValidationEmail = (value, callback) => {
        if(value){
            const error = checkEmailSuccess(value)
            if (error === false) {
                callback(titleErrorEmail)
                setIsErrorEmail(true)
                return
            }
            setIsErrorEmail(false)
        }
    }

    const handleChangeToggle = (isCheck) => {
        setStateForm({
            isDisable: !isCheck
        })
    }

    const callbackSuccess = () => {
        onClickIconClose && onClickIconClose()
        dispatch(getListPermission({
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
    }

    const _handleSubmit = async () => {

        const { email, fullname, isDisable } = stateForm

        const dataRequest = {
            fullname,
            email,
            isDisable,
        }

        if (isErrorEmail === false) {
            dispatch(createNewAccountPermission(
                dataRequest, callbackSuccess
            ))
        }

    }

    useEffect(() => {
        const { fullname, email } = stateForm
        const isEnable = fullname && email 
        setIsDisabledBtnCreate(!isEnable)
    }, [stateForm]);

    useOnClickOutside(refNewEmail, () => setIsShowModalAddNew(false))

    return (
        <OverlayFullScreen >
            <div className="wrap-inner-screen" >
                <WrapCreatNewAccountPermission ref={refNewEmail}>
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <p className="title">Add New Account</p>
                    <p className="description">Input email information to receive automatic reports</p>
                    <InputFullname
                        valueInput={stateForm.fullname}
                        onChange={_handleChange("fullname")}
                        placeholder="Fullname"
                        className="full-name"
                    />
                    <InputEmail
                        valueInput={stateForm.email}
                        onChange={_handleChange("email")}
                        placeholder="Email Address"
                        className="email"
                        handleValidationUser={handleValidationEmail}
                    />
                    <div className="active-account">
                        <div className="wrap">
                            <div className="title">Active Account</div>
                            <p className="des">*The account is activated allowing login to the system</p>
                        </div>
                        <BtnToggle
                            defaultChecked={isToggle}
                            handleChangeCheck={(isCheck) => handleChangeToggle(isCheck)}
                        />
                    </div>
                    <Button
                        text="Add New"
                        iconBtn={<IcCreateNew />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapCreatNewAccountPermission>
            </div>
        </OverlayFullScreen>
    )
}

export default CreatNewAccountPermission
