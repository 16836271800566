import { useRef, useEffect } from 'react'

const useResizeContentTable = (wrapTable, paddingRightTable, refHeadTable, refListContentScroll, deps) => {

    const timer = useRef(null)

    const _handleResize = () => {
        clearTimeout(timer.current)
        if(!wrapTable.current || !refHeadTable.current || !refListContentScroll.current) return;

        const { width } = refHeadTable.current.getBoundingClientRect()
        const { offsetWidth, scrollWidth } = refListContentScroll.current
        timer.current = setTimeout(() => {
            const epsilon = offsetWidth - scrollWidth
            wrapTable.current.style.paddingRight = `${paddingRightTable - epsilon}px`
            refHeadTable.current.style.width = `${width}px`
            refListContentScroll.current.style.width = `${width + epsilon}px`
            refListContentScroll.current.querySelector('div').style.width = `${width}px`
        }, 100)
    }

    useEffect(() => {
        _handleResize()
    }, deps);

    useEffect(() => {
        window.addEventListener('resize', _handleResize)

        return () => {
            clearTimeout(timer.current)
            window.removeEventListener('resize', _handleResize)
        }
    }, deps);
}

export default useResizeContentTable
