import React from 'react'

const IcHelp = (props) => {
    return (
        <svg {...props} width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 9H6.005" stroke="#313131" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.54492 4.9998C4.66247 4.66563 4.8945 4.38385 5.1999 4.20436C5.5053 4.02488 5.86437 3.95927 6.21351 4.01915C6.56265 4.07904 6.87933 4.26056 7.10746 4.53156C7.33559 4.80256 7.46045 5.14556 7.45992 5.4998C7.45992 6.4998 5.95992 6.9998 5.95992 6.9998" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcHelp
