import React from 'react'


import IcStore from '../../../../../../../../../assets/images/IcStore'
import IcStoreActive from '../../../../../../../../../assets/images/IcStoreActive'
import IcStoreError from '../../../../../../../../../assets/images/IcStoreError'
import Input from '../../../../../../../../Control/Input'


const iconStore = {
    icon: IcStore,
    iconActive: IcStoreActive,
    iconError: IcStoreError,
}

const InputName = (valueInput) => {
    return (
        <Input
            name="name"
            className="input-store"
            placeholder="Tên Cửa hàng"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconStore}
        />
    )
}

export default InputName
