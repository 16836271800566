import styled from 'styled-components'

// styles
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapCustomerDetails = styled(WrapPageItem)`
    
`;

export {
    WrapCustomerDetails
}