import styled from 'styled-components'

// styles
// import { WrapTransHistoryItem } from '../TransactionHistory/TransHistoryItem/index.styles';
import { WrapPageItem } from '../TransactionsList/index.styles';


const WrapCustTransHistory = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;

    .wrap-list-history {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        overflow: auto;
        margin-top: 11px;
        position: relative;
        .list-history {
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
        }
    }
`;

export {
    WrapCustTransHistory
}