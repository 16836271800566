import styled from 'styled-components'

import { WrapPopupDel } from '../../../../../MerchantList/MerchantItem/OptionMerchant/PopupDelMerchant/index.styles';

const WrapPopupDelAdvertisement = styled(WrapPopupDel)`
    width: 244px;
    .sub-title {
        margin-top: 10px;
    }

    .notice {
        margin-top: 25px;
    }

    .btn-del {
        margin-top: 30px;
    }

    .btn-cancel {
        margin-top: 10px;
    }
`;

export {
    WrapPopupDelAdvertisement
}