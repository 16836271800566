import styled from 'styled-components'

// styles
import { WrapPageItem } from '../../TransactionsList/index.styles';

const WrapHeader = styled.div`
    display: flex;
    justify-content: space-between;
    .page-header{
        display: flex;
        flex-direction: column;
        .des{
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #313131;
            padding: 0 0 16px 16px;
        }
    }
    .button{
        display: flex;
        cursor: pointer;
        padding-top: 22px;
        .icon{
            padding-top: 2.5px
        }
        .text{
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            padding-left: 4px;
        }
    }

`

const LabelCol = styled.div`
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #313131;
`

const Row = styled.div`
    display: grid;
    grid-column-gap: 10px;
    align-items: center;    
    padding: 10px 11px 10px 20px;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-sizing: border-box;

    /* &:not(:first-child) {
        margin-top: 6px;
    } */

    &:hover {
        border: 1px solid #33ADFF;
    }
`

const RowLabelColumn = styled(Row)`
    margin-top: 23px;
    padding-bottom: 4px;
    box-shadow: unset;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #313131;
    .status{
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 40px;
        .icon{
            margin-left: 3px;
            cursor: pointer;
        }       
        .tooltip {
            width: 158px;
            height: 50px;
            background: #313131;
            border-radius: 4px;
            padding: 10px;
            box-sizing: border-box;
            font-weight: normal;
            color: #FFFFFF;
            position: absolute;
            bottom: 20px;
            right: 0px;
            z-index: 999;
            visibility: hidden;
            transition: visibility 0.3s ease 0.1s; 
        }
        &:hover .tooltip, .tooltip:hover {
            visibility: visible;
        }        
    }
    &:hover {
        border: none;
    }
`
const WrapRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;  
    .wrap-list {
        width: 100%;
        flex-grow: 1;
        overflow: auto;
        margin-top: 11px;
        position: relative; 
        .wrap-inner {
            position: absolute;
            width: 100%;
            & > *:not(:first-child) {
                margin-top: 8px;
            }
        }
    }
`

const WrapListAccount = styled(WrapPageItem)`
    display: flex;
    flex-direction: column;
    height: 100%;
        
    .row {
        grid-template-columns: 55px 145px 1fr 270px 100px 100px 90px;
    }
    .wrap-empty {
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

export {
    WrapHeader,
    LabelCol,
    Row,
    RowLabelColumn,
    WrapRow,
    WrapListAccount
}