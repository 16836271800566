import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
// styles
import { WrapDatePickerItem, WrapSelectDate } from './index.styles';

const SelectDate = forwardRef(({ textEmpty, value, onClick }, ref) => {
    return (
        <WrapSelectDate className={value ? 'have-value' : 'empty'} onClick={onClick} ref={ref}>
            {value || textEmpty}
        </WrapSelectDate>
    )
});

const DatePickerItem = ({ textEmpty, ...props }) => {
    return (
        <WrapDatePickerItem>
            <DatePicker 
                customInput={<SelectDate textEmpty={textEmpty} />}
                dateFormat="HH:mm - dd/MM/yy"
                showTimeSelect
                showPopperArrow={false}
                timeFormat="HH:mm"
                timeIntervals={60}
                popperPlacement="bottom"
                {...props}
            />
        </WrapDatePickerItem>
    )
}

export default DatePickerItem
