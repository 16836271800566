import React, { useReducer, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// component
import IcClose from '../../../../../assets/images/IcClose'
import IcCreateNew from '../../../../../assets/images/IcCreateNew'
import InputDeviceName from './InputDeviceName'
import InputMerchant from './InputMerchant'
import InputStore from './InputStore'
import QRCode from './QRCode'
import Button from '../../../../../components/Control/Button'
import Portal from '../../../../Control/Portal'

// styles
import { OverlayFullScreen } from '../../Page/TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapCreateNewDevice } from './index.styles'

// action
import { getListStoreMerchant } from '../../../../../redux/merchant/detailsMerchant/store/action'
import { createDevice } from '../../../../../redux/device/listDevicesByAdmin/action'

const CreateNewDevice = ({ handleClose }) => {

    const dispatch = useDispatch()
    
    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(false);
    const [isShowQRCode, setIsShowQRCode] = useState(false);
    const [tokenInfos, setTokenInfos] = useState(null);

    const [formData, setFormData] = useReducer(
        (state, action) => ({...state, ...action}),
        {
            name: '',
            merchant: null,
            store: null
        }
    );

    const _handleSelectMerchant = (merchant) => {
        const { key } = merchant || {}
        if(!key) return;
        if(key === formData.merchant?.key) return;
        
        setFormData({ 
            merchant,
            store: null
        })
        
        dispatch(getListStoreMerchant({
            merchantId: key, 
            filterFrom: "", 
            filterSkip: 0, 
            filterSize: 20 
        }))
    }

    const callbackSubmitSuccess = (tokenInfos) => {
        setIsShowQRCode(true)
        setTokenInfos(tokenInfos)
    }

    const _handleSubmit = () => {
        
        const { name, store, merchant } = formData
        const { key: storeId } = store
        const { key: merchantId } = merchant
        dispatch(createDevice({
            name,
            merchantId,
            storeId
        }, callbackSubmitSuccess))
    }

    useEffect(() => {
        if(Object.values(formData).some(item => !item)) {
            setIsDisabledBtnCreate(true)
            return;
        }
        setIsDisabledBtnCreate(false)
        return;
    }, [JSON.stringify(formData)]);

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapCreateNewDevice isShowQRCode={isShowQRCode}>
                    <IcClose className="ic-close" onClick={handleClose} />
                    <div className="title">Thêm mới thiết bị</div>
                    <div className="sub-title">Bố sung thông tin thiết bị mới</div>
                    <InputDeviceName
                        placeholder="Tên thiết bị"
                        valueInput={formData.name}
                        onChange={(e) => setFormData({ name: e.target.value })}
                        className="device-name"
                    />
                    <InputMerchant 
                        handleGetValue={_handleSelectMerchant}
                    />
                    <InputStore
                        handleGetValue={(store) => setFormData({ store })}
                        store={formData.store}
                        merchant={formData.merchant}
                    />
                    <Button
                        text="Thêm mới"
                        iconBtn={<IcCreateNew />}
                        className="btn-submit"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapCreateNewDevice>
                {
                    isShowQRCode &&
                    <Portal>
                        <QRCode 
                            tokenInfos={tokenInfos}
                            handleClose={() => setIsShowQRCode(false)}
                            handleRetry={_handleSubmit}
                        />
                    </Portal>
                }
            </div>
        </OverlayFullScreen>
    )
}

export default CreateNewDevice
