import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListBank = `${host}/supplier/bank/filter`
const apiCreateNewBank = `${host}/supplier/bank/create`
const apiUpdateBank = `${host}/supplier/bank/update`
const apiDeleteBank = `${host}/supplier/bank/delete`

export const getListBank = ({ filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        searchValue: null,
        keyFilter: '',
        filterFrom,
        filterSkip,
        filterSize
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetListBank, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_BANK,
                payload: {
                    list: data.bankList || [],
                    condFilters,
                    total: data.total || 0,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_BANK_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.GET_LIST_BANK_ERR))
    }
}

// export const resetListBank = (callbackSuccess) => ({
//     type: types.RESET_LIST_BANK
// })
export const resetListBank = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_LIST_BANK,
    })
    callbackSuccess && callbackSuccess()
}

export const createNewBank = ({ logo, bankName, bankShortName, bankCode }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        logo,
        bankName,
        bankShortName,
        bankCode,
    })
    try {
        const res = await Axios.post(apiCreateNewBank, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CREATE_NEW_BANK,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.CREATE_NEW_BANK_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.CREATE_NEW_BANK_ERR))
    }
}

export const updateBank = ({ id, logo, bankName, bankShortName, bankCode }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id,
        logo,
        bankName,
        bankShortName,
        bankCode,
    })
    try {
        const res = await Axios.post(apiUpdateBank, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UPDATE_BANK,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.UPDATE_BANK_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_BANK_ERR))
    }
}

export const deleteBank = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    try {
        const res = await Axios.delete(`${apiDeleteBank}?id=${id}`)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.DELETE_BANK,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.DELETE_BANK_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.DELETE_BANK_ERR))
    }
}

