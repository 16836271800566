import * as types from './types'

const initialState = {
    list: [],
    condFilters: {},
    totalDisconnect: 0,
    total: 0,
    isStatusView: true,
    isShowPopupAdd: false,
    screenShot: {
        deviceId: "",
        isLoading: false,
        image: "",
        imageName: "",
        error: ""
    }
}
const listDevicesByAdmin = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_DATA_SCREEN_SHOT: {
            return {
                ...state,
                screenShot: {
                    ...state.screenShot,
                    ...action.payload.screenShot
                }
            }
        }
        case types.SET_TOTAL_DEVICE_DISCONNECTED: {
            return {
                ...state,
                totalDisconnect: action.payload.total
            }
        }
        case types.TOGGLE_SHOW_POPUP_ADD:
            return {
                ...state,
                isShowPopupAdd: action.payload.isShow !== undefined 
                    ? action.payload.isShow
                    : !state.isShowPopupAdd
            }
        case types.GET_LIST_DEVICES_BY_ADMIN:
        case types.GET_LIST_DEVICES_BY_ADMIN_BY_ID:
        case types.GET_LIST_DEVICES_BY_ADMIN_BY_STATUS:
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        case types.TOGGLE_LIST_VIEW_MODE: {
            return {
                ...state,
                isStatusView: !state.isStatusView
            }
        }
        case types.RESET_LIST_VIEW_MODE: {
            return {
                ...state,
                isStatusView: initialState.isStatusView
            }
        }
        case types.RESET_LIST_DEVICES_BY_ADMIN:
            return {
                ...initialState,
                isStatusView: state.isStatusView,
                totalDisconnect: state.totalDisconnect
            }
        default:
            return state;
    }

}

export default listDevicesByAdmin