import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// styles
import { WrapGeneralInfos } from './index.styles'

// component
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault'
import OptionMerchant from '../../MerchantList/MerchantItem/OptionMerchant'

// api
import { apiMerchantLogo } from '../../../../../../constant/host'

//help
import { convertDateTime } from '../../../../../../helper/convertDateTime'


const IMG_MERCHANT_MOCK = "https://picsum.photos/480/480"

const GeneralInfos = () => {

    const {
        listCategories,
        generalInfos
    } = useSelector(state => ({
        listCategories: state.merchantReducer.listMerchantReducer.listCategories,
        generalInfos: state.merchantReducer.detailsMerchantReducer.generalInfos,
    }))
    // console.log(generalInfos);

    const [isErrorLogo, setIsErrorLogo] = useState(false);

    useEffect(() => {
        setIsErrorLogo(false)
    }, [generalInfos?.id])

    const merchantType = generalInfos?.category ? listCategories[generalInfos?.category]?.text : ""
   
    return (
        <WrapGeneralInfos>
            <div className="wrap-logo">
                {
                    !generalInfos?.id || isErrorLogo
                        ? (
                            <IcMerchantLogoDefault
                                className="logo"
                            />
                        )
                        : (
                            <img
                                className="logo"
                                src={apiMerchantLogo(generalInfos?.id)}
                                onError={() => setIsErrorLogo(true)}
                                alt="merchant-logo"
                            />
                        )
                }
            </div>
            <div className="wrap-merchant-infos merchant-name">
                <div className="content-bold" title={generalInfos?.name}>{generalInfos?.name || '-'}</div>
                <div className="content-gray" title={generalInfos?.code}>{generalInfos?.code || '-'}</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold merchant-type" title={merchantType}>{merchantType || '-'}</div>
                <div className="content-gray">Loại chuỗi</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{generalInfos?.email || '-'}</div>
                <div className="content-gray">Email Merchant</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{generalInfos?.phoneNumber || '-'}</div>
                <div className="content-gray">Số điện thoại</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{generalInfos?.createAt ? convertDateTime(generalInfos?.createAt, "DD/MM/YYYY") : '-'}</div>
                <div className="content-gray">Ngày tạo</div>
            </div>
            <div className="wrap-merchant-infos">
                <div className="content-bold">{generalInfos?.lastUpdateAt ? convertDateTime(generalInfos?.lastUpdateAt, "DD/MM/YYYY") : '-'}</div>
                <div className="content-gray">Ngày update</div>
            </div>
            <div className="wrap-tools">
                <OptionMerchant merchantData={generalInfos} isDetails={true}/>
            </div>
        </WrapGeneralInfos>
    )
}

export default GeneralInfos
