import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";

// component
import Button from '../../../../../../../Control/Button'
import IcTrashWhite from '../../../../../../../../assets/images/IcTrashWhite'

// styles
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupDelMerchant } from './index.styles'

// constant
import * as path from '../../../../../../../../constant/path'

//action
import { deleteMerchant, getListMerchant } from '../../../../../../../../redux/merchant/listMerchant/action'
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside';

const PopupDelMerchant = ({ merchantData: { id, name }, handleClose, setKeyShowForm, isDetails = false }) => {

    const dispatch = useDispatch()
    const refDeleteBank = useRef(null)
    const history = useHistory()

    const callbackSuccess = () => {
        setKeyShowForm("")
        dispatch(getListMerchant({
            filterFrom: "",
            filterSkip: 0,
            filterSize: 20
        }))
    }
    const _handleSubmit = () => {
        dispatch(deleteMerchant(
            { id: id },
            callbackSuccess
        ))
        if (!!isDetails) {
            history.push(`/${path.MAIN}/${path.MERCHANT}`)
        }
    }

    useOnClickOutside(refDeleteBank, () => setKeyShowForm(""))

    return (
        <OverlayFullScreen onClick={e => e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupDelMerchant ref={refDeleteBank}>
                    <div className="title">Xóa Merchant</div>
                    <div className="sub-title">
                        Bạn vui lòng xác nhận xóa Merchant <span title={name}>“{name.length > 20 ? `${name.substring(0, 20)}...` : name}”</span>
                    </div>
                    <div className="notice">
                        *Merchant bị xóa sẽ không thể phục hồi
                    </div>
                    <Button
                        text="Xóa Merchant"
                        iconBtn={<IcTrashWhite />}
                        className="btn-del"
                        onClick={_handleSubmit}
                    />
                    <Button
                        text="Hủy bỏ"
                        className="btn-cancel"
                        onClick={handleClose}
                    />
                </WrapPopupDelMerchant>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupDelMerchant
