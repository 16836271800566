import styled from 'styled-components'

import { WrapCreateNewPopup } from '../../../../TabListAndTool/ToolStore/CreateNewStore/index.styles';

const WrapPopupEditStore = styled(WrapCreateNewPopup)`
    min-width: 320px;
    .title{
        margin-bottom: 10px;
    }
    .description{
        margin-bottom: 33px;
    }
    /* .input-store{
        margin-bottom: 10px;
    } */
    .btn-creteNew{
        width: 100%;
        margin-top:36px;
    }
`;

export {
    WrapPopupEditStore,
    WrapCreateNewPopup,
}