import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// mockData
// import { listDevices } from '../../../../../../mockData/listDevices'

//component
import DeviceItemByList from './DeviceItemByList'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import IcDeviceDefault from '../../../../../../assets/images/IcDeviceDefault'

// styles
import { LabelCol, RowLabelColumn, WrapRow } from '../../MerchantDetails/ListStores/index.styles'
import { WrapDeviceAdminByList } from './index.styles'

// action
import { getListDevicesByAdmin, getListDevicesByAdminById, getListDevicesByAdminByStatus, resetListDevicesAdmin } from '../../../../../../redux/device/listDevicesByAdmin/action'

// hook
import useScrollEnd from '../../../../../../hook/useScrollEnd'
import useCallOnlyOnce from '../../../../../../hook/useCallOnlyOnce'

// constant
import { GET_LIST_DEVICES_BY_ADMIN_BY_ID, GET_LIST_DEVICES_BY_ADMIN_BY_STATUS } from '../../../../../../redux/device/listDevicesByAdmin/types'


const labelColumns = [
    "Status",
    "Devices ID",
    "Tên thiết bị",
    "Merchant",
    "Cửa hàng",
    "Địa chỉ",
    "Phiên bản",
    "Thời gian mở thiết bị"
]

const DeviceAdminByListView = () => {
    const listRef = useRef()
    const dispatch = useDispatch()

    const {
        listDevicesByAdmin: {
            list,
            total,
            type,
            condFilters: {
                id: idFilter,
                status: statusFilter
            } = {}
        }
    } = useSelector(state => ({
        listDevicesByAdmin: state.deviceReducer.listDevicesByAdmin
    }))

    useCallOnlyOnce(() => {
        switch (type) {
            case GET_LIST_DEVICES_BY_ADMIN_BY_ID: {
                dispatch(getListDevicesByAdminById({
                    id: idFilter,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: 20
                }))
                return true;
            }
            case GET_LIST_DEVICES_BY_ADMIN_BY_STATUS: {
                dispatch(getListDevicesByAdminByStatus({
                    status: statusFilter,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: 20
                }))
                return true;
            }
            default: {
                dispatch(getListDevicesByAdmin({
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: 20
                }))
                return true;
            }
        }
    }, [type, idFilter, statusFilter]);

    useEffect(() => {
        return () => {
            dispatch(resetListDevicesAdmin())
        }
    }, []);

    useScrollEnd(listRef, (callback) => {
        switch (type) {
            case GET_LIST_DEVICES_BY_ADMIN_BY_ID: {
                dispatch(getListDevicesByAdminById({
                    id: idFilter,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            }
            case GET_LIST_DEVICES_BY_ADMIN_BY_STATUS: {
                dispatch(getListDevicesByAdminByStatus({
                    status: statusFilter,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            }
            default: {
                dispatch(getListDevicesByAdmin({
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            }
        }
    }, [list, idFilter, statusFilter])

    return (
        <WrapDeviceAdminByList>
            {
                total === 0
                    ? (
                        <div className="wrap-empty">
                            <EmptyDataControl
                                icon={<IcDeviceDefault />}
                                text={"There are currently no new devices available"}
                            />
                        </div>
                    )
                    : (
                        <>
                            <RowLabelColumn className="row header-list">
                                {
                                    labelColumns.map((labelcolumn) => (
                                        <LabelCol key={labelcolumn}>{labelcolumn}</LabelCol>
                                    ))
                                }
                            </RowLabelColumn>
                            <WrapRow ref={listRef}>
                                <div className="wrap-inner">
                                    {
                                        list.map(item => (
                                            <DeviceItemByList
                                                key={item.id}
                                                className="row"
                                                data={item}
                                                refList={listRef}
                                            />
                                        ))
                                    }
                                </div>
                            </WrapRow>
                        </>
                    )
            }
        </WrapDeviceAdminByList>
    )
}

export default DeviceAdminByListView
