import { useEffect, useRef } from 'react'

const useCallOnlyOnce = (callback, dependencies) => {
    const isCalled = useRef(false)

    useEffect(() => {
        if(isCalled.current) return;
        const checkCall = callback()
        if(typeof(checkCall) === 'undefined')
            throw Error('callback parameter in useCallOnlyOnce must return boolean value')
        isCalled.current = checkCall
    }, dependencies);
}

export default useCallOnlyOnce
