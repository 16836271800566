import styled from "styled-components";

const InputWrapper = styled.div`
    margin-bottom: 20px;
    height: ${props => props.heightInput || "40px"};
    .wrap-input{
        height: 40px;
        position: relative;
        .icon-left{
            position: absolute;
            left: 10px;
            top: 10px;
        }
        .icon-right{
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
        input{
            height: 100%;
            width: 100%;
            margin-left: 50p;
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            padding-left: 40px;
            padding-right: 10px;
            border: 0.5px solid #B7B7B7;
            border-radius: 4px;
            letter-spacing: -0.01em;
            font-size: 13px;
            line-height: 18px;

            &:disabled {
                background: #F7F7F7;
                color: #B7B7B7;
            }

            &:focus{
                outline: none;
                border: 0.5px solid #33adff;
            }
            &::placeholder{
                color: #B7B7B7;
            }
        }
        & > .error-input{
            border: 0.5px solid #F45C5C;
        }
    }
    .error{
        color: #F45C5C;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        padding-left: 15px;
        float: left;
        padding-top: 4px;
    }
`

export {InputWrapper};