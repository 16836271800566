import React from 'react'
import { BANK_ID, FACEPAY_ID, INTERVAL, MERCHANT_ID } from '../../../../../../constant/keys'

// component
import DropdownControl from '../../../../../Control/DropdownControl'

// export const KEY_MERCHANT_ID = "1"
// export const KEY_BANK_ID = "2"
// export const KEY_FACEPAY_ID = "3"
// export const KEY_TIME_SELECTION = "4"

export const dataSelection = [
    {
        text: "Merchant Tx ID",
        key: MERCHANT_ID
    },
    {
        text: "Bank Tx ID",
        key: BANK_ID
    },
    {
        text: "Facepay Tx ID",
        key: FACEPAY_ID
    },
    {
        text: "Ngày",
        key: INTERVAL
    },
]

const DropdownSelectTypeSearch = ({ filterCond: { text, key }, setFilterCond }) => {

    const _handleSetValueSelect = (val) => {
        setFilterCond(val)
    }

    return (
        <DropdownControl 
            dataSelection={dataSelection}
            valueSelected={{ text, key }}
            handleSetValueSelect={_handleSetValueSelect}
        />
    )
}

export default DropdownSelectTypeSearch
