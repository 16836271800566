import * as types from './types'

const initialState = {
    list: [],
    condFilters: {},
    total: 0,
}
const store = (state = initialState, action) =>{
    switch (action.type) {
        case types.GET_LIST_ADVERTISEMENT_MERCHANT:
        case types.GET_ADVERTISEMENT_MERCHANT_SEARCH_FILE:
        case types.GET_ADVERTISEMENT_MERCHANT_SEARCH_INTERVAL:
            return {
                ...state,
                ...action.payload,
                type: action.type,
                list: action.payload.isScroll
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        case types.RESET_LIST_ADVERTISEMENT_MERCHANT:
            return { ...initialState }
        default:
            return state;
    }
}

export default store