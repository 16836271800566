import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//mockData
// import { listDevices } from '../../../../../../mockData/listDevices'

// action
import { getListDevicesByAdmin, getListDevicesByAdminById, getListDevicesByAdminByStatus, resetListDevicesAdmin } from '../../../../../../redux/device/listDevicesByAdmin/action'

// constant
import { GET_LIST_DEVICES_BY_ADMIN_BY_ID, GET_LIST_DEVICES_BY_ADMIN_BY_STATUS } from '../../../../../../redux/device/listDevicesByAdmin/types'

//component
import DeviceAdminByStatus from './DeviceItemByStatus'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import IcDeviceDefault from '../../../../../../assets/images/IcDeviceDefault'

// styles
import { WrapDeviceAdminByStatusView } from './index.styles'

// hook
import useCallOnlyOnce from '../../../../../../hook/useCallOnlyOnce'


const DeviceAdminByStatusView = () => {
    const dispatch = useDispatch()

    const { 
        listDevicesByAdmin: { 
            list, 
            total, 
            type,
            condFilters: { 
                id: idFilter, 
                status: statusFilter
            }={}
        } 
    } = useSelector(state => ({
        listDevicesByAdmin: state.deviceReducer.listDevicesByAdmin
    }))
    
    useCallOnlyOnce(() => {
        switch(type) {
            case GET_LIST_DEVICES_BY_ADMIN_BY_ID: {
                dispatch(getListDevicesByAdminById({
                    id: idFilter,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: -1
                }))
                return true;
            }
            case GET_LIST_DEVICES_BY_ADMIN_BY_STATUS: {
                dispatch(getListDevicesByAdminByStatus({
                    status: statusFilter,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: -1
                }))
                return true;
            }
            default: {
                dispatch(getListDevicesByAdmin({
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: -1
                }))
                return true;
            }
        }
    }, [type, idFilter, statusFilter]);

    useEffect(() => {
        return () => {
            dispatch(resetListDevicesAdmin())
        }
    }, []);

    return (
        <WrapDeviceAdminByStatusView>
            <div className="wrap-list">
                {
                    total === 0
                    ? (
                        <div className="wrap-empty">
                            <EmptyDataControl
                            icon={<IcDeviceDefault />} 
                            text={"There are currently no new devices available"}
                            /> 
                        </div>
                    )
                    : 
                    <div className="wrap-inner-list">
                        {
                            list.map((data) => ( 
                            /* listDevices.map((data) => ( */
                                <DeviceAdminByStatus
                                    key={data.id}
                                    data={data}
                                />
                            ))
                        }
                    </div>
                }
            </div>
        </WrapDeviceAdminByStatusView>
    )
}

export default DeviceAdminByStatusView
