import React from 'react'


import IcStore from '../../../../../../../../../assets/images/IcStore'
import IcStoreActive from '../../../../../../../../../assets/images/IcStoreActive'
import Input from '../../../../../../../../Control/Input'


const iconStore = {
    icon: IcStore,
    iconActive: IcStoreActive,
    iconError: IcStoreActive,
}

const InputCode = (valueInput) => {
    return (
        <Input
            name="code"
            className="input-store"
            placeholder="Mã cửa hàng"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconStore}
        />
    )
}

export default InputCode
