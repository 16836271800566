import React from 'react'

//const
import { ANALYTIC, MAIN, TRANSACTION, DEVICE, MERCHANT, PAYMENT_TERMINAL, BANK, SETTING, CUSTOMER, PERMISSION } from "../constant/path";
import { WEE_ENV, BANK_ENV, DEV_ENV } from '../constant/environmentTypes';

//image
import IcAnalytic from '../assets/images/IcAnalytic';
import IcAnalyticActive from '../assets/images/IcAnalyticActive';
import IcTransaction from '../assets/images/IcTransaction';
import IcTransactionActive from '../assets/images/IcTransactionActive';
import IcDevices from '../assets/images/IcDevices';
import IcDevicesActive from '../assets/images/IcDevicesActive';
import IcMerchant from '../assets/images/IcMerchant';
import IcMerchantAcive from '../assets/images/IcMerchantActive'
import IcBanks from '../assets/images/IcBanks';
import IcBanksActive from '../assets/images/IcBanksActive';
import IcSettings from '../assets/images/IcSettings';
import IcSettingsActive from '../assets/images/IcSettingsActive';
import IcUsers from '../assets/images/IcUsers';
import IcUsersActive from '../assets/images/IcUsersActive';
import IcPermissions from '../assets/images/IcPermissions';
import IcPermissionsActive from '../assets/images/IcPermissionsActive';

const dataMenu = [
    {
        path: `/${MAIN}/${ANALYTIC}`,
        Icon: IcAnalytic,
        IconActive: IcAnalyticActive,
        name: 'Phân tích dữ liệu',
        key: ANALYTIC,
        envValid: [DEV_ENV, WEE_ENV, BANK_ENV]
    },
    {
        path: `/${MAIN}/${TRANSACTION}`,
        Icon: IcTransaction,
        IconActive: IcTransactionActive,
        name: 'Dữ liệu giao dịch',
        key: TRANSACTION,
        envValid: [DEV_ENV, WEE_ENV, BANK_ENV]
    },
    {
        path: `/${MAIN}/${DEVICE}`,
        Icon: IcDevices,
        IconActive: IcDevicesActive,
        name: 'Payment Terminal',
        DEVICE: DEVICE,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        path: `/${MAIN}/${PAYMENT_TERMINAL}`,
        Icon: IcDevices,
        IconActive: IcDevicesActive,
        name: 'Payment Terminal',
        key: PAYMENT_TERMINAL,
        envValid: [DEV_ENV]
    },
    {
        path: `/${MAIN}/${CUSTOMER}`,
        Icon: IcUsers,
        IconActive: IcUsersActive,
        name: 'Customers',
        key: CUSTOMER,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        path: `/${MAIN}/${MERCHANT}`,
        Icon: IcMerchant,
        IconActive: IcMerchantAcive,
        name: 'Merchants',
        key: MERCHANT,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        path: `/${MAIN}/${BANK}`,
        Icon: IcBanks,
        IconActive: IcBanksActive,
        name: 'Banks',
        key: BANK,
        envValid: [DEV_ENV, WEE_ENV]
    },
    {
        path: `/${MAIN}/${PERMISSION}`,
        Icon: IcUsers,
        IconActive: IcPermissionsActive,
        name: 'Permissions',
        key: PERMISSION,
        envValid: [DEV_ENV]
    },
]

export default dataMenu