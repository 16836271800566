import React from 'react'


import IcPhone from '../../../../../../assets/images/IcPhone'
import IcPhoneActive from '../../../../../../assets/images/IcPhoneActive'
import Input from '../../../../../Control/Input'

const iconPhone = {
    icon: IcPhone,
    iconActive: IcPhoneActive,
    iconError: IcPhoneActive,
}

const InputPhone = (valueInput) => {
    return (
        <Input
            name="phoneNumber"
            className="input-merchant"
            placeholder="Số điện thoại Merchant"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconPhone}
        />
    )
}

export default InputPhone
