import styled from 'styled-components'

// styles
import { WrapPopupCreate } from '../../Page/MerchantDetails/TabListAndTool/ToolAccount/PopupCreateAccount/index.styles';

const WrapFlowNewDevice =  styled(WrapPopupCreate)`
    width: 300px;

    .sub-title {
        margin-top: 10px;
    }

    .device-name {
        margin-top: 33px;
    }
`;

const WrapCreateNewDevice = styled(WrapFlowNewDevice)`
    visibility: ${props => props.isShowQRCode ? 'hidden' : 'initial'};
`;

export {
    WrapFlowNewDevice,
    WrapCreateNewDevice
}