export const GET_LIST_DEVICES_BY_ADMIN = 'GET_LIST_DEVICES_BY_ADMIN'
export const GET_LIST_DEVICES_BY_ADMIN_ERR = 'GET_LIST_DEVICES_BY_ADMIN_ERR'

export const GET_LIST_DEVICES_BY_ADMIN_BY_ID = 'GET_LIST_DEVICES_BY_ADMIN_BY_ID'
export const GET_LIST_DEVICES_BY_ADMIN_BY_ID_ERR = 'GET_LIST_DEVICES_BY_ADMIN_BY_ID_ERR'

export const GET_LIST_DEVICES_BY_ADMIN_BY_STATUS = 'GET_LIST_DEVICES_BY_ADMIN_BY_STATUS'
export const GET_LIST_DEVICES_BY_ADMIN_BY_STATUS_ERR = 'GET_LIST_DEVICES_BY_ADMIN_BY_STATUS_ERR'

export const CREATE_DEVICE = "CREATE_DEVICE"
export const CREATE_DEVICE_ERR = "CREATE_DEVICE_ERR"

export const EDIT_DEVICE = "EDIT_DEVICE"
export const EDIT_DEVICE_ERR = "EDIT_DEVICE_ERR"

export const DEL_DEVICE = "DEL_DEVICE"
export const DEL_DEVICE_ERR = "DEL_DEVICE_ERR"

export const CONTROL_DEVICE = "CONTROL_DEVICE"
export const CONTROL_DEVICE_ERR = "CONTROL_DEVICE_ERR"

export const TOGGLE_LIST_VIEW_MODE = 'TOGGLE_LIST_VIEW_MODE'

export const TOGGLE_SHOW_POPUP_ADD = 'TOGGLE_SHOW_POPUP_ADD'

export const RESET_LIST_VIEW_MODE = 'RESET_LIST_VIEW_MODE'

export const RESET_LIST_DEVICES_BY_ADMIN = 'RESET_LIST_DEVICES_BY_ADMIN'

export const SET_TOTAL_DEVICE_DISCONNECTED = 'SET_TOTAL_DEVICE_DISCONNECTED'

export const SET_DATA_SCREEN_SHOT = "SET_DATA_SCREEN_SHOT"