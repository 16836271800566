import * as types from './types'

const intitialState = {
    list: [],
    condFilters: {},
    total: 0,
}
const listCustomerReducer = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_LIST_CUSTOMER:
        case types.GET_CUSTOMER_SEARCH_BIOMERTRIC_ID:
        case types.GET_CUSTOMER_SEARCH_PROFILE_ID:
        case types.GET_CUSTOMER_SEARCH_INTERVAL: {
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll 
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list,
                type: action.type
            }
        }
        case types.RESET_LIST_CUSTOMER: {
            return { ...intitialState, type: action.type }
        }

        default:
            return state

    }
}
export default listCustomerReducer