import React from 'react'

const IcDeviceDefault = (props) => {
    return (
        <svg {...props} width={141} height={140} viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="70.5" cy={70} r={70} fill="#B7B7B7" />
            <path d="M87.1665 36.667H53.8332C50.1513 36.667 47.1665 39.6518 47.1665 43.3337V96.667C47.1665 100.349 50.1513 103.334 53.8332 103.334H87.1665C90.8484 103.334 93.8332 100.349 93.8332 96.667V43.3337C93.8332 39.6518 90.8484 36.667 87.1665 36.667Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.5 90H70.5333" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcDeviceDefault
