import styled from 'styled-components'

// styles
import { WrapPageItem } from '../../TransactionsList/index.styles';

const WrapListAdvertisement = styled(WrapPageItem)`
    display: flex;
    flex-direction: column;
    height: 100%;
    .row {
        grid-template-columns: 1fr 90px 125px 125px 25px;
    }
    .wrap-empty {
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

export {
    WrapListAdvertisement
}