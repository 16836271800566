import React from 'react'
import IcInputUser from '../../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../../assets/images/IcInputUserError'
import Input from '../../../../../Control/Input'


const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

const InputName = (valueInput) => {
    return (
        <Input
            name="name"
            className="input-merchant"
            placeholder="Tên Merchant"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconUser}
        />
    )
}

export default InputName
