import React, { forwardRef } from 'react'

// styles
import { WrapPageHeader } from './index.styles'

//image
import IcArrowDown from '../../../../../assets/images/IcArrowDown'

const PageHeader = ({ children }, ref) => {
    return (
        <WrapPageHeader ref={ref}>
            {/* <IcArrowDown />  */}
            <p className="title">{children}</p>
        </WrapPageHeader>
    )
}

export default forwardRef(PageHeader)
