import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// constant
import { env, WEE_ENV, DEV_ENV } from '../constant/environmentTypes'
import { hostWebSocket } from '../constant/host';
import { DEVICE_CONNECTION_NOTIFY, LINK_DEVICE_RESPONSE, SCREENSHOT_DEVICE_RESPONSE } from '../constant/socketEvt';

// action
import { toggleShowPopupAdd, getListDevicesByAdmin, setTotalDisconnected, setDataScreenShot } from '../redux/device/listDevicesByAdmin/action';

// hook
import useCallOnlyOnce from './useCallOnlyOnce';

const CLOSE_SAFE = 4000

const useSocket = () => {
    const dispatch = useDispatch()
    const socket = useRef(null)
    const timer = useRef(null)
    const isStatusViewRef = useRef(false)

    const { token, isStatusView } = useSelector(state => ({
        token: state.loginReducer.dataLogin?.token,
        isStatusView: state.deviceReducer.listDevicesByAdmin.isStatusView
    }))

    useEffect(() => {
        isStatusViewRef.current = isStatusView
    }, [isStatusView]);

    const _connect = () => {
        socket.current = new WebSocket(`${hostWebSocket}?token=${token}`)

        socket.current.onopen = () => {
            // console.log('connected')
        }

        socket.current.onmessage = (e) => {
            // console.log('message', e)
            const { data } = e
            try {
                const objData = JSON.parse(data)
                const { command, content } = objData
                switch(command) {
                    case LINK_DEVICE_RESPONSE: {
                        const { isLinked } = content
                        if(!isLinked){
                            // console.log('connect device failed')
                            break;
                        }
                        dispatch(toggleShowPopupAdd(false))
                        if (isStatusViewRef.current) {
                            dispatch(getListDevicesByAdmin({
                                filterFrom: "",
                                filterSkip: 0,
                                filterSize: -1
                            }))
                        }
                        else {
                            dispatch(getListDevicesByAdmin({
                                filterFrom: "", 
                                filterSkip: 0, 
                                filterSize: 20 
                            }))
                        }
                        break;
                    }
                    case DEVICE_CONNECTION_NOTIFY: {
                        const { disconnectedCount } = content
                        dispatch(setTotalDisconnected(disconnectedCount || 0))
                        break;
                    }
                    case SCREENSHOT_DEVICE_RESPONSE: {
                        const { screenshotPhoto } = content
                        dispatch(setDataScreenShot({
                            isLoading: false,
                            image: screenshotPhoto,
                            imageName: ""
                        }))
                        break;
                    }
                    default:
                        // console.log('undefined websocket event:', command)
                        break;
                }
            }
            catch(err){
                // console.log('error websocket message:', err)
            }
        }

        socket.current.onclose = (e) => {
            // console.log('close', e)
            const { code } = e
            if(code === CLOSE_SAFE) return;
            timer.current = setTimeout(_connect, 2000)
        }

        socket.current.onerror = (e) => {
            socket.current.close()
        }
    }
    
    useCallOnlyOnce(() => {
        if(![WEE_ENV, DEV_ENV].includes(env)) return false;
        if(!token) return false;
        _connect()
        return true;
    }, [token]);
    
    useEffect(() => {
        return () => {
            clearTimeout(timer.current)
            socket.current?.close(CLOSE_SAFE)
        }
    }, []);

    return null
}

export default useSocket
