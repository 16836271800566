import React, { useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

// component
import FilterControl from '../../../../../../Control/FilterControl'
import DropdownControl from '../../../../../../Control/DropdownControl'
import Button from '../../../../../../Control/Button'

// styles
import { WrapToolStore } from './index.styles'
import { InputFilter } from '../../../../../../Control/FilterControl/index.styles'

//image
import IcCreateNew from '../../../../../../../assets/images/IcCreateNew';
import Portal from '../../../../../../Control/Portal'
import CreateNewStore from './CreateNewStore'
import { getListStoreMerchant, getListStoreSearchByCode, getListStoreSearchByName, resetListStoreMerchant } from '../../../../../../../redux/merchant/detailsMerchant/store/action'
import { GET_LIST_STORE_MERCHANT } from '../../../../../../../redux/merchant/detailsMerchant/store/types'


const KEY_STORE_CODE = "1"
const KEY_STORE_NAME = "2"

const listFilterStoreTypes = [
    {
        key: KEY_STORE_CODE,
        text: 'Mã cửa hàng'
    },
    {
        key: KEY_STORE_NAME,
        text: 'Tên cửa hàng'
    },
]

const filterSize = 20


const ToolStore = () => {
    const dispatch = useDispatch()
    const { merchantId } = useParams();
    const inputSearchRef = useRef(null)

    const [filterCond, setFilterCond] = useState(listFilterStoreTypes[0]);
    const [isShowModalCreateStore, setIsShowModalCreateStore] = useState(false)
    const [idSearch, setIdSearch] = useState("");

    const { total, list, type } = useSelector(state => ({
        total: state.merchantReducer.detailsMerchantReducer.store.total,
        list: state.merchantReducer.detailsMerchantReducer.store.list,
        type: state.merchantReducer.detailsMerchantReducer.store.type,
    }))

    useEffect(() => {
        setIdSearch("")
        if (inputSearchRef.current) inputSearchRef.current.value = ''
    }, [JSON.stringify(filterCond)]);

    useEffect(() => {
        const { key } = filterCond
        if(key !== KEY_STORE_CODE && type === GET_LIST_STORE_MERCHANT){
            setIdSearch("")
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key !== KEY_STORE_CODE && type === GET_LIST_STORE_MERCHANT && idSearch) {
            setIdSearch("")
            setFilterCond(listFilterStoreTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key === KEY_STORE_CODE && type === GET_LIST_STORE_MERCHANT) {
            setIdSearch("")
            setFilterCond(listFilterStoreTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }

    }, [JSON.stringify(list), JSON.stringify(filterCond), type]);
    
    const _handleSearch = () => {
        const { key } = filterCond
        if (!idSearch) {
            dispatch(resetListStoreMerchant())
            dispatch(getListStoreMerchant({
                merchantId,
                filterFrom: '',
                filterSkip: 0,
                filterSize,
            }))
        }
        switch (key) {
            case KEY_STORE_CODE: 
                if(!idSearch) break;
                dispatch(resetListStoreMerchant())
                dispatch(getListStoreSearchByCode({
                    merchantId,
                    code: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                break;
            case KEY_STORE_NAME:
                if(!idSearch) break;
                dispatch(resetListStoreMerchant())
                dispatch(getListStoreSearchByName({
                    merchantId,
                    name: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                break;
            default:
                break;
        }
    }

    const dropdownFilter = useMemo(() => {
        return (
            <DropdownControl 
                dataSelection={listFilterStoreTypes}
                valueSelected={filterCond} 
                handleSetValueSelect={val => setFilterCond(val)}
            />
        )
    }, [filterCond])

    const filterComponent = useMemo(() => {
        const { key, text } = filterCond
        return (
            <InputFilter 
                type="text"
                placeholder={`Nhập ${text}`}
                ref={inputSearchRef}
                onKeyPress={(e) => {
                    if(e.key === 'Enter') {
                        _handleSearch()
                    }
                }}
                onChange={e => setIdSearch(e.target.value)}
            />
        )
    }, [JSON.stringify(filterCond), _handleSearch])

    const _handleClickBtnCreate = () =>{
        setIsShowModalCreateStore(pre => !pre)
    }

    return (
        <WrapToolStore>
            <div className="wrap-filter">
                <FilterControl 
                    dropdownFilter={dropdownFilter}
                    filterComponent={filterComponent}
                    handleSearch={_handleSearch}
                />
            </div>
            <div className="total-of-data">
                <span>Cửa hàng:</span>
                <span>{total}</span>
            </div>
            <div>
                <Button 
                    iconBtn={<IcCreateNew />}
                    text="Thêm mới"
                    onClick={_handleClickBtnCreate}
                />
            </div>
            {
                isShowModalCreateStore &&
                <Portal>
                    <CreateNewStore 
                        onClickIconClose={_handleClickBtnCreate} 
                        setIsShowModalCreateStore={setIsShowModalCreateStore}
                    />
                </Portal>
            }
        </WrapToolStore>
    )
}

export default ToolStore
