import React from 'react'

const IcMoreOption = ({...props}) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z" fill="#B7B7B7" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99967 8.66634C8.36786 8.66634 8.66634 8.36786 8.66634 7.99967C8.66634 7.63148 8.36786 7.33301 7.99967 7.33301C7.63148 7.33301 7.33301 7.63148 7.33301 7.99967C7.33301 8.36786 7.63148 8.66634 7.99967 8.66634Z" fill="#B7B7B7" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.99967 4.00033C8.36786 4.00033 8.66634 3.70185 8.66634 3.33366C8.66634 2.96547 8.36786 2.66699 7.99967 2.66699C7.63148 2.66699 7.33301 2.96547 7.33301 3.33366C7.33301 3.70185 7.63148 4.00033 7.99967 4.00033Z" fill="#B7B7B7" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcMoreOption
