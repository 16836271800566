import styled from 'styled-components'

const WrapPopupDeviceInfos = styled.div`
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    border-radius: 4px;
    background: #FFFFFF;
    width: 300px;
    box-sizing: border-box;

    &:hover {
        cursor: initial;
    }


    & > * {
        padding: 0 16px;
    }

    .wrap-row {
        min-height: 94px;
        display: flex;
        align-items: center;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        .image {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            flex-shrink: 0;
            border: 1px solid ${props => props.colorByStatus || "transparent"};
            img {
                height: 100%;
            }
        }

        .wrap-column {
            min-width: 0;
            margin-left: 13px;
            .device-name {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }

            .device-id {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #777777;
            }
        }
    }

    .wrap-field {
        min-height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        &:not(.last-field) {
            box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        }

        .wrap-outer-value {
            flex-grow: 1;
            min-width: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .field-name {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #777777;
            white-space: nowrap;
        }
        
        .field-value {
            margin-left: 15px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.status {
                color: #F45C5C;
            }
        }

        .map {
            margin-left: 8px;
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            cursor: pointer
        }
    }
`;

export {
    WrapPopupDeviceInfos
}