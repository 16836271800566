export const GET_LIST_CUSTOMER = "GET_LIST_CUSTOMER"
export const GET_CUSTOMER_SEARCH_BIOMERTRIC_ID = "GET_CUSTOMER_SEARCH_BIOMERTRIC_ID"
export const GET_CUSTOMER_SEARCH_PROFILE_ID = "GET_CUSTOMER_SEARCH_PROFILE_ID"
export const GET_CUSTOMER_SEARCH_INTERVAL = "GET_CUSTOMER_SEARCH_INTERVAL"

export const GET_LIST_CUSTOMER_ERR = "GET_LIST_CUSTOMER_ERR"
export const GET_CUSTOMER_SEARCH_BIOMERTRIC_ID_ERR = "GET_CUSTOMER_SEARCH_BIOMERTRIC_ID_ERR"
export const GET_CUSTOMER_SEARCH_PROFILE_ID_ERR = "GET_CUSTOMER_SEARCH_PROFILE_ID_ERR"
export const GET_CUSTOMER_SEARCH_INTERVAL_ERR = "GET_CUSTOMER_SEARCH_INTERVAL_ERR"

export const RESET_LIST_CUSTOMER = "RESET_LIST_CUSTOMER" 