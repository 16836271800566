export const GET_LIST_BANK = 'GET_LIST_BANK'
export const GET_LIST_BANK_ERR = 'GET_LIST_BANK_ERR'

export const RESET_LIST_BANK = 'RESET_LIST_BANK'

export const CREATE_NEW_BANK = 'CREATE_NEW_BANK'
export const CREATE_NEW_BANK_ERR = 'CREATE_NEW_BANK_ERR'

export const UPDATE_BANK = 'UPDATE_BANK'
export const UPDATE_BANK_ERR = 'UPDATE_BANK_ERR'

export const DELETE_BANK = 'DELETE_BANK'
export const DELETE_BANK_ERR = 'DELETE_BANK_ERR'


