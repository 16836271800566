import React from 'react'

const IcLockWhite = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6667 7.33301H3.33333C2.59695 7.33301 2 7.92996 2 8.66634V13.333C2 14.0694 2.59695 14.6663 3.33333 14.6663H12.6667C13.403 14.6663 14 14.0694 14 13.333V8.66634C14 7.92996 13.403 7.33301 12.6667 7.33301Z" fill="#FFFFFF" />
            <path d="M4.66699 7.33301V4.66634C4.66699 3.78229 5.01818 2.93444 5.6433 2.30932C6.26842 1.6842 7.11627 1.33301 8.00033 1.33301C8.88438 1.33301 9.73223 1.6842 10.3573 2.30932C10.9825 2.93444 11.3337 3.78229 11.3337 4.66634V7.33301" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcLockWhite
