import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

// component
import Button from '../../../../../../../../Control/Button'
import IcDeleteWhite from '../../../../../../../../../assets/images/IcDeleteWhite'

// styles
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupDelAccountPermission } from './index.styles'

// action
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'
import { getListPermission, deleteAccountPermission } from '../../../../../../../../../redux/permission/listPermission/action'

const PopupDelAccountPermission = ({ data: { id, email }, setActiveKey }) => {

    const dispatch = useDispatch()
    const refDelReport = useRef(null)

    const callbackSuccess = () => {
        dispatch(getListPermission({
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        setActiveKey('')
    }

    const _handleDel = () => {
        dispatch(deleteAccountPermission(
            { id },
            callbackSuccess
        ))
    }

    useOnClickOutside(refDelReport, () => setActiveKey(''))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupDelAccountPermission ref={refDelReport}>
                    <div className="title">Remove Email Address</div>
                    <div className="sub-title">
                        Please confirm to remove email address <span title={email}>“{email.length > 20 ? `${email.substring(0, 20)}...` : email}”</span>
                    </div>
                    <div className="notice">
                        *Remove emails cannot be recovered
                    </div>
                    <Button
                        text="Remove"
                        iconBtn={<IcDeleteWhite />}
                        className="btn-del"
                        onClick={_handleDel}
                    />
                    <Button
                        text="Cancel"
                        className="btn-cancel"
                        onClick={() => setActiveKey('')}
                    />
                </WrapPopupDelAccountPermission>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupDelAccountPermission
