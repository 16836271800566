export const GET_LIST_TRANSACTION = "GET_LIST_TRANSACTION"
export const GET_TRANSACTION_SEARCH_MERCHANTTX_ID = "GET_TRANSACTION_SEARCH_MERCHANTTX_ID"
export const GET_TRANSACTION_SEARCH_BANKTX_ID = "GET_TRANSACTION_SEARCH_BANKTX_ID"
export const GET_TRANSACTION_SEARCH_FACEPAYTX_ID = "GET_TRANSACTION_SEARCH_FACEPAYTX_ID"
export const GET_TRANSACTION_SEARCH_INTERVAL = "GET_TRANSACTION_SEARCH_INTERVAL"

export const GET_LIST_TRANSACTION_ERR = "GET_LIST_TRANSACTION_ERR"
export const GET_TRANSACTION_SEARCH_MERCHANTTX_ID_ERR = "GET_TRANSACTION_SEARCH_MERCHANTTX_ID_ERR"
export const GET_TRANSACTION_SEARCH_BANKTX_ID_ERR = "GET_TRANSACTION_SEARCH_BANKTX_ID_ERR"
export const GET_TRANSACTION_SEARCH_FACEPAYTX_ID_ERR = "GET_TRANSACTION_SEARCH_FACEPAYTX_ID_ERR"
export const GET_TRANSACTION_SEARCH_INTERVAL_ERR = "GET_TRANSACTION_SEARCH_INTERVAL_ERR"

export const GET_COUNT_EXPORT_TRANSACTION = "GET_COUNT_EXPORT_TRANSACTION"
export const GET_COUNT_EXPORT_TRANSACTION_ERR = "GET_COUNT_EXPORT_TRANSACTION_ERR"

export const EXPORT_TRANSACTION = "EXPORT_TRANSACTION"
export const EXPORT_TRANSACTION_ERR = "EXPORT_TRANSACTION_ERR"

export const RESET_LIST_TRANSACTION = "RESET_LIST_TRANSACTION" 