import React from 'react'

import IcEmail from '../../../../../../assets/images/IcEmail'
import IcEmailActive from '../../../../../../assets/images/IcEmailActive'
import Input from '../../../../../Control/Input'

const iconEmail = {
    icon: IcEmail,
    iconActive: IcEmailActive,
    iconError: IcEmailActive,
}

const InputEmail = (valueInput) => {
    return (
        <Input
            name="email"
            className="input-merchant"
            placeholder="Email Merchant"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconEmail}
        />
    )
}

export default InputEmail
