import styled from 'styled-components'

const WrapImage = styled.div`
    width: 252px;
    height: 352px;
    box-sizing: border-box;
    border-radius: 4px;
`

const WrapDropImage = styled(WrapImage)`
    position: relative;
    .wrap-face {
        width: 100%;
        height: 100%;
        /* border: 2px solid #33ADFF;
        box-sizing: border-box;
        border-radius: 4px; */
        background: #313131;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 4px;
            display: block;
        }
    }

    .remove-face {
        position: absolute;
        right: 7px;
        top: 7px;

        &:hover {
            cursor: pointer;
        }
    }
`

const DragAndDropArea = styled(WrapImage)`
    border: 1px dashed #B7B7B7;
    background: #F7F7F7;
    padding-bottom: 8px;
    text-align: center;

    .ic-upload {
        width:  52px;
        height: 52px;
        margin-top: 79px;
        padding: 28px;
        border-radius: 50%;
        background: #b7b7b71a;
    }
    p {
        margin-top: 58px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #777777;
    }
    .desc{
        margin-top: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        &.first{
            margin-top: 18px;
        }
        &.last{
            
        }
    }

    &:hover {
        cursor: pointer;
        background: #EFF9FF;
        border: 1px dashed #33ADFF;
    }
`;

export {
    WrapDropImage,
    DragAndDropArea
}