import styled from 'styled-components'

const WrapDeviceItemByStatus = styled.div`
    width: 48px;
    height: 48px;
    background-color: ${props => props.color};
    /* cursor: pointer; */
    position: relative;

    &:hover {
        .wrap-device-infos {
            display: block;
        }
    }

    .wrap-device-infos {
        position: absolute;
        top: ${props => props.posPopup.top};
        left: ${props => props.posPopup.left};
        right: ${props => props.posPopup.right};
        bottom: ${props => props.posPopup.bottom};
        z-index: 1;
        display: none;
    }
`;

export {
    WrapDeviceItemByStatus
}