import React, { useEffect, useReducer, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { sha256 } from 'js-sha256';
import { useParams } from 'react-router-dom'

// styles
import { WrapCreateNewAccountBank } from './index.styles'
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

//component
import IcClose from '../../../../../../../../assets/images/IcClose'
import Button from '../../../../../../../Control/Button'
import IcCreateNew from '../../../../../../../../assets/images/IcCreateNew'
import InputFullName from './InputFullName'
import InputEmail from './InputEmail'
import InputPassword from '../../../../../../../Control/InputPassword';

//help
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'
import { checkEmailSuccess } from '../../../../../../../../helper/checkEmailSuccess';

//action
import { createAccountBank, getListAccountBank } from '../../../../../../../../redux/bank/detailtsBank/account/action'


const titleErrorEmail = "Email không đúng định dạng"

const CreateNewAccountBank = ({ onClickIconClose, setIsShowModalAddAccountBank }) => {

    const dispatch = useDispatch()
    const refNewAccountBank = useRef(null)
    const { bankId } = useParams();

    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);
    const [isErrorEmail, setIsErrorEmail] = useState(false)

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fullname: "",
            username: "",
        }
    )

    const _handleChange = (stateKey) => (e) => {
        setStateForm({ [stateKey]: e.target.value })       
    }

    const handleValidationEmail = (value, callback) => {
        if(value){
            const error = checkEmailSuccess(value)
            if (error === false) {
                callback(titleErrorEmail)
                setIsErrorEmail(true)
                return
            }
            setIsErrorEmail(false)
        }
    }

    const callbackSuccess = () => {
        onClickIconClose && onClickIconClose()
        dispatch(getListAccountBank({
            bankId,
            filterFrom: "",
            filterSkip: 0,
            filterSize: 20,
        }))
    }

    const _handleSubmit = () => {
        if (isErrorEmail === false) {
            dispatch(createAccountBank(
                {
                    bankId,
                    ...stateForm,
                },
                callbackSuccess
            ))
        }
    }

    useEffect(() => {
        const { fullname, username } = stateForm
        const isEnable = fullname && username
        setIsDisabledBtnCreate(!isEnable)
        return () => {
        }
    }, [stateForm])

    useOnClickOutside(refNewAccountBank, () => setIsShowModalAddAccountBank(false))

    return (
        <OverlayFullScreen >
            <div className="wrap-inner-screen" >
                <WrapCreateNewAccountBank ref={refNewAccountBank}>
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <p className="title">Tạo mới tài khoản</p>
                    <p className="description">Bổ sung thông tin tài khoản đăng nhập</p>

                    <InputFullName
                        valueInput={stateForm.fullname}
                        onChange={_handleChange("fullname")}
                        placeholder="Họ tên"
                        className="full-name"
                    />
                    <InputEmail
                        valueInput={stateForm.username}
                        onChange={_handleChange("username")}
                        placeholder="Email"
                        className="username"
                        handleValidationUser={handleValidationEmail}
                    />
                    {/* <InputPassword
                        type="password"
                        value={stateForm.pwd}
                        placeholder="Password"
                        inputType="PWD"
                        // autoComplete="off"
                        onChange={_handleChange("pwd")}
                    /> */}
                    <Button
                        text="Tạo mới"
                        iconBtn={<IcCreateNew />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapCreateNewAccountBank>
            </div>
        </OverlayFullScreen>
    )
}

export default CreateNewAccountBank
