import styled from 'styled-components'

import { WrapTools } from '../ToolStore/index.styles';

const WrapToolAdvertisement = styled(WrapTools)`
    
`;

export {
    WrapTools,
    WrapToolAdvertisement
}