import styled from 'styled-components'

// styles
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapBankList = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    .wrap-empty {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .wrap-list {
        position: relative;
        flex-grow: 1;
        overflow: auto;

        .wrap-inner-list {
            position: absolute;
            width: 100%;

            & > *:not(:first-child) {
                margin-top: 8px;
            }
        }
    }
`;

export {
    WrapBankList
}