// lỗi TPB bank trả về
export const dataErrorTPB = {
    ["2011"]: {text: "Quá thời hạn giao dịch, liên hệ cashier để thử lại"},
    ["2012"]: {text: "Số dư không khả dụng."},
    ["2013"]: {text: "Dịch vụ Facepay ko khả dụng, cần bật tính năng Facepay trong ứng dụng TPbank để sử dụng"},
    ["2014"]: {text: "Dịch vụ Facepay ko khả dụng, cần bật tính năng Facepay trong ứng dụng TPbank để sử dụng"},
    ["2016"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1101"]: {text: "Tài khoản TPBank không hợp lệ. Vui lòng liên hệ  tổng đài TPBank."},
    ["1103"]: {text: "Tài khoản TPBank không hợp lệ. Vui lòng liên hệ  tổng đài TPBank."},
    ["1104"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1106"]: {text: "Sđt không đúng, KH cần thử lại giao dịch"},
    ["1107"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1108"]: {text: "Chưa kích hoạt dịch vụ Facepay"},
    ["1109"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["10"]: {text: "Khuôn mặt chưa được đăng ký dịch vụ Facepay."},
    ["-99"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},
    ["40"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["21"]: {text: "Không tìm được khuôn mặt trùng khớp trong hệ thống"},
    ["5010"]: {text: "Khuôn mặt chưa được đăng ký"},
    ["5021"]: {text: "Không tìm thấy khuôn mặt trùng khớp với khuôn mặt trong hệ thống"},
    ["5040"]: {text: "Lỗi hệ thống Facepay: Yêu cầu không hợp lệ"},
    ["5043"]: {text: "Lỗi hệ thống Facepay: không gửi được yêu cầu"},
    ["5046"]: {text: "Lỗi hệ thống Facepay: yêu cầu không được chấp nhận."},
    ["5053"]: {text: "Dịch vụ Facepay hiện không khả dụng."},
    ["5008"]: {text: "Lỗi hệ thống Facepay: Yêu cầu không hợp lệ"},
    ["5010"]: {text: "Lỗi hệ thống Facepay: Yêu cầu không hợp lệ"},
    ["5011"]: {text: "Lỗi hệ thống Facepay: Yêu cầu không hợp lệ"},
    ["06"]: {text: "Tài khoản bị khóa từ hệ thống CSKH Facepay"},
    ["default"]: {text: "Giao dịch không thành công"},


}