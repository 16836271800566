import React, { useEffect, useReducer, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapCreateNewMerchant } from './index.styles'
import { OverlayFullScreen } from '../../Page/TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

//help
import fileToBase64 from '../../../../../helper/fileToBase64'

//component
import Button from '../../../../Control/Button'
import IcCreateNew from '../../../../../assets/images/IcCreateNew'
import IcClose from '../../../../../assets/images/IcClose'
import DragAndDropImage from '../../../../Control/DragAndDropImage'
import InputName from './InputName'
import InputLocation from './InputLocation'
import InputEmail from './InputEmail'
import InputPhone from './InputPhone'
import InputDropdownStore from './InputDropdownStore'
import { useOnClickOutside } from '../../../../../hook/useClickOutside'

//action
import { createNewMerchant, getListMerchant } from '../../../../../redux/merchant/listMerchant/action'


const CreateNewMerchant = ({ onClickIconClose, setIsShowModalAddMerChant }) => {

    const dispatch = useDispatch()
    const refNewMerchant = useRef(null)

    const {condFilters} = useSelector(state => ({
        condFilters: state.merchantReducer.listMerchantReducer.condFilters
    }))
    
    const [merchantImg, setMerchantImg] = useState(null);
    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            name: "",
            category: "",
            address: "",
            email: "",
            phoneNumber: "",
        }
    )

    useEffect(() => {
        const { name, category, address, email, phoneNumber } = stateForm
        const isEnable = name && category && address && email && phoneNumber && !!merchantImg
        setIsDisabledBtnCreate(!isEnable)
        return () => {
        }
    }, [stateForm, merchantImg])

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const _handleSetValueDropdown = (val) =>{
        setStateForm({
            category: val.key,
        })
    }

    const callbackSuccess = () =>{
        onClickIconClose && onClickIconClose()
        dispatch(getListMerchant({ ...condFilters }))
    }

    const _handleSubmit = async  () =>{
        const base64 = await fileToBase64(merchantImg)
        const { name, category, address, email, phoneNumber} = stateForm
        const dataRequest = { 
            name,
            category,
            address,
            email,
            phoneNumber,
            logo: base64.split(',')[1]
        }
        
        dispatch(createNewMerchant(dataRequest, callbackSuccess))

        // setStateForm({
        //     name: "",
        //     category: "",
        //     address: "",
        //     email: "",
        //     phoneNumber: "",
        // })
        // setMerchantImg(null)
    }

    useOnClickOutside(refNewMerchant, () => setIsShowModalAddMerChant(false))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapCreateNewMerchant ref={refNewMerchant}>
                    <IcClose className="icon-close" onClick={onClickIconClose}/>
                    <p className="title">Tạo mới Merchant</p>
                    <p className="description">Bổ sung thông tin tạo Merchant</p>
                    <div className="image">
                        <DragAndDropImage 
                            file={merchantImg}
                            handleRemoveFile={() => setMerchantImg(null)}
                            handleDropFiles={files => setMerchantImg(files[0])}
                        />
                    </div>
                    <InputName 
                        valueInput={stateForm.name}
                        onChange={handleChangeValue}
                    />
                    <InputDropdownStore 
                        valueDropdown={_handleSetValueDropdown}
                    />
                    <InputLocation 
                        valueInput={stateForm.address}
                        onChange={handleChangeValue}
                    />
                    <InputEmail 
                        valueInput={stateForm.email}
                        onChange={handleChangeValue}
                    />
                    <InputPhone 
                        valueInput={stateForm.phoneNumber}
                        onChange={handleChangeValue}
                    />
                    <Button
                        text="Tạo mới"
                        iconBtn={<IcCreateNew />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapCreateNewMerchant>
            </div>
        </OverlayFullScreen>
    )
}

export default CreateNewMerchant
