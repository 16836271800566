import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

//component
import IcClose from '../../../../../../../../assets/images/IcClose'
import IcCreateNew from '../../../../../../../../assets/images/IcCreateNew'
import InputAddress from './InputAddress'
import InputCode from './InputCode'
import InputName from './InputName'
import Button from '../../../../../../../Control/Button'
import InputLng from './InputLng'
import InputLat from './InputLat'

//hook
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'

// styles
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapCreateNewStore } from './index.styles'

//action
import { createStoreMerchant, getListStoreMerchant } from '../../../../../../../../redux/merchant/detailsMerchant/store/action'

const getValueNumber = (str) => Number(str)

const CreateNewStore = ({ setIsShowModalCreateStore, onClickIconClose }) => {
    const { merchantId } = useParams()
    const dispatch = useDispatch() 
    const refNewStore = useRef(null)

    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            merchantId: merchantId,
            code: "",
            name: "",
            address: "",
            lat: "",
            lng: "",
        }
    )

    const {condFilters} = useSelector(state => ({
        condFilters: state.merchantReducer.detailsMerchantReducer.store.condFilters
    }))

    useEffect(() => {
        const { name, code, address, lng, lat } = stateForm
        const isEnable = name && code && address && lng && lat
        setIsDisabledBtnCreate(!isEnable)
        return () => {
        }
    }, [stateForm])

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const handleChangeValueNumber = (e) =>{
        const { name, value } = e.target;
        const number = getValueNumber(value)
        if(isNaN(number)){
            return;
        }
        if(!number) {
            setStateForm({
                [name]: ""
            })
            return;
        }
        setStateForm({
            [name]: number
        })
    }

    const callbackSuccess = () =>{
        onClickIconClose && onClickIconClose()
        dispatch(getListStoreMerchant({ ...condFilters }))
    }

    const _handleSubmit = () =>{
        dispatch(createStoreMerchant(stateForm, callbackSuccess))
    }


    useOnClickOutside(refNewStore, () => setIsShowModalCreateStore(false))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapCreateNewStore ref={refNewStore}>
                    <IcClose className="icon-close" onClick={onClickIconClose}/>
                    <p className="title">Tạo mới Cửa hàng</p>
                    <p className="description">Cập nhật thông tin cửa hàng mới</p>
                    <InputCode 
                        valueInput={stateForm.code}
                        onChange={handleChangeValue}
                    />
                    <InputName
                        valueInput={stateForm.name}
                        onChange={handleChangeValue}
                    />
                    <InputAddress
                        valueInput={stateForm.address}
                        onChange={handleChangeValue}
                    />
                    <InputLat
                        valueInput={stateForm.lat}
                        onChange={handleChangeValueNumber}
                    />
                    <InputLng
                        valueInput={stateForm.lng}
                        onChange={handleChangeValueNumber}
                    />
                    <Button
                        text="Tạo mới"
                        iconBtn={<IcCreateNew />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapCreateNewStore>
            </div>
        </OverlayFullScreen>
    )
}

export default CreateNewStore
