import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { sha256 } from 'js-sha256'

// styles
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupCreateAccount } from './index.styles'

// component
import InputUser from './InputUser'
import InputPassword from '../../../../../../../Control/InputPassword'
import Button from '../../../../../../../Control/Button'
import IcCreateNew from '../../../../../../../../assets/images/IcCreateNew'
import IcClose from '../../../../../../../../assets/images/IcClose'

// action
import { createAccountMerchant, getListAccountMerchant } from '../../../../../../../../redux/merchant/detailsMerchant/account/action'
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'
import { checkEmailSuccess } from '../../../../../../../../helper/checkEmailSuccess'

const titleErrorEmail = "Email không đúng định dạng"

const PopupCreateAccount = ({ handleClose, setIsShowPopupAdd }) => {

    const { merchantId } = useParams()
    const refNewAccountBank = useRef(null)
    const dispatch = useDispatch()

    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);
    const [isErrorEmail, setIsErrorEmail] = useState(false)

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fullname: '',
            username: '',
        }
    );

    const callbackCreateAccountSuccess = () => {
        dispatch(getListAccountMerchant({
            merchantId,
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        handleClose()
    }

    const _handleSubmit = () => {
        if (isErrorEmail === false) {
            dispatch(createAccountMerchant(
                {
                    merchantId,
                    ...stateForm,
                },
                callbackCreateAccountSuccess
            ))
        }
    }

    const _handleChange = (stateKey) => (e) => {
        setStateForm({ [stateKey]: e.target.value })
    }

    const handleValidationEmail = (value, callback) => {
        if(value){
            const error = checkEmailSuccess(value)
            if (error === false) {
                callback(titleErrorEmail)
                setIsErrorEmail(true)
                return
            }
            setIsErrorEmail(false)
        }
    }

    useEffect(() => {
        if (Object.values(stateForm).some(item => !item)) {
            setIsDisabledBtnCreate(true)
            return;
        }
        setIsDisabledBtnCreate(false)
    }, [stateForm]);

    useOnClickOutside(refNewAccountBank, () => setIsShowPopupAdd(false))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupCreateAccount ref={refNewAccountBank}>
                    <IcClose className="ic-close" onClick={handleClose} />
                    <div className="title">Tạo mới tài khoản</div>
                    <div className="sub-title">Bổ sung thông tin tài khoản đăng nhập</div>
                    <InputUser
                        valueInput={stateForm.fullname}
                        onChange={_handleChange("fullname")}
                        placeholder="Họ tên"
                        className="full-name"
                    />
                    <InputUser
                        valueInput={stateForm.username}
                        onChange={_handleChange("username")}
                        placeholder="Email"
                        className="username"
                        handleValidationUser={handleValidationEmail}
                    />

                    <Button
                        text="Tạo mới"
                        iconBtn={<IcCreateNew />}
                        className="btn-submit"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapPopupCreateAccount>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupCreateAccount
