import React from 'react'

//component
import MoreOption from '../../../../../../Control/MoreOption'

//styles
import { WrapAdvertisementItem } from './index.styles'

//help
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import OptionAdvertisement from './OptionAdvertisement'

const AdvertisementItem = ({ dataItem, refList, ...props }) => {
    return (
        <WrapAdvertisementItem {...props}>
            <div className="bold" title={dataItem?.name ? `${dataItem?.name}` : ""}>{dataItem?.name || "-"}</div>
            <div className="normal" title={dataItem?.format ? `${dataItem?.format}` : ""}>{dataItem?.format || "-"}</div>
            <div className="normal" title={dataItem?.createAt ? convertDateTime(dataItem.createAt, "DD/MM/YYYY") : ""}>
                {dataItem?.createAt ? convertDateTime(dataItem.createAt, "DD/MM/YYYY") : "-"}
            </div>
            <div className="normal" title={dataItem?.lastUpdateAt ? convertDateTime(dataItem.lastUpdateAt, "DD/MM/YYYY") : ""}>
                {dataItem?.lastUpdateAt ? convertDateTime(dataItem.lastUpdateAt, "DD/MM/YYYY") : "-"}
            </div>
            <div>
                <OptionAdvertisement
                    dataAdvertisement={dataItem}
                    refList={refList}
                />
            </div>
        </WrapAdvertisementItem>
    )
}

export default AdvertisementItem
