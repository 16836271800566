import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// component
import IcAlertCircle from '../../../../../../../../assets/images/IcAlertCircle'
import IcArrowDownGray from '../../../../../../../../assets/images/IcArrowDownGray'
import DropdownList from '../../../../../../../Control/DropdownList'

// hook
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'

// styles
import { SelectedValue, WrapSelectReason } from './index.styles'

// action
import { getReasonRefund } from '../../../../../../../../redux/transaction/transactionDetail/action'


export let KEY_OTHER = 0
// const dataSelect = [
//     {
//         key: 1,
//         text: 'Lỗi hệ thống vận hành'
//     },
//     {
//         key: 2,
//         text: 'Lỗi trừ tiền quá hạn mức'
//     },
//     {
//         key: 3,
//         text: 'Giao dịch bị hệ thống trừ quá số tiền'
//     },
//     {
//         key: KEY_OTHER,
//         text: 'Lỗi khác'
//     },
// ]

const SelectReason = ({
    supplier,
    selected,
    setSelected
}) => {
    const dispatch = useDispatch()
    const selectRef = useRef(null)
    const { refundReasons } = useSelector(state => ({
        refundReasons: state.transactionReducer.transactionDetailReducer.refundReasons.map(({ code, description }) => ({
            key: code,
            text: description
        }))
    }))

    const [showDropdown, setShowDropdown] = useState(false);


    useEffect(() => {
        dispatch(getReasonRefund(supplier))
    }, []);

    const _handleSelect = (selected) => {
        setShowDropdown(false)
        setSelected(selected)
    }

    useOnClickOutside(selectRef, () => {
        setShowDropdown(false)
    })

    const isSelected = !!selected

    return (
        <WrapSelectReason ref={selectRef}>
            <div className="wrap-select" onClick={() => setShowDropdown(pre => !pre)}>
                <IcAlertCircle className="icon" />
                <SelectedValue isSelected={isSelected} title={selected?.text}>
                    {
                        isSelected
                        ? selected.text
                        : "Chọn lý do hoàn tiền"
                    }
                </SelectedValue>
                <IcArrowDownGray className="icon" />
            </div>
            {
                showDropdown &&
                <DropdownList 
                    isShowCheckStatus={true}
                    className="dropdown"
                    listItem={refundReasons}
                    alignTop="2px"
                    maxItemShow={4}
                    valueSelected={selected}
                    setValueSelect={_handleSelect}
                />
            }
        </WrapSelectReason>
    )
}

export default SelectReason
