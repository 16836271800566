import React from 'react'


import IcOptionExport from '../../../../../../assets/images/IcOptionExport'
import InputDropdown from '../../../../../Control/InputDropdown'


const iconStore = {
    icon: IcOptionExport,
    iconActive: IcOptionExport,
    iconError: IcOptionExport,
}

export const TODAY = "TODAY"
export const THIS_MONTH = "THIS_MONTH"
export const CUSTOM = "CUSTOM"

export const objOption = [
    {
        key: TODAY,
        text: "Hôm nay"
    },
    {
        key: THIS_MONTH,
        text: "Tháng này"
    },
    {
        key: CUSTOM,
        text: "Tuỳ chỉnh"
    }
]

const InputOptionExport = ({ valueDropdown, defaultValue }) => {

    const _handleSetValueDropdown = (val) =>{
        valueDropdown && valueDropdown(val)
    }

    return (
        <InputDropdown 
            className="input-dropdown"
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder="Option"
            dataFilterOption={objOption}
            objCategory={defaultValue}
        />
    )
}

export default InputOptionExport
