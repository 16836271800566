import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sha256 } from 'js-sha256'

// styles
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupEditAccount } from './index.styles'

// component
import IcEditWhite from '../../../../../../../../../assets/images/IcEditWhite'
import InputUserBank from './InputUserBank'
import InputPassword from '../../../../../../../../Control/InputPassword'
import Button from '../../../../../../../../Control/Button'
import IcClose from '../../../../../../../../../assets/images/IcClose'

// action
import { getListAccountBank, updateAccountBank } from '../../../../../../../../../redux/bank/detailtsBank/account/action'
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'
import InputEmail from '../../../../TabListAndTool/ToolAccount/CreateNewAccountBank/InputEmail'
import InputFullName from '../../../../TabListAndTool/ToolAccount/CreateNewAccountBank/InputFullName'
import { checkEmailSuccess } from '../../../../../../../../../helper/checkEmailSuccess'

const titleErrorEmail = "Email không đúng định dạng"

const PopupEditAccount = ({ data, setActiveKey }) => {
    const { id, username, fullname } = data

    const dispatch = useDispatch()
    const refEditAccountBank = useRef(null)
    const { bankId } = useParams()

    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);    

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fullname,
            username,
        }
    );    

    const callbackUpdateAccountSuccess = () => {
        dispatch(getListAccountBank({
            bankId,
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        setActiveKey('')
    }

    const _handleSubmit = () => {
        const { fullname } = stateForm
        dispatch(updateAccountBank(
            {
                id,
                fullname,
            },
            callbackUpdateAccountSuccess
        ))
    }

    const _handleChange = (stateKey) => (e) => {
        setStateForm({ [stateKey]: e.target.value })
    }

    useEffect(() => {
        if (Object.values(stateForm).some(item => !item)) {
            setIsDisabledBtnCreate(true)
            return;
        }
        setIsDisabledBtnCreate(false)
    }, [stateForm]);

    useOnClickOutside(refEditAccountBank, () => setActiveKey(''))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupEditAccount ref={refEditAccountBank}>
                    <IcClose className="icon-close" onClick={() => setActiveKey('')} />
                    <div className="title">Thông tin tài khoản</div>
                    <div className="description">Thông tin chi tiết tài khoản</div>
                    <InputFullName
                        valueInput={stateForm.fullname}
                        onChange={_handleChange("fullname")}
                        placeholder="Họ tên"
                        className="full-name"
                    />
                    <InputEmail
                        valueInput={stateForm.username}
                        onChange={_handleChange("username")}
                        placeholder="Email"
                        className="username"
                        disabled="disabled"
                    />
                    <Button
                        text="Chỉnh sửa"
                        iconBtn={<IcEditWhite />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapPopupEditAccount>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupEditAccount
