import React from 'react'

// component
import IcArrowDownGray from '../../../../../assets/images/IcArrowDownGray'

// styles
import { WrapBreadcrumb } from './index.styles'

const Breadcrumb = ({ arrPath }) => {
    return (
        <WrapBreadcrumb>
            {
                arrPath.map((pathItem, index) => (
                    <React.Fragment key={pathItem.value}>
                        <p onClick={pathItem.onClick} style={pathItem.onClick?{cursor: 'pointer'}:{}}>{pathItem.value}</p>
                        {
                            arrPath.length - 1 !== index &&
                            <IcArrowDownGray className="icon-text" />
                        }
                    </React.Fragment>
                ))
            }
        </WrapBreadcrumb>
    )
}

export default Breadcrumb
