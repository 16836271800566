import styled from 'styled-components'

import { WrapCreateNewBank } from '../../../../../BreadcrumbAndTool/CreateNewBank/index.styles';

const WrapPopupEditBank = styled(WrapCreateNewBank)`
  
`;

export {
    WrapPopupEditBank
}