import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//styles
import { WrapTransactionList, TableTransactionList, TableHeaderTransaction, TableContentTransaction } from './index.styles'

//data
import { headerTransListData } from '../../../../../data/headerTransListData'
import { listTransactions } from '../../../../../mockData/listTransactions'

//constant
import * as keys from '../../../../../constant/keys'

// component
import PageHeader from '../PageHeader'
import EmptyDataControl from '../../../../Control/EmptyDataControl'
import IcTransactionDefault from '../../../../../assets/images/IcTransactionDefault'
import ItemTransaction from './ItemTransaction'

//action
import {
    getListTransaction,
    resetListTransaction,
    getTransSearchBankId,
    getTransSearchFacepayId,
    getTransSearchInterval,
    getTransSearchMerchantId,
} from '../../../../../redux/transaction/listTransaction/action'

// hooks
import useScrollEnd from '../../../../../hook/useScrollEnd'

// helper
import numberFormatToThousand from '../../../../../helper/numberFormatToThousand'
import { useTargetActiveElement } from '../../../../../hook/useTargetActiveElement'

const filterSize = 20

const TransactionsList = () => {

    const dispatch = useDispatch()
    const listRef = useRef()
    const elementActiveRef = useRef()

    const [data, setData] = useState([])

    const { listTransactions, total, condFilters , isSearching, detailId} = useSelector(state => ({
        listTransactions: state.transactionReducer.listTransactionReducer,
        total: state.transactionReducer.listTransactionReducer.total,
        condFilters: state.transactionReducer.listTransactionReducer.condFilters,
        isSearching: state.transactionReducer.listTransactionReducer.isSearching,
        detailId: state.transactionReducer.transactionDetailReducer.detailId
    }))

    useEffect(() => {
        switch (condFilters?.keyFilter) {
            case keys.MERCHANT_ID: {
                const callbackSuccess = () => dispatch(getTransSearchMerchantId({
                    merchantTrxCode: condFilters.searchValue.value,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: condFilters.filterSkip ? condFilters.filterSkip + 20 : condFilters.filterSize,
                }))
                dispatch(resetListTransaction(callbackSuccess))
            }
                break;
            case keys.BANK_ID: {
                const callbackSuccess = () => dispatch(getTransSearchBankId({
                    bankTrxCode: condFilters.searchValue.value,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: condFilters.filterSkip ? condFilters.filterSkip + 20 : condFilters.filterSize,
                }))
                dispatch(resetListTransaction(callbackSuccess))
            }
                break;
            case keys.FACEPAY_ID: {
                const callbackSuccess = () => dispatch(getTransSearchFacepayId({
                    facepayTrxCode: condFilters.searchValue.value,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: condFilters.filterSkip ? condFilters.filterSkip + 20 : condFilters.filterSize,
                }))
                dispatch(resetListTransaction(callbackSuccess))
            }
                break;
            case keys.INTERVAL: {
                const callbackSuccess = () => dispatch(getTransSearchInterval({
                    startTime: condFilters.searchValue.startTime,
                    closeTime: condFilters.searchValue.closeTime,
                    filterFrom: "",
                    filterSkip: 0,
                    filterSize: condFilters.filterSkip ? condFilters.filterSkip + 20 : condFilters.filterSize,
                }))
                dispatch(resetListTransaction(callbackSuccess))
            }
                break;

            default:
                const callbackSuccess = () => dispatch(getListTransaction({ filterFrom: "", filterSkip: 0, filterSize: condFilters.filterSkip ? condFilters.filterSkip + 20 : condFilters.filterSize }))
                dispatch(resetListTransaction(callbackSuccess))
                break;
        }
        // const callbackSuccess = () => dispatch(getListTransaction({ filterFrom: "", filterSkip: 0, filterSize: condFilters.filterSkip + 20 }))
        // dispatch(resetListTransaction(callbackSuccess))
    }, [])

    useEffect(() => {
        if (listTransactions?.list)
            setData(listTransactions.list)
    }, [JSON.stringify(listTransactions?.list)])

    useEffect(()=>{
        if (!detailId) return
        elementActiveRef.current = listRef.current.querySelector(`.transaction-${detailId}`) 
    },[listRef.current])
    // hook scroll to item last view
    useTargetActiveElement(listRef,elementActiveRef,listRef.current)

    useScrollEnd(listRef, (callbackSuccess) => {
        switch (condFilters?.keyFilter) {
            case keys.MERCHANT_ID: {
                dispatch(getTransSearchMerchantId({
                    merchantTrxCode: condFilters.searchValue.value,
                    filterFrom: data[data.length - 1]?.id,
                    filterSkip: data.length,
                    filterSize: filterSize,
                }, true, callbackSuccess))
            }
                break;
            case keys.BANK_ID: {
                dispatch(getTransSearchBankId({
                    bankTrxCode: condFilters.searchValue.value,
                    filterFrom: data[data.length - 1]?.id,
                    filterSkip: data.length,
                    filterSize: filterSize,
                }, true, callbackSuccess))
            }
                break;
            case keys.FACEPAY_ID: {
                dispatch(getTransSearchFacepayId({
                    facepayTrxCode: condFilters.searchValue.value,
                    filterFrom: data[data.length - 1]?.id,
                    filterSkip: data.length,
                    filterSize: filterSize,
                }, true, callbackSuccess))
            }
                break;
            case keys.INTERVAL: {
                dispatch(getTransSearchInterval({
                    startTime: condFilters.searchValue.startTime,
                    closeTime: condFilters.searchValue.closeTime,
                    filterFrom: data[data.length - 1]?.id,
                    filterSkip: data.length,
                    filterSize: filterSize,
                }, true, callbackSuccess))
            }
                break;

            default:
                dispatch(getListTransaction({ filterFrom: data[data.length - 1]?.id, filterSkip: data.length, filterSize }, true, callbackSuccess))
                break;
        }

    }, [data, condFilters], [total])
    
    return (
        <WrapTransactionList>
            <PageHeader>Giao dịch ({total ? numberFormatToThousand(total) : 0})</PageHeader>
            <TableTransactionList>
                {
                    total === 0 ?
                        <div className="wrap-empty">
                            { isSearching && (
                            <EmptyDataControl 
                                icon={<IcTransactionDefault />}
                                text='Không tim thấy giao dịch phù hợp'/>
                            )}
                            { !isSearching && (
                            <EmptyDataControl 
                                icon={<IcTransactionDefault />}
                                text={"Chưa có lịch sử giao dịch"}
                            />
                            )}
                        </div>
                        :
                        <>
                            <TableHeaderTransaction>
                                {
                                    headerTransListData.map((item, index) => (
                                        <div className="table-header" key={index}>{item}</div>
                                    ))
                                }
                            </TableHeaderTransaction>
                            <TableContentTransaction>
                                <div className="wrap-list-trans" ref={listRef}>
                                    <div className="list-trans">
                                        {
                                            data.map((item, i) => (
                                                <ItemTransaction key={item.id} data={item} className = {item.id} />
                                            ))
                                        }
                                    </div>
                                </div>
                            </TableContentTransaction>
                        </>
                }


            </TableTransactionList>
        </WrapTransactionList>
    )
}

export default TransactionsList
