import styled from 'styled-components'

const WrapBtnToggle = styled.div`
    width: 47px;
    height: 27px;
    box-sizing: border-box;
    background: ${props => props.isDisabled ? "#808080" : "rgba(120, 120, 128, 0.16)"};
    border-radius: 16px;
    position: relative;
    padding: 1px;
    transition: background-color 0.3s ease-in-out;
    
    pointer-events: ${props => props.isDisabled ? "none" : "initial"};

    &:hover {
        cursor: pointer;
    }

    &::before {
        content: '';
        width: 23px;
        height: 23px;
        box-sizing: border-box;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translate(0, -50%);
        border-radius: 50%;
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
        transition: left 0.3s ease-in-out;
    }

    &.checked {
        background: ${props => props.isDisabled ? "#808080" : "#33ADFF"};
        &::before {
            border: none;
            left: calc(100% - 23px - 2px);
        }
    }
`;

export {
    WrapBtnToggle
}