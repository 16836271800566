import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

//style
import { WrapPopupEditStore } from './index.styles'

//component
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import IcClose from '../../../../../../../../../assets/images/IcClose'
import InputCode from '../../../../TabListAndTool/ToolStore/CreateNewStore/InputCode'
import InputName from '../../../../TabListAndTool/ToolStore/CreateNewStore/InputName'
import InputAddress from '../../../../TabListAndTool/ToolStore/CreateNewStore/InputAddress'
import InputLng from '../../../../TabListAndTool/ToolStore/CreateNewStore/InputLng'
import InputLat from '../../../../TabListAndTool/ToolStore/CreateNewStore/InputLat'
import Button from '../../../../../../../../Control/Button'

//hook
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'
import IcEditWhite from '../../../../../../../../../assets/images/IcEditWhite'
import { getListStoreMerchant, updateStoreMerchant } from '../../../../../../../../../redux/merchant/detailsMerchant/store/action'

const getValueNumber = (str) => Number(str)

const PopupEditStore = ({ dataStore, handleClose, setKeyShowForm}) => {

    const { merchantId } = useParams()
    const refEditStore = useRef(null)
    const dispatch = useDispatch() 

    const [isDisabledBtnEdit, setIsDisabledBtnEdit] = useState(true);
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            merchantId: merchantId,
            id: dataStore.id,
            code: dataStore.code,
            name: dataStore.name,
            address: dataStore.address,
            lat: dataStore.lat,
            lng: dataStore.lng,
        }
    )

    const {condFilters} = useSelector(state => ({
        condFilters: state.merchantReducer.detailsMerchantReducer.store.condFilters
    }))

    useEffect(() => {
        const { name, code, address, lng, lat } = stateForm
        const isEnable = name && code && address && lng && lat
        setIsDisabledBtnEdit(!isEnable)
        return () => {
        }
    }, [stateForm])

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const handleChangeValueNumber = (e) =>{
        const { name, value } = e.target;
        const number = getValueNumber(value)
        if(isNaN(number)){
            return;
        }
        if(!number) {
            setStateForm({
                [name]: ""
            })
            return;
        }
        setStateForm({
            [name]: number
        })
    }

    const callbackSuccess = () =>{
        setKeyShowForm('')
        dispatch(getListStoreMerchant({ ...condFilters }))
    }

    const _handleSubmit = () =>{
        dispatch(updateStoreMerchant(stateForm, callbackSuccess))
    }

    useOnClickOutside(refEditStore, () => setKeyShowForm(''))


    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupEditStore ref={refEditStore}>
                    <IcClose className="icon-close" onClick={handleClose}/>
                    <p className="title">Thông tin cửa hàng</p>
                    <p className="description">{dataStore.name}</p>
                    <InputCode 
                        valueInput={stateForm.code}
                        onChange={handleChangeValue}
                    />
                    <InputName
                        valueInput={stateForm.name}
                        onChange={handleChangeValue}
                    />
                    <InputAddress
                        valueInput={stateForm.address}
                        onChange={handleChangeValue}
                    />
                    <InputLat
                        valueInput={stateForm.lat}
                        onChange={handleChangeValueNumber}
                    />
                    <InputLng
                        valueInput={stateForm.lng}
                        onChange={handleChangeValueNumber}
                    />
                    <Button
                        text="Chỉnh sửa"
                        iconBtn={<IcEditWhite />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnEdit}
                    />
                </WrapPopupEditStore>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupEditStore
