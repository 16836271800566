export const GET_LIST_SETTING_REPORT = 'GET_LIST_SETTING_REPORT'
export const GET_LIST_SETTING_REPORT_ERR = 'GET_LIST_SETTING_REPORT_ERR'

export const RESET_LIST_SETTING_REPORT = 'RESET_LIST_SETTING_REPORT'

export const CREATE_NEW_SETTING_EMAIL = 'CREATE_NEW_SETTING_EMAIL'
export const CREATE_NEW_SETTING_EMAIL_ERR = 'CREATE_NEW_SETTING_EMAIL_ERR'

export const UPDATE_SETTING_EMAIL = 'UPDATE_SETTING_EMAIL'
export const UPDATE_SETTING_EMAIL_ERR = 'UPDATE_SETTING_EMAIL_ERR'

export const DELETE_SETTING_EMAIL = 'DELETE_SETTING_EMAIL'
export const DELETE_SETTING_EMAIL_ERR = 'DELETE_SETTING_EMAIL_ERR'

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

