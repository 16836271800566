import styled from 'styled-components'

const WrapMerchantGenInfos = styled.div`
    display: grid;
    align-items: center;
    background: #FFFFFF;
    height: 94px;
    box-sizing: border-box;
    border-radius: 4px;
    
    .wrap-logo {
        height: 48px;
        width: 48px;
        .logo {
            height: 48px;
            width: 48px;
            object-fit: cover;
            /* border-radius: 50%; */
        }
    }

    .wrap-merchant-infos {
        font-family: "Open Sans";

        .content-bold {
            font-style: normal;
            font-weight: 600;
            letter-spacing: -0.01em;
            color: #313131;

            &.merchant-type {
                max-width: 17ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .content-gray {
            margin-top: 4px;
            white-space: nowrap;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #777777;
        }

        &.merchant-name {
            flex-grow: 1;
            margin-left: 12px;

            .content-bold {
                font-size: 20px;
                line-height: 27px;
                max-width: 40ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .content-gray {
                max-width: 72ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:not(.merchant-name) {
            flex-shrink: 0;
            margin-left: 36px;
            .content-bold {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    .wrap-tools {
        margin-left: 19px;
    }
`;

const WrapMerchantItem = styled(WrapMerchantGenInfos)`
    grid-template-columns: auto 2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr auto;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    padding: 23px 11px 23px 24px;

    &:hover {
        border: 1px solid #33ADFF;
        cursor: pointer;
    }
`;

export {
    WrapMerchantGenInfos,
    WrapMerchantItem
}