import React from 'react'

const IconAmount = ({...props}) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.375 11.4502V6.7002C6.375 6.14791 6.82272 5.7002 7.375 5.7002H9.62501C9.62501 5.7002 12.375 5.7002 12.375 9.07521C12.375 12.4502 9.62501 12.4502 9.62501 12.4502H7.375C6.82272 12.4502 6.375 12.0025 6.375 11.4502Z" stroke="#B7B7B7" />
            <line x1="5.375" y1="8.9502" x2="7.87501" y2="8.9502" stroke="#B7B7B7" strokeLinecap="round" />
        </svg>
    )
}

export default IconAmount
