import React from 'react'

const IcStatusFalse = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r={12} fill="#F45C5C" />
            <path d="M16.5 7.5L7.5 16.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 7.5L16.5 16.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcStatusFalse
