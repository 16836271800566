import styled, { css } from 'styled-components'

// styles
import { WrapFlowNewDevice } from '../index.styles';

const WrapQRCode = styled(WrapFlowNewDevice)`
    
    .wrap-qr-image {
        margin-top: 20px;
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
        }
    }

    .time-remaining {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Open Sans";
        font-style: normal;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;

        & > *:first-child {
            font-weight: normal;
            color: #777777;
        }

        .time {
            margin-left: 4px;
            font-weight: 600;
            color: #313131;
        }

        .btn-retry {
            margin-left: 4px;
            font-weight: 600;
            color: #33ADFF;
            cursor: pointer;
        }
    }
`;

export {
    WrapQRCode
}