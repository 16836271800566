import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

// component
import FilterControl from '../../../../../../Control/FilterControl'
import DropdownControl from '../../../../../../Control/DropdownControl'
import ButtonCustom from '../../../../../../Control/Button/index'
import Portal from '../../../../../../Control/Portal'
import CreateNewAccountBank from './CreateNewAccountBank'
import DatePickerItem from '../../../../BreadcrumbAndTool/FilterTransaction/DatePickerItem'

// styles
import { WrapToolAccount } from './index.styles'
import { InputFilter } from '../../../../../../Control/FilterControl/index.styles'
import { WrapDateRange } from '../../../../BreadcrumbAndTool/FilterTransaction/index.styles'

//image
import IcCreateNew from '../../../../../../../assets/images/IcCreateNew';
import IcArrowDownGray from '../../../../../../../assets/images/IcArrowDownGray'

//action
import { GET_LIST_ACCOUNT_BANK } from '../../../../../../../redux/bank/detailtsBank/account/types'
import { getAccountBankSearchFullname, getAccountBankSearchInterval, getAccountBankSearchUsername, getListAccountBank, resetListAccountBank } from '../../../../../../../redux/bank/detailtsBank/account/action'
import { formatTimeEndDate } from '../../../../../../../helper/formatTimeEndDate'


const KEY_USERNAME = "1"
const KEY_FULLNAME = "2"
const KEY_TIME_SELECTION = "3"

const listFilterBankTypes = [
    {
        key: KEY_USERNAME,
        text: 'Username'
    },
    {
        key: KEY_FULLNAME,
        text: 'Họ tên'
    },
    {
        key: KEY_TIME_SELECTION,
        text: 'Ngày tạo'
    },
]

const filterSize = 20

const SelectDateRange = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) => {
    return <WrapDateRange>
        <DatePickerItem
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            textEmpty="Từ ngày"
            showTimeSelect={false}
            dateFormat="dd/MM/yy"
        />
        <IcArrowDownGray className="arrow-right" />
        <DatePickerItem
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            showTimeSelect={false}
            textEmpty="Đến ngày"
            dateFormat="dd/MM/yy"
        />
    </WrapDateRange>
}

const ToolAccount = () => {

    const dispatch = useDispatch()
    const inputSearchRef = useRef(null)
    const { bankId } = useParams();

    const [filterCond, setFilterCond] = useState(listFilterBankTypes[0]);
    const [isShowModalAddAccountBank, setIsShowModalAddAccountBank] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [idSearch, setIdSearch] = useState("");

    const { list, type, total } = useSelector(state => ({
        list: state.bankReducer.detailsBankReducer.accountReducer.list,
        type: state.bankReducer.detailsBankReducer.accountReducer.type,
        total: state.bankReducer.detailsBankReducer.accountReducer.total
    }))

    useEffect(() => {
        setIdSearch("")
        setStartDate(null)
        setEndDate(null)
        if (inputSearchRef.current) inputSearchRef.current.value = ''
    }, [JSON.stringify(filterCond)]);

    useEffect(() => {
        const { key } = filterCond
        if ( key !== KEY_USERNAME && type === GET_LIST_ACCOUNT_BANK) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key !== KEY_USERNAME && type === GET_LIST_ACCOUNT_BANK && idSearch) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            setFilterCond(listFilterBankTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key !== KEY_USERNAME && type === GET_LIST_ACCOUNT_BANK && startDate && endDate) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            setFilterCond(listFilterBankTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
        if (key === KEY_USERNAME && type === GET_LIST_ACCOUNT_BANK) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            setFilterCond(listFilterBankTypes[0])
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
    }, [JSON.stringify(list), JSON.stringify(filterCond), type]);

    const _handleSearch = () => {
        const { key } = filterCond
        if (!idSearch && !startDate && !endDate) {
            dispatch(getListAccountBank({
                bankId,
                filterFrom: '',
                filterSkip: 0,
                filterSize,
            }))
            dispatch(resetListAccountBank())
        }
        switch (key) {
            case KEY_USERNAME: {
                if (!idSearch) break;
                dispatch(getAccountBankSearchUsername({
                    bankId,
                    username: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                dispatch(resetListAccountBank())
                break;
            }
            case KEY_FULLNAME: {
                if (!idSearch) break;
                dispatch(getAccountBankSearchFullname({
                    bankId,
                    fullname: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                dispatch(resetListAccountBank())
                break;
            }
            case KEY_TIME_SELECTION: {
                if (!startDate || !endDate) break;
                dispatch(getAccountBankSearchInterval({
                    bankId,
                    startTime: startDate,
                    closeTime: formatTimeEndDate(endDate),
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                dispatch(resetListAccountBank())
                break;
            }
            default:
                break;
        }
    }

    const _handleClickBtn = () => {
        setIsShowModalAddAccountBank(pre => !pre)
    }

    const dropdownFilter = useMemo(() => {
        return (
            <DropdownControl
                dataSelection={listFilterBankTypes}
                valueSelected={filterCond}
                handleSetValueSelect={val => setFilterCond(val)}
            />
        )
    }, [filterCond])

    const filterComponent = useMemo(() => {
        const { key, text } = filterCond
        if (key === KEY_TIME_SELECTION)
            return <SelectDateRange
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        return (
            <InputFilter
                type="text"
                placeholder={`Nhập ${text}`}
                ref={inputSearchRef}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        _handleSearch()
                    }
                }}
                onChange={e => setIdSearch(e.target.value)}
            />
        )
    }, [startDate, endDate, JSON.stringify(filterCond), _handleSearch])

    return (
        <WrapToolAccount>
            <div className="wrap-filter">
                <FilterControl
                    dropdownFilter={dropdownFilter}
                    filterComponent={filterComponent}
                    handleSearch={_handleSearch}
                />
            </div>
            <div className="total-of-data">
                <span>Tài khoản:</span>
                <span>{total || 0}</span>
            </div>
            <div>
                <ButtonCustom
                    text="Thêm mới"
                    iconBtn={<IcCreateNew />}
                    className="btn-creteNew"
                    onClick={_handleClickBtn}
                />
            </div>
            {
                isShowModalAddAccountBank &&
                <Portal>
                    <CreateNewAccountBank onClickIconClose={_handleClickBtn} setIsShowModalAddAccountBank={setIsShowModalAddAccountBank} />
                </Portal>
            }
        </WrapToolAccount>
    )
}

export default ToolAccount
