import moment from 'moment'

// constant
import { BANK_ENV, WEE_ENV, env } from './environmentTypes';

// export const host = "https://dev.facepay.vn/cloud"
// export const hostMedia = "https://dev.facepay.vn/cloud/media"
// export const host = "https://rtc.wee.vn/cloud"
// export const host = "https://bank.facepay.vn/cloud"
// export const hostMedia = "https://bank.facepay.vn/cloud/media"

// export const apiGetAds = (adsId) => 
//     `${host}/advertisement/download/media?id=${adsId}&time=${moment().unix()}`

// export const apiGetThumbnailAds = (adsId) => 
//     `${host}/advertotalTransHistisement/download/thumbnail?id=${adsId}&time=${moment().unix()}`

export const { host, hostFb, hostMedia, hostWebSocket } = (() => {
    switch(env) {
        case BANK_ENV: {
            switch(process.env.REACT_APP_SERVER_ENV) {
                case 'qc':
                    return {
                        hostFb: "https://facepay-admin-service-luom7lul3q-as.a.run.app",
                        host: "https://bank-qc.facepay.vn/cloud", 
                        hostMedia: "https://bank-qc.facepay.vn/cloud/media"
                    }
                case 'uat':
                    return{
                        hostFb: "https://facepay-admin-service-luom7lul3q-as.a.run.app",
                        host: "https://bank-uat.facepay.vn/cloud", 
                        hostMedia: "https://bank-uat.facepay.vn/cloud/media"
                    }
                case 'prod': 
                    return {
                        hostFb: "https://facepay-admin-service-luom7lul3q-as.a.run.app",
                        host: "https://bank.facepay.vn/cloud", 
                        hostMedia: "https://bank.facepay.vn/cloud/media"
                    }
                
                default:
                    return {
                        hostFb: "https://facepay-admin-service-luom7lul3q-as.a.run.app",
                        host: "https://bank-dev.facepay.vn/cloud", 
                        hostMedia: "https://bank-dev.facepay.vn/cloud/media"
                    }
            }
        }
        case WEE_ENV:
            switch(process.env.REACT_APP_SERVER_ENV) {
                case 'qc':
                    return {
                        host: "https://dev.facepay.vn/cloud", 
                        hostMedia: "https://dev.facepay.vn/cloud/media",
                        hostWebSocket: "wss://dev.facepay.vn/wss/notify/connect"
                    }
                case 'uat':
                    return{
                        host: "https://admin-uat.facepay.vn/cloud", 
                        hostMedia: "https://admin-uat.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin-uat.facepay.vn/wss/notify/connect"
                    }
                case 'prod': 
                    return {
                        host: "https://admin.facepay.vn/cloud", 
                        hostMedia: "https://admin.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin.facepay.vn/wss/notify/connect"
                    }
                
                default:
                    return {
                        host: "https://admin-ppe.facepay.vn/cloud", 
                        hostMedia: "https://admin-ppe.facepay.vn/cloud/media",
                        hostWebSocket: "wss://admin-ppe.facepay.vn/wss/notify/connect"
                    }
            }
        default:
            return {
                host: "https://dev.facepay.vn/cloud",
                hostMedia: "https://dev.facepay.vn/cloud/media",
                hostWebSocket: "wss://dev.facepay.vn/wss/notify/connect"
            }
    }
})()

export const apiMerchantLogo = (merchantId = "") => {
    return `${hostMedia}/download/merchantLogo?merchantId=${merchantId}&time=${moment().unix()}`;
};

export const apiSupplierLogo = (supplier = "") => {
    return `${hostMedia}/download/supplierLogo?supplier=${supplier}&time=${moment().unix()}`;
};

export const apiTransactionPhoto = (id = "") => {
    return `${hostMedia}/download/transactionPhoto?id=${id}&time=${moment().unix()}`;
};

export const apiTransactionVerifyVideo = (id = "") => {
    return `${hostMedia}/download/transactionVerifyVideo?id=${id}&time=${moment().unix()}`;
};

export const apiAdvertisementMerchantPhoto = (id = "") => {
    return `${hostMedia}/download/merchantAdvertisement?id=${id}&time=${moment().unix()}`;
};
