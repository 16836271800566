import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//component
import Button from '../../../../../../../../Control/Button'
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import UploadImageAdvertisement from '../../../../TabListAndTool/ToolAdvertisement/CreateNewAdvertisement/UploadImageAdvertisement'
import IcClose from '../../../../../../../../../assets/images/IcClose'
import IcEditWhite from '../../../../../../../../../assets/images/IcEditWhite'


//help
import { apiAdvertisementMerchantPhoto } from '../../../../../../../../../constant/host'
import bytesToSize from '../../../../../../../../../helper/convertBytesToSize'
import fileToBase64 from '../../../../../../../../../helper/fileToBase64'

//hook
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'

//style
import { WrapPopupEditAdvertisement } from './index.styles'

//action
import { getListAdvertisementMerchant, updateAdvertisementMerchant } from '../../../../../../../../../redux/merchant/detailsMerchant/advertisement/action'

const textImage = "hình ảnh"

const PopupEditAdvertisement = ({ dataAdvertisement, handleClose, setKeyShowForm }) => {
    const dispatch = useDispatch() 
    const refEditAdvertisement = useRef(null)

    const [advertisementImg, setAdvertisementImg] = useState(null);
    const [showBtnUpdate, setShowBtnUpdate] = useState(true)

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            id: dataAdvertisement.id,
            name: dataAdvertisement.name,
            format: dataAdvertisement.format,
            file: dataAdvertisement.file,
            size: dataAdvertisement.size,
        }
    )

    const {condFilters} = useSelector(state => ({
        condFilters: state.merchantReducer.detailsMerchantReducer.advertisement.condFilters
    }))

    useEffect(() => {
        if(advertisementImg){
            // console.log(advertisementImg)
            setStateForm({
                name: advertisementImg.name,
                format: advertisementImg.format,
                file: advertisementImg.file,
                size: advertisementImg.size,
            })
            setShowBtnUpdate(true)
        }
        return () => {
        }
    }, [advertisementImg])

    const _handleRemoveFile = () =>{
        setAdvertisementImg(null)
        setShowBtnUpdate(false)
    }

    const callbackSuccess = () =>{
        setKeyShowForm('')
        dispatch(getListAdvertisementMerchant({ ...condFilters }))
    }

    const _handleSubmit = async () =>{
        const { name, format, file, size, id } = stateForm
        const dataRequest = {
            id,
            name,
            size,
            format,
            file,
        }
        if(advertisementImg){
            const base64 = await fileToBase64(advertisementImg)
            const { name, size } = advertisementImg
            dataRequest.name = name;
            dataRequest.size = size + "";
            dataRequest.file = base64.split(',')[1];
            dataRequest.format = textImage
        }
        dispatch(updateAdvertisementMerchant(dataRequest, callbackSuccess))
    }

    // console.log(dataAdvertisement)

    useOnClickOutside(refEditAdvertisement, () => setKeyShowForm(''))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupEditAdvertisement ref={refEditAdvertisement}>
                    <IcClose className="icon-close" onClick={handleClose}/>    
                    <p className="title">Chỉnh sửa quảng cáo</p>
                    <div className="image">
                        <UploadImageAdvertisement 
                            defaultUrl={apiAdvertisementMerchantPhoto(dataAdvertisement.id)}
                            handleRemoveFile={_handleRemoveFile}
                            handleDropFiles={files => setAdvertisementImg(files[0])}
                        />
                    </div>
                    {
                        showBtnUpdate &&
                        <>
                            <div className="description">
                                <p className="desc-img" title={stateForm.name}>{stateForm.name}</p>
                                <p className="desc-size">{bytesToSize(stateForm.size)}</p>
                            </div>
                            <Button
                                text="Chỉnh sửa"
                                iconBtn={<IcEditWhite />}
                                className="btn-creteNew"
                                onClick={_handleSubmit}
                            />
                        </>
                    }
            </WrapPopupEditAdvertisement>
        </div>
    </OverlayFullScreen>
    )
}

export default PopupEditAdvertisement
