// data lỗi Viettin bank
export const dataErrorVTB = {
    ["01"]: {text: "Giao dịch không thành công do lỗi thông tin nhà Cung cấp phát sinh từ hệ thống ngân hàng"},
    ["02"]: {text: "Giao dịch không thành công do tài khoản/thẻ không hợp lệ.KH Cần đổi tài khoản mặc định trên iPay để giao dịch!"},
    ["03"]: {text: "Giao dịch không thành công do tài khoản/ thẻ chưa đăng ký iPay.KH cần đăng ký iPay để giao dịch!"},
    ["04"]: {text: "Giao dịch không thành công do không xác thực được thông tin chủ thẻ (sai thông tin ngày phát hành hoặc họ tên in trên thẻ). Cần đổi tài khoản mặc định để giao dịch!"},
    ["05"]: {text: "Giao dịch không thành công do tài khoản không đủ số dư. KH cần nạp tiền vào tài khoản để thanh toán!"},
    ["06"]: {text: "Giao dịch không thành công do tài khoản/thẻ không hợp lệ. KH Cần đổi tài khoản mặc định trên iPay để giao dịch!"},
    ["07"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["08"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["09"]: {text: "Giao dịch không thành công do lỗi hệ thống"},
    ["10"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["11"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["12"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["13"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["14"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["15"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["16"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["17"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["18"]: {text: "Khuôn mặt không trùng khớp."},
    ["20"]: {text: "Số tiền vượt hạn mức thanh toán cho phép cần xác thực OTP. "},
    ["21"]: {text: "Số tiền vượt quá hạn mức tối thiểu /lần giao dịch. "},
    ["22"]: {text: "Số tiền vượt quá hạn mức tối đa / lần giao dịch."},
    ["23"]: {text: "Số tiền vượt quá hạn mức tối đa / ngày giao dịch. "},
    ["24"]: {text: "Giao dịch không thành công do tài khoản/thẻ không hợp lệ. KH Cần đổi tài khoản mặc định trên iPay để giao dịch!"},
    ["25"]: {text: "Giao dịch không thành công do tài khoản/thẻ không hợp lệ. KH cần đổi tài khoản mặc định trên iPay để giao dịch!"},
    ["26"]: {text: "Số tiền thanh toán không hợp lệ. Vui lòng thực hiện lại"},
    ["27"]: {text: "Giao dịch không thành công do tài khoản không đủ số dư. KH cần nạp tiền vào tài khoản để thanh toán!"},
    ["31"]: {text: "Sai thông tin xác thực OTP. Vui lòng thực hiện lại"},
    ["32"]: {text: "Quý khách nhập mã OTP quá số lần cho phép. "},
    ["33"]: {text: "Mã OTP đã hết hạn. "},
    ["34"]: {text: "Giao dịch đã thực hiện xác thực, lỗi từ hệ thống Vietinbank, cần kiểm tra lại tk hoặc liên hệ ngân hàng hỗ trợ"},
    ["35"]: {text: "Không tìm thấy thông tin giao dịch cần xác thực trong DB của VietinBank. "},
    ["36"]: {text: "Lỗi xác thực OTP, "},
    ["37"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["40"]: {text: "Giao dịch đã trừ tiền thành công"},
    ["41"]: {text: "Giao dịch đã được trừ tiền nhưng chưa thành công"},
    ["42"]: {text: "Giao dịch tồn tại nhưng chưa thành công"},
    ["43"]: {text: "Giao dịch không tồn tại trên hệ thống VietinBank"},
    ["44"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["50"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng."},
    ["51"]: {text: "Số tiền yêu cầu Hoàn tiền không hợp lệ"},
    ["52"]: {text: "Giao dịch Hoàn tiền không thành công"},
    ["53"]: {text: "Ðã tồn tại giao dịch hoàn tiền."},
    ["54"]: {text: "Không tạo được giao dịch hoàn tiền"},
    ["57"]: {text: "Không tìm thấy thông tin Khách hàng do tài khoản ko hợp lệ, cần liên hệ Vietinbank hỗ trợ"},
    ["58"]: {text: "Quý khách chưa đăng ký Sinh trắc học tại VietinBank. Vui lòng đăng ký để giao dịch!"},
    ["59"]: {text: "Khuôn mặt không trùng khớp"},
    ["61"]: {text: "Thông tin người thụ hưởng sai"},
    ["62"]: {text: "Thông tin tài khoản thụ hưởng sai"},
    ["63"]: {text: "Không tồn tại thông tin HÐ"},
    ["64"]: {text: "Không tồn tại thông tin nhà cung cấp"},
    ["70"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["71"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["75"]: {text: "Thông tin Khách hàng không hợp lệ. "},
    ["76"]: {text: "Thông tin Số điện thoại không đúng. "},
    ["89"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["90"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["91"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["92"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["93"]: {text: "Giao dịch lỗi hệ thống ngân hàng. "},
    ["94"]: {text: "Giao dịch không thành công do yêu cầu bảo mật. "},
    ["95"]: {text: "Giao dịch không thành công do yêu cầu bảo mật. "},
    ["96"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["99"]: {text: "Giao dịch không thành công do lỗi hệ thống ngân hàng. "},
    ["FP00"]: {text: "Giao dịch không thành công do không tìm thấy thông tin Khách hàng. "},
    ["FP02"]: {text: "Khách hàng có hình ảnh đăng ký và hình ảnh thanh toán chưa trùng khớp"},
    ["FP03"]: {text: "Giao dịch không thành công do hình ảnh thanh toán không đạt chất lượng. "},
    ["-99"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},
    ["-1"]: {text: "KH chưa đăng ký tk mặc định trên ipay, cần đăng nhập ipay để dky"},
    ["-2"]: {text: "KH chưa đăng ký dịch vụ ipay, cần đăng ký để giao dịch"},
}