import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// component
import IcStore from '../../../../../../assets/images/IcStore'
import IcStoreActive from '../../../../../../assets/images/IcStoreActive'
import IcStoreError from '../../../../../../assets/images/IcStoreError'
import InputDropdown from '../../../../../Control/InputDropdown'

// action
import { getListMerchant, resetListMerchant } from '../../../../../../redux/merchant/listMerchant/action'

const iconStore = {
    icon: IcStore,
    iconActive: IcStoreActive,
    iconError: IcStoreError,
}

const InputMerchant = ({ handleGetValue, merchant }) => {
    const dispatch = useDispatch()

    const { list } = useSelector(state => ({
        list: state.merchantReducer.listMerchantReducer.list.map(item => ({
            ...item,
            key: item.id,
            text: item.name
        }))
    }))

    const _handleSetValueDropdown = (val) =>{
        handleGetValue && handleGetValue(val)
    }

    const _handleScrollEnd = useCallback((callback) => {
        dispatch(getListMerchant({
            filterFrom: list[0]?.id,
            filterSkip: list.length,
            filterSize: 20
        }, true, callback))
    }, [list])

    useEffect(() => {
        dispatch(getListMerchant({
            filterFrom: "",
            filterSkip: 0,
            filterSize: 20
        }))

        return () => {
            dispatch(resetListMerchant())
        }
    }, []);

    return (
        <InputDropdown 
            key={merchant?.key}
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder="Merchant"
            dataFilterOption={list}
            objCategory={merchant}
            handleScrollEnd={_handleScrollEnd}
        />
    )
}

export default InputMerchant
