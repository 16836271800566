import { combineReducers } from "redux";

import listBankReducer from './listBank'
import detailsBankReducer from './detailtsBank'
import bankCountReducer from './bankCount'

const bankReducer = combineReducers({
    listBankReducer,
    detailsBankReducer,
    bankCountReducer
})

export default bankReducer;