import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// data
import dataDeviceStatus from '../../../../../data/dataDeviceStatus'

// action
import { getListDevices, resetListDevices } from '../../../../../redux/device/listDevices/action'

// component
import PageHeader from '../PageHeader'
import DeviceItem from './DeviceItem'

// styles
import { WrapDeviceList } from './index.styles'

const DeviceList = () => {
    const dispatch = useDispatch()

    const { list } = useSelector(state => ({
        list: state.deviceReducer.listDevicesReducer.list
    }))

    useEffect(() => {
        dispatch(getListDevices({ filterSize: - 1 }))

        return () => {
            dispatch(resetListDevices())
        }
    }, []);

    return (
        <WrapDeviceList>
            <div className="wrap-device-header">
                <PageHeader>Device List</PageHeader>
                <div className="wrap-status-device">
                    {
                        Object.values(dataDeviceStatus).map(({ type, text, color }) => (
                            <div className="devices-status" key={type}>
                                <div style={{ background:color }} className="block-status"></div>
                                <p>{text}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="wrap-list-devices">
                <div className="wrap-inner-list-devices">
                    {
                        list.map((item,index) => (
                            <DeviceItem 
                                key={index}
                                dataItem={item}
                            />  
                        ))
                    }
                </div>
            </div>
        </WrapDeviceList>
    )
}

export default DeviceList
