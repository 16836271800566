import styled from 'styled-components'
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapDeviceListByAdmin = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
   .wrap-header{
       display: flex;
       justify-content: space-between;
       align-items: center;
       .wrap-status-device{
            display: flex;
            align-items: center;
            .devices-status{
                margin-left: 32px;
                display: flex;
                align-items: center;
                .block-status{
                    width: 12px;
                    height: 12px;
                    margin-right: 8px;
                }
                p{
                    color: #313131;
                    letter-spacing: -0.01em;
                    font-family: "Open Sans";
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 18px;
                }
            }
            .wrap-view-type{
                width: 90px;
                margin-left: 28px;
                display: flex;
                justify-content: flex-end;
                .btn-header{
                    background: transparent;
                    color: #33ADFF;
                    padding: 0;
                }
            }
       }
    }
`;

export {
    WrapDeviceListByAdmin
}