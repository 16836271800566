import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";

//image
import IcArrowRight from '../../../../../../assets/images/IcArrowRight';
import IcFacePrintDefault from '../../../../../../assets/images/IcFacePrintDefault';
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault';
//tools
import { convertDateTime } from '../../../../../../helper/convertDateTime';
//styles
import { Bound } from './index.styles'
//path
import * as path from '../../../../../../constant/path'
import { apiSupplierLogo, apiTransactionPhoto } from '../../../../../../constant/host';

const ItemCustomer = ({ data }) => {

    const history = useHistory()

    const [isErrImg, setIsErrImg] = useState(false)
    const [isErrChannel, setIsErrChannel] = useState(false)

    useEffect(() => {
        setIsErrImg(false)
    }, [data?.id])

    useEffect(() => {
        setIsErrChannel(false)
    }, [data?.id])

    const _handleClick = () => {
        history.push(`/${path.MAIN}/${path.CUSTOMER}/${data?.id}`)
    }

    return (
        <Bound onClick={_handleClick}>
            <div title={data?.profileId ? `${data?.profileId}` : "-"}>
                {data?.profileId ? `${data?.profileId}` : "-"}
            </div>
            <div title={data?.id ? `${data?.id}` : "-"}>
                {data?.id ? `${data?.id}` : "-"}
            </div>
            <div className="avatar-user">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="avatar" />
                        :
                        <img
                            className="avatar"
                            src={data?.id ? apiTransactionPhoto(data.id) : ""}
                            alt="avatar"
                            onError={() => setIsErrImg(true)}
                        />
                }
            </div>
            <div title={data?.fullname ? `${data?.fullname}` : "-"}>
                {data?.fullname ? `${data?.fullname}` : "-"}
            </div>
            <div className="customer-channel">
                {
                    isErrChannel ?
                        <IcMerchantLogoDefault className="logo" />
                        :
                        <img
                            className="logo"
                            src={data?.supplier ? apiSupplierLogo(data.supplier) : ""}
                            alt="logo"
                            onError={() => setIsErrChannel(true)}
                        />
                }
                <div className="name-channel" title={data?.supplierShortName ? `${data?.supplierShortName}` : "-"}>
                    {data?.supplierShortName ? `${data?.supplierShortName}` : "-"}
                </div>
            </div>
            <div title={data?.createAt ? convertDateTime(data.createAt, "DD/MM/YYYY") : ""}>
                {data?.createAt ? convertDateTime(data.createAt, "DD/MM/YYYY") : "-"}
            </div>
            <div className="wrap-time" title={data?.createAt ? convertDateTime(data.createAt, "HH:mm") : ""}>
                <div className="time">{data?.createAt ? convertDateTime(data.createAt, "HH:mm") : "-"}</div>
                <IcArrowRight className="icon-arrow" />
            </div>
        </Bound>
    )
}

export default ItemCustomer
