import React, { useState, useEffect, useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapPopupEditAccountPermission } from './index.styles'

// component
import IcEditWhite from '../../../../../../../../../assets/images/IcEditWhite'
import Button from '../../../../../../../../Control/Button'
import IcClose from '../../../../../../../../../assets/images/IcClose'
import InputEmail from '../../../CreatNewAccountPermission/InputEmail'
import InputFullname from '../../../CreatNewAccountPermission/InputFullname'
import BtnToggle from '../../../../../../../../Control/BtnToggle'

// action
import { useOnClickOutside } from '../../../../../../../../../hook/useClickOutside'
import { getListPermission, updateAccountPermission } from '../../../../../../../../../redux/permission/listPermission/action'

const PopupEditAccountPermission = ({ data, setActiveKey }) => {

    const dispatch = useDispatch()
    const refEditReport = useRef(null)

    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);

    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            id: data.id,
            email: data.email,
            fullname: data.fullname,
            dailyReport: data.dailyReport,
            monthlyReport: data.monthlyReport,
            isDisable: data.isDisable
        }
    );

    const callbackSuccess = () => {
        dispatch(getListPermission({
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        setActiveKey('')
    }

    const _handleSubmit = async () => {
        const { id, email, fullname, dailyReport, monthlyReport, isDisable } = stateForm

        const dataRequest = {
            id,
            email,
            fullname,
            dailyReport,
            monthlyReport,
            isDisable
        }

        dispatch(updateAccountPermission(
            dataRequest, callbackSuccess, null
        ))
    }

    const _handleChange = (stateKey) => (e) => {
        setStateForm({ [stateKey]: e.target.value })
    }

    const handleChangeToggle = (isCheck) => {
        setStateForm({
            isDisable: !isCheck
        })        
    }

    useEffect(() => {
        const { fullname } = stateForm
        const isEnable = fullname 
        setIsDisabledBtnCreate(!isEnable)
        return () => {
        }
    }, [stateForm])

    useOnClickOutside(refEditReport, () => setActiveKey(''))

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupEditAccountPermission ref={refEditReport}>
                    <IcClose className="icon-close" onClick={() => setActiveKey('')} />
                    <div className="title">Configured Account Profile</div>
                    <div className="description">Input email information to receive automatic reports</div>
                    <InputFullname
                        valueInput={stateForm.fullname}
                        onChange={_handleChange("fullname")}
                        placeholder="Fullname"
                        className="full-name"
                    />
                    <InputEmail
                        valueInput={stateForm.email}
                        onChange={_handleChange("email")}
                        placeholder="Email Address"
                        disabled="disabled"
                        className="email"
                    />
                    <div className="active-account">
                        <div className="wrap">
                            <div className="title">Active Account</div>
                            <p className="des">*The account is activated allowing login to the system</p>
                        </div>
                        <BtnToggle
                            defaultChecked={!stateForm?.isDisable}
                            handleChangeCheck={(isCheck) => handleChangeToggle(isCheck)}
                        />
                    </div>
                    
                    <Button
                        text="Save Profile"
                        iconBtn={<IcEditWhite />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapPopupEditAccountPermission>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupEditAccountPermission
