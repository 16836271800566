import Logo_ViettinBank from "../assets/images/Vietinbank.png";
import Logo_TPBank from "../assets/images/TPBank.png";
import Logo_OCB from "../assets/images/OCB.png";
import Logo_FacePay from "../assets/images/Facepay.png";
import Logo_SmartPay from "../assets/images/SmartPay.png";
import Logo_DefautBank from "../assets/images/Default_Bank.png";
import Logo_BIDV from "../assets/images/BIDV.png";
import Logo_PVCB from "../assets/images/PVCB.png";


export const VIETTINBANK = "CTG";
export const TPBANK = "TPB";
export const OCB = "OCB";
export const FACEPAY = "FACEPAY";
export const SMARTPAY = "SMARTPAY";
export const BIDV = "BIDV";
export const PVCOMBANK = "PVCB"

export const listBank = [
  {
    logo: Logo_ViettinBank,
    logoDefault: Logo_DefautBank,
    shortName: "ViettinBank",
    fullName: "Ngân hàng Thương mại Cổ Phần Công Thương Việt Nam",
    key: VIETTINBANK,
  },
  {
    logo: Logo_TPBank,
    logoDefault: Logo_DefautBank,
    shortName: "TPBank",
    fullName: "Ngân hàng Thương mại Cổ Phần Tiên Phong",
    key: TPBANK,
  },
  {
    logo: Logo_OCB,
    logoDefault: Logo_DefautBank,
    shortName: "OCB",
    fullName: "Ngân hàng Thương mại Cổ Phần Phương Đông",
    key: OCB,
  },
  {
    logo: Logo_FacePay,
    logoDefault: Logo_DefautBank,
    shortName: "FacePay",
    fullName: "Ví điện tử FacePay",
    key: FACEPAY,
  },
  {
    logo: Logo_SmartPay,
    logoDefault: Logo_DefautBank,
    shortName: "SmartPay",
    fullName: "Ví điện tử SmartPay",
    key: SMARTPAY,
  },
  {
    logo: Logo_BIDV,
    logoDefault: Logo_DefautBank,
    shortName: "BIDV",
    fullName: "Ngân hàng TMCP Đầu tư và Phát triển Việt Nam",
    key: BIDV,
  },
  {
    logo: Logo_PVCB,
    logoDefault: Logo_DefautBank,
    shortName: "PVcombank",
    fullName: "Ngân hàng TMCP Đại Chúng Việt Nam",
    key: PVCOMBANK,
  }
];
