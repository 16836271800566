import React from 'react'

// data
import { listTabs } from '../../../../../../redux/setting/listSetting/listSettingReportReducer'

// styles
import { WrapTabListAndTool } from './index.styles'

const TabListAndTool = ({
    activeTab: {
        type: activeTabType,
    },
    setActiveTab
}) => {
    
    return (
        <WrapTabListAndTool>
            <div className="wrap-tab">
                {
                    listTabs.map(({ type, name }) => (
                        <div
                            key={type}
                            className={`tab${activeTabType === type ? ' active' : ''}`}
                            onClick={() => setActiveTab({ type, name })}
                        >
                            {name}
                        </div>
                    ))
                }
            </div>
        </WrapTabListAndTool>
    )
}

export default TabListAndTool
