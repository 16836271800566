import React, { useState, useEffect } from 'react'

// styles
import { WrapBtnToggle } from './index.styles'

const BtnToggle = ({ defaultChecked=false, handleChangeCheck, isDisabled }) => {

    const [checked, setChecked] = useState(defaultChecked)

    useEffect(() => {
        setChecked(defaultChecked)
    }, [defaultChecked])

    const _toggleBtn = () => {
        if(isDisabled) return;
        
        const newCheck = !checked
        setChecked(newCheck)
        handleChangeCheck && handleChangeCheck(newCheck)
    }

    return (
        <WrapBtnToggle 
            className={checked?'checked':''}
            onClick={_toggleBtn}
            isDisabled={isDisabled}
        />
    )
}

export default BtnToggle
