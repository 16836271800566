import styled from 'styled-components'

const WrapPageHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 22px 0;
    .title{
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #313131;
        padding-left: 8px;
    }
`;

export {
    WrapPageHeader
}