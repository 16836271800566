import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// component
import IcArrowDownGray from '../../../../../assets/images/IcArrowDownGray'
import DropdownSelectTypeSearch, { dataSelection, KEY_PROFILE_ID, KEY_BIOMETRIC_ID, KEY_TIME_SELECTION } from './DropdownSelectTypeSearch'
// import DatePickerItem from './DatePickerItem'
import FilterControl from '../../../../Control/FilterControl'
import { InputFilter } from '../../../../Control/FilterControl/index.styles'

// styles
import { WrapDateRange } from './index.styles'

//action
import { getListCustomer, getCustomerSearchProfileId, getCustomerSearchBiometricId, getCustomerSearchInterval, resetListCustomer } from '../../../../../redux/customer/listCustomer/action'

// type
import DatePickerItem from '../FilterTransaction/DatePickerItem'
import { GET_LIST_CUSTOMER } from '../../../../../redux/customer/listCustomer/types'


const filterSize = 20

const SelectDateRange = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) => {
    return <WrapDateRange>
        <DatePickerItem
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            textEmpty="Từ ngày"
        />
        <IcArrowDownGray className="arrow-right" />
        <DatePickerItem
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            textEmpty="Đến ngày"
        />
    </WrapDateRange>
}

const FilterCustomer = () => {

    const dispatch = useDispatch()
    const inputSearchRef = useRef(null)

    const [filterCond, setFilterCond] = useState(dataSelection[0]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [idSearch, setIdSearch] = useState("");

    const { listCustomers, condFilters } = useSelector(state => ({
        listCustomers: state.customerReducer.listCustomerReducer,
        condFilters: state.customerReducer.listCustomerReducer.condFilters,
    }))

    useEffect(() => {
        setIdSearch("")
        setStartDate(null)
        setEndDate(null)
        if (inputSearchRef.current) inputSearchRef.current.value = ''
    }, [JSON.stringify(filterCond)]);

    useEffect(() => {
        if (listCustomers.type === GET_LIST_CUSTOMER) {
            setIdSearch("")
            setStartDate(null)
            setEndDate(null)
            if (inputSearchRef.current) inputSearchRef.current.value = ''
        }
    }, [JSON.stringify(listCustomers)]);

    const _handleSearch = () => {
        const { key } = filterCond
        if (!idSearch && !startDate && !endDate) {
            const callbackSuccess = () => dispatch(getListCustomer({
                filterFrom: '',
                filterSkip: 0,
                filterSize,
            }))
            dispatch(resetListCustomer(callbackSuccess))
        }
        switch (key) {
            case KEY_PROFILE_ID: {
                if (!idSearch) break;
                const callbackSuccess = () => dispatch(getCustomerSearchProfileId({
                    profileId: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                dispatch(resetListCustomer(callbackSuccess))
                break;
            }
            case KEY_BIOMETRIC_ID: {
                if (!idSearch) break;
                const callbackSuccess = () => dispatch(getCustomerSearchBiometricId({
                    facepayTrxCode: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                dispatch(resetListCustomer(callbackSuccess))
                break;
            }
            case KEY_TIME_SELECTION: {
                if (!startDate || !endDate) break;
                const callbackSuccess = () => dispatch(getCustomerSearchInterval({
                    startTime: startDate,
                    closeTime: endDate,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize,
                }))
                dispatch(resetListCustomer(callbackSuccess))
                break;
            }
            default:
                break;
        }
    }

    const dropdownFilter = useMemo(() => (
        <DropdownSelectTypeSearch
            filterCond={filterCond}
            setFilterCond={setFilterCond}
        />
    ), [filterCond, setFilterCond])

    const filterComponent = useMemo(() => {
        const { key, text } = filterCond
        if (key === KEY_TIME_SELECTION)
            return <SelectDateRange
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
        return <InputFilter
            type="text"
            placeholder={`Input ${text}`}
            ref={inputSearchRef}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    _handleSearch()
                }
            }}
            onChange={e => setIdSearch(e.target.value)}
        />
    }, [startDate, endDate, JSON.stringify(filterCond), _handleSearch])

    return (
        <FilterControl
            dropdownFilter={dropdownFilter}
            filterComponent={filterComponent}
            handleSearch={_handleSearch}
        />
    )
}

export default FilterCustomer
