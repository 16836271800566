import styled from 'styled-components'

import { WrapCreateNewPopup } from '../CreateNewMerchant/index.styles';

const WrapPopupExportFile = styled(WrapCreateNewPopup)`
    width: 270px;
    padding: 20px;
    box-sizing: border-box;
    .description{
        margin-top: 10px;
        font-size: 13px;
        line-height: 18px;
        align-items: center;
        letter-spacing: -0.01em;
        color: #777777;
    }
    .input-dropdown{
        margin-top: 24px;
        .wrap-input{
            margin-bottom: 0;
        }
    }
    .count-amount{
        margin-top: 12px;
        color: #777777;
        letter-spacing: -0.02em;
        font-family: "Open Sans";
        font-size: 11px;
        line-height: 15px;
        visibility: hidden;
        span{
            color: #313131;
        }

        &.visible {
            visibility: initial;
        }
    }
    .btn-export{
        margin-top: 40px;
        width: 100%;
    }
`;

export {
    WrapPopupExportFile,
}