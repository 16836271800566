import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import moment from 'moment'

//image
import IcArrowRight from '../../../../../../assets/images/IcArrowRight';
import IcStatusFalse from '../../../../../../assets/images/IcStatusFalse';
import IcStatusTrue from '../../../../../../assets/images/IcStatusTrue';
import IcFacePrintDefault from '../../../../../../assets/images/IcFacePrintDefault';
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault';
//tools
import { convertDateTime } from '../../../../../../helper/convertDateTime';
import numberFormatToThousand from '../../../../../../helper/numberFormatToThousand';
//styles
import { Bound } from './index.styles'
//path
import * as path from '../../../../../../constant/path'
import { apiMerchantLogo, apiSupplierLogo, apiTransactionPhoto } from '../../../../../../constant/host';

// constant
import { DEFAULT_VAL } from '../../../../../../constant/keys';
import { REFUNDED, SUCCESS } from '../../../../../../constant/transactionStatusTypes';
import { listMerchant } from '../../../../../../constant/dataMerchant';
import { listBank } from '../../../../../../constant/dataBank';

const ItemTransaction = ({ data, refTrans, className }) => {

    const history = useHistory()

    const [isErrImg, setIsErrImg] = useState(false)
    const [isErrMerchantLogo, setIsErrMerchantLogo] = useState(false)
    const [isErrBankLogo, setIsErrBankLogo] = useState(false)

    useEffect(() => {
        setIsErrImg(false)
    }, [data?.id])

    useEffect(() => {
        setIsErrMerchantLogo(false)
    }, [data?.id])

    useEffect(() => {
        setIsErrBankLogo(false)
    }, [data?.id])

    const _handleClick = () => {
        history.push(`/${path.MAIN}/${path.TRANSACTION}/${data?.id}`)
    }

    return (
        <Bound onClick={_handleClick} className={`transaction-${className}`}  >
            <div className="status">
                {
                    [REFUNDED, SUCCESS].includes(data?.status)?
                        <IcStatusTrue />
                        :
                        <IcStatusFalse />
                }
            </div>
            <div>{data?.merchantTrxCode ? `${data?.merchantTrxCode}` : DEFAULT_VAL}</div>
            <div>{data?.bankTrxCode ? `${data?.bankTrxCode}` : DEFAULT_VAL}</div>
            <div>{data?.facepayTrxCode ? `${data?.facepayTrxCode}` : DEFAULT_VAL}</div>
            <div className="avatar-user">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="avatar" />
                        :
                        <img
                            className="avatar"
                            src={data?.id ? apiTransactionPhoto(data.id) : ""}
                            alt="avatar"
                            onError={() => setIsErrImg(true)}
                        />
                }
            </div>
            <div>{data?.trxRequestTime ? convertDateTime(data.trxRequestTime, "DD/MM/YYYY") : DEFAULT_VAL}</div>
            <div>{data?.trxRequestTime ? convertDateTime(data.trxRequestTime, "HH:mm") : DEFAULT_VAL}</div>
            <div className="amount" title={data?.trxAmount ? numberFormatToThousand(data.trxAmount) : ""}>
                {data?.trxAmount ? numberFormatToThousand(data.trxAmount) : DEFAULT_VAL}
            </div>
            <div className="merchant">
                <div className="logo-merchant">
                    {
                        isErrMerchantLogo ?
                            <IcMerchantLogoDefault />
                            :
                            <img
                                className="logo"
                                src={ data.merchantId ? apiMerchantLogo(data.merchantId) : listMerchant[0].logoDefault }
                                alt="logo"
                                onError={() => setIsErrMerchantLogo(true)}
                            />
                    }
                </div>
            </div>
            <div className="merchant">
                <div className="logo-merchant">
                    {
                        isErrBankLogo ?
                            <IcMerchantLogoDefault />
                            :
                            <img
                                className="logo"
                                // src={ listBank.filter((bank,i)=>bank.key === data?.supplier)[0]?.logo || listBank[0].logoDefault }
                                src={ listBank.filter((bank,i)=>bank.key === data?.supplier)[0]?.logo || listBank[0].logoDefault }
                                alt="logo"
                                onError={() => setIsErrBankLogo(true)}
                            />
                    }
                </div>
            </div>
            <div className="refund">
                {
                    data?.status === REFUNDED
                    ? <span>Refunded</span>
                    : "-"
                }
            </div>
            <IcArrowRight className="icon-arrow" />
        </Bound>
    )
}

export default ItemTransaction
