import styled from 'styled-components'

// styles
import { WrapMerchantGenInfos } from '../../MerchantList/MerchantItem/index.styles';

const WrapGeneralInfos = styled(WrapMerchantGenInfos)`
    grid-template-columns: auto 2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr auto;
`;

export {
    WrapGeneralInfos
}