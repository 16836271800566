import React, { useState } from 'react'

// styles
import { WrapAccountItem } from './index.styles'

//component
import IcLockRed from '../../../../../../../assets/images/IcLockRed'
import IcUnlockBlue from '../../../../../../../assets/images/IcUnlockBlue'
import OptionAccountBank from './OptionAccountBank'

//help
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

const AccountItem = ({ data, refList, ...props}) => {

    // const { data, refList } = props      

    return (
        <WrapAccountItem {...props}>
            <div className="bold" title={data?.username || ""}>
                {data?.username || "-"}
            </div>
            <div className="normal" title={data?.fullname || ""}>
                {data?.fullname || "-"}
            </div>
            <div className="normal">{data?.createAt ? convertDateTime(data.createAt, "DD/MM/YYYY") : "-"}</div>
            <div className="normal">{data?.lastUpdateAt ? convertDateTime(data.lastUpdateAt, "DD/MM/YYYY") : "-"}</div>
            <div className="icon-lock">
                {
                    data?.isDisable === true
                        ? <IcLockRed />
                        : <IcUnlockBlue />
                }
            </div>
            <div className="wrap-tools">                
                <OptionAccountBank
                    data={data}
                    className="option"
                    refList={refList}
                />
            </div>           
        </WrapAccountItem>
    )
}

export default AccountItem
