import * as types from './types'

const initialState = {
    details: {}
}
const customerDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DETAIL_CUSTOMER: {
            return {
                ...state,
                ...action.payload
              }
        }             
        case types.GET_DETAIL_CUSTOMER_ERR: {
            return initialState
        }
        case types.CLEAR_CUSTOMER_DETAILS: {
            return initialState
        }    
        default:
            return state;
    }

}

export default customerDetailReducer