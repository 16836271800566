import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'


const apiGetTransactionHistory = `${host}/transaction/history`

export const getTransactionHistory = ({ biometricId, filterFrom, filterSkip, filterSize },isScroll, callbackSuccess) => async dispatch => {

    const condFilters = {
        biometricId,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        biometricId,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetTransactionHistory, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_TRANSACTION_HISTORY,
                payload: {                    
                    list: data.transactionList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll               
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_TRANSACTION_HISTORY_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_TRANSACTION_HISTORY_ERR))
    }
}

export const clearTransactionHistory = () => ({
    type: types.CLEAR_TRANSACTION_HISTORY
})

