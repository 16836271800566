import * as types from './types'

const initialState = {
    list: [],
    total: null,
    condFilters: {}
}
const customerHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CUSTOMER_HISTORY: {
            const { list, total, ...payload } = action.payload
            return {
                ...state,
                ...payload,
                total: total,
                list: action.payload.isScroll
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        }
        case types.CLEAR_CUSTOMER_HISTORY: {
            return initialState
        }
        default:
            return state;
    }

}

export default customerHistoryReducer