import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'


// styles
import { WrapSetting } from './index.styles'

//component
import ListPermission from './ListPermission'

// constant
import { ACCOUNT } from '../../../../../redux/permission/listPermission/listPermissionReducer'

const Permission = () => {

    const { activeTab } = useSelector(state => ({
        activeTab: state.settingReducer.listSettingReportReducer.activeTab
    }))

    const listData = useMemo(() => {
        const { type } = activeTab
        switch (type) {
            case ACCOUNT:
                return <ListPermission />
            default:
                return null
        }
    }, [activeTab])

    return (
        <WrapSetting>
            {listData}
        </WrapSetting>
    )
}

export default Permission
