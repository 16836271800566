import styled from 'styled-components'

// styles
import { WrapPopupInfosAccount } from '../../../../TabListAndTool/ToolAccount/PopupCreateAccount/index.styles';

const WrapPopupEditAccount = styled(WrapPopupInfosAccount)`
    
`;

export {
    WrapPopupEditAccount
}