import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetDetailCustomer = `${host}/customer/details`

export const getDetailCustomer = ({ id }, callbackSuccess) => async dispatch => {
   
    const dataRequest = JSON.stringify({ id });

    try {
        const res = await Axios.post(apiGetDetailCustomer, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_DETAIL_CUSTOMER,
                payload: {
                    details: {
                        ...data
                    }
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_DETAIL_CUSTOMER_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_CUSTOMER_ERR))
    }  
}

export const clearDetailCustomer = () => ({
    type: types.CLEAR_CUSTOMER_DETAILS
})
