import * as types from './types'

const initialState = {
    list: [],
    condFilters: {},
    total: 0,
}
const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_ACCOUNT_BANK:
        case types.GET_ACCOUNT_BANK_SEARCH_USERNAME:
        case types.GET_ACCOUNT_BANK_SEARCH_FULLNAME:
        case types.GET_ACCOUNT_BANK_SEARCH_INTERVAL:
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll 
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list,
                type: action.type
            }
        case types.RESET_LIST_ACCOUNT_BANK:
            return {...initialState, type: action.type}
        default:
            return state;
    }

}

export default accountReducer