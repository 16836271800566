import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// styles
import { WrapBankItem } from './index.styles'

//component
import OptionBank from './OptionBank'

//constant
import { BANK, MAIN } from '../../../../../../constant/path'

//image
import IcMerchantLogoDefault from '../../../../../../assets/images/IcMerchantLogoDefault'

//help
import { apiSupplierLogo } from '../../../../../../constant/host'
import { convertDateTime } from '../../../../../../helper/convertDateTime'
// import { getGeneralBankInfos } from '../../../../../../redux/bank/detailtsBank/generalInfos/action'


const BankItem = ({ data, extendStyles, refList }) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [isErrLogoBank, setIsErrLogoBank] = useState(false)

    useEffect(() => {
        setIsErrLogoBank(false)
    }, [data?.bankCode])



    const _goToDetails = () => {
        // dispatch(getGeneralBankInfos(data))
        history.push(`/${MAIN}/${BANK}/${data.id}`)
    }

    return (
        <WrapBankItem extendStyles={extendStyles} onClick={_goToDetails}>
            <div className="wrap-logo">
                {
                    isErrLogoBank ?
                        <IcMerchantLogoDefault className="logo" />
                        :
                        <img
                            className="logo"
                            src={data?.bankCode ? apiSupplierLogo(data.bankCode) : ""}
                            alt="logo"
                            onError={() => setIsErrLogoBank(true)}
                        />
                }
            </div>
            <div className="wrap-bank-infos bank-name">
                <div className="content-bold" title={data?.bankShortName || ""}>
                    {data?.bankShortName || "-"}
                </div>
                <div className="content-gray" title={data?.bankName || ""}>
                    {data?.bankName || "-"}
                </div>
            </div>
            <div className="wrap-bank-infos">
                <div className="content-bold">{data?.bankCode || "-"}</div>
                <div className="content-gray">Bankcode</div>
            </div>
            <div className="wrap-bank-infos">
                <div className="content-bold">{data?.userTotal || "0"}</div>
                <div className="content-gray">Tài khoản</div>
            </div>
            <div className="wrap-bank-infos">
                <div className="content-bold">{data?.createAt ? convertDateTime(data.createAt, "DD/MM/YYYY") : "-"}</div>
                <div className="content-gray">Ngày tạo</div>
            </div>
            <div className="wrap-bank-infos">
                <div className="content-bold">{data?.lastUpdateAt ? convertDateTime(data.lastUpdateAt, "DD/MM/YYYY") : "-"}</div>
                <div className="content-gray">Ngày update</div>
            </div>
            <div className="wrap-tools">
                <OptionBank bankData={data} refList={refList}/>
            </div>
        </WrapBankItem>
    )
}

export default BankItem
