import React from 'react'

import IcMap from '../../../../../../../../../assets/images/IcMap'
import IcMapActive from '../../../../../../../../../assets/images/IcMapActive'
import Input from '../../../../../../../../Control/Input'

const iconMap = {
    icon: IcMap,
    iconActive: IcMapActive,
    iconError: IcMapActive,
}

const InputLat = (valueInput) => {
    return (
        <Input
            name="lat"
            className="input-store"
            placeholder="Nhập Latitude"
            inputType="TEXT"
            value={valueInput.valueInput}
            onChange={valueInput.onChange}
            iconInput={iconMap}
        />
    )
}

export default InputLat
