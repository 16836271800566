import React, { useRef, useState } from 'react'

// hooks
import { useOnClickOutside } from '../../../hook/useClickOutside';

// component
import DropdownList from '../DropdownList';
import IcArrowDownGray from '../../../assets/images/IcArrowDownGray';

// styles
import { WrapDropdownControl } from './index.styles'

const DropdownControl = ({ 
    dataSelection, 
    valueSelected: { text, key }, 
    handleSetValueSelect 
}) => {

    const refTitleDropdown = useRef(null)
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    const _handleSetValueSelect = (val) => {
        handleSetValueSelect(val)
        setIsShowDropdown(false)
    }

    useOnClickOutside(refTitleDropdown, () => setIsShowDropdown(false))

    return (
        <WrapDropdownControl ref={refTitleDropdown}>
            <div className="wrap-title-dropdown" onClick={() => setIsShowDropdown(pre => !pre)}>
                <span className="value">{text}</span>
                <IcArrowDownGray className="arrow-down" />
            </div>
            {
                isShowDropdown &&
                <DropdownList 
                    valueSelected={{ text, key }}
                    listItem={dataSelection}
                    setValueSelect={_handleSetValueSelect}
                    isShowCheckStatus={true}
                />
            }
        </WrapDropdownControl>
    )
}

export default DropdownControl
