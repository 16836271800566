import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'

// action
import { getListStoreMerchant } from '../../../../../../../../../redux/merchant/detailsMerchant/store/action'
import { getListDevicesByAdmin, editDevice } from '../../../../../../../../../redux/device/listDevicesByAdmin/action'

// component
import Button from '../../../../../../../../Control/Button'
import InputDeviceName from '../../../../../../BreadcrumbAndTool/CreateNewDevice/InputDeviceName'
import InputMerchant from '../../../../../../BreadcrumbAndTool/CreateNewDevice/InputMerchant'
import InputStore from '../../../../../../BreadcrumbAndTool/CreateNewDevice/InputStore'
import IcEditWhite from '../../../../../../../../../assets/images/IcEditWhite'
import IcClose from '../../../../../../../../../assets/images/IcClose'

// styles
import { WrapPopupEditDevice } from './index.styles'
import { OverlayFullScreen } from '../../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

const PopupEditDevice = ({ data, handleClose }) => {

    const dispatch = useDispatch()
    
    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(false);

    const [formData, setFormData] = useReducer(
        (state, action) => ({...state, ...action}),
        {
            name: '',
            merchant: null,
            store: null
        }
    );

    const _handleSelectMerchant = (merchant) => {
        const { key } = merchant || {}
        if(!key) return;
        if(key === formData.merchant?.key) return;
        
        setFormData({ 
            merchant,
            store: null
        })
        
        dispatch(getListStoreMerchant({
            merchantId: key, 
            filterFrom: "", 
            filterSkip: 0, 
            filterSize: 20 
        }))
    }

    const callbackSuccess = () => {
        dispatch(getListDevicesByAdmin({
            filterFrom: "", 
            filterSkip: 0, 
            filterSize: 20
        }))
        handleClose()
    }

    const _handleSubmit = () => {
        const { id } = data
        const { 
            name, 
            merchant: { key: merchantId },
            store: { key: storeId }
        } = formData
        dispatch(editDevice({
            id,
            name,
            merchantId,
            storeId
        }, callbackSuccess))
    }

    useEffect(() => {
        const { 
            merchantId, 
            merchantName, 
            merchantStoreName, 
            storeId,
            name
        } = data
        const formData = {
            name,
            merchant: {
                key: merchantId,
                text: merchantName
            },
            store: {
                key: storeId,
                text: merchantStoreName
            }
        }
        dispatch(getListStoreMerchant({
            merchantId: merchantId, 
            filterFrom: "", 
            filterSkip: 0, 
            filterSize: 20 
        }))
        setFormData(formData)
    }, [JSON.stringify(data)]);

    useEffect(() => {
        if(Object.values(formData).some(item => !item)) {
            setIsDisabledBtnCreate(true)
            return;
        }
        setIsDisabledBtnCreate(false)
        return;
    }, [JSON.stringify(formData)]);

    return (
        <OverlayFullScreen>
            <div className="wrap-inner-screen">
                <WrapPopupEditDevice>
                    <IcClose className="ic-close" onClick={handleClose} />
                    <div className="title">Thông tin thiết bị</div>
                    <div className="sub-title">Bố sung thông tin thiết bị</div>
                    <InputDeviceName
                        placeholder="Tên thiết bị"
                        valueInput={formData.name}
                        onChange={(e) => setFormData({ name: e.target.value })}
                        className="device-name"
                    />
                    <InputMerchant 
                        merchant={formData.merchant}
                        handleGetValue={_handleSelectMerchant}
                    />
                    <InputStore
                        handleGetValue={(store) => setFormData({ store })}
                        store={formData.store}
                        merchant={formData.merchant}
                    />
                    <Button
                        text="Chỉnh sửa"
                        iconBtn={<IcEditWhite />}
                        className="btn-submit"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapPopupEditDevice>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupEditDevice
