import styled from 'styled-components'

const WrapContent = styled.div`
    display: flex;
    flex-direction: column;

    .page-content {
        flex-grow: 1;
    }
`;

export {
    WrapContent
}