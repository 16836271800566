import React from 'react'

//component
import MoreOption from '../../../../../../Control/MoreOption'
import IcUnlockBlue from '../../../../../../../assets/images/IcUnlockBlue'
import IcLockRed from '../../../../../../../assets/images/IcLockRed'
import OptionAccount from './OptionAccount'

//styles
import { WrapAccountItem } from './index.styles'

// helper
import { convertDateTime } from '../../../../../../../helper/convertDateTime'

const AccountItem = ({ data, refList, ...props}) => {
    const { username, fullname, createAt, lastUpdateAt, isDisable } = data

    return (
        <WrapAccountItem {...props}>
            <div className="bold" title={username ? `${username}` : ""}>{username}</div>
            <div className="normal" title={fullname ? `${fullname}` : ""}>{fullname}</div>
            <div className="normal" title={createAt ? convertDateTime(createAt, "DD/MM/YYYY") : ""}>
                {convertDateTime(createAt, "DD/MM/YYYY")}
            </div>
            <div className="normal" title={lastUpdateAt ? convertDateTime(lastUpdateAt, "DD/MM/YYYY") : ""}>
                {convertDateTime(lastUpdateAt, "DD/MM/YYYY")}
            </div>
            {
                isDisable 
                ? <IcLockRed />
                : <IcUnlockBlue />
            }
            <div>
                <OptionAccount 
                    data={data}
                    className="option"
                    refList={refList}
                />
            </div>
        </WrapAccountItem>
    )
}

export default AccountItem
