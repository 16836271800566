import styled, { css } from 'styled-components'

// styles
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapBankDetails = styled(WrapPageItem)`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 0 30px;
    
    .wrap-head {
        background: #FFFFFF;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 0 30px;
    }
`;

const extendStylesBankItem = css`
    padding: 0;
    box-shadow: unset;

    &:hover {
        border: unset;
    }
`

export {
    WrapBankDetails,
    extendStylesBankItem
}