import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetDetailsDeviceByAdmin = `${host}/terminal/details `

export const getDetailsDeviceByAdmin = ({ deviceId }) => async dispatch => {
    const dataRequest = JSON.stringify({
        id: deviceId
    })

    try {
        const res = await Axios.post(apiGetDetailsDeviceByAdmin, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_DETAILS_DEVICE_BY_ADMIN,
                payload: {
                    details: data.details
                }
            })
        }
        else {
            dispatch({
                type: types.GET_DETAILS_DEVICE_BY_ADMIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_DETAILS_DEVICE_BY_ADMIN_ERR))
    }
}

export const resetDetailsDeviceByAdmin = () => ({
    type: types.RESET_DETAILS_DEVICE
})