import React from 'react'

// styles
import { WrapInputOther } from './index.styles'

const InputOther = (props) => {
    return (
        <WrapInputOther 
            placeholder="Nhập lý do lỗi..."
            {...props}
        />
    )
}

export default InputOther
