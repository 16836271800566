import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// action
import { getListStoreMerchant, 
    getListStoreSearchByCode, 
    getListStoreSearchByName, 
    resetListStoreMerchant } from '../../../../../../redux/merchant/detailsMerchant/store/action'
import { GET_LIST_STORE_MERCHANT, 
    GET_STORE_MERCHANT_SEARCH_CODE, 
    GET_STORE_MERCHANT_SEARCH_NAME } from '../../../../../../redux/merchant/detailsMerchant/store/types'

// hook
import useScrollEnd from '../../../../../../hook/useScrollEnd'

// styles
import { LabelCol, Row, RowLabelColumn, WrapListStore, WrapRow } from './index.styles'

// component
import StoreItem from './StoreItem'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'


const labelColumns = [
    "Mã cửa hàng",
    "Tên cửa hàng",
    "Địa điểm",
    "Location",
    "Thiết bị",
    "Ngày tạo",
    "Ngày update"
]

const ListStores = ({ merchantId }) => {
    const dispatch = useDispatch()
    const listRef = useRef(null)

    const { list, total, type, condFilters } = useSelector(state => ({
        list: state.merchantReducer.detailsMerchantReducer.store.list,
        total: state.merchantReducer.detailsMerchantReducer.store.total,
        type: state.merchantReducer.detailsMerchantReducer.store.type,
        condFilters: state.merchantReducer.detailsMerchantReducer.store.condFilters,
    }))

    useEffect(() => {
        dispatch(getListStoreMerchant({
            merchantId,
            filterFrom: '',
            filterSkip: 0,
            filterSize: 20
        }))
        return () => {
            dispatch(resetListStoreMerchant())
        }
    }, []);

    useScrollEnd(listRef, (callback) => {
        switch (type) {
            case GET_LIST_STORE_MERCHANT:
                dispatch(getListStoreMerchant({
                    merchantId,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_STORE_MERCHANT_SEARCH_CODE:
                dispatch(getListStoreSearchByCode({
                    merchantId,
                    code: condFilters.code,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            case GET_STORE_MERCHANT_SEARCH_NAME:
                dispatch(getListStoreSearchByName({
                    merchantId,
                    name: condFilters.name,
                    filterFrom: list[0]?.id,
                    filterSkip: list.length,
                    filterSize: 20
                }, true, callback))
                break;
            default:
                break;
        }
    }, [list, type, condFilters], [total])

    return (
        <WrapListStore>
            {
                total === 0
                    ? (
                        <div className="wrap-empty">
                            <EmptyDataControl />
                        </div>
                    )
                    : (
                        <>
                            <RowLabelColumn className="row">
                                {
                                    labelColumns.map((labelColumn) => (
                                        <LabelCol key={labelColumn}>{labelColumn}</LabelCol>
                                    ))
                                }
                            </RowLabelColumn>
                            <WrapRow ref={listRef}>
                                <div className="wrap-inner">
                                    {
                                        list.map((item, i) => (
                                            <StoreItem
                                                className="row"
                                                dataItem={item}
                                                key={i}
                                                refList={listRef}
                                            />
                                        ))
                                    }
                                </div>
                            </WrapRow>
                        </>
                    )
            }            
        </WrapListStore>
    )
}

export default ListStores
