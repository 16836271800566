import styled from 'styled-components'

const WrapPageItem = styled.div`
    padding: 0 30px;
    width: calc(1280px - 100px);
    box-sizing: border-box;
`

const WrapTransactionList = styled(WrapPageItem)`
    height: 100%;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 30px; */
`

const TableTransactionList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .wrap-empty {
        flex-grow: 1;
        height: 100%;
        display: flex;
        align-items: center;
    }
`

const TableHeaderTransaction = styled.div`    
    display: grid;
    grid-template-columns: 40px 90px 80px 82px 70px 100px 78px 85px 79px 76px 75px 14px;
    grid-gap: 20px; 
    padding: 0 12px 0 18px;
    box-sizing: border-box;
    width: 100%;
    .table-header {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #313131;
    }
`

const TableContentTransaction = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;    
   
    .wrap-list-trans {
        width: 100%;
        flex-grow: 1;
        overflow: auto;
        margin-top: 11px;
        position: relative;
        .list-trans {
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
        }
    }
    
`

export { WrapPageItem, WrapTransactionList, TableTransactionList, TableHeaderTransaction, TableContentTransaction }