import React from 'react'

// component
import HeaderContent from './HeaderContent'
import BreadcrumbAndTool from './BreadcrumbAndTool'
import Page from './Page'

// styles
import { WrapContent } from './index.styles'

const Content = () => {
    return (
        <WrapContent>
            <HeaderContent />
            <BreadcrumbAndTool />
            <Page className="page-content" />
        </WrapContent>
    )
}

export default Content
