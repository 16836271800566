import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// component
import Breadcrumb from '../Breadcrumb';
import TabListAndTool from './TabListAndTool';

// data
// import { listTabs } from '../../../../../redux/setting/listSetting/listSettingReportReducer';

// action
import { setActiveTab } from '../../../../../redux/setting/listSetting/action';

const SettingBread = ({ setNotHaveBoxShadow }) => {
    const dispatch = useDispatch()

    const { activeTab } = useSelector(state => ({
        activeTab: state.settingReducer.listSettingReportReducer.activeTab
    }))

    const _setActiveTab = (activeTab) => dispatch(setActiveTab(activeTab))

    useEffect(() => {
        setNotHaveBoxShadow(true)
        return () => {
            setNotHaveBoxShadow(false)
        }
    }, []);

    return (
        <>
            <div className="wrap-inner-breadcrumb">
                <Breadcrumb arrPath={[{ value: "Permission" }]} />
            </div>
            <div className="bread-row">
                <TabListAndTool
                    activeTab={activeTab}
                    setActiveTab={_setActiveTab}
                />
            </div>
        </>
    )
}

export default SettingBread