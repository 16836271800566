import React, { useState } from 'react'

//component
import IcEdit from '../../../../../../../assets/images/IcEdit'
import IcDelete from '../../../../../../../assets/images/IcDetete'
import MoreOption from '../../../../../../Control/MoreOption'
import PopupDelMerchant from './PopupDelMerchant'
import Portal from '../../../../../../Control/Portal'
import PopupCannotDelMerchant from './PopupCannotDelMerchant'
import PopupEditMerchant from './PopupEditMerchant'

const EDIT = 'EDIT'
const DEL = 'DEL'
const ALERT_CANNOT_DEL = 'ALERT_CANNOT_DEL'

const OptionMerchant = ({ merchantData, isDetails, refList }) => {

    const [keyShowForm, setKeyShowForm] = useState('');

    const handleEdit = (e) => {
        setKeyShowForm(EDIT)
    }

    const handleDel = () => {
        setKeyShowForm(DEL)
    }

    const handleClose = (e) => {
        e.stopPropagation()
        setKeyShowForm('')
    }

    const dataMoreOption = [
        {
            Icon: IcEdit,
            text: "Sửa thông tin",
            handleOption: handleEdit
        },
        {
            Icon: IcDelete,
            text: "Xóa Merchant",
            handleOption: handleDel
        },
    ]

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
            />
            {
                keyShowForm === DEL &&
                <Portal>
                    <PopupDelMerchant
                        merchantData={merchantData}
                        handleClose={handleClose}
                        setKeyShowForm={setKeyShowForm}
                        isDetails={isDetails}
                    />
                </Portal>
            }
            {
                keyShowForm === ALERT_CANNOT_DEL &&
                <Portal>
                    <PopupCannotDelMerchant
                        handleClose={handleClose}
                    />
                </Portal>
            }
            {
                keyShowForm === EDIT &&
                <Portal>
                    <PopupEditMerchant
                        merchantData={merchantData}
                        onClickIconClose={handleClose}
                        setKeyShowForm={setKeyShowForm}
                        isDetails={isDetails}
                    />
                </Portal>
            }
        </>
    )
}

export default OptionMerchant
