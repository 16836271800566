import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

// styles
import { WrapHeader } from './index.styles'

// images
import LogoFacepay from '../../../assets/images/LogoFacepay'
import IcLogOut from '../../../assets/images/IcLogOut'
import IcLogOutBlue from '../../../assets/images/IcLogOutBlue'
import IcLogOutWhite from '../../../assets/images/IcLogOutWhite'
import IcCloseOutlineWhite from '../../../assets/images/IcCloseOutlineWhite'

//hook
import { useOnClickOutside } from '../../../hook/useClickOutside'

//action
import { logoutAction } from '../../../redux/login/action'

// constant
import { DEVICE, MAIN } from '../../../constant/path'
import IcUserHeader from '../../../assets/images/IcUserHeader'
import PopupChangePass from './PopupChangePass'
import { env, WEE_ENV } from '../../../constant/environmentTypes';
import Portal from '../../Control/Portal'
import IcLogoutBlack from '../../../assets/images/IcLogoutBlack'

const Header = (props) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const refDropdown = useRef(null)

    const [isShow, setIsShow] = useState(false)
    const [isShowPopupChangePass, setIsShowPopupChangePass] = useState(false)

    const { userName, totalDisconnect } = useSelector(state => ({
        userName: state.loginReducer.dataLogin.fullname,
        totalDisconnect: state.deviceReducer.listDevicesByAdmin.totalDisconnect
    }))

    useOnClickOutside(refDropdown, () => {
        setIsShow(false)
    })

    const _handleLogOut = () => {
        dispatch(logoutAction(history, true))
    }

    const matchDeviceAdmin = useRouteMatch(`/${MAIN}/${DEVICE}`)

    return (
        <WrapHeader {...props} ref={refDropdown} isWarning={!!matchDeviceAdmin && !!totalDisconnect}>
            <div className='wrap-header'>
                <Link to={`/${MAIN}`} className="logo-facepay">
                    <LogoFacepay />
                </Link>
                {
                    !!matchDeviceAdmin &&
                    <div className="device-disconnected">
                        <IcCloseOutlineWhite />
                        <span>{totalDisconnect} thiết bị hiện đang mất kết nối</span>
                    </div>
                }
                <div className="user">
                    <p className="user_name">{userName}</p>
                    {
                        env !== WEE_ENV &&
                        <IcUserHeader className="ic-user" onClick={() => setIsShowPopupChangePass(true)} />
                    }
                    {/* {
                        isShowPopupChangePass &&
                        <Portal>
                            <PopupChangePass
                                setIsShowPopupChangePass={setIsShowPopupChangePass}
                                userName={userName}
                            />
                        </Portal>
                    } */}
                    <IcLogoutBlack className="ic-logout" onClick={() => setIsShow(!isShow)} />
                    {
                        isShow &&
                        <div className="popup-logout">
                            <p className="tit-popup">Đăng xuất</p>
                            <p className="des-popup">Bạn có muốn đăng xuất tài khoản khỏi ứng dụng?</p>
                            <div className="button" onClick={_handleLogOut}>
                                <IcLogOutWhite />
                                <p className="text-popup">Đăng xuất</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </WrapHeader>
    )
}

export default Header
