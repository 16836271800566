import styled from 'styled-components'

const WrapDropdownSelectTypeChart = styled.div`
    position: relative;
    margin-left: 6px;
    z-index: 2;

    .wrap-title {

        display: flex;
        align-items: center;
        .value {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #313131;
        }
        .icon {
            margin-left: 6px;
        }

        &:hover {
            cursor: pointer;
        }
    }
`;

export {
    WrapDropdownSelectTypeChart
}