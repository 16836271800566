import styled from 'styled-components'

const WrapBreadcrumbAndTool = styled.div`
    min-height: 64px;
    background: #FFFFFF;
    box-shadow: ${props => props.notHaveBoxShadow ? "initial" : "inset 0px -0.5px 0px rgba(0, 0, 0, 0.15)"};
    
    .wrap-breadcrumb-and-tool{
        width: calc(1280px - 100px);
        box-sizing: border-box;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        padding: 0 30px;
        .list-button{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .wrap-right-content{
            display: flex;
            flex-direction: row;
            .btn-creteNew{
                margin-left: 25px;
            }
            .wrap-id-trans {
                display: flex;
                align-items: center;
                font-family: "Open Sans";
                font-style: normal;
                color: #313131;
                letter-spacing: -0.01em;
                min-width: 0;
                &:first-of-type {
                    margin-left: 10px;
                }
                &:not(:first-of-type) {
                    margin-left: 24px;
                }

                .id-name {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    white-space: nowrap;
                }

                .id-value {
                    margin-left: 8px;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    /* display: inline-block; */
                    max-width: 24ch;
                }
            }
        }

        .wrap-inner-breadcrumb {
            padding: 19px 0 18px;
        }

        .bread-row {
            width: 100%;
            padding-top: 10px;
        }
    }
`;

export {
    WrapBreadcrumbAndTool
}