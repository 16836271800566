import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { LabelCol, Row, RowLabelColumn, WrapHeader, WrapListAccount, WrapRow } from './index.styles'

// component
import PermissionItem from './PermissionItem'
import EmptyDataControl from '../../../../../Control/EmptyDataControl'
import PageHeader from '../../PageHeader'
import Portal from '../../../../../Control/Portal'
import CreatNewAccountPermission from './CreatNewAccountPermission'
import IcSettingDefault from '../../../../../../assets/images/IcSettingDefault'

//image
import IcAddBlue from '../../../../../../assets/images/IcAddBlue'

//action
import useScrollEnd from '../../../../../../hook/useScrollEnd'
import { getListPermission, resetListPermission } from '../../../../../../redux/permission/listPermission/action'
import IcHelp from '../../../../../../assets/images/IcHelp'

// const labelColumns = [
//     "ID",
//     "Fullname",
//     "Email Address",
//     "Report Type",
//     "Created Date",
//     "Updated Date",
//     "Status"
// ]

const ListPermission = () => {

    const dispatch = useDispatch()
    const listRef = useRef()

    const [isShowModalAddNew, setIsShowModalAddNew] = useState(false)

    const _handleClickBtn = () => {
        setIsShowModalAddNew(pre => !pre)
    }

    const { listPermission, total } = useSelector(state => ({
        listPermission: state.permissionReducer.listPermissionReducer.list,
        total: state.permissionReducer.listPermissionReducer.total
    }))

    useEffect(() => {
        dispatch(getListPermission({
            filterFrom: "",
            filterSkip: 0,
            filterSize: 20
        }))

        return () => {
            dispatch(resetListPermission())
        }
    }, []);

    useScrollEnd(listRef, (callback) => {

        dispatch(getListPermission({
            filterFrom: listPermission[0]?.id,
            filterSkip: listPermission.length,
            filterSize: 20,
        }, true, callback))

    }, [listPermission], [total])

    return (
        <WrapListAccount>
            <WrapHeader>
                <div className="page-header">
                    <PageHeader>Configured Account List ({total ? total : 0})</PageHeader>
                    <p className="des">Automatically send reports to configured email at 0:00 AM every day</p>
                </div>
                <div className="button" onClick={_handleClickBtn}>
                    <IcAddBlue className="icon" />
                    <p className="text">Add New</p>
                </div>
            </WrapHeader>
            {
                total === 0
                    ? (
                        <div className="wrap-empty">
                            <EmptyDataControl
                                icon={<IcSettingDefault />}
                                text={"No Configured Account on List "}
                            />
                        </div>
                    )
                    : (
                        <>
                            <RowLabelColumn className="row">
                                {/* {
                                    labelColumns.map((labelColumn) => (
                                        <LabelCol key={labelColumn}>{labelColumn}</LabelCol>
                                    ))
                                } */}
                                <div>ID</div>
                                <div>Fullname</div>
                                <div>Email Address</div>
                                <div>Report Type</div>
                                <div>Created Date</div>
                                <div>Updated Date</div>
                                <div className="status">
                                    Status
                                    <IcHelp className="icon" />
                                    <div className="tooltip" >The account is activated allowing login to the system</div>
                                </div>
                            </RowLabelColumn>
                            <WrapRow>
                                <div className="wrap-list" ref={listRef}>
                                    <div className="wrap-inner">
                                        {
                                            listPermission.map((item, i) => (
                                                <PermissionItem data={item} key={item.id} stt={i} className="row" refList={listRef} />
                                            ))
                                        }
                                    </div>
                                </div>
                            </WrapRow>
                        </>
                    )
            }
            {
                isShowModalAddNew &&
                <Portal>
                    <CreatNewAccountPermission setIsShowModalAddNew={setIsShowModalAddNew} onClickIconClose={_handleClickBtn} />
                </Portal>
            }
        </WrapListAccount>
    )
}

export default ListPermission
