import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

// styles
import { WrapPage } from './index.styles'

// data
import dataRoutes from '../../../../data/dataRoutes'

// constant
import { env } from '../../../../constant/environmentTypes'
import * as path from '../../../../constant/path'

const Page = (props) => {
    return (
        <WrapPage {...props}>
            <Switch>
                {
                    dataRoutes.map(({ Component, envValid, ...props}) => (
                        envValid.includes(env) 
                        ? (
                            <Route {...props} key={props.path}>
                                <Component />
                            </Route>
                        )
                        : null
                    ))
                }
                <Redirect to={`/${path.MAIN}/${path.ANALYTIC}`} />
            </Switch>
        </WrapPage>
    )
}

export default Page
