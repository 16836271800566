import React, { useState, useRef, useCallback, useEffect } from 'react'

const DragAndDrop = ({ children, handleDropFiles, extendsClass }) => {
    const [drag, setDrag] = useState(false)

    const dropRef = useRef(null)
    const dragCounter = useRef(0);

    const _handleDrag = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const _handleDragIn = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDrag(true)
        }
    }, [])

    const _handleDragOut = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current--
        if (dragCounter.current === 0) {
            setDrag(false)
        }
    }, [])

    const _handleDrop = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        setDrag(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleDropFiles(e.dataTransfer.files)
            e.dataTransfer.clearData()
            dragCounter.current = 0
        }
    }, [])
    
    useEffect(() => {
        const div = dropRef.current
        div.addEventListener('dragenter', _handleDragIn)
        div.addEventListener('dragleave', _handleDragOut)
        div.addEventListener('dragover', _handleDrag)
        div.addEventListener('drop', _handleDrop)
        return () => {
            div.removeEventListener('dragenter', _handleDragIn)
            div.removeEventListener('dragleave', _handleDragOut)
            div.removeEventListener('dragover', _handleDrag)
            div.removeEventListener('drop', _handleDrop)
        }
    }, [dropRef])


    return (
        <div 
            className={extendsClass}
            ref={dropRef} 
            style={{ display: 'inline-block', position: 'relative' }}
        >
            {children}
        </div>
    )
}
export default DragAndDrop