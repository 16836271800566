import React from 'react'

const IcEdit = ({...props}) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.3335 2.66699H2.66683C2.31321 2.66699 1.97407 2.80747 1.72402 3.05752C1.47397 3.30756 1.3335 3.6467 1.3335 4.00033V13.3337C1.3335 13.6873 1.47397 14.0264 1.72402 14.2765C1.97407 14.5265 2.31321 14.667 2.66683 14.667H12.0002C12.3538 14.667 12.6929 14.5265 12.943 14.2765C13.193 14.0264 13.3335 13.6873 13.3335 13.3337V8.66699" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.3335 1.66714C12.5987 1.40193 12.9584 1.25293 13.3335 1.25293C13.7086 1.25293 14.0683 1.40193 14.3335 1.66714C14.5987 1.93236 14.7477 2.29207 14.7477 2.66714C14.7477 3.04222 14.5987 3.40193 14.3335 3.66714L8.00016 10.0005L5.3335 10.6671L6.00016 8.00048L12.3335 1.66714Z" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcEdit
