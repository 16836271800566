import React, { useState, useRef, useEffect } from 'react'
import QRCodePkg from 'qrcode'
import moment from 'moment'

// component
import IcClose from '../../../../../../assets/images/IcClose'

// styles
import { OverlayFullScreen } from '../../../Page/TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'
import { WrapQRCode } from './index.styles'

const DEFAULT_TIMING = "--:--"

const QRCode = ({ tokenInfos, handleClose, handleRetry }) => {
    const canvasRef = useRef(null)

    const [isExpired, setIsExpired] = useState(false);
    const [timing, setTiming] = useState(DEFAULT_TIMING);

    const _handleRetry = () => {
        // setIsExpired(false)
        handleRetry()
    }

    const genTiming = (expDate) => {
        const millisecTimeout = moment(expDate).diff(moment())
        if(millisecTimeout <= 0) {
            setIsExpired(true)
            return;
        }
        isExpired && setIsExpired(false)
        const duration = moment.duration(millisecTimeout)
        const minDur = Math.floor(duration.as('minutes'))
        const secDur = duration.get('seconds')
        const timing = `${minDur > 9 ? minDur : ('0' + minDur) }:${secDur > 9 ? secDur : ('0' + secDur)}`
        setTiming(timing)
    }

    useEffect(() => {
        if(!tokenInfos) return;
        const { token } = tokenInfos
        QRCodePkg.toCanvas(canvasRef.current, token, { margin: 2, width: 256 }, function (error) {
            if (error){ 
                // console.error(error)
                return;
            }
            // console.log('success!');
        })
    }, [JSON.stringify(tokenInfos)]);

    useEffect(() => {
        if(!tokenInfos) return;
        const { expiredDate } = tokenInfos
        genTiming(expiredDate)
    }, [JSON.stringify(tokenInfos)]);

    useEffect(() => {
        if(DEFAULT_TIMING === timing) return;
        const timer = setTimeout(() => {
            const { expiredDate } = tokenInfos
            genTiming(expiredDate)
        }, 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [timing]);

    return (
        <OverlayFullScreen notUseOverlay={true}>
            <div className="wrap-inner-screen">
                <WrapQRCode>
                    <IcClose className="ic-close" onClick={handleClose} />
                    <div className="title">Kết nối thiết bị</div>
                    <div className="sub-title">Quét mã nhận dạng thiết bị</div>
                    <div className="wrap-qr-image">
                        <canvas ref={canvasRef} width="256" height="256" />
                        {isExpired && <div className="overlay" />}
                    </div>
                    <div className="time-remaining">
                        {
                            isExpired
                            ? (
                                <>
                                    <div>Mã thiết bị hết hiệu lực</div>
                                    <div className="btn-retry" onClick={_handleRetry}>Thử lại</div>
                                </>
                            )
                            : (
                                <>
                                    <div>Thời gian hiệu lực:</div>
                                    <div className="time">{timing}</div>
                                </>
                            )
                        }
                    </div>
                </WrapQRCode>
            </div>
        </OverlayFullScreen>
    )
}

export default QRCode
