import React from 'react'

const IcUnlockBlue = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6667 7.33301H3.33333C2.59695 7.33301 2 7.92996 2 8.66634V13.333C2 14.0694 2.59695 14.6663 3.33333 14.6663H12.6667C13.403 14.6663 14 14.0694 14 13.333V8.66634C14 7.92996 13.403 7.33301 12.6667 7.33301Z" fill="#33ADFF" />
            <path d="M4.66699 7.33344V4.66677C4.66616 3.84013 4.97251 3.04268 5.52658 2.42921C6.08064 1.81575 6.84288 1.43004 7.66533 1.34696C8.48778 1.26389 9.31176 1.48937 9.97731 1.97964C10.6429 2.46992 11.1025 3.19 11.267 4.0001" stroke="#33ADFF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcUnlockBlue
