import styled from 'styled-components'

// styles
import { WrapPopupInfosAccount } from '../../../../../MerchantDetails/TabListAndTool/ToolAccount/PopupCreateAccount/index.styles';
import { WrapCreateNewAccountBank } from '../../../../TabListAndTool/ToolAccount/CreateNewAccountBank/index.styles';

const WrapPopupEditAccount = styled(WrapCreateNewAccountBank)`
    
`;

export {
    WrapPopupEditAccount
}