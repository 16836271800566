import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapCustomerDetails } from './index.styles'

// component
import PageHeader from '../PageHeader'
import CustomerItem from './CustomerItem'

//action
import { clearDetailCustomer, getDetailCustomer } from '../../../../../redux/customer/customerDetail/action'

const CustomerDetails = () => {

    const dispatch = useDispatch()

    const { custId } = useParams() 

    const { customerDetail } = useSelector(state => ({
        customerDetail: state.customerReducer.customerDetailReducer.details
    })) 

    useEffect(() => {
        dispatch(getDetailCustomer({ id: custId }))
        return () => {
            dispatch(clearDetailCustomer())
        }
    }, [])

    return (
        <WrapCustomerDetails>
            <PageHeader>Thông tin hồ sơ</PageHeader>
            <CustomerItem data={customerDetail} isExpandByDefault/>
        </WrapCustomerDetails>
    )
}

export default CustomerDetails
