import Axios from 'axios'

//host
import { host } from '../../../constant/host'

//help
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction';
import mapErrors from '../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListMerchant = `${host}/merchant/chain/filter`
const apiGetListCategories = `${host}/merchant/chain/listCategory`
const apiCreateNewMerchant = `${host}/merchant/chain/create`
const apiUpdateMerchant = `${host}/merchant/chain/update`
const apiDeleteMerchant = `${host}/merchant/chain/delete`

export const getListMerchant = ({ filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        searchValue: null,
        keyFilter: '',
        filterFrom,
        filterSkip,
        filterSize
    }

    const dataRequest = JSON.stringify({
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetListMerchant, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_MERCHANT,
                payload: {
                    list: data.merchantList || [],
                    condFilters,
                    total: data.total || 0,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.GET_LIST_MERCHANT_ERR))
    }
}

export const getListCategories = () => async dispatch => {
    try {
        const res = await Axios.post(apiGetListCategories)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            const listCategories = (data.listCategory || [])
                .map((({ code, name }) => ({ key: code, text: name })))
                .reduce((obj, curr) => ({ ...obj, [curr.key]: curr }),{}) 
            dispatch({
                type: types.GET_LIST_CATEGORIES,
                payload: {
                    listCategories
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_CATEGORIES_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_CATEGORIES_ERR))
    }
}

export const createNewMerchant = ({logo, name, category, address, email, phoneNumber}, callbackSuccess, callbackError) => async dispatch =>{
    const dataRequest = JSON.stringify({
        logo,
        name,
        category,
        address,
        email,
        phoneNumber,
    })
    try{
        const res = await Axios.post(apiCreateNewMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.CREATE_NEW_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.CREATE_NEW_MERCHANT_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.CREATE_NEW_MERCHANT_ERR))
    }
}

export const updateMerchant = ({id, logo, name, category, address, email, phoneNumber}, callbackSuccess, callbackError) => async dispatch =>{
    const dataRequest = JSON.stringify({
        id,
        logo,
        name,
        category,
        address,
        email,
        phoneNumber,
    })
    try{
        const res = await Axios.post(apiUpdateMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)){
            dispatch({
                type: types.UPDATE_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.UPDATE_MERCHANT_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_MERCHANT_ERR))
    }
}

export const deleteMerchant = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    try {
        const res = await Axios.delete(`${apiDeleteMerchant}?id=${id}`)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.DELETE_MERCHANT,
                payload: data
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.DELETE_MERCHANT_ERR,
                payload: data
            })
            callbackError && callbackError()
        }
    } catch (error) {
        dispatch(mapErrors(error, types.DELETE_MERCHANT_ERR))
    }
}

export const resetListMerchant = (callbackSuccess) => async dispatch => {
    dispatch({
        type: types.RESET_LIST_MERCHANT,
    })
    callbackSuccess && callbackSuccess()
}

