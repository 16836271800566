import * as types from './types'

const initialState = {
    list: [],
    condFilters: {},
    total: 0,
}
const store = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_STORE_MERCHANT:
        case types.GET_STORE_MERCHANT_SEARCH_CODE:
        case types.GET_STORE_MERCHANT_SEARCH_NAME:
            return {
                ...state,
                ...action.payload,
                type: action.type,
                list: action.payload.isScroll
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        case types.RESET_LIST_STORE_MERCHANT:
            return { ...initialState }
        default:
            return state;
    }

}

export default store