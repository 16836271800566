import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPopupEditMerchant } from './index.styles'
import { OverlayFullScreen } from '../../../../TransactionHistory/TransHistoryItem/PopupShowMedia/index.styles'

//help
import fileToBase64 from '../../../../../../../../helper/fileToBase64'

//component
import DragAndDropImage from '../../../../../../../Control/DragAndDropImage'
import IcClose from '../../../../../../../../assets/images/IcClose'
import InputName from '../../../../../BreadcrumbAndTool/CreateNewMerchant/InputName'
import InputDropdownStore from '../../../../../BreadcrumbAndTool/CreateNewMerchant/InputDropdownStore'
import InputLocation from '../../../../../BreadcrumbAndTool/CreateNewMerchant/InputLocation'
import InputEmail from '../../../../../BreadcrumbAndTool/CreateNewMerchant/InputEmail'
import InputPhone from '../../../../../BreadcrumbAndTool/CreateNewMerchant/InputPhone'
import Button from '../../../../../../../Control/Button'
import IcEditWhite from '../../../../../../../../assets/images/IcEditWhite'
import { useOnClickOutside } from '../../../../../../../../hook/useClickOutside'

//action
import { getListMerchant, updateMerchant } from '../../../../../../../../redux/merchant/listMerchant/action'
import { getDetailsMerchant } from '../../../../../../../../redux/merchant/detailsMerchant/generalInfos/action'

// api
import { apiMerchantLogo } from '../../../../../../../../constant/host'



const PopupEditMerchant = ({ onClickIconClose, merchantData, setKeyShowForm, isDetails = false }) => {
    const dispatch = useDispatch()
    const refEditMerchant = useRef(null)

    const { condFilters } = useSelector(state => ({
        condFilters: state.merchantReducer.listMerchantReducer.condFilters
    }))

    const [merchantImg, setMerchantImg] = useState(null);
    const [isDisabledBtnCreate, setIsDisabledBtnCreate] = useState(true);
    const [stateForm, setStateForm] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            id: merchantData.id,
            name: merchantData.name,
            category: merchantData.category,
            address: merchantData.address,
            email: merchantData.email,
            phoneNumber: merchantData.phoneNumber,
        }
    )

    useEffect(() => {
        const { name, category, address, email, phoneNumber } = stateForm
        const isEnable = name && category && address && email && phoneNumber
        setIsDisabledBtnCreate(!isEnable)
        return () => {
        }
    }, [stateForm, merchantImg])

    const handleChangeValue = (event) => {
        const { name, value } = event.target;
        setStateForm({
            [name]: value
        })
    }

    const _handleSetValueDropdown = (val) => {
        setStateForm({
            category: val.key,
        })
    }

    const callbackSuccess = () => {
        setKeyShowForm("")
        if (!!isDetails) {
            dispatch(getDetailsMerchant({ id: stateForm.id }))
        } else {
            dispatch(getListMerchant({ 
                filterFrom: '',
                filterSkip: 0,
                filterSize: 20
            }))
        }
    }

    const _handleSubmit = async () => {
        const { name, category, address, email, phoneNumber, id } = stateForm
        const dataRequest = {
            id,
            name,
            category,
            address,
            email,
            phoneNumber,
        }
        if (merchantImg) {
            const base64 = await fileToBase64(merchantImg)
            dataRequest.logo = base64.split(',')[1]
        }

        dispatch(updateMerchant(dataRequest, callbackSuccess))

    }

    useOnClickOutside(refEditMerchant, () => setKeyShowForm(""))


    return (
        <OverlayFullScreen onClick={e => e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupEditMerchant ref={refEditMerchant}>
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <p className="title">Thông tin Merchant</p>
                    <p className="description">Cập nhât thông tin Merchant</p>
                    <div className="image">
                        <DragAndDropImage
                            defaultUrl={apiMerchantLogo(merchantData.id)}
                            handleRemoveFile={() => setMerchantImg(null)}
                            handleDropFiles={files => setMerchantImg(files[0])}
                        />
                    </div>
                    <InputName
                        valueInput={stateForm.name}
                        onChange={handleChangeValue}
                    />
                    <InputDropdownStore
                        valueDropdown={_handleSetValueDropdown}
                        categoryProps={merchantData.category}
                    />
                    <InputLocation
                        valueInput={stateForm.address}
                        onChange={handleChangeValue}
                    />
                    <InputEmail
                        valueInput={stateForm.email}
                        onChange={handleChangeValue}
                    />
                    <InputPhone
                        valueInput={stateForm.phoneNumber}
                        onChange={handleChangeValue}
                    />
                    <Button
                        text="Chỉnh sửa"
                        iconBtn={<IcEditWhite />}
                        className="btn-creteNew"
                        onClick={_handleSubmit}
                        isDisable={isDisabledBtnCreate}
                    />
                </WrapPopupEditMerchant>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupEditMerchant
