import styled from 'styled-components'

const WrapDeviceAdminByStatusView = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .wrap-list{
        flex-grow: 1;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;

        .wrap-empty {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .wrap-inner-list{   
            position: absolute;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            column-gap: 4px;
            row-gap: 6px;
        }
    }
`;

export {
    WrapDeviceAdminByStatusView
}