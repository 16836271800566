import * as types from "./types";

const initialState = {
    list: [],
    maxAmount: 0
};

const analytic = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DATA_ANALYTIC:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export default analytic;
