import React, { useState, useMemo } from 'react'

// styles
import { WrapBankDetails } from './index.styles'

// component
import TabListAndTool, { ACCOUNT, listTabs } from './TabListAndTool'
import ListAccounts from './ListAccounts'
import GeneralInfosBank from './GeneralInfosBank'

const BankDetails = () => {   

    const [activeTab, setActiveTab] = useState(listTabs[0]);
        
    const listData = useMemo(() => {
        const { type } = activeTab
        switch (type) {
            case ACCOUNT:
                return <ListAccounts />
            default:
                return null
        }
    }, [activeTab])

    return (
        <WrapBankDetails>
            <div className="wrap-head">
                <GeneralInfosBank />
                <TabListAndTool
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
            {listData}
        </WrapBankDetails>
    )
}

export default BankDetails
