import React from 'react'

const IcButtonLogin = ({...props}) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.33337 9.91666L9.25004 6.99999L6.33337 4.08333" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.25 7H2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.25 1.75H11.5833C11.8928 1.75 12.1895 1.87292 12.4083 2.09171C12.6271 2.3105 12.75 2.60725 12.75 2.91667V11.0833C12.75 11.3928 12.6271 11.6895 12.4083 11.9083C12.1895 12.1271 11.8928 12.25 11.5833 12.25H9.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcButtonLogin
