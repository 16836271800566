import * as types from './types'

const initialState = {
    details: {}
}
const generalInfosReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_GENERAL_BANK_INFOS:
            return {
                ...state,
                ...action.payload
            }
        case types.RESET_GENERAL_BANK_INFOS:
            return initialState
        default:
            return state;
    }

}

export default generalInfosReducer