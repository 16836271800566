import styled from 'styled-components'

const WrapSideMenu = styled.div`
    box-shadow: inset -2px 0px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 130px 0 19px 0;

    .wrap-menu {
        width: 100%;
        flex-grow: 1;
        position: relative;
        .link-item{
            text-decoration: none;
            width: 100%;
            margin-bottom: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-left: 5px solid transparent;
            .icon{
                display: block;
            }
            .icon.active{
                display: none;
            }
            p{
                color: #313131;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                padding-top: 2px;
                width: 58px;
                text-align: center;
            }
            &.selected{
                border-left: 5px solid #33ADFF;
                /* box-sizing: border-box; */
                .icon.active{
                    display: block;
                }
                .icon{
                    display: none;
                }
            }
        }
    }
    
    .crafted {
        text-align: center;
        span {
            /* display: block; */
            font-family: Inter;
            font-size: 9px;
            line-height: 11px;
            color: #212123;
        }

        .ic-heart {
            margin: 0 3px;
        }
    }
`;

export {
    WrapSideMenu
}