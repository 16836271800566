import styled from 'styled-components'

// styles
import { WrapBankGenInfos } from '../../BankList/BankItem/index.styles';

const WrapGeneralInfos = styled(WrapBankGenInfos)`
    grid-template-columns: auto 2fr 0.5fr 0.5fr 0.5fr auto;
`;

export {
    WrapGeneralInfos
}