import Axios from 'axios'

//host
import { host } from '../../../../constant/host'

//help
import checkIsSuccessAction from '../../../../helper/checkIsSuccessAction';
import mapErrors from '../../../../helper/mapErrors';

//types
import * as types from './types'

const USERNAME = "USERNAME"
const FULLNAME = "FULLNAME"
const INTERVAL = "INTERVAL"

const apiGetListAccountBank = `${host}/supplier/account/filter`
const apiCreateAccountBank = `${host}/supplier/account/create`
const apiUpdateAccountBank = `${host}/supplier/account/update`
const apiLockAccountBank = `${host}/supplier/account/lock`
const apiUnlockAccountBank = `${host}/supplier/account/unlock`
const apiGetAccountBankSearchUsername = `${host}/supplier/account/search/byUsername`
const apiGetAccountBankSearchFullname = `${host}/supplier/account/search/byFullname`
const apiGetAccountBankSearchInterval = `${host}/supplier/account/search/byInterval`

export const getListAccountBank = ({ bankId, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess, callbackError) => async dispatch => {
    const condFilters = {
        bankId,
        searchValue: null,
        filterFrom,
        filterSkip,
        filterSize
    }

    const dataRequest = JSON.stringify({
        bankId,
        filterFrom,
        filterSkip,
        filterSize
    })

    try {
        const res = await Axios.post(apiGetListAccountBank, dataRequest)
        const { data } = res;
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_ACCOUNT_BANK,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_LIST_ACCOUNT_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.GET_LIST_ACCOUNT_BANK_ERR))
    }
}

export const resetListAccountBank = () => ({
    type: types.RESET_LIST_ACCOUNT_BANK
})

export const createAccountBank = ({ bankId, fullname, username }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        bankId,
        fullname,
        username,
    })

    try {
        const res = await Axios.post(apiCreateAccountBank, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CREATE_ACCOUNT_BANK,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.CREATE_ACCOUNT_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.CREATE_ACCOUNT_BANK_ERR))
    }
}

export const updateAccountBank = ({ id, fullname, pwd }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id,
        fullname,
        pwd
    })

    try {
        const res = await Axios.post(apiUpdateAccountBank, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UPDATE_ACCOUNT_BANK,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.UPDATE_ACCOUNT_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.UPDATE_ACCOUNT_BANK_ERR))
    }
}

export const lockAccountBank = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id
    })

    try {
        const res = await Axios.post(apiLockAccountBank, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.LOCK_ACCOUNT_BANK,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.LOCK_ACCOUNT_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.LOCK_ACCOUNT_BANK_ERR))
    }
}

export const unlockAccountBank = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id
    })

    try {
        const res = await Axios.post(apiUnlockAccountBank, dataRequest)
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UNLOCK_ACCOUNT_BANK,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.UNLOCK_ACCOUNT_BANK_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch (err) {
        dispatch(mapErrors(err, types.UNLOCK_ACCOUNT_BANK_ERR))
    }
}

export const getAccountBankSearchUsername = ({ bankId, username, filterFrom, filterSkip, filterSize }, callbackSuccess) => async dispatch => {

    const condFilters = {
        bankId,
        searchValue: { value: username },
        keyFilter: USERNAME,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        bankId,
        username,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetAccountBankSearchUsername, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_ACCOUNT_BANK_SEARCH_USERNAME,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_ACCOUNT_BANK_SEARCH_USERNAME_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_ACCOUNT_BANK_SEARCH_USERNAME_ERR))
    }
}

export const getAccountBankSearchFullname = ({ bankId, fullname, filterFrom, filterSkip, filterSize }, callbackSuccess) => async dispatch => {

    const condFilters = {
        bankId,
        searchValue: { value: fullname },
        keyFilter: FULLNAME,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        bankId,
        fullname,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetAccountBankSearchFullname, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_ACCOUNT_BANK_SEARCH_FULLNAME,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_ACCOUNT_BANK_SEARCH_FULLNAME_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_ACCOUNT_BANK_SEARCH_FULLNAME_ERR))
    }
}

export const getAccountBankSearchInterval = ({ bankId, startTime, closeTime, filterFrom, filterSkip, filterSize }, callbackSuccess) => async dispatch => {

    const condFilters = {
        bankId,
        searchValue: { startTime, closeTime },
        keyFilter: INTERVAL,
        filterFrom,
        filterSkip,
        filterSize,
    }

    const dataRequest = JSON.stringify({
        bankId,
        startTime,
        closeTime,
        filterFrom,
        filterSkip,
        filterSize,
    })

    try {
        const res = await Axios.post(apiGetAccountBankSearchInterval, dataRequest);
        const { data } = res
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_ACCOUNT_BANK_SEARCH_INTERVAL,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_ACCOUNT_BANK_SEARCH_INTERVAL_ERR,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {
        dispatch(mapErrors(error, types.GET_ACCOUNT_BANK_SEARCH_INTERVAL_ERR))
    }
}