import Axios from 'axios'

//host
import { host } from '../../../../constant/host'

//help
import checkIsSuccessAction from '../../../../helper/checkIsSuccessAction';
import mapErrors from '../../../../helper/mapErrors';

//types
import * as types from './types'

const apiGetListAccountMerchant = `${host}/merchant/account/filter`
const apiCreateAccountMerchant = `${host}/merchant/account/create`
const apiEditAccountMerchant = `${host}/merchant/account/update`
const apiLockAccountMerchant = `${host}/merchant/account/lock`
const apiUnlockAccountMerchant = `${host}/merchant/account/unlock`
const apiGetAccountSearchByUserName = `${host}/merchant/account/search/byUsername`
const apiGetAccountSearchByFullName = `${host}/merchant/account/search/byFullname`
const apiGetAccountSearchByInterval = `${host}/merchant/account/search/byInterval`



export const getListAccountMerchant = ({ merchantId, filterFrom, filterSkip, filterSize }, isScroll) => async dispatch => {
    const condFilters = {
        merchantId, 
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetListAccountMerchant, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_LIST_ACCOUNT_MERCHANT,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_ACCOUNT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_ACCOUNT_MERCHANT_ERR))
    }
}

export const resetListAccountMerchant = () => ({
    type: types.RESET_LIST_ACCOUNT_MERCHANT
})

export const createAccountMerchant = ({ merchantId, fullname, username }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        merchantId, 
        fullname, 
        username,
    })

    try {
        const res = await Axios.post(apiCreateAccountMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.CREATE_ACCOUNT_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.CREATE_ACCOUNT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.CREATE_ACCOUNT_MERCHANT_ERR))
    }
}

export const editAccountMerchant = ({ id, fullname }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id, 
        fullname,
    })

    try {
        const res = await Axios.post(apiEditAccountMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UPDATE_ACCOUNT_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.UPDATE_ACCOUNT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.UPDATE_ACCOUNT_MERCHANT_ERR))
    }
}

export const lockAccountMerchant = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id
    })

    try {
        const res = await Axios.post(apiLockAccountMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.LOCK_ACCOUNT_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.LOCK_ACCOUNT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.LOCK_ACCOUNT_MERCHANT_ERR))
    }
}

export const unlockAccountMerchant = ({ id }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        id
    })

    try {
        const res = await Axios.post(apiUnlockAccountMerchant, dataRequest)
        const { data } = res
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.UNLOCK_ACCOUNT_MERCHANT,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.UNLOCK_ACCOUNT_MERCHANT_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.UNLOCK_ACCOUNT_MERCHANT_ERR))
    }
}

export const getListAccountSearchUserName = ({ merchantId, username, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        merchantId,
        username, 
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetAccountSearchByUserName, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_ACCOUNT_MERCHANT_SEARCH_USERNAME,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_ACCOUNT_MERCHANT_SEARCH_USERNAME_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_ACCOUNT_MERCHANT_SEARCH_USERNAME_ERR))
    }
}

export const getListAccountSearchFullName = ({ merchantId, fullname, filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        merchantId,
        fullname, 
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetAccountSearchByFullName, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_ACCOUNT_MERCHANT_SEARCH_FULLNAME_ERR))
    }
}

export const getListAccountSearchInterval = ({ merchantId, startTime, closeTime,  filterFrom, filterSkip, filterSize }, isScroll, callbackSuccess) => async dispatch => {
    const condFilters = {
        merchantId,
        startTime, 
        closeTime,
        filterFrom, 
        filterSkip, 
        filterSize 
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetAccountSearchByInterval, dataRequest)
        const { data } = res;
        if(checkIsSuccessAction(data.code)) {
            dispatch({
                type: types.GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL,
                payload: {
                    list: data.accountList || [],
                    total: data.total || 0,
                    condFilters,
                    isScroll
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_ACCOUNT_MERCHANT_SEARCH_INTERVAL_ERR))
    }
}