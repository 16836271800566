import * as types from './types'

export const ACCOUNT = 'ACCOUNT'

export const listTabs = [
    {
        type: ACCOUNT,
        name: 'Account'
    },
]

const initialState = {
    activeTab: listTabs[0],
    list: [],
    condFilters: {},
    total: 0
}
const listSettingReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload.activeTab
            }
        case types.GET_LIST_SETTING_REPORT:
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll 
                    ? [...state.list, ...action.payload.list]
                    : action.payload.list
            }
        case types.RESET_LIST_SETTING_REPORT:
            return {
                ...initialState,
                activeTab: state.activeTab
            }
        default:
            return state;
    }

}

export default listSettingReportReducer