import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//component
import FilterControl from '../../../../Control/FilterControl'
import DropdownSelectTypeSearch, { dataSelection, DEVICE_ID, STATUS_ID } from './DropdownSelectTypeSearch'
import SelectStatus from './SelectStatus'

//styles
import { InputFilter } from '../../../../Control/FilterControl/index.styles';
import { WrapSelectStatus } from './index.styles';

// action
import { getListDevicesByAdmin, getListDevicesByAdminById, getListDevicesByAdminByStatus } from '../../../../../redux/device/listDevicesByAdmin/action';

// data
import { mapStatusToNum } from '../../../../../data/dataDeviceStatus'

// constant
import { GET_LIST_DEVICES_BY_ADMIN } from '../../../../../redux/device/listDevicesByAdmin/types'

const FilterDevices = () => {
    const dispatch = useDispatch()
    const inputSearchRef = useRef(null)

    const { type, isStatusView } = useSelector(state => ({
        type: state.deviceReducer.listDevicesByAdmin.type,
        isStatusView: state.deviceReducer.listDevicesByAdmin.isStatusView
    }))

    const [filterCond, setFilterCond] = useState(dataSelection[0]);
    const [idSearch, setIdSearch] = useState("");
    const [status, setStatus] = useState(null);
    
    useEffect(() => {
        setIdSearch("")
        setStatus(null)
    }, [filterCond]);

    useEffect(() => {
        if(type === GET_LIST_DEVICES_BY_ADMIN) {
            setIdSearch("")
            setStatus(null)
        }
    }, [type]);

    const dropdownFilter = useMemo(() => (
        <DropdownSelectTypeSearch 
            filterCond={filterCond} 
            setFilterCond={setFilterCond} 
        />
    ), [filterCond, setFilterCond])
    
    const _handleSearch = () => {
        const { key } = filterCond
        if(!idSearch && !status) {
            dispatch(getListDevicesByAdmin({
                filterFrom: '',
                filterSkip: 0,
                filterSize: isStatusView ? -1: 20
            }))
            return;
        }
        switch(key) {
            case DEVICE_ID: {
                dispatch(getListDevicesByAdminById({
                    id: idSearch,
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize: isStatusView ? -1: 20
                }))
                break;
            }
            case STATUS_ID: {
                dispatch(getListDevicesByAdminByStatus({
                    status: mapStatusToNum[status.type],
                    filterFrom: '',
                    filterSkip: 0,
                    filterSize: isStatusView ? -1: 20
                }))
                break;
            }
            default:
                return;
        }
    }

    const _handleSelectStatus = (val) => {
        setStatus(val)
    }

    const filterComponent = useMemo(() => {
        const { key, text } = filterCond
        switch(key) {
            case DEVICE_ID: {
                return  <InputFilter
                    type="text"
                    placeholder={`Nhập ${text}...`}
                    ref={inputSearchRef}
                    onKeyPress={(e) => {
                        if(e.key === 'Enter') {
                            _handleSearch()
                        }
                    }}
                    onChange={e => setIdSearch(e.target.value)}
                />
            }
            case STATUS_ID: {
                return <WrapSelectStatus>
                    <SelectStatus 
                        objValue={status}
                        handleSelectStatus={_handleSelectStatus}
                    />
                </WrapSelectStatus>
            }
            default: 
                return null;
        }
    }, [JSON.stringify(filterCond), JSON.stringify(status)])

    return (
        <FilterControl 
            dropdownFilter={dropdownFilter}
            filterComponent={filterComponent}
            handleSearch={_handleSearch}
        />
    )
}

export default FilterDevices
