import React, { useMemo } from 'react'
// 100 - 500
// 20

const Y_OF_LABEL = 25
const Y_OF_CHART = 50
export const listColors = ["#33ADFF", "#FFBA33", "#F45C5C", "#5033FF", "#15BA39"]
export const legends = ["10,000 - 50,000", "50,001 - 100,000", "101,000 - 200.000", "201,000 - 500,000", ">= 501,000"]

const ChartPercentage = ({ listPercentage }) => {
    const dataBySvg = useMemo(() => listPercentage.map(item => item*600/100), [JSON.stringify(listPercentage)])
    const dataSvg = useMemo(() => dataBySvg.map((width, index) => {
        if(width === 0) return null
        if(index === 0) {
            return {
                dataText: {
                    x: width/2 - (String(listPercentage[index]).length + 1) * 4,
                    y: Y_OF_LABEL
                },
                dataLine: {
                    x1: 0,
                    y1: Y_OF_CHART,
                    x2: width,
                    y2: Y_OF_CHART
                }
            }
        }
        const sumLastWidth = dataBySvg.slice(0, index).reduce((sum, num) => sum + num)
        return {
            dataText: {
                x: sumLastWidth + width/2 - (String(listPercentage[index]).length + 1) * 4,
                y: Y_OF_LABEL
            },
            dataLine: {
                x1: sumLastWidth,
                y1: Y_OF_CHART,
                x2: sumLastWidth + width,
                y2: Y_OF_CHART
            }
        }
    }), [JSON.stringify(dataBySvg)])

    return (
        <svg viewBox="0 0 620 100">
            <g fill="none" style={{padding: "10px"}}>
            {
                dataSvg.map((item, index) => {
                    if(!item) return null
                    const { dataText, dataLine } = item
                    return (
                        <g fill="none" key={index}>
                            <text {...dataText} fill="#313131" className="label">{listPercentage[index]}%</text>
                            <line {...dataLine} stroke={listColors[index]} strokeWidth={10} />
                        </g>
                    )
                })
            }
            </g>
            Sorry, your browser does not support inline SVG.
        </svg>
    )
}

export default ChartPercentage
