import styled from 'styled-components'

const WrapPopupDel = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;

    .title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #313131;
    }

    .sub-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #313131;

        span {
            font-weight: 600;
        }
    }

    .notice {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #F45C5C;
    }

    & > button {
        width: 100%;
    }

    .btn-del {
        background: #F45C5C;
    }

    .btn-cancel {
        color: #777777;
        background: #F7F7F7;
    }
`;

const WrapPopupDelMerchant = styled(WrapPopupDel)`
    width: 242px;

    .sub-title {
        margin-top: 10px;
    }

    .notice {
        margin-top: 25px;
    }

    .btn-del {
        margin-top: 30px;
    }

    .btn-cancel {
        margin-top: 10px;
    }
`;

export {
    WrapPopupDel,
    WrapPopupDelMerchant
}