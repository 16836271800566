import React, { useState } from 'react'

//component
import IcDelete from '../../../../../../../../assets/images/IcDetete'
import IcEdit from '../../../../../../../../assets/images/IcEdit'
import MoreOption from '../../../../../../../Control/MoreOption'
import Portal from '../../../../../../../Control/Portal'
import PopupEditAdvertisement from './PopupEditAdvertisement'
import PopupDelAdvertisement from './PoupDelAdvertisement'

const EDIT = 'EDIT'
const DEL = 'DEL'

const OptionAdvertisement = ( {dataAdvertisement, refList} ) => {
    const [keyShowForm, setKeyShowForm] = useState('')

    const handleEdit = () =>{
        setKeyShowForm(EDIT)
    }

    const handleDelete = () =>{
        setKeyShowForm(DEL)
    }

    const handleClose = (e) => {
        e.stopPropagation()
        setKeyShowForm('')
    }

    const dataMoreOption =  [
        {
            Icon : IcEdit,
            text : "Tùy chỉnh",
            handleOption :  handleEdit
        },
        {
            Icon : IcDelete,
            text : "Xóa quảng cáo",
            handleOption :  handleDelete
        },
    ]

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
            />
            {
                keyShowForm === DEL &&
                <Portal>
                    <PopupDelAdvertisement 
                        dataAdvertisement={dataAdvertisement}
                        handleClose={handleClose}
                        setKeyShowForm={setKeyShowForm}
                    />
                </Portal>
            }
            {
                keyShowForm === EDIT &&
                <Portal>
                    <PopupEditAdvertisement
                        dataAdvertisement={dataAdvertisement}
                        handleClose={handleClose}
                        setKeyShowForm={setKeyShowForm}
                    />
                </Portal>
            }
        </>
    )
}

export default OptionAdvertisement
