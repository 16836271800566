import moment from 'moment';

const hour = '23'
const minute = '59'
const second = '59'

export const formatTimeEndDate = (endDate) =>{
    // const time = moment(endDate).set("hour", hour).set("minute", minute).set("second", second);
    const result = moment(endDate).endOf('day').toDate()
    return result;
}