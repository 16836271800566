import React from 'react'

// component
import DropdownControl from '../../../../../Control/DropdownControl'

export const DEVICE_ID = "1"
export const STATUS_ID = "2"
// export const AREA_ID = "3"

export const dataSelection = [
    {
        text: "DeviceID",
        key: DEVICE_ID
    },
    {
        text: "Trạng thái",
        key: STATUS_ID
    },
    // {
    //     text: "Khu vực",
    //     key: AREA_ID
    // },
]

const DropdownSelectTypeSearch = ({ filterCond: { text, key }, setFilterCond }) => {

    const _handleSetValueSelect = (val) => {
        setFilterCond(val)
    }

    return (
        <DropdownControl 
            dataSelection={dataSelection}
            valueSelected={{ text, key }}
            handleSetValueSelect={_handleSetValueSelect}
        />
    )
}

export default DropdownSelectTypeSearch
