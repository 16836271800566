import styled from 'styled-components'

const WrapTabListAndTool = styled.div`
    display: flex;
    align-items: flex-end;

    .wrap-tab {
        display: flex;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.01em;
        
        .tab {
            padding: 0 8px 8px;
            user-select: none;

            &:not(.active) {
                color: #B7B7B7;
            }
    
            &.active {
                color: #313131;
                box-shadow: inset 0px -2px 0px #33ADFF;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .wrap-tool {
        flex-grow: 1;
        padding-bottom: 13px;
    }
`;

export {
    WrapTabListAndTool
}