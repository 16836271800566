import React from 'react'

const IcSettingDefault = (props) => {
    return (
        <svg {...props} width={141} height={140} viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="70.5" cy={70} r={70} fill="#B7B7B7" />
            <path d="M107.167 99.9998V93.3331C107.164 90.3789 106.181 87.509 104.371 85.1742C102.561 82.8393 100.027 81.1717 97.1667 80.4331" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M87.1667 100V93.3333C87.1667 89.7971 85.7619 86.4057 83.2614 83.9052C80.7609 81.4048 77.3696 80 73.8333 80H47.1667C43.6305 80 40.2391 81.4048 37.7386 83.9052C35.2381 86.4057 33.8333 89.7971 33.8333 93.3333V100" fill="#B7B7B7" />
            <path d="M83.8333 40.4331C86.7014 41.1674 89.2435 42.8354 91.0588 45.1741C92.8741 47.5128 93.8595 50.3892 93.8595 53.3498C93.8595 56.3103 92.8741 59.1867 91.0588 61.5254C89.2435 63.8641 86.7014 65.5321 83.8333 66.2664" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M60.5 66.6667C67.8638 66.6667 73.8333 60.6971 73.8333 53.3333C73.8333 45.9695 67.8638 40 60.5 40C53.1362 40 47.1667 45.9695 47.1667 53.3333C47.1667 60.6971 53.1362 66.6667 60.5 66.6667Z" fill="#B7B7B7" />
        </svg>
    )
}

export default IcSettingDefault
