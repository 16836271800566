import styled from 'styled-components'

// styles
import { WrapItem } from '../../ListStores/StoreItem/index.styles';

const WrapAdvertisementItem = styled(WrapItem)`
    
`;

export {
    WrapAdvertisementItem
}