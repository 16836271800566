import React from 'react'

const IcUsers = (props) => {
    return (
        <svg {...props} width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5 21.4999V19.4999C23.4993 18.6136 23.2044 17.7527 22.6614 17.0522C22.1184 16.3517 21.3581 15.8515 20.5 15.6299" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 21.5V19.5C17.5 18.4391 17.0786 17.4217 16.3284 16.6716C15.5783 15.9214 14.5609 15.5 13.5 15.5H5.5C4.43913 15.5 3.42172 15.9214 2.67157 16.6716C1.92143 17.4217 1.5 18.4391 1.5 19.5V21.5" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.5 3.62988C17.3604 3.85018 18.123 4.35058 18.6676 5.05219C19.2122 5.7538 19.5078 6.61671 19.5078 7.50488C19.5078 8.39305 19.2122 9.25596 18.6676 9.95757C18.123 10.6592 17.3604 11.1596 16.5 11.3799" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.5 11.5C11.7091 11.5 13.5 9.70914 13.5 7.5C13.5 5.29086 11.7091 3.5 9.5 3.5C7.29086 3.5 5.5 5.29086 5.5 7.5C5.5 9.70914 7.29086 11.5 9.5 11.5Z" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcUsers
