import React from 'react'
import IcInputUser from '../../../../../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../../../../../assets/images/IcInputUserError'
import Input from '../../../../../../../../Control/Input'


const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

const InputUser = ({ valueInput, onChange, placeholder, ...props }) => {
    return (
        <Input
            type="text"
            className="input-merchant"
            placeholder={placeholder}
            inputType="TEXT"
            value={valueInput}
            onChange={onChange}
            iconInput={iconUser}
            {...props}
        />
    )
}

export default InputUser
