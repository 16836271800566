import styled from 'styled-components'

const WrapMain = styled.div`
    min-width: 1280px;
    min-height: 100%;
    width: 100%;
    background: #F7F7F7;
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 60px 1fr;

    .header {
        grid-column: 1/-1;
    }
`;

export {
    WrapMain
}