// lỗi OCB bank trả về
export const dataErrorOCB = {
    ["105101"]: {text: "Mã giao dịch của đối tác đã tồn tại"},
    ["105131"]: {text: "Kết nối OCB thất bại do lỗi hệ thống ngân hàng"},
    ["105151"]: {text: "Kết nối OCB thất bại do lỗi hệ thống ngân hàng"},
    ["105132"]: {text: "Kết nối OCB thất bại do giao dịch chưa hoàn tất đối soát từ ngân hàng"},
    ["105133"]: {text: "Tài khoản nguồn OCB không thể sử dụng."},
    ["105135"]: {text: "Số tiền vượt quá hạn mức/ngày,"},
    ["105136"]: {text: "Số tiền vượt quá hạn mức/giao dịch,"},
    ["105137"]: {text: "Số dư tài khoản không đủ để thực hiện giao dịch. Quý khách vui lòng kiểm tra lại"},
    ["105138"]: {text: "Vượt hạn mức giao dịch> 2tr. Khách hàng thử lại trong hạn mức OCB cho phép"},
    ["105139"]: {text: "Tài khoản ebanking không hợp lệ do số dư không khả dụng"},
    ["105141"]: {text: "tài khoản thanh toán không hợp lệ do lỗi phát sinh từ OCB",},
    ["105142"]: {text: "Số điện thoại không trùng với số điện thoại đăng ký",},
    ["105143"]: {text: "Giao dịch đã được hoàn tiền trước đó. Quý khách vui lòng thử lại"},
    ["105144"]: {text: "Giao dịch bị Timeout hoăc không xác định do lỗi hệ thống ngân hàng"},
    ["105145"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["105146"]: {text: "Giao dịch có lỗi phát sinh từ hệ thống ngân hàng ocb"},
    ["105147"]: {text: "Giao dịch có lỗi phát sinh từ hệ thống ngân hàng ocb"},
    ["105148"]: {text: "Kết nối Facepay thất bại do lỗi hệ thống ngân hàng"},
    ["105149"]: {text: "Gói dịch vụ OCB OMNI không hợp lệ. KH cần nâng cấp gói với OCB trước khi thanh toán hoặc liên hệ tổng đài OCB",},
    ["105152"]: {text: "Kết nối Facepay thất bại do chưa kích hoạt face otp"},
    ["105153"]: {text: "Gói dịch vụ OCB OMNI không hợp lệ. KH cần nâng cấp gói với OCB trước khi thanh toán hoặc liên hệ tổng đài OCB"},
    ["105155"]: {text: "Số tiền vượt quá hạn mức/giao dịch"},
    ["06"]: {text: "Tài khoản bị khóa từ hệ thống CSKH Facepay"},
    ["10"]: {text: "Kết nối OCB thất bại, cần bật tính năng face otp trong ứng dụng ocb omni sử dụng"},
    ["21"]: {text: "Kết nối OCB thất bại, hệ thống không nhận dạng được khuôn mặt, khách hàng cần thử lại"},
    ["43"]: {text: "Chưa kích hoạt dịch vụ FaceOTP"},
    ["-99"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-100"]: {text: "Giao dịch không thành công do chưa hoàn tất."},
    ["-101"]: {text: "Giao dịch thất bại do lỗi hệ thống ngân hàng"},
    ["-102"]: {text: "Khách hàng chưa nhập đúng số điện thoại"},
    ["-103"]: {text: "Khách hàng cancel giao dịch do không được áp dụng khuyến mãi"},
    ["5006"]: {text: "Tài khoản Facepay đang bị khóa "},
    ["5010"]: {text: "Khuôn mặt chưa được đăng ký"},
    ["5021"]: {text: "Không tìm thấy khuôn mặt trùng khớp với khuôn mặt trong hệ thống"},
    ["5040"]: {text: "Lỗi hệ thống Facepay: Yêu cầu không hợp lệ"},
    ["5043"]: {text: "Lỗi hệ thống Facepay: không gửi được yêu cầu"},
    ["5046"]: {text: "Lỗi hệ thống Facepay: yêu cầu không được chấp nhận"},
    ["5053"]: {text: "Dịch vụ Facepay hiện không khả dụng."},
    ["5008"]: {text: "Lỗi hệ thống Facepay: Yêu cầu không hợp lệ"},
    ["5011"]: {text: "Lỗi hệ thống Facepay: Yêu cầu không hợp lệ"},

    ["default"]: {text: "Giao dịch không thành công"},

}