import React from 'react'

// styles
import { WrapButton } from './index.styles'

const Button = ({ Icon, children, _handleOnClick }) => {
    return (
        <WrapButton onClick={_handleOnClick}>
            {Icon}
            {children}
        </WrapButton>
    )
}

export default Button
