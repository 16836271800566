import styled from 'styled-components'
import { WrapPopupCreate } from '../../../../../MerchantDetails/TabListAndTool/ToolAccount/PopupCreateAccount/index.styles';

const WrapPopupEditDevice = styled(WrapPopupCreate)`
    width: 300px;

    .sub-title {
        margin-top: 10px;
    }

    .device-name {
        margin-top: 33px;
    }
`;

export {
    WrapPopupEditDevice
}