import styled from "styled-components";


const WrapInputDropdown = styled.div`
    position: relative;
    .wrap-input{
        height: 40px;
        border: 0.5px solid #B7B7B7;
        box-sizing: border-box;
        border-radius: 4px;  
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        padding: 0px 15px 0px 45px;
        align-items: center;
        cursor: pointer;
        width: 100%;
        &.active{
            border: 0.5px solid #33adff;
        }
        .icon-left{
            position: absolute;
            left: 10px;
            top: 10px;
        }
        .placeholder{
            font-family: "Open Sans";
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #B7B7B7;
        }
        .text-dropdown{
            font-family: "Open Sans";
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .arrow-down{
            cursor: pointer;
            flex-shrink: 0;
        }
        .wrap-dropdown{
            width: 100%;
            z-index: 10;
            top: calc(100% + 2px);
        }
    }
`

export {WrapInputDropdown};