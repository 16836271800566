import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
//style
import { WrapTransactionHistory } from "./index.styles";
// component
import PageHeader from "../PageHeader";
import TransHistoryItem from "./TransHistoryItem";
import EmptyDataControl from "../../../../Control/EmptyDataControl";
//action
import {
  clearTransactionHistory,
  getTransactionHistory,
} from "../../../../../redux/transaction/transactionHistory/action";
import { getDetailTransaction } from "../../../../../redux/transaction/transactionDetail/action";
import useScrollEnd from "../../../../../hook/useScrollEnd";
import useResizeContentTable from "../../../../../hook/useResizeContentTable";

//image
import IcNoDataDefault from "../../../../../assets/images/IcNoDataDefault";

const TransactionHistory = () => {
  const { facepayId, biometricId } = useParams();

  const dispatch = useDispatch();
  const listRef = useRef();
  const wrapTable = useRef();
  const refHeadTable = useRef();

  const [expandId, setExpandId] = useState("");

  const { matchedTo, transactionHistory, totalTransHis } = useSelector(
    (state) => ({
      matchedTo:
        state.transactionReducer.transactionDetailReducer.details.matchedTo,
      transactionHistory:
        state.transactionReducer.transactionHistoryReducer.list,
      totalTransHis: state.transactionReducer.transactionHistoryReducer.total,
    })
  );

  useEffect(() => {
    if (!matchedTo) dispatch(getDetailTransaction({ id: facepayId }));
    dispatch(
      getTransactionHistory({
        biometricId: biometricId,
        filterFrom: "",
        filterSkip: 0,
        filterSize: 20,
      })
    );
  }, []);

  useScrollEnd(
    listRef,
    (callbackSuccess) => {
      dispatch(
        getTransactionHistory(
          {
            biometricId: biometricId,
            filterFrom: transactionHistory[transactionHistory.length - 1]?.id,
            filterSkip: transactionHistory.length,
            filterSize: 20,
          },
          true,
          callbackSuccess
        )
      );
    },
    [transactionHistory],[transactionHistory]
  );

  useEffect(() => {
    return () => {
      dispatch(clearTransactionHistory());
    };
  }, []);

  useResizeContentTable(wrapTable, 30, refHeadTable, listRef, [
    transactionHistory.length,
  ]);

  return (
    <WrapTransactionHistory ref={wrapTable}>
      {transactionHistory?.length === 0 ? (
        <div className="wrap-empty">
          <EmptyDataControl
            icon={<IcNoDataDefault />}
            text="Chưa có lịch sử giao dịch"
          />
        </div>
      ) : (
        <>
          <PageHeader ref={refHeadTable}>
            Lịch sử giao dịch ({totalTransHis ? totalTransHis : 0})
          </PageHeader>
          <div className="wrap-list-history" ref={listRef}>
            <div className="list-history">
              {transactionHistory.map((item, i) => (
                <TransHistoryItem
                  key={i}
                  data={item}
                  isExpand={item.id === expandId}
                  setIsExpand={() =>
                    setExpandId((pre) => (pre === item.id ? "" : item.id))
                  }
                />
              ))}
            </div>
          </div>
        </>
      )}
    </WrapTransactionHistory>
  );
};

export default TransactionHistory;
