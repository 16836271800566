export const GET_LIST_MERCHANT = 'GET_LIST_MERCHANT'
export const GET_LIST_MERCHANT_ERR = 'GET_LIST_MERCHANT_ERR'

export const GET_LIST_CATEGORIES = 'GET_LIST_CATEGORIES'
export const GET_LIST_CATEGORIES_ERR = 'GET_LIST_CATEGORIES_ERR'

export const CREATE_NEW_MERCHANT = 'CREATE_NEW_MERCHANT'
export const CREATE_NEW_MERCHANT_ERR = 'CREATE_NEW_MERCHANT_ERR'

export const UPDATE_MERCHANT = 'UPDATE_MERCHANT'
export const UPDATE_MERCHANT_ERR = 'UPDATE_MERCHANT_ERR'

export const DELETE_MERCHANT = 'DELETE_MERCHANT'
export const DELETE_MERCHANT_ERR = 'DELETE_MERCHANT_ERR'

export const RESET_LIST_MERCHANT = 'RESET_LIST_MERCHANT'