import React from 'react'

const IcCreateNew = ({...props}) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 2.91699V11.0837" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.91699 7H11.0837" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCreateNew
