import { combineReducers } from "redux";

import customerCountReducer from './customerCount/customerCountReducer'
import listCustomerReducer from './listCustomer/listCustomerReducer'
import customerDetailReducer from "./customerDetail/customerDetailReducer";
import customerHistoryReducer from "./customerHistory/customerHistoryReducer";

const customerReducer = combineReducers({
    customerCountReducer,
    listCustomerReducer,
    customerDetailReducer,
    customerHistoryReducer,
})

export default customerReducer;