import styled from 'styled-components'
// styles
import { WrapPageItem } from '../TransactionsList/index.styles';

const WrapAnalyticPage = styled(WrapPageItem)`
    margin-bottom: 30px;
    
    .wrap-chart, .wrap-detail-column-selected {
        background: #FFFFFF;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    }

    .wrap-chart {
        padding: 12px 12px 14px 12px;
        .chart-tools {
            display: flex;
            align-items: center;

            .wrap-sort {
                display: flex;
                align-items: center;
                
                & > span {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #B7B7B7;
                }
            }

            .chart-title {
                flex-grow: 1;
                text-align: center;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
            }
        }

        .wrap-control-range {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            .left, .right {
                padding: 5px 10px;
                &:hover {
                    cursor: pointer;
                }
            }

            .left {
                transform: rotate(90deg);
            }

            span {
                /* margin-left: 8px; */
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #313131;
            }

            .right {
                /* margin-left: 8px; */
                transform: rotate(-90deg);
            }
        }
    }

    .wrap-detail-column-selected {
        /* min-height: 183px; */
        padding: 19px 18px 40px;

        .analytic {
            display: flex;
            align-items: center;
            font-family: 'Open Sans';
            font-style: normal;
            color: #313131;

            .analytic-title {
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
            }

            .time {
                margin-left: 5px;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
            }
        }

        .wrap-analytic-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 29px;

            .wrap-data-number {
                flex-basis: 40%;
                display: flex;
                justify-content: space-between;
                padding: 0 20px 0 12px;

                .wrap-data {
                    font-family: "Open Sans";
                    font-style: normal;

                    .value {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 33px;
                        color: #313131;
                        letter-spacing: -0.01em;


                        span {
                            color: #B7B7B7;
                        }
                    }

                    .unit {
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 15px;
                        color: #777777;
                        letter-spacing: -0.02em;
                    }
                }
            }

            .wrap-data-chart {
                flex-basis: 50%;
                .label {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.01em;
                }

                .wrap-chart-legend {
                    display: flex;
                    justify-content: space-between;

                    .legend {
                        display: flex;
                        align-items: center;
                        span:first-child {
                            margin-right: 3px;
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                        }
                        span:last-child {
                            font-family: "Open Sans";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 11px;
                            line-height: 16px;
                            color: #777777;
                            letter-spacing: -0.02em;
                        }
                    }
                }
            }
        }
    }
`;

export {
    WrapAnalyticPage
}