
export const convertNumberSttList = (x) => {
    const number = x + 1
    if (x < 9) {
        return "000" + number
    }
    if (x >= 9 || x < 99) {
        return "00" + number
    }
    if (x >= 99 || x < 999) {
        return "0" + number
    } else {
        return number
    }
};