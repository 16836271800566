import React, { useMemo } from 'react'

// styles
import { WrapTabListAndTool } from './index.styles'

// component

import ToolAccount from './ToolAccount'

export const ACCOUNT = 'ACCOUNT'

export const listTabs = [
    {
        type: ACCOUNT,
        name: 'Tài khoản'
    },
]

const TabListAndTool = ({
    activeTab: {
        type: activeTabType,
    },
    setActiveTab
}) => {

    const toolComponent = useMemo(() => {
        switch (activeTabType) {
            case ACCOUNT:
                return <ToolAccount />
            default:
                return null
        }
    })

    return (
        <WrapTabListAndTool>
            <div className="wrap-tab">
                {
                    listTabs.map(({ type, name }) => (
                        <div
                            key={type}
                            className={`tab${activeTabType === type ? ' active' : ''}`}
                            onClick={() => setActiveTab({ type, name })}
                        >
                            {name}
                        </div>
                    ))
                }
            </div>
            <div className="wrap-tool">
                {toolComponent}
            </div>
        </WrapTabListAndTool>
    )
}

export default TabListAndTool
