import React from 'react'

const IcArrowRight = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 10.5L8.75 7L5.25 3.5" stroke="#B7B7B7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcArrowRight
