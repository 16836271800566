import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns:  40px 90px 80px 82px 70px 100px 78px 85px 79px 76px 75px 14px;
    grid-gap: 20px;    
    align-items: center;
    height: 60px;    
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 6px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #313131;
    padding: 0 12px 0 18px;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        border: 1px solid #33ADFF;
    }
    div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        /* width: 100%; */
    }
    img{
        border-radius: 50%;
    }
    .status {
        text-align: center;
    }
    .merchant{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-merchant{
            height: 36px;
            .logo{
                height: 36px;
                width: 100%;
                object-fit: contain;
                border-radius: 50%;
            }
        }
    }

    .refund {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #777777;
        letter-spacing: -0.01em;

        span {
            color: #F45C5C;
        }
    }

    /* .icon-arrow{
        margin-left: 24px ;
    } */

    .avatar-user {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        .avatar {
            width: 36px;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .amount {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
    }
`
export { Bound }